import React from "react";
import Navbar from "./Navbar/Navbar";
import ImageSlider from "./ImageSlider/ImageSlider";
import TrendingCourses from "./TrendingCourses/TrendingCourses";
import CareerRelatedPrograms from "./CareerRelatedProgram/CareerRelatedPrograms";
import RecentAdditions from "./RecentAddition/RecentAdditions";
import TopCategories from "./TopCategories/TopCategories";
// import BlogPostsSection from "./BlogPostSection/BlogPostsSection";
import BlogComponent from "./BlogComponent/blog";
import Footer from "./Footer/Footer";
import Cards from "./Cards";
import GoogleReview from "./GoogleReview/Google_review";


function HomePage() {
  return (
    <>
      <Navbar />
      <ImageSlider />
      <Cards />
      <TrendingCourses />
      <CareerRelatedPrograms />
      <RecentAdditions />
      <TopCategories />
      {/* <BlogPostsSection /> */}
      <BlogComponent />
      <GoogleReview/>
      <Footer />
    </>
  );
}

export default HomePage;
