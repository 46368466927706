import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./contact.css";
import contact from "../../assets/contact-icon.png";

const Contact = () => {
  return (
    <div id="contact-page">
      <Navbar />
      <div className="container mt-3 mb-3 d-flex justify-content-center">
        <h1>Contact us</h1>
      </div>
      {/* <div className="container-fluid banner-img">
           <img src={contact_banner} alt="Digipaath contact us" srcset="" />
        </div> */}
      <div className="container contact-container pt-2">
        <div className="row">
          <div className="col-lg-6 col-sm-12 m-auto">
            <form
              action="https://formspree.io/f/xgegzkpj"
              method="POST"
              id="contact-form"
            >
              <div class="mb-3">
                <label for="fname" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="fname"
                  class="form-control"
                  id="fname"
                  placeholder="Enter your name"
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your e-mail"
                />
              </div>
              <div class="mb-3">
                <label for="message" class="form-label">
                  Query
                </label>
                <textarea
                  class="form-control"
                  id="message"
                  name="message"
                  rows="3"
                ></textarea>
              </div>
              <div id="contact-submit-btn">
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-sm-12 contact-banner">
            <img src={contact} alt="" srcset="" />
          </div>
          <div
            className="row mt-4 mb-5"
          >
            <div className="icon-sec mt-4">
              <div className="row d-flex">
                <div className="col-4">
                  <div className="d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-phone"></i>
                    <p className="mb-0 ms-2 font-roboto">
                      <b>Phone</b>
                      <br /> +91 8750731943
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center justify-content-center">
                    <i class="fa-regular fa-envelope"></i>
                    <p className="mb-0 ms-2 font-roboto">
                      <b>Email</b>
                      <br /> Shivam@digipaath.com
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-location-dot"></i>
                    <p className="mb-0 ms-2 font-roboto">
                      <b>Address</b>
                      <br />
                      17 Princess Road,Noida Sector 63
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
