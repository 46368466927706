// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import work from "../../assets/images/workwith1.png";
// import { FaChalkboardTeacher } from 'react-icons/fa'; // Importing a teacher icon from react-icons

// const Instructor = () => {
//   return (
//     <div style={{ backgroundColor: '#007bff', color: '#fff', padding: '50px 0' }}>
//       <Container>
//         <Row className="align-items-center text-center">
//           <Col xs={12} md={3}>
//           <img className="affiliate" src={work} alt="" srcset="" />
//           </Col>
//           <Col xs={12} md={9}>
//             <h1 className="display-4">Become An Instructor</h1>
//             <p className="lead">
//               Teach what you love! Turn what you know into an opportunity to help thousands advance their careers!
//             </p>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Instructor;
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "./Instructor.css";
import instructor from "../../assets/instructor.png";
import Cards from '../Cards';
import NavBar from '../Navbar/Navbar';
import TopCategories from '../TopCategories/TopCategories';
import GoogleReview from "../GoogleReview/Google_review";
import Footer from "../Footer/Footer";

const Instructor = () => {
  return (
    <>
    <NavBar />
    <div className='Container'>
    <div style={{ padding: '50px 0', backgroundColor: '#ff5722' }}>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={4}>
            <div  className='instructor' style={{ backgroundColor: '#ff5722', padding: '10px' }}>
              <img
                src={instructor}
                alt="Instructor"
                
              />
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-start mt-0 mt-md-0">
            <h2 className=" inst mb-4" >Become an instructor</h2>
            <p className=" inst1 mb-4" >
              Instructors from around the world teach millions of students on Udemy. We provide the tools and skills to teach what you love.
            </p>
           <a href='/modal-link'> <Button className='teaching' variant="dark" size="lg" >Start teaching today</Button></a>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
    <Cards />
    <TopCategories />
      <GoogleReview />
      <Footer />
    </>
  );
}

export default Instructor;

