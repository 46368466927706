import React, { useEffect,useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import "./Modal.css";

function ModalPage() {
  const [showSignUp, setShowSignUp] = useState(true);

  const toggleForm = () => {
    setShowSignUp(!showSignUp);
  };

  useEffect(() => {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div id="get-start" className="modal-page mt-5 mb-5">
        <div className="container" id="container">
          <div className="form-container sign-up-container">
            <form action="/">
              <h1>Create Account</h1>
              <div className="social-container">
                <Link to="/" className="social">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="/" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </Link>
                <Link to="/" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
              <span>or use your email for registration</span>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <button>SignUp</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form action="#">
              <h1>Sign in</h1>
              <div className="social-container">
                <Link to="/" className="social">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="/" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </Link>
                <Link to="/" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
              <span>or use your account</span>
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <Link to="/">Forgot your password?</Link>
              <button>Sign In</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>To keep connected with us please login with your personal info</p>
                <button className="ghost" id="signIn">
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button className="ghost" id="signUp">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto d-flex justify-content-center align-items-center">
          <Link to="/AdminPanel">
            <b className="teach mx-4">Admin SignIn/SignUp</b>
          </Link>
          <Link to="/TeacherPanel">
            <b className="teach ms-5">Teacher SignIn/SignUp</b>
          </Link>
        </div>
      </div>

      <div className="get-started-mobile">
      <div className="container">
        {showSignUp ? (
          <div className="sign-up mx-3 mt-3 mb-5 p-3 border-0 shadow">
            <form>
              <div>
                <h3 className="d-flex justify-content-center">Sign Up</h3>
              </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input type="text" className="form-control" id="username" aria-describedby="username" />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" />
              </div>
              <div className="d-flex justify-content-end">
                <p><a href="#" className="lg-form" onClick={toggleForm}>Already have an account?</a></p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">Sign Up</button>
              </div>
            </form>
          </div>
        ) : (
          <div className="login mx-3 mt-3 mb-5 p-3 border-0 shadow">
            <form>
              <div>
                <h3 className="d-flex justify-content-center">Sign In</h3>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" />
              </div>
              <div className="d-flex justify-content-end">
                <p><a href="#" className="sg-form" onClick={toggleForm}>Don't have an account?</a></p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">Sign In</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
      <Footer />
    </div>
  );
} 

export default ModalPage;
