import hero_img from "../assets/images/hero_img.png";
import logo_udemy from "../assets/images/logo_udemy.svg";

import aws_1 from "../assets/images/aws.jpg";
import aws_2 from "../assets/images/aws_2.jpg";
import aws_3 from "../assets/images/aws_3.jpg";
// Data Science Course Image
import data_science from "../assets/images/datascience.png";
import data_science_1 from "../assets/images/data_science_1.png";
import data_science_2 from "../assets/images/data_science_2.jpg";
import data_science_3 from "../assets/images/data_science_2.png";
import data_science_4 from "../assets/images/data_science_4.png";
import data_science_5 from "../assets/images/data_science_5.jpg";
//Programing course image
import programing_1 from "../assets/images/java.png";
import programing_2 from "../assets/images/javascripit.png";
import programing_3 from "../assets/images/php.png";
import programing_4 from "../assets/images/python.png";
import programing_5 from "../assets/images/c.png";
import web_dev_1 from "../assets/images/webdev.jpg";
import web_dev_2 from "../assets/images/web_dev_2.png";
import web_dev_3 from "../assets/images/reactweb.jpg";
import web_dev_4 from "../assets/images/software_testing.jpg";
import web_dev_5 from "../assets/images/web_dev_5.jpg";
import marketing_1 from "../assets/images/email-marketing.jpg";
import marketing_2 from "../assets/images/digital_market.jpg";
import design_1 from "../assets/images/graphic_design.jpg";
import design_2 from "../assets/images/graphic.jpg";
import cat_aws from "../assets/images/cat_aws.png";
import cat_data_science from "../assets/images/cat_data_science.png";
import cat_web_development from "../assets/images/cat_web_development.png";
import cat_design from "../assets/images/cat_design.png";
import cat_marketing from "../assets/images/cat_marketing.png";
import cat_python from "../assets/images/cat_python.png";
//Oracle Course Image
import oracle from "../assets/images/oracle.png"

//Andriod Devlopment

import andriod_dev_1 from "../assets/images/andriod-dev-1.jpg";

//ui-ux

import ui_ux from "../assets/images/ui-ux.jpg";

//ai

import ai from "../assets/images/ai.jpg"

//ML

import ml from "../assets/images/ML.png"

// Microsoft Course Image

import microsoft_azure from "../assets/images/microsoft-azure.jpg";
import microsoft_dynamic_365 from "../assets/images/microsoft-dynamic-365.jpg";
import microsoft_365 from "../assets/images/microsoft-365.jpg";
import microsoft_power_platform from "../assets/images/microsoft-power-platform.jpg";

//Google Course Image

import google_first from "../assets/images/google.jpg";

//Snowflake Course Image

import snowflake_first from "../assets/images/snowflake.jpg";

//HR Training Image

import hr_first from "../assets/images/HR-fundamentals.png";
import hr_second from "../assets/images/HR-Generalist.png";
import hr_third from "../assets/images/HR-Analytics.png";
import hr_fourth from "../assets/images/HR-Social-Compliance.png";
import hr_fifth from "../assets/images/HR-Payroll-Specialist.png";
import hr_sixth from "../assets/images/All-5-HR-certifications.png";


//Sap Course Image

import sap_first from "../assets/images/sap.jpg";
import sap_sec from "../assets/images/sap.png";




const course_images = {ai,andriod_dev_1,ui_ux,ml,data_science,oracle,hr_first,hr_second,hr_third,hr_fourth,hr_fifth,hr_sixth,sap_first,sap_sec,google_first,snowflake_first,microsoft_azure,microsoft_365,microsoft_dynamic_365,microsoft_power_platform,aws_1, aws_2, aws_3, data_science_1, data_science_2, data_science_3, data_science_4, data_science_5, web_dev_1, web_dev_2, web_dev_3, web_dev_4, web_dev_5, marketing_1, marketing_2, design_1, design_2,programing_1,programing_2,programing_3,programing_4,programing_5};

const other_images = {hero_img, logo_udemy};

const categories_images = [cat_python, cat_web_development,  cat_data_science, cat_aws, cat_design, cat_marketing ];

export {course_images, other_images, categories_images};