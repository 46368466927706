import React, { useState, useEffect } from "react";
import styles from "./TopCategories.module.css";

const categoriesData = [
  { icon: "bi-cloud", name: "Cloud Computing" },
  { icon: "bi-infinity", name: "Dev Ops" },
  { icon: "bi-lightbulb", name: "BI and Visualization" },
  { icon: "bi-shield-lock", name: "Cyber Security" },
  { icon: "bi-bar-chart-steps", name: "Data Science" },
  { icon: "bi-journals", name: "PG Programs" },
  { icon: "bi-plugin", name: "Software Testing" },
  { icon: "bi-graph-up-arrow", name: "Big Data" },
  { icon: "bi-headset-vr", name: "Artificial Intelligence" },
  { icon: "bi-code-slash", name: "Frontend Development" },
  { icon: "bi-database-fill-up", name: "Data Warehousing and ETL" },
  { icon: "bi-megaphone", name: "Digital Marketing" },
  { icon: "bi-window-dash", name: "Operating Systems" },
  { icon: "bi-phone", name: "Mobile Development" },
  { icon: "bi-box-seam-fill", name: "Architecture & Design Patterns" },
  { icon: "bi-braces", name: "Programming and Frameworks" },
  { icon: "bi-card-list", name: "Project Management and Methodologies" },
  { icon: "bi-database", name: "Databases" },
  { icon: "bi-robot", name: "Robotic Process Automation" },
  { icon: "bi-bezier", name: "Blockchain" },
];

function TopCategories() {
  const [visibleCategories, setVisibleCategories] = useState(6);
  const [expanded, setExpanded] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1510) {
      setVisibleCategories(7);
    } else if (windowWidth > 1000) {
      setVisibleCategories(6);
    } else if (windowWidth > 700) {
      setVisibleCategories(5);
    } else {
      setVisibleCategories(6); 
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleRows = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={styles["top-categories"]}>
        <div className="container discover-categories mt-5">
          <h3>Discover Top Categories</h3>
        </div>
        <div
          className={`container d-flex flex-column justify-content-left ${styles["container"]}`}
        >
          <div className={`row align-items-start bg-white py-4 ${styles["row"]}`}>
            {categoriesData.slice(0, expanded ? categoriesData.length : visibleCategories).map(
              (category, index) => (
                <div key={index} className={`col-md-4 col-sm-6 col-6 border ${styles["col"]}`}>
                  <i className={`bi ${category.icon}`}></i>
                  <p>{category.name}</p>
                </div>
              )
            )}
          </div>
        </div>

        <div className="text-center mt-1">
          <button
            onClick={toggleRows}
            id="viewMoreBtn"
            className={`btn btn-sm btn-light ${styles["btn"]}`}style={{background:" rgb(240, 76, 36)", borderRadius:"25px", fontWeight:"100px", textDecoration:"none", color:"white"}}
          >
            {expanded ? "View Less" : "View More"}
          </button>
        </div>
      </div>
    </>
  );
}

export default TopCategories;
