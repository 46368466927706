import React from "react";
import styles from "./Styling/Cards.module.css";
import "./Styling/card.css";
import { Link } from "react-router-dom";
function Cards() {
  return (
    <div id="cards" className={`container`}>
      <div className={`row`}>
        <div className={`col-sm-4 mb-3 mb-sm-0`}>
          <Link to="/Web-dev-20">
            <div className={`card border-0 shadow ${styles["pa-m"]}`}>
              <div className={`card-body ${styles["body"]}`}>
                <h5 className={`card-title`}>BECOME A Web Developer</h5>
                {/* <a href="/" className={`btn`}>
                Go somewhere
              </a> */}
              </div>
            </div>
          </Link>
        </div>
        <div className={`col-sm-4`}>
          <Link to="/canva">
            <div className={`card border-0 shadow ${styles["pa-m"]}`}>
              <div className={`card-body ${styles["body"]}`}>
                <h5 className={`card-title`}> BECOME A Graphic Designer</h5>
                {/* <a href="/" className={`btn`}>
                Go somewhere
              </a> */}
              </div>
            </div>
          </Link>
        </div>
        <div className={`col-sm-4`}>
          <Link to="/digital-marketing">
            <div className={`card border-0 shadow ${styles["pa-m"]}`}>
              <div className={`card-body ${styles["body"]}`}>
                <h5 className={`card-title`}> BECOME A Digital Marketer</h5>
                {/* <a href="/" className={`btn`}>
                Go somewhere
              </a> */}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Cards;
