import coprate_training_mobile from "../../assets/images/corporate-training-mob.jpg";
import cptraining from "../../assets/images/cptraining.jpg";
import Image1 from "../../assets/images/pencil-and-ruler.png";
import Image2 from "../../assets/images/analytics.png";
import Image3 from "../../assets/images/certificate.png";
import Image4 from "../../assets/images/project.png";
import Image5 from "../../assets/images/cloud-storage.png";
import Image6 from "../../assets/images/support.png";
import training9 from "../../assets/images/organic.jpg";
import training10 from "../../assets/images/online.jpg";
import training11 from "../../assets/images/selfpaced.jpg";
import training12 from "../../assets/images/blended.jpg";
import "./TrainingFeature.css";

const style1 = {
  filter:
    "invert(39%) sepia(63%) saturate(4102%) hue-rotate(350deg) brightness(98%) contrast(91%)",
};

function TrainingFeature() {
  return (
    <div id="Ct">
      <div className="corpfeatures">
        <h2 className="heading py-5">Digi Paath Corporate Training Features</h2>
        <div className="container py-5">
          <div className="cpt pb-5">
            <div className="container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                    <div
                      className="card border-0 shadow"
                      style={{ width: "20rem" }}
                    >
                      <div className="card-body p-0">
                        <div className="d-flex align-items-center border-bottom p-2">
                          <img src={Image1} alt="" style={style1} />
                          <h5 className="card-title">Custom-make Course</h5>
                        </div>
                        <div className="card-detail pt-2 pb-3">
                          <ul>
                            <li className="text-dark">
                              World-class Instructors
                            </li>
                            <li className="text-dark">
                              Expert-Led Mentoring Sessions
                            </li>
                            <li className="text-dark">Instant doubt clearing</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                    <div
                      className="card border-0 shadow"
                      style={{ width: "20rem" }}
                    >
                      <div className="card-body p-0">
                        <div className="d-flex align-items-center border-bottom p-2">
                          <img src={Image2} style={style1} alt="" />
                          <h5 className="card-title">Learning Analysis</h5>
                        </div>
                        <div className="card-detail pt-2 pb-3">
                          <ul>
                            <li className="text-dark">
                              World-class Instructors
                            </li>
                            <li className="text-dark">
                              Expert-Led Mentoring Sessions
                            </li>
                            <li className="text-dark">Instant doubt clearing</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                    <div
                      className="card border-0 shadow"
                      style={{ width: "20rem" }}
                    >
                      <div className="card-body p-0">
                        <div className="d-flex align-items-center border-bottom p-2">
                          <img src={Image3} style={style1} alt="" />
                          <h5 className="card-title">Certifications</h5>
                        </div>
                        <div className="card-detail pt-2 pb-3">
                          <ul>
                            <li className="text-dark">
                              World-class Instructors
                            </li>
                            <li className="text-dark">
                              Expert-Led Mentoring Sessions
                            </li>
                            <li className="text-dark">Instant doubt clearing</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
          
              
                  <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                    <div
                      className="card border-0 shadow"
                      style={{ width: "20rem" }}
                    >
                      <div className="card-body p-0">
                        <div className="d-flex align-items-center border-bottom p-2">
                          <img src={Image4} alt="" style={style1} />
                          <h5 className="card-title">Live Interactive Class</h5>
                        </div>
                        <div className="card-detail pt-2 pb-3">
                          <ul>
                            <li className="text-dark">
                              World-class Instructors
                            </li>
                            <li className="text-dark">
                              Expert-Led Mentoring Sessions
                            </li>
                            <li className="text-dark">Instant doubt clearing</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
          
                <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                  <div
                    className="card border-0 shadow"
                    style={{ width: "20rem" }}
                  >
                    <div className="card-body p-0">
                      <div className="d-flex align-items-center border-bottom p-2">
                        <img src={Image5} alt="" style={style1} />
                        <h5 className="card-title">Cloud Labs Provided</h5>
                      </div>
                      <div className="card-detail pt-2 pb-3">
                        <ul>
                          <li className="text-dark">
                            World-class Instructors
                          </li>
                          <li className="text-dark">
                            Expert-Led Mentoring Sessions
                          </li>
                          <li className="text-dark">Instant doubt clearing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center mb-3">
                  <div
                    className="card border-0 shadow"
                    style={{ width: "20rem" }}
                  >
                    <div className="card-body p-0">
                      <div className="d-flex align-items-center border-bottom p-2">
                        <img src={Image6} alt="" style={style1} />
                        <h5 className="card-title">24 x 7 Support</h5>
                      </div>
                      <div className="card-detail pt-2 pb-3">
                        <ul>
                          <li className="text-dark">
                            World-class Instructors
                          </li>
                          <li className="text-dark">
                            Expert-Led Mentoring Sessions
                          </li>
                          <li className="text-dark">Instant doubt clearing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingFeature;
