import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './InnerCoursePage.css';
import Image1 from "../../assets/images/logo1.png";
import microsoft from "../../assets/images/microsoft.jpg";
import live1 from "../../assets/images/live1.webp";
import live2 from "../../assets/images/live2.webp";
import live3 from "../../assets/images/live3.webp";
import live4 from "../../assets/images/live4.webp";
import live5 from "../../assets/images/live5.webp";
import project1 from "../../assets/images/project1.png";
import project2 from "../../assets/images/project2.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesTwoToneIcon from "@mui/icons-material/AutoStoriesTwoTone";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

function InnerCoursePage() {
  return (
    <>
      <Navbar />
      <div className="course-outline py-5 bg-light " id="inner-coursepage">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 p-2 cp-img">
                <img
                  src={microsoft}
                  alt="Course-image"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-lg-6 col-sm-12 px-5 pt-2 cp-des">
                <p className="text-dark">
                  This course covers three central elements of Microsoft 365
                  enterprise administration – Microsoft 365 tenant and service
                  management, Microsoft 365 management, and Microsoft 365
                  identity management. In Microsoft 365 tenant and service
                  management, you will examine all the key components that must
                  be planned for when designing your Microsoft 365 tenant. 
                  <span className="desktop-content">Once this planning phase is complete, you will learn how to
                  configure your Microsoft 365 tenant, including your
                  organizational profile, tenant subscription options, component
                  services, user accounts and licenses, and security groups.
                  Finally, you will learn how to manage your tenant, which
                  includes the configuration of tenant roles and managing your
                  tenant health and services. With your Microsoft 365 tenant now
                  firmly in place, you will examine the key components of
                  Microsoft 365 management. This begins with an overview of
                  Microsoft 365 product functionality, including Exchange
                  Online, SharePoint Online, Microsoft Teams, Microsoft Power
                  Platform, and device management. You will then transition to
                  configuring Microsoft 365, with a primary focus on configuring
                  Office client connectivity. 
                  </span>
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary me-3"
                    style={{ width: "100%" }}
                  >
                    Enroll Now
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary ms-3"
                    style={{ width: "100%" }}
                  >
                    Download Syllabus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="certi-prereq mt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex justify-content-center mb-3">
                  <h3>Prerequisite</h3>
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample1"
                >
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingPreOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsePreOne"
                        aria-expanded="false"
                        aria-controls="flush-collapsePreOne"
                      >
                       Students Must Have
                      </button>
                    </h2>
                    <div
                      id="flush-collapsePreOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingPreOne"
                      data-bs-parent="#accordionFlushExample1"
                    >
                      <div className="accordion-body">
                        <ul>
                          <li className="text-dark">
                            Completed a role-based administrator course such as
                            Messaging, Teamwork, Security and Compliance, or
                            Collaboration.
                          </li>
                          <li className="text-dark">
                            A proficient understanding of DNS and basic
                            functional experience with Microsoft 365 services.
                          </li>
                          <li className="text-dark">
                            A proficient understanding of general IT practices.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex justify-content-center mb-3">
                  <h3>Certification</h3>
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample2"
                >
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Certification
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample2"
                    >
                      <div className="accordion-body">
                        <p>
                          Candidates for this exam have expert-level skills in
                          evaluating, planning, migrating, deploying, and
                          managing Microsoft 365. They perform Microsoft 365
                          tenant-level planning, implementation, and
                          administration of cloud and hybrid enterprise
                          environments. Candidates for this exam have subject
                          matter expertise in Microsoft 365 applications,
                          infrastructure, and identity. The enterprise
                          administrator functions as the integrating hub for all
                          Microsoft 365 workloads. This role coordinates across
                          multiple Microsoft 365 workloads and advises the
                          architects and workload administrators. Candidates for
                          this exam have functional experience with all
                          Microsoft 365 workloads and Microsoft Azure Active
                          Directory (Azure AD), part of Microsoft Entra, and
                          have administered at least one of these. They also
                          have a working knowledge of networking, server
                          administration, DNS, and PowerShell.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whybi mt-5 bg-light pb-5">
        <div className="container-fluid">
          <div className="container">
            <div className={`d-flex justify-content-center py-5`}>
              <h2>
                Why Microsoft Power BI Certification Course from digiiPath
              </h2>
            </div>
            <div className="row">
              <div className="col mb-3 d-flex justify-content-center">
                <div
                  className="card border-0 shadow"
                  style={{ width: "25rem" }}
                >
                  <div className="card-body p-0">
                    <div className="d-flex align-items-center border-bottom p-2">
                      <img src={live1} alt="" />
                      <h5 className="card-title">Instructor-Led Live, Online Training</h5>
                    </div>
                    <div className={`card-detail pt-2`}>
                      <ul>
                        <li className="text-dark">
                          World-className Instructors
                        </li>
                        <li className="text-dark">
                          Expert-Led Mentoring Sessions
                        </li>
                        <li className="text-dark">Instant doubt clearing</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-3 d-flex justify-content-center">
                <div
                  className="card border-0 shadow"
                  style={{ width: "25rem" }}
                >
                  <div className="card-body p-0">
                    <div className="d-flex align-items-center border-bottom p-2">
                      <img src={live2} alt="" />
                      <h5 className="card-title">On-Premise classNameroom Training</h5>
                    </div>
                    <div className={`card-detail pt-2`}>
                      <ul>
                        <li className="text-dark">
                          World-className Instructors
                        </li>
                        <li className="text-dark">
                          Expert-Led Mentoring Sessions
                        </li>
                        <li className="text-dark">Instant doubt clearing</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col mb-3 d-flex justify-content-center">
                <div
                  className="card border-0 shadow"
                  style={{ width: "25rem" }}
                >
                  <div className="card-body p-0">
                    <div className="d-flex align-items-center border-bottom p-2">
                      <img src={live3} alt="" />
                      <h5 className="card-title">Live Intracting className</h5>
                    </div>
                    <div className={`card-detail pt-2`}>
                      <ul>
                        <li className="text-dark">
                          World-className Instructors
                        </li>
                        <li className="text-dark">
                          Expert-Led Mentoring Sessions
                        </li>
                        <li className="text-dark">Instant doubt clearing</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-course mt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="d-flex justify-content-center">
              <h5>About your Microsoft Power BI Certification Course</h5>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <h3>Power BI Skills Covered</h3>
            </div>
            <div className="row mt-4">
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />{" "}
                  Desktop Layout
                </span>
              </div>
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} /> Data
                  Transformation
                </span>
              </div>
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} /> DAX
                  Commands
                </span>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} /> Charts
                  and Page Layout
                </span>
              </div>
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />{" "}
                  Reports and Dashboards
                </span>
              </div>
              <div className="col">
                <span>
                  <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />{" "}
                  Advanced Analytics
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Course-outline mt-5 bg-light py-5">
        <div className="container-fluid">
          <div className="container">
            {/* <div className="d-flex justify-content-center">
              <h5>Power BI Course Syllabus</h5>
            </div> */}
            <div className="d-flex justify-content-center">
              <h3>Couse Outline Designed by Experts</h3>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Accordion Item #1
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> className. This is the
                        first item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Accordion Item #2
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> className. This is the
                        second item's accordion body. Let's imagine this being
                        filled with some actual content.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Accordion Item #3
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> className. This is the
                        third item's accordion body. Nothing more exciting
                        happening here in terms of content, but just filling up
                        the space to make it look, at least at first glance, a
                        bit more representative of how this would look in a
                        real-world application.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-2 border-0 shadow">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        Accordion Item #4
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> className. This is the
                        third item's accordion body. Nothing more exciting
                        happening here in terms of content, but just filling up
                        the space to make it look, at least at first glance, a
                        bit more representative of how this would look in a
                        real-world application.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="biProject" className="mt-5">
        <div className="container-fluid">
          <div className="container">
            <div className={`d-flex justify-content-center py-5`}>
              <h2>Power BI Course Projects</h2>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div
                  className="card border-0 shadow"
                  style={{ width: "35rem" }}
                >
                  <div className="card-body px-5">
                    <div className={`mb-5`}>
                      <img
                        src={project1}
                        alt="project1"
                        srcset=""
                        style={{ width: "130px" }}
                      />
                    </div>
                    <h4 className="card-title mb-3">Industry: Retail Sector</h4>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Eum ut excepturi earum velit incidunt. Tempora accusantium
                      explicabo vitae delectus ea voluptas, id, nihil quis ullam
                      consectetur doloribus temporibus. Unde, facere.
                    </p>
                    <div className={`d-flex justify-content-center`}>
                      <a
                        href="#"
                        className="card-link pb-3"
                        style={{ color: "#f04c24" }}
                      >
                        View Project Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col d-flex justify-content-center">
                <div
                  className="card border-0 shadow"
                  style={{ width: "35rem" }}
                >
                  <div className="card-body px-5">
                    <div className={`mb-5`}>
                      <img
                        src={project2}
                        alt="project2"
                        srcset=""
                        style={{ width: "130px" }}
                      />
                    </div>
                    <h4 className="card-title mb-3">Industry: Retail Sector</h4>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Natus adipisci eius hic provident corporis voluptate
                      reprehenderit culpa earum. Sed, repellat. Sint nulla dolor
                      earum, harum exercitationem enim ducimus iure libero!
                    </p>
                    <div className={`d-flex justify-content-center`}>
                      <a
                        href="#"
                        className="card-link pb-3"
                        style={{ color: "#f04c24" }}
                      >
                        View Project Detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div id="faq" className="mt-5">
        <div className={`container-fluid py-5`}>
          <div className={`container`}>
            <div className={`mb-5`}>
              <h2 className={`d-flex justify-content-center`}>
                Microsoft Power BI Training FAQs
              </h2>
            </div>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Q: What is Microsoft Identity?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <p>A: Microsoft Azure is Infrastructure in the Cloud. It is simply a processor, disk and RAM and this which users are still needed to upload and patch the software. Microsoft Office 365 is a Software as a Service (SaaS) which is mainly managed and also routinely upgraded by Microsoft.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    Q: What is the difference between Office 365 and Microsoft Azure?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <p>A: The Microsoft account can be used to log into a Windows 8 computer and it gives you access to online file storage with Skydrive, as well as a host of other services. The Office 365 account is basically used to log into user mailbox (either in Outlook or in webmail) and also various other Office 365 services.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    Q: What is the difference between a Microsoft account and an Office 365 account?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classNamees that we use to style each element.
                    These classNamees control the overall appearance, as well as
                    the showing and hiding via CSS transitions. You can modify
                    any of this with custom CSS or overriding our default
                    variables. It's also worth noting that just about any HTML
                    can go within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    Q: What is the purpose of Office 365?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body">
                    <p>A: Microsoft 365 is the productivity cloud designed to help you pursue your passion and run your business. More than various apps like Word, Power, Point Excel, as well Microsoft 365 brings together best-in-class productivity apps with powerful cloud services, device management as well as advanced security in one connected experience.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Q: What is the infrastructure required to attend your training program?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body">
                    <p>A: To attend the training session you should have an operational Desktops or Laptops with required specification along with good internet connection to access the labs. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InnerCoursePage;
