
import React, { useEffect } from "react";
import styles from "./Footer.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image1 from "../../assets/images/logo1.png";
import "./footer.css";

function Footer() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="footer">
      <div className={styles.footerContainer}>
        <div className={styles.topSection}>
          {/* <div className={styles.redBar}></div> */}
          <Container className={styles.footerRow}>
            <Row className={styles.footerContent}>
              <Col md={3} className="d-flex align-items-center tab-view-logo">
                <img src={Image1} alt="Logo" className={styles.logoImage} />
              </Col>
              <Col md={3}>
                <h5 className={styles.sectionTitle}>
                  TRENDING CERTIFICATION COURSES
                </h5>
                <ul className={styles.list}>
                  <Link to={"/microsoft-103"}>
                    {" "}
                    <li>Microsoft Power BI Data Analyst</li>
                  </Link>
                  <Link to={"/java-detail"}>
                    {" "}
                    <li>Java Certification Training Course</li>
                  </Link>
                  <Link to={"/python-detail"}>
                    {" "}
                    <li>Python Certification Training Course</li>
                  </Link>
                  <Link to={"/php-detail"}>
                    {" "}
                    <li>PHP Certification Training Course</li>
                  </Link>
                  <Link to={"/java-script-detail"}>
                    {" "}
                    <li>JavaScript Certification Training Course</li>
                  </Link>
                  <Link to={"/cplush-detail"}>
                    {" "}
                    <li>C++ Certification Training Course</li>
                  </Link>
                  <Link to={"/canva"}>
                    {" "}
                    <li>Canva for 1 month Training Course</li>
                  </Link>
                  <Link to={"/photoshop"}>
                    {" "}
                    <li>Canva + Photoshop Training Course</li>
                  </Link>
                </ul>
              </Col>
              <Col md={3} className={styles.company}>
                <h5 className={styles.sectionTitle}>COMPANY</h5>
                <ul className={styles.list}>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/corporatetraining">Corporate Training</a>
                  </li>
                  <li>
                    <a href="/courses-link">Course</a>
                  </li>
                  <li>
                    <a href="/internship">Internship</a>
                  </li>

                  <li><a href="/about" >
                  About Us
                    </a></li>
                  <li>
                    <Link to="/#google-reviews" >
                      Reviews
                    </Link>
                  </li>
                 

                  {/* <li>Community</li>
                <li>Locations</li>
                <li>Sitemap</li> */}

                  <li>
                  <a href="/modal-link">Login</a>
                  </li>
                  <li>
                  <a href="/modal-link">Sign Up</a>
                  </li>

                </ul>
              </Col>
              <Col md={3}>
                <h5 className={styles.sectionTitle}>Join Us</h5>
                <ul className={styles.workInfo}>
                  <li>
                    <a href="/work">Work With Us</a>
                  </li>
                
                  <li><a href="/affiliate">Become an Affiliate</a></li>
                  <li><a href="/instructor">Become an Instructor</a></li>
                  <li><a href="/partner">Become a Partner</a></li>
                 
                </ul>
              
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.bottomSection}>
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <p style={{ color: "white" }}>
                  Copyright © 2020 DigiPaath. All rights reserved.
                </p>
              </Col>
            
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Footer;
