import React from "react";
import "./becomePartner.css";
import Image from "../../assets/images/partnerPg2ndbn.png";

const becomePartner = () => {
  return (
    <div>
      <div className="become-partner">
        <div className="container">
          <div className="content mx-4 my-4">
            <h1>BECOME A PARTNER</h1>
            <h2 className="bp-h1 mt-4">
              Partner with us to support families across the UK get online.
            </h2>
            <a
              href="https://www.digipaath.com/"
              className="btn btn-primary mt-5"
            >
              Digiपाठ
            </a>
          </div>
          <img src={Image} alt="Partner with us" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default becomePartner;
