
import training1 from "../../assets/images/cisco.jpg";
import training2 from "../../assets/images/tcs.jpg";
import training3 from "../../assets/images/infosys.jpg";
import training4 from "../../assets/images/mercedes.jpg";
import training5 from "../../assets/images/visa.jpg";
import training6 from "../../assets/images/kpmg.jpg";
import training7 from "../../assets/images/wipro.jpg";
import training8 from "../../assets/images/united.jpg";
import training9 from "../../assets/images/organic.jpg";
import training10 from "../../assets/images/online.jpg";
import training11 from "../../assets/images/selfpaced.jpg";
import training12 from "../../assets/images/blended.jpg";

import "./TrustedBy.css";
const style1 = {
    filter:
      "invert(39%) sepia(63%) saturate(4102%) hue-rotate(350deg) brightness(98%) contrast(91%)",
  };
  
  const style2 = {
    marginLeft: "70px",
  };
  
  const style3 = {
    width: "780px",
  };
  const style4 = {
    width: "370px",
  };
  function TrustedBy() {
    return (  
        <div className="container-fluid shadow pb-4 pt-4 mt-3" id="trustedBy">
            <div className="container">
              <div className="row" id="rightContent">
                <div className="col"  >
                  <h5 style={{width:"150px", color:"#f04c24", fontSize:"30px", margin:"0px 60px 10px -30px"}}>Trusted By</h5>
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training1}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training2}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training3}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training4}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "6rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training5}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training6}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training7}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
                <div className="card border-0 col" style={{ width: "7rem" }}>
                  <img
                    src={training8}
                    className="card-img-top"
                    alt="..."
                    style={{ width: "5rem", height: "40px" }}
                  />
                </div>
              </div>
            </div>
          </div>
    )
}
export default TrustedBy