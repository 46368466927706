import React from 'react';
import training10 from "../../assets/images/online.jpg";
import training11 from "../../assets/images/selfpaced.jpg";
import training12 from "../../assets/images/blended.jpg";

import "./OurAdvisorForm.css";

const style1 = {
    filter:
      "invert(39%) sepia(63%) saturate(4102%) hue-rotate(350deg) brightness(98%) contrast(91%) ",
  };
  
  const style2 = {
    marginLeft: "70px",
  };
  
  const style3 = {
    width: "780px",
  };
  const style4 = {
    width: "370px",
  };

function OurAdvisorForm() {
    return (
        <div className="main-con">
          <div className="container-form">
            <div className="in-con">
              <header>Talk to our advisor</header>
              <form action="https://formspree.io/f/xgegzkpj" method="POST" className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="fullname" className="form-label">
                    Name
                  </label>
                  <input type="text" name="fullname" className="form-control" id="fullname" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phoneno" className="form-label">
                    Phone no
                  </label>
                  <input
                    type="number"
                    name="phoneno"
                    className="form-control"
                    id="phoneno"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="training" className="form-label">
                    Training
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="training"
                    id="training"
                    placeholder="Corporate, self"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Cityname" className="form-label">
                    City
                  </label>
                  <input type="text" className="form-control" id="Cityname" name="Cityname" />
                </div>
              
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    );
}

export default OurAdvisorForm;
