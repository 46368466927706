import React, { useState, useEffect } from "react";
import "./trend.css";
import styles from "./TrendingCourses.module.css";
import { Tab, Tabs, Card, Carousel } from "react-bootstrap";
import coursesData from "../../utils/data";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

const categories = [
  "All",
  "Programing",
  "Web Development",
  "Microsoft",
  "Design",
  "Marketing",
  "HR Training",
  "AWS",
  "Snowflake",
];

function renderStarRating(rating) {
  const filledStars = Math.floor(rating);
  const halfStar = rating - filledStars >= 0.5;
  const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);

  return (
    <div className={styles["star-rating"]}>
      {[...Array(filledStars)].map((_, index) => (
        <i key={index} className={`bi bi-star-fill ${styles["star-icon"]}`}></i>
      ))}
      {halfStar && <i className={`bi bi-star-half ${styles["star-icon"]}`}></i>}
      {[...Array(emptyStars)].map((_, index) => (
        <i key={index} className={`bi bi-star ${styles["star-icon-empty"]}`}></i>
      ))}
      <span className={styles["rating-value"]}>{rating}</span>
    </div>
  );
}

const CustomPrevButton = ({ onClick }) => (
  <div className={styles["carousel-control-prev"]} onClick={onClick}>
    <BiChevronLeft className={styles["carousel-control-icon"]} size={31} />
  </div>
);

const CustomNextButton = ({ onClick }) => (
  <div className={styles["carousel-control-next"]} onClick={onClick}>
    <BiChevronRight className={styles["carousel-control-icon"]} size={31} />
  </div>
);

function TrendingCourses() {
  const [chunkSize, setChunkSize] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      // const newChunkSize = windowWidth <= 912 ? 1 : 4;
      let newChunkSize;
      if(windowWidth <=500){
        newChunkSize=1;
      }
      else if(windowWidth <=912)
        {
          newChunkSize=2;
        }
        else if(windowWidth >913)
          {
            newChunkSize=4
          }
          setChunkSize(newChunkSize);
    };

    handleResize(); // Call initially to set the chunkSize based on the initial window width

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ marginTop: "10px" }} className="trend">
      <div className={styles["trending-courses"]}>
        <h3 className={styles["trending-courses-heading"]}>Trending Courses</h3>
        <Tabs defaultActiveKey="All" id="course-tabs" style={{color:""}}>
          {categories.map((category, index) => (
            <Tab key={index} eventKey={category} title={category} style={{color:"red "}}>
              <div className={styles["carousel-container"]}>
                <Carousel
                  className={styles["course-carousel"]}
                  indicators={false}
                  interval={null}
                  prevIcon={<CustomPrevButton />}
                  nextIcon={<CustomNextButton />}
                >
                  {coursesData
                    .filter(
                      (course) =>
                        category.toLowerCase() === "all" ||
                        course.category.toLowerCase() === category.toLowerCase()
                    )
                    .reduce((chunks, course, index) => {
                      const chunkIndex = Math.floor(index / chunkSize);
                      if (!chunks[chunkIndex]) {
                        chunks[chunkIndex] = [];
                      }
                      chunks[chunkIndex].push(course);
                      return chunks;
                    }, [])
                    .map((chunk, chunkIndex) => (
                      <Carousel.Item key={chunkIndex}>
                        <div className={styles["card-row"]}>
                          {chunk.map((course, cardIndex) => (
                            <Card
                              key={cardIndex}
                              className={`border-0 shadow rounded-0 ${styles["card-container"]}`}
                            >
                              <Link to={course.link}>
                                <Card.Img
                                  variant="top"
                                  src={course.image}
                                  className={`rounded-0 ${styles["card-image"]}`} 
                                />
                                <Card.Body className={`${styles["mobile-card"]}`}>
                                  <Card.Title className={styles["card-title"]}>
                                    {course.course_name}
                                  </Card.Title>
                                  {renderStarRating(course.rating_star)}
                                </Card.Body>
                              </Link>
                            </Card>
                          ))}
                          {chunk.length < chunkSize &&
                            [...Array(chunkSize - chunk.length)].map((_, index) => (
                              <Card
                                key={`empty-${index}`}
                                className={`${styles["card-container"]} ${styles["empty-card"]}`}
                              />
                            ))}
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default TrendingCourses;
