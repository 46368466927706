import coprate_training_mobile from "../../assets/images/corporate-training-mob.jpg";
import cptraining from "../../assets/images/cptraining.jpg";
import Image1 from "../../assets/images/pencil-and-ruler.png";
import Image2 from "../../assets/images/analytics.png";
import Image3 from "../../assets/images/certificate.png";
import Image4 from "../../assets/images/project.png";
import Image5 from "../../assets/images/cloud-storage.png";
import Image6 from "../../assets/images/support.png";
import training9 from "../../assets/images/organic.jpg";
import training10 from "../../assets/images/online.jpg";
import training11 from "../../assets/images/selfpaced.jpg";
import training12 from "../../assets/images/blended.jpg";
import "./TrainingNeeds.css";

function TrainingNeeds() {
  return (
    <div id="cp-training" className="training-need">
      <div className="container mt-5" id="trainingNeeds">
        <h2 className="training-card d-flex justify-content-center mb-5">
          Designed for all your Training Needs
        </h2>
        <div className="row" id="needCards">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center mb-3">
            <div className="card border-0 shadow" style={{ width: "16rem" }}>
              <img
                src={training9}
                className="card-img-top"
                alt="On-Premise Class Room Training"
                style={{ width: "16rem", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title justify-content-center text-center">On-Premise Class Room Training</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center mb-3">
            <div className="card border-0 shadow" style={{ width: "16rem" }}>
              <img
                src={training10}
                className="card-img-top"
                alt="Instructor-Led Live, Online Training"
                style={{ width: "16rem", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title justify-content-center text-center">Instructor-Led Live, Online Training</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center mb-3">
            <div className="card border-0 shadow" style={{ width: "16rem" }}>
              <img
                src={training12}
                className="card-img-top"
                alt="Self-Paced Online Training"
                style={{ width: "16rem", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title justify-content-center text-center">Self-Paced Online Training</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center mb-3">
            <div className="card border-0 shadow" style={{ width: "16rem" }}>
              <img
                src={training11}
                className="card-img-top"
                alt="Learning Analysis"
                style={{ width: "16rem", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title justify-content-center text-center">Learning Analysis</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingNeeds;
