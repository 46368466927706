import React from "react";
import "./WorkWithUs.css";
import work from "../../assets/images/workwith1.png"
import NavBar from "../Navbar/Navbar";
import CareerRelatedPrograms from "../CareerRelatedProgram/CareerRelatedPrograms";
import GoogleReview from "../GoogleReview/Google_review";
import Footer from "../Footer/Footer";
import Promotion_Executive from "../Promotion_Executive";
import Campus_Ambassador from "../Campus_Ambassador";

const WorkWithUs = () => {
  return (
    <>
    <NavBar />
    <section className="works-container">
      <div className="content">
        
        <h2>Careers at DigiPaath</h2>
        <h1>Work with us</h1>
        <p>
          Explore remote-friendly, flexible opportunities and join our mission to make work life simpler, more pleasant, and more productive.
        </p>
        <a href="/internship" className="careers-button">Looking for internships?</a>
        {/* <div className="internship" style={{marginTop:"20px"}}>
        <a href="/internship" className="internships-link">Looking for internships?</a>
        <div className=" internship-blue-line2"></div>
        </div> */}
      </div>
      <div className="illustration">
      <img className="workwith" src={work} alt="" srcset="" />
      </div>
      
    </section>
    <Promotion_Executive />
    <Campus_Ambassador />
    <CareerRelatedPrograms />
    <GoogleReview />
    <Footer />
    </>
  );
};

export default WorkWithUs;
