export const API_BASE_URL = 'http://51.20.31.3:8000';
export const GOOGLE_API =
  'https://maps.googleapis.com/maps/api/place/nearbysearch/json';
export const getApiUrl = endpoint => API_BASE_URL + endpoint;
//landowner apis
export const PHONE_NUMBER_LOGIN = getApiUrl('/User/phoneNumberLogin');
export const PHONE_NUMBER_VERIFICATION = getApiUrl('/User/otpVerification');
export const HOME_PAGE = getApiUrl('/User/homePage');
export const ADD_TO_CART = getApiUrl('/User/add_product_to_cart');
export const ABOUT_PAGE = getApiUrl('/User/about_us_listing');
export const BEST_SELLER = getApiUrl('/User/best_seller_listing');
export const CATEGORY_DETAILS = getApiUrl("/User/category_details");
export const CATEGORY_LIST = getApiUrl("/User/category_listing");
export const INFORMATION_LISTING =getApiUrl("/User/information_listing");
export const TESTIMONIAL_LISTING = getApiUrl("/User/testimonial_listing");
export const POST_REVIEW = getApiUrl("/User/post_review");
export const OFFER = getApiUrl("/User/offer_listing");
export const PLACE_ORDER = getApiUrl("/User/place_order");
export const UPDATE_DELETE_CART = getApiUrl("/User/update_delete_cart");
export const CART_LIST = getApiUrl("/User/cart_listing");
export const USER_ADDRESS = getApiUrl("/User/addressListing");
export const ORDER_HISTORY = getApiUrl("/User/orders_listing");
export const ADD_ADDRESS = getApiUrl("/User/addAddress");
export const UPDATE_DELETE_ADDRESS = getApiUrl("/User/updateDeleteAddress");
export const SEARCH_PRODUCT = getApiUrl("/User/search_product");
export const RELATED_PRODUCT = getApiUrl("/User/you_may_want_to_try");
export const PRODUCT_REVIEW = getApiUrl("/User/product_review_listing")
export const PRODUCT_DETAIL =getApiUrl("/User/product_details");
export const DISH_LISTING = getApiUrl("/User/dish_listing");
export const EDIT_PROFILE =getApiUrl("/User/edit_user_profile");
export const UPLOAD_PROFILE_IMAGE = getApiUrl("/Admin/image_upload");
export const ADD_TO_WISHLIST = getApiUrl("/User/add_to_wishlist");
export const WISHLIST_LISTING = getApiUrl("/User/wishlist_listing");
export const DELETE_FROM_WISHLIST = getApiUrl("/User/delete_from_wishlist");