

const CourseData =[

    // Web Development Course card
    {
        id:1,
        category:'Web Development',
        course_name:'Become A Certified HTML,CSS,Java Script Web Developer',
        link:'/Web-dev-20'
    },
    {
        id:2,
        category:'Web Development',
        course_name:'The Complete FullStack Web Developer Course',
        link:"/web-dev-21"
    },
    {
        id:3,
        category:'Web Development',
        course_name:'Introduction to Web Development',
         link:'/Web-dev-22'
    },

    //Programming course card
    {
        id:4,
        category:'Programming',
        course_name:'Python',
         link:'/python-detail'
    },
    {
        id:5,
        category:'Programming',
        course_name:'Java',
         link:'/java-detail'
    },
    {
        id:6,
        category:'Programming',
        course_name:'JavaScript',
        link:'/java-script-detail'
    },
    {
        id:7,
        category:'Programming',
        course_name:'PHP',
         link:'/php-detail'
    },

    //Marketing

    {
        id:8,
        category:'Marketing',
        course_name:'Email Marketing - Become a Freelance Email Marketer, Your own boss',
         link:'/email-marketing'
    },
    {
        id:9,
        category:'Marketing',
        course_name:'Digital Marketer',
        link:'/digital-marketing'
    },

    //HR

    {
        id:10,
        category:'HR',
        course_name:'HR Fundamentals',
        link:'/hr-50'
    },
    {
        id:11,
        category:'HR',
        course_name:'HR social compiance',
         link:'/hr-53'
    },

    //Microsoft

    {
        id:12,
        category:'Microsoft',
        course_name:`Trainng AI 102T00 Designing Implementing a Microsoft Azure AI Solution`,
         link:'/microsoft-100'
    },
    {
        id:13,
        category:'Microsoft',
        course_name:`PL-500T00: Microsoft Power Automate RPA Developer`,
        link:'/microsoft-101',
    },
    {
        id:14,
        category:'Microsoft',
        course_name:`PL-400T00: Microsoft Power Platform Developer`,
        link:'/microsoft-102',
    },
    {
        id:15,
        category:'Microsoft',
        course_name:`PL-300T00: Microsoft Power BI Data Analyst`,
        link:'/microsoft-103',
    },
    {
        id:16,
        category:'Microsoft',
        course_name:`Trainng AI 102T00 Designing Implementing a Microsoft Azure AI Solution`,
        link:'/microsoft-104',
    },
    {
        id:17,
        category:'Microsoft',
        course_name:`Trainng AI 102T00 Designing Implementing a Microsoft Azure AI Solution`,
        link:'/microsoft-105',
    },
    {
        id:18,
        category:'Microsoft',
        course_name:`MS-900T01: Microsoft 365 Fundamentals`,
        link:'/microsoft-108',
    },
    {
        id:19,
        category:'Microsoft',
        course_name:`MS-100T00: Microsoft 365 Identity & Services`,
         link:'/microsoft-117'
    },
    {
        id:20,
        category:'Microsoft',
        course_name:`MS-900T01: Microsoft 365 Fundamentals`,
        link:'/microsoft-120',
    },
    // {
    //     id:21,
    //     category:'Microsoft',
    //     course_name:`MS-700T00: Managing Microsoft Teams`,
    //     link:'/microsoft-103',
    // },
    // {
    //     id:22,
    //     category:'Microsoft',
    //     course_name:`MS-500T00: Microsoft 365 Security Administration`,
    //     link:'/microsoft-103',
    // },
    // {
    //     id:23,
    //     category:'Microsoft',
    //     course_name:`MS 203T00 Microsoft 365 Messaging`,
    //     link:'/microsoft-103',
    // },
    // {
    //     id:24,
    //     category:'Microsoft',
    //     course_name:`MD 101T00 Managing Modern Desktops`,
    //     link:'/microsoft-103',
    // },
    // {
    //     id:25,
    //     category:'Microsoft',
    //     course_name:`MD 100T00 Windows Client`,
    //     link:'/microsoft-103',
    // },
    // {
    //     id:26,
    //     category:'Microsoft',
    //     course_name:`MS 900T01 Microsoft 365 Fundamentals`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:27,
    //     category:'Microsoft',
    //     course_name:`MS 720T00 Microsoft Teams Voice Engineer`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:28,
    //     category:'Microsoft',
    //     course_name:`MS 101T00 Microsoft 365 Mobility Security`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:29,
    //     category:'Microsoft',
    //     course_name:`MS-100T00: Microsoft 365 Identity & Services`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:30,
    //     category:'Microsoft',
    //     course_name:`MB-210T01 Microsoft Dynamics 365 Sales`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:31,
    //     category:'Microsoft',
    //     course_name:`MB-310T00: Microsoft Dynamics 365 Finance`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:32,
    //     category:'Microsoft',
    //     course_name:`MB-240T00: Microsoft Dynamics 365 Field Service`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:33,
    //     category:'Microsoft',
    //     course_name:`MB-230T01: Microsoft Dynamics 365 Customer Service`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:34,
    //     category:'Microsoft',
    //     course_name:`MB-220T00: Microsoft Dynamics 365 Marketing`,
    //     link:'/Web-dev-20',
    // },
    {
        id:35,
        category:'Microsoft',
        course_name:`MB-210T01 Microsoft Dynamics 365 Sales`,
        link:'/microsoft-118',
    },
    // {
    //     id:36,
    //     category:'Microsoft',
    //     course_name:`MB-500T00 Microsoft Dynamics 365Finance Operations Apps Developer`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:37,
    //     category:'Microsoft',
    //     course_name:`MB-500T00 Microsoft Dynamics 365Finance Operations Apps Developer`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:38,
    //     category:'Microsoft',
    //     course_name:`PL-600T00: Microsoft Power Platform Solution Architect`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:39,
    //     category:'Microsoft',
    //     course_name:`MB-920T00 Microsoft Dynamics 365 Fundamentals (ERP)`,
    //     link:'/Web-dev-20',
    // },
    // {
    //     id:40,
    //     category:'Microsoft',
    //     course_name:`MB-910T00: Microsoft Dynamics 365 Fundamentals (CRM)`,
    //      link:'/Web-dev-20',
    // },
    // {
    //     id:41,
    //     category:'Microsoft',
    //     course_name:`PL-100T00: Microsoft Power Platform App Maker`,
    //     link:'/Web-dev-20',
    // },

    //AWS

    {
        id:42,
        category:'AWS',
        course_name:`Advanced Architecting on AWS`,
        link:'/aws-150',
    },
    {
        id:43,
        category:'AWS',
        course_name:`Architecting On AWS`,
        link:'/aws-151',
    },
    {
        id:44,
        category:'AWS',
        course_name:`Architecting on AWS accelerator`,
        link:'/aws-152',
    },
    {
        id:45,
        category:'AWS',
        course_name:`Running containers on amazon elastic Kubernetes service`,
        link:'/aws-153',
    },
    {
        id:46,
        category:'AWS',
        course_name:`AWS cloud for finance professionals`,
        link:'/aws-154',
    },
    {
        id:47,
        category:'AWS',
        course_name:`AWS-cloud-financial-management-for-builders`,
        link:'/aws-155',
    },
    {
        id:48,
        category:'AWS',
        course_name:`Developing serverless solutions on AWS`,
        link:'/aws-156',
    },
    {
        id:49,
        category:'AWS',
        course_name:`AWS security fundamentals`,
        link:'/aws-157',
    },
    {
        id:50,
        category:'AWS',
        course_name:`AWS security governance at scale`,
        link:'/aws-158',
    },
    {
        id:51,
        category:'AWS',
        course_name:`Security engineering on AWS`,
        link:'/aws-159',
    },
    {
        id:52,
        category:'AWS',
        course_name:`Systems operations on AWS`,
        link:'/aws-160',
    },
    {
        id:53,
        category:'AWS',
        course_name:`Migrating to AWS`,
        link:'/aws-161',
    },
    {
        id:54,
        category:'AWS',
        course_name:`Video streaming fundamentals for AWS media services`,
        link:'/aws-162',
    },
    {
        id:55,
        category:'AWS',
        course_name:`DevOps engineering on AWS`,
        link:'/aws-163',
    },
    {
        id:56,
        category:'AWS',
        course_name:`Planning-and-designing-databases-on-AWS`,
        link:'/aws-164',
    },
    {
        id:57,
        category:'AWS',
        course_name:`Deep learning on AWS`,
        link:'/aws-165',
    },
    {
        id:58,
        category:'AWS',
        course_name:`MLOps engineering on AWS`,
        link:'/aws-166',
    },
    {
        id:59,
        category:'AWS',
        course_name:`The Machine Learning Pipeline On AWS`,
        link:'/aws-167',
    },
    {
        id:60,
        category:'AWS',
        course_name:`Advanced development on AWS`,
        link:'/aws-168',
    },
    {
        id:61,
        category:'AWS',
        course_name:`Developing on AWS`,
        link:'/aws-169',
    },
    {
        id:62,
        category:'AWS',
        course_name:`Authoring Visual Analytics using Amazon QuickSight`,
        link:'/aws-170',
    },
    {
        id:63,
        category:'AWS',
        course_name:`Building Big Data Analytics Solutions on AWS`,
        link:'/aws-171',
    },
    {
        id:64,
        category:'AWS',
        course_name:`Building Data Analytics Solutions Using Amazon Redshift`,
        link:'/aws-172',
    },
    {
        id:65,
        category:'AWS',
        course_name:`Building Data Lakes on AWS`,
        link:'/aws-173',
    },
    {
        id:66,
        category:'AWS',
        course_name:`Building Streaming Data Analytics Solutions on AWS`,
        link:'/aws-174',
    },
    {
        id:67,
        category:'AWS',
        course_name:`Planning-and-designing-databases-on-AWS`,
        link:'/aws-175',
    },

    //Snowflake

    {
        id:68,
        category:'Snowflake',
        course_name:`Snowflake Snowpark DataFrame Programming Training`,
        link:'/snowflake-200',
    },
    {
        id:69,
        category:'Snowflake',
        course_name:`Snowflake Performance Automation and Tuning 3-Day Training – Private`,
        link:'/snowflake-201'
    },
    {
        id:70,
        category:'Snowflake',
        course_name:`Snowflake New Features Training – Private`,
        link:'/snowflake-202',
    },
    {
        id:71,
        category:'Snowflake',
        course_name:`Snowflake Fundamentals 4-Day – Private`,
        link:'/snowflake-203',
    },
    {
        id:72,
        category:'Snowflake',
        course_name:`Snowflake Data Governance Training – Private`,
        link:'/snowflake-204',
    },
    {
        id:73,
        category:'Snowflake',
        course_name:`Snowflake Data Engineer Training - Private`,
        link:'/snowflake-205',
    },
    {
        id:74,
        category:'Snowflake',
        course_name:`Snowflake Data Engineer II Training - Private`,
        link:'/snowflake-206',
    },
    {
        id:75,
        category:'Snowflake',
        course_name:`Snowflake Data Analyst Training – Private`,
        link:'/snowflake-207',
    },
    {
        id:76,
        category:'Snowflake',
        course_name:`Snowflake Advanced Training – Private`,
        link:'/snowflake-208',
    },
    {
        id:77,
        category:'Snowflake',
        course_name:`Data Science Training – Private`,
        link:'/snowflake-209',
    },
    {
        id:79,
        category:'Snowflake',
        course_name:`Supervising Snowflake Training - Individual`,
        link:'/snowflake-210',
    },

]
export default CourseData ;