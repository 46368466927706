import React from "react";
import Slider from "react-slick";
import "./Blog.css";
import software_quality_blog_card from '../../assets/images/Software-quality-blog-card.png';
import google_blog_card from '../../assets/images/google-blog-card.png';
import web_dev_blog_card from '../../assets/images/webdev-blog-card.png';
import hr_blog_card from '../../assets/images/HR-blog-card.png';
import digital_marketing_blog_card from '../../assets/images/Digital-blog-card.png';
import marketing_blog_card from '../../assets/images/microsoft-blog-card.png';
import { Link } from "react-router-dom";

const Blog = () => {
  const cards = [
    {
      id: 1,
      title: "Software Quality",
      image: software_quality_blog_card,
      date: "March 2, 2024",
      link: "/software-quality-post",
    },
    {
      id: 2,
      title: "Digital Marketing",
      image: digital_marketing_blog_card,
      date: "March 3, 2024",
      link: "/digital-marketing-post",
    },
    {
      id: 3,
      title: "Microsoft",
      image: marketing_blog_card,
      date: "March 4, 2024",
      link: "/microsoft-post",
    },
    {
      id: 4,
      title: "Web Development",
      image: web_dev_blog_card,
      date: "March 6, 2024",
      link: "/web-dev-post",
    },
    {
      id: 5,
      title: "Google",
      image: google_blog_card,
      date: "March 1, 2024",
      link: "/google-post",
    },
    {
      id: 6,
      title: "HR",
      image: hr_blog_card,
      date: "March 6, 2024",
      link: "/hr-post",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    customPaging: (i) => <button className="slick-dot"></button>,
    appendDots: (dots) => (
      <div style={{ position: "relative", bottom: "20px", padding: "10px" }}>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

    return (
        <div className="container">
            <div className="main-box">
                <div className=" mt-5 mb-4 ms-1">
                    <h3 className="text-left text-dark">Our Blog</h3>
                </div>

                <div className="scrollable-container">
                    <Slider {...settings}>
                        {cards.map((card, index) => (
                            <div key={index} className="p-2">
                                <div className='blogcomponent'>
                                    <div className="card border shadow">
                                    <Link to={card.link}>
                                        <img src={card.image} className="card-img-top img-fluid" alt={card.title} />
                                        <div className="card-body">
                                            <h5 className="card-title d-flex justify-content-center">{card.title}</h5>
                                            <p className="card-text w-100">{card.date}</p>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
  );
};

export default Blog;
