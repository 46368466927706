import coprate_training_mobile from "../../assets/images/corporate-training-mob.jpg";
import cptraining from "../../assets/images/cptraining.jpg"
import Image1 from "../../assets/images/pencil-and-ruler.png";
import Image2 from "../../assets/images/analytics.png";
import Image3 from "../../assets/images/certificate.png";
import Image4 from "../../assets/images/project.png";
import Image5 from "../../assets/images/cloud-storage.png";
import Image6 from "../../assets/images/support.png";
import training9 from "../../assets/images/organic.jpg";
import training10 from "../../assets/images/online.jpg";
import training11 from "../../assets/images/selfpaced.jpg";
import training12 from "../../assets/images/blended.jpg";
import "./banner.css"
const style1 = {
  filter:
    "invert(39%) sepia(63%) saturate(4102%) hue-rotate(350deg) brightness(98%) contrast(91%)",
};

const style2 = {
  marginLeft: "70px",
};

const style3 = {
  width: "780px",
};
const style4 = {
  width: "370px",
};

function Banner() {
  return (
    <div id="Ct1">
      <div className="corporate-training" id="corpTraining">
        <div className="container-fluid" id="trainingContainer">
          <div className="jumbotron text-left" id="trainingContent">
            <img className="cpbanner" src={cptraining} alt="" srcset="" />
            <img className="cpbanner-mobile" src={coprate_training_mobile} alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner       