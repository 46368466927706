import React from 'react';
import "./partnerStrategy.css";
import Image from "../../assets/images/partnerPgimg1.png";

const partneringStrategy = () => {
  return (
    <>
      <div className='partner-strategy '>
        <div className="container">
          <div className="row mt-3">
            {/* Large Heading with Paragraph in the Left Top Corner */}
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="heading-left">
                <h1>PARTNERING</h1>
              </div>
            </div>
          </div>
          {/* Second Heading with Paragraph in the Right Top Corner */}
          <div className="col-12 d-flex justify-content-end align-items-start">
            <div className="heading-right">
              <h2 className='col-12 d-flex justify-content-center align-items-start'>VALUE <br/> FOR PARTNER </h2>
              <p className='col-12 d-flex justify-content-end align-items-start'>PROVIDE SIGNIFICANT VALUE</p>
            </div>
          </div>

          <div className="row mt-4">
            {/* Content 1: Heading with Paragraph */}
            
              <div className="content-section-left col-12 col-md-4 my-4">
                <h3>PARTNER <br/> NETWORK & PROFILE</h3>
                <p>UNDERSTAND THE INDUSTRY NETWORK AND THE VALUE CHAIN TO BUILD RELATIONSHIP</p>
              </div>

              {/* Last Section: Heading with Paragraph (Aligned to the Left, Below Content Section Left) */}
              <div className="last-section content-section-right col-12 col-md-4 mt-4" id='right-cont'>
                <h3>PARTNER <br/> SELECTION CRITERIA</h3>
                <p>HOW DOES THE RELATIONSHIP HELP ACHIEVE GOALS? CREATE A CHECKLIST TO ASSESS THE VALUE OF A PARTNERSHIP</p>

              </div>
          
            {/* Content 2: Large Image Centered */}
            <div className="col-12 col-md-4 ">
              <img src={Image} alt="logo" loading="lazy" className='part-img' />
            </div>
            {/* Content 3: Heading with Paragraph */}
            <div className="col-12 col-md-4 ">
              <div className="content-section-right my-4" id='end-cont'>
              <h3>PARTNER <br/> PROCESS MANAGEMENT</h3>
              <p>MANAGE THE PROCESS FOR MUTUAL SUCCESS</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default partneringStrategy;
