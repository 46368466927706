import React, { useState, useEffect } from 'react';
import { FiEdit, FiUpload } from 'react-icons/fi';
import { DeleteOutline } from '@material-ui/icons';
import Card1 from "../Images/Card1.jpg";
import Card2 from "../Images/Card2.jpg";
import Card3 from "../Images/Card3.jpg";
import styles from './OnboardingScreen.module.css';

const CardComponent = ({showForm,setShowForm}) => {
  const [caption, setCaption] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const cardData = [
    { image: Card1 , title: 'CSS Crash Course'},
    { image: Card2 , title: 'Placement Preparation'},
    { image: Card3 , title: 'MERN Stack Development'},
  ];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleSave = () => {
    //console.log('Image:', selectedImage);
    //console.log('Caption:', caption);
    setFormSubmitted(true);
    if (showForm) {
      setShowForm(false);
    }
    setCaption('');
    setSelectedImage(null);
  };

  const handleCancel = () => {
    setFormSubmitted(true);
    if (showForm) {
      setShowForm(false);
    }
    setCaption('');
    setSelectedImage(null);
  };

  useEffect(() => {
    if (!showForm) {
      setFormSubmitted(false);
    }
  }, [showForm]);


  const handleEdit = () => {
    // Perform edit operation
    
  };

  const handleDelete = () => {
    // Perform delete operation
    
  };

    return (
      <div className={styles.container}>
        {showForm && !formSubmitted ? (
          <div className={styles.card}>
          <div className = {styles.cardImageContainer} >
          <input
              type="file"
              accept="image/*"
              id="imageUpload"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            {selectedImage ? (
              <img src={selectedImage} alt="Selected" className={styles.uploadedCardImage} />
            ) : (
              <label htmlFor="imageUpload" className={styles.uploadButton}>
                <FiUpload className={styles.uploadIcon} />
                <span className={styles.uploadText}>Upload Image</span>
              </label>
            )}
          </div>
          <input
            type="text"
            value={caption}
            onChange={handleCaptionChange}
            placeholder="Write Description"
            className={styles.captionInput}
          />
          <div className={styles.buttonContainer}>
            <button className={styles.saveButton} onClick={handleSave}>Save</button>
            <button className={styles.cancelButton} onClick={handleCancel}>Cancel</button>
          </div>
        </div>
        ) : (
          cardData.map((card, index) => (
            <div key={index} className={styles.card}>
              <img src={card.image} alt={card.title} className={styles.cardImage} />
              <div className={styles.cardIcons}>
                <FiEdit className={styles.icon} onClick={handleEdit}/>
                <DeleteOutline className={styles.icon} onClick={handleDelete} />
              </div>
              <div className={styles.cardTitle}>{card.title}</div>
            </div>
          ))
        )
      }
    </div>
  );
};

export default CardComponent;