import React, { useState } from 'react';
import styles from './Dashboard.module.css';
import Sidebar from '../SideBar/Sidebar';
import TopBarWelcome from '../TopBar&Welcome/TopBarWelcome';
import PieGraphsContent from "../PieGraphsContent/PieGraphsContent";
import Demographic from '../Demographics/Demographic';
//import Profile from '../Profile/Profile';
//import Profile from '../Profile/Profile';
//import Roles from '../Roles/Roles';
//import Mail from '../Mail/Mail';

const Dashboard = () => {
  const name = 'Pranav Bhargava';
  const position = 'Marketing Advisor';
  const [activePage, setActivePage] = useState('Dashboard'); 
  return (
    <div className={styles.dashboardContainer}>
      <TopBarWelcome name={name} position={position} />
      <Sidebar setActivePage={setActivePage} />
      <div className={styles.rightContainer}>
        {/* PieGraphs */}
        {activePage === 'Dashboard' && <PieGraphsContent />}

        {/*Demographic*/}
        {activePage === 'Demographic' && <Demographic />}

         {/* Profile */}
        {/*activePage === 'Profile' && <Profile />*/}

        {/* Roles */}
        {/*activePage === 'Roles' && <Roles />*/}

        {/*Mail
        {activePage === 'Mail' && <Mail />}*/}

      </div>
    </div>
  );
}

export default Dashboard;