import React from 'react'


import "./Styling/jobs.css";
import campus_amb from "../assets/images/cam-amb.jpg";

const Promotion_Executive = () => {
  return (
    <div>
        
            <div className="container mt-4 jobs">
                <div className="row d-flex justify-content-center mb-4">
                    {/* <h5 className='d-flex justify-content-center'>Our Job Program</h5> */}
                    <h1 className='d-flex justify-content-center text-center' style={{color:"#f04c24"}}>Join Our Job Program  and Ignite Your Career!</h1>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6 d-flex align-items-center flex-column justify-content-center">
                        <h2 className='mb-3 d-flex justify-content-center'>Promotion Executive</h2>
                        <p>Digipaath is excited to offer engaging part-time job opportunities for individuals eager to promote our services and attract new clients. As a Digipaath promoter, you will have the fantastic chance to earn a lucrative 20% commission for each confirmed client you bring in successfully.
                        <br />
                         Join our team today and discover the potential for substantial rewards for your efforts.</p>
                        <div className='d-flex justify-content-center mt-3 mb-4'>
                            <a href="https://forms.gle/Kzgiu8pNgAWQCt2LA">
                                <button type="button" class="btn job-btn">Apply</button>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 mobile-card">
                        <img src={campus_amb} alt="" srcset="" />
                    </div>
                </div>
                
            </div>

        
      
    </div>
  )
}

export default Promotion_Executive
