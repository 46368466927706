import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './k-20.css';
import k_20_banner from "../../assets/images/k-20.png";
import accessibility_services_main_k_20 from '../../assets/images/Accessibility-services-main-k-20.png';
import editorial_services_main_k_20 from '../../assets/images/Editorial-services-main-k-20.png';
import content_development_services_main_k_20 from '../../assets/images/content-development-services-main-k-20.png';
import digital_learning_content_services_main_k_20 from '../../assets/images/digital-learning-content-services-main-k-20.png';

const KTwenty = () => {
  return (
    <div>
      <Navbar />
      <div id="K-20">
        <div className="container-fluid banner-img">
          <img src={k_20_banner} alt="" srcset="" />
        </div>
        <div className="container-fluid pt-5 pb-5 bg-light">
          <div className="container">
            <div className="row pt-5">
              <div className="col-xl-3 col-sm-12 k-20-card">
                <div class="border-0">
                  <img src={accessibility_services_main_k_20} class="card-img-top" alt="..."/>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12 k-20-card">
                <div class="border-0">
                  <img src={editorial_services_main_k_20} class="card-img-top" alt="..."/>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12 k-20-card">
                <div class="border-0">
                  <img src={content_development_services_main_k_20} class="card-img-top" alt="..."/>
                </div>
              </div>
              <div className="col-xl-3 col-sm-12 k-20-card">
                <div class="border-0">
                  <img src={digital_learning_content_services_main_k_20} class="card-img-top" alt="..."/>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-xl-6 col-sm-12">
                <div className='d-flex justify-content-center mb-3 page-heading'>
                  <h1>K-20 Education</h1>
                </div>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Content Development
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <ul>
                          <li><a href="#">Curriculum Design</a></li>
                          <li><a href="#">Translation Services</a></li>
                          <li><a href="#">Assesnment Development</a></li>
                          <li><a href="#">Project Management</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Digital Learning Content
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <ul>
                          <li><a href="#">2D/3D videos</a></li>
                          <li><a href="#">PPT Creation</a></li>
                          <li><a href="#">Game Based Learning</a></li>
                          <li><a href="#">Blended Learning</a></li>
                          <li><a href="#">Ebook Solution</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Accessibility
                      </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <ul>
                          <li><a href="#">Website Accesibillity Services</a></li>
                          <li><a href="#">Testing Services</a></li>
                          <li><a href="#">Ecommerce Accesibilty</a></li>
                          <li><a href="#">PDF,Audio,Video Accesibility</a></li>
                          <li><a href="">Software Accesibilty</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        Editional Services
                      </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <ul>
                          <li><a href="#">Content Editing</a></li>
                          <li><a href="#">Proof Reading</a></li>
                          <li><a href="#">Book Indexing</a></li>
                          <li><a href="#">Digitalization Services</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className='k20-form-dec'>
                  <div className='d-flex justify-content-center mb-3 page-heading'>
                    <h1>Contact us</h1>
                  </div>
                  <form action="https://formspree.io/f/xgegzkpj" method='POST' id='k20-form'>
                    <div class="mb-3">
                      <label for="fullname" class="form-label">Name</label>
                      <input type="text" class="form-control" id="fullname" name='fullname' placeholder="Enter your name" />
                    </div>
                    <div class="mb-3">
                      <label for="email" class="form-label">Email address</label>
                      <input type="email" class="form-control" id="email" name='email' placeholder="Enter your e-mail" />
                    </div>
                    <div class="mb-3">
                      <label for="company-name" class="form-label">Company Name</label>
                      <input type="text" class="form-control" id="company-name" name='company-name' placeholder="Enter your Company Name" />
                    </div>
                    <div class="mb-3">
                      <label for="contact-no" class="form-label">Contact no</label>
                      <input type="number" class="form-control" id="contact-no" name='contact-no' placeholder="Enter your e-mail" />
                    </div>
                    <div class="mb-3">
                      <label for="message" class="form-label">Message</label>
                      <textarea class="form-control" id="message" name='message' rows="3"></textarea>
                    </div>
                    <div id="contact-submit-btn">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default KTwenty
