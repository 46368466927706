import React from 'react'
import NavBar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
// import TrustedBy from '../TrustedBy/TrustedBy';
import TrainingNeeds from '../TrainingNeeds/TrainingNeeds';
import TrainingFeature from '../TrainingFeatures/TrainingFeature';
import OurAdvisorForm from '../CorporateOurAdvisorForm/OurAdvisorForm';
import Corporatetrainningbanner from "../../components/Corporatetrainningbanner/banner"
import GoogleReview from '../GoogleReview/Google_review';

const CorporateTrainingScreen = () => {
  return (
     
     <>
     <NavBar />
     <Corporatetrainningbanner />
    {/* <TrustedBy /> */}
    <TrainingNeeds />
    <TrainingFeature />
    <OurAdvisorForm />
    <GoogleReview />
    <Footer />
     
     </>
  )
}

export default CorporateTrainingScreen