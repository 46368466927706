import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import CourseData from "../../utils/CourseData"; // Assuming CourseData contains course information
import styles from "./CoursesPage.module.css";
import Footer from "../Footer/Footer";
import "./CoursePage.css";


const categories = [
  "All",
  "Web Development",
  "Microsoft",
  "Data Science",
  "Design",
  "Marketing",
  "SAP",
  "HR",
  "Google",
  "AWS",
  "Oracle",
  "Snowflake",
  // Changed from "Programing" to "Programming" for consistency
];

function CoursesPage() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    setFilteredCourses(
      selectedCategory === "All"
        ? CourseData
        : CourseData.filter((course) => course.category === selectedCategory)
    );
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div id="course-page">
      <div className={`${styles["desktop-view"]}`}>
        <div className={`${styles["navbar-postion"]}`}>
          <Navbar />
        </div>
        <div className={`container-fluid mb-5`}>
          <div className={`Course-container row`} id="Course-card">
            <div className={`col-3  ${styles["fixed-col"]}`}>
              <ul className={styles["grid-container"]}>
                {categories.map((category) => (
                  <div
                    key={category}
                    className={`category ${styles["category-title"]} ${selectedCategory === category ? "active" : ""
                      }`}
                    id={category.toLowerCase().replace(" ", "-")}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <li
                      style={{ padding: "0px 0px", color: "black" }}
                      className={styles.categoriesList}
                    >
                      {category}
                    </li>
                  </div>
                ))}
              </ul>
            </div>

            <div className={`posts-collect col-9 ${styles["scrollable-col"]}`}>
              <div className={``}>
                <div
                  className={`${styles["cp-title"]} page-title container d-flex flex-column justify-content-center align-items-center mt-4 mb-4`}
                >
                  <h1>Choose a category to find your course</h1>
                  <div className={`${styles["sub-title"]}`}>
                    <p>
                      100+ Live online courses chosen by 50000+ working
                      professionals
                    </p>
                  </div>
                </div>
              </div>



              <div className={styles["posts-main-container"]}>
                {filteredCourses.map(course => (
                  <div className={`all course-card ${course.category.toLowerCase().replace(" ", "-")}`} key={course.id} style={{background: "rgb(216,204,188)",
                    background: "linear-gradient(0deg, rgba(216,204,188,1) 0%, rgba(240,76,36,1) 47%)", borderRadius:"10px"}}>
                       <Link to={course.link}>
                    <div className={styles["post-content"]}>
                      {/* <div className={styles["post-content-top"]}>
                        <span><b>{course.category}</b></span>
                      </div> */}
                      <h2>{course.course_name}</h2>
                      {/* <p>{course.course_des}</p> */}
                    </div>
                    {/* <Link to={course.link} className="d-flex justify-content-center">
                      <button type="button" className={`${styles["read-btn"]}`}>View Course Details</button>
                    </Link> */}
                     </Link>
                  </div>
                 
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles["footer-size"]}`}>
          <Footer />
        </div>
      </div>



      <div className={`${styles["mobile-view"]}`}>
        <Navbar />
        <div className={`container mb-5`}>
          <div className={`${styles["cp-title"]} page-title container`}>
            <h1>Choose a category to find your course</h1>
            <div className={`${styles["sub-title"]}`}>
              <p>
                100+ Live online courses chosen by 50000+ working professionals
              </p>
            </div>
          </div>
          <div>
            <div>
              <ul className={`${styles["ul-container"]}`}>
                {categories.map((category) => (
                  <div
                    key={category}
                    className={`category ${styles["category-title"]} ${selectedCategory === category ? "active" : ""
                      }`}
                    id={category.toLowerCase().replace(" ", "-")}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <li style={{color:"black"}}>{category}</li>
                  </div>
                ))}
              </ul>
            </div>
            <div className={`posts-collect`}>
              <div className={styles["posts-main-container"]}>
                {filteredCourses.map((course) => (
                  <div
                    className={`all course-card ${course.category
                      .toLowerCase()
                      .replace(" ", "-")}`}
                    key={course.id}
                    style={{
                      background: "rgb(216,204,188)",
                      background: "linear-gradient(0deg, rgba(216,204,188,1) 0%, rgba(240,76,36,1) 47%)", borderRadius: "10px"
                    }}
                  >
                    <div className={styles["post-content"]}>
                      {/* <div className={styles["post-content-top"]}>
                        <span><b>{course.category}</b></span>
                      </div> */}
                      <h2>{course.course_name}</h2>
                      {/* <p>{course.course_des}</p> */}
                    </div>
                    {/* <Link to={course.link} className="d-flex justify-content-center">
                      <button type="button" className={`${styles["read-btn"]}`}>View Course Details</button>
                    </Link> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CoursesPage;
