import React from 'react';
import "./partnertype.css";

const partnerType = () => {
  return (
    <>
      <div id='partnertype'>
        <div className="container text-center">
          <h6 className='pb-3'>MARKET AND SELL PARTNERSTACK</h6>
          <h1 className="head mb-5">What type of partner are you?</h1>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title pt-3">Solutions Partners</h5>
                  <p className="card-text">Register deals, implement PartnerStack, and receive a commission on sales</p>
                  <a href="#" className="btn btn-primary mt-5">Apply to be a Solutions Partner <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title pt-3">Referral Partners</h5>
                  <p className="card-text">Submit leads and get rewarded when they become PartnerStack customers</p>
                  <a href="#" className="btn btn-primary mt-5">Apply to be a Referral Partner <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title pt-3">Affiliate Partners</h5>
                  <p className="card-text">Earn rewards by driving traffic to PartnerStack through your unique, trackable affiliate link</p>
                  <a href="#" className="btn btn-primary mt-5">Apply to be an Affiliate Partner <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default partnerType;
