import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import Image1 from "../../assets/images/image1.jpg";
import banner1 from "../../assets/images/banner4.png";
import banner2 from "../../assets/images/banner5.png";
import banner3 from "../../assets/images/banner6.png";
import mobilebanner1 from "../../assets/images/mobile-banner1.jpg";
import mobilebanner2 from "../../assets/images/mobile-banner2.jpg";
import mobilebanner3 from "../../assets/images/mobile-banner3.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ImageSlider.css";

SwiperCore.use([Autoplay, Navigation]);

function ImageSlider() {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        swiperInstance.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperInstance.slideNext();
      }

      // Restart autoplay timer
      swiperInstance.autoplay.start();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div id="slider" style={{ zIndex: "1" }}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false, // Allow autoplay to continue even when interacting with slides
          }}
          navigation={true}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={banner1} className="desk-img" alt="First Banner"></img>
            <img src={mobilebanner1} className="mobile-img" alt="First Banner"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={banner2} className="desk-img" alt="Second Banner"></img>
            <img src={mobilebanner2} className="mobile-img" alt="First Banner"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={banner3} className="desk-img" alt="Third Banner"></img>
            <img src={mobilebanner3} className="mobile-img" alt="First Banner"></img>
          </SwiperSlide>
          {/* <SwiperSlide>Slide 4</SwiperSlide>
          <SwiperSlide>Slide 5</SwiperSlide> */}
        </Swiper>
      </div>
    </>
  );
}

export default ImageSlider;
