import { course_images } from "./images";
const Programmingdata =[
    {
        id:1,
        categories: "java",
        course_detail_image:course_images.programing_1,
        course_overview:"Welcome to our programming with Java Fundamentals course! In this extensive instruction, learners are going on a journey to acquire the essentials of Java programming technology. From the fundamentals basic grammar and data types to more complicated subjects like Object-Oriented Development and exception management, this course covers everything that you need to know to become effective in developing in Java. Through a mixture of interactive lectures, physical coding workouts and real-life assignments, learners will get hands-on training and create a solid basis in Java programming. If you're a beginner eager to start your coding journey or an experienced programmer hoping to upgrade your skills, this course is designed to equip you to flourish in the dynamic world of Java development.",
        prerequisites:'Prerequisites for Java include understanding basic programming concepts such variables and control frameworks, familiarity with object-oriented programming principles, basic command line use, installation of JDK, knowledge of Java syntax and standard library, and experience. A text processor or Interface is recommended for coding.',
        certification:'Java certifications, like Oracle Certified Java Programmer (OCPJP), verify competency in Java programming. They cover topics such as syntax, data types, OOP ideas, and APIs. Certification levels vary, allowing paths for beginners to specialists. Earning Java certifications indicates abilities and boosts employment possibilities in software development',
        outline_heading_1:'Object-Oriented Programming (OOP)',
        outline_heading_2:'Problem Solving',
        outline_heading_3:'Application Development',
        outline_heading_4:'Database Connectivity',
        outline_heading_5:'Web Development',
        outline_heading_6:'Concurrency and Multithreading',
        outline_detail_1:'Mastery over OOP principles like classes, objects, inheritance, polymorphism, and encapsulation.',
        outline_detail_2:'Ability to solve complex problems efficiently through algorithmic thinking and data structures.',
        outline_detail_3:'Skills to develop desktop, web, and mobile applications using Java frameworks like Spring, JavaFX, and Android.',
        outline_detail_4:'Knowledge of JDBC for connecting Java applications to databases like MySQL, Oracle, or PostgreSQL.',
        outline_detail_5:'Proficiency in building web applications using Java-based technologies like Servlets, JSP, and frameworks like Spring MVC.',
        outline_detail_6:'Understanding of concurrent programming concepts for developing efficient, multithreaded applications.',
        course_faq_name:'Java Training FAQs',
        faq_question_1:'What is Java?',
        faq_question_2:'How is Java different from other programming languages?',
        faq_question_3:'What is the Java Virtual Machine (JVM)?',
        faq_question_4:'What are the key features of Java?',
        faq_question_5:'How do I get started with Java programming?',
        faq_answer_1:'Java is a high-level, object-oriented programming language developed by Sun Microsystems (now owned by Oracle Corporation). It is designed to be platform-independent, meaning that compiled Java code can run on any device that has a Java Virtual Machine (JVM) installed.',
        faq_answer_2:'Java stands out for its "write once, run anywhere" mantra, meaning code written in Java can run on any platform with a JVM. It also emphasizes security and portability, and its object-oriented nature makes it conducive to building modular and scalable applications.',
        faq_answer_3:'The JVM is an abstract computing machine that enables a computer to run Java programs. It acts as an intermediary between Java bytecode and the underlying hardware. The JVM translates Java bytecode into machine code, allowing Java programs to be executed on various platforms without modification.',
        faq_answer_4:`Java boasts several features, including:
        • Object-oriented: Java supports the creation of modular and reusable code through classes and objects.
        • Platform independence: Java code can run on any device with a JVM, making it highly portable.
        • Garbage collection: Java automatically manages memory allocation and deallocation, reducing the likelihood of memory leaks.
        • Multi-threading: Java supports concurrent programming by allowing multiple threads of execution.
        • Exception handling: Java provides robust mechanisms for handling errors and exceptions, improving program reliability.`,
        faq_answer_5:"To start programming in Java, you'll need to install the Java Development Kit (JDK), which includes tools such as the Java compiler (javac) and the JVM. You can write Java code using a simple text editor or an Integrated Development Environment (IDE) like Eclipse, IntelliJ IDEA, or NetBeans. There are numerous online resources, tutorials, and books available to help you learn Java, including Oracle's official Java documentation and tutorials. Additionally, practicing coding exercises and working on small projects can enhance your Java programming skills.",
    },
    {
        id:2,
        categories:'java script',
        course_detail_image:course_images.programing_2,
        course_overview:"This exciting course provides a complete introduction to JavaScript, the key language for developing websites. Students will study the basic principles of JavaScript coding, including variables, data types, control structures, functions, and arrays. They will delve into the Document Object Model (DOM) and learn how to modify elements in HTML and interact with web pages interactively. Practical exercises and projects will enhance theoretical knowledge, allowing students to construct interactive online apps, internet games, and dynamic user interfaces. Topics like as asynchronous programming using Promises and AJAX, error handling, and contemporary JavaScript capabilities (the ES6+) will be taught to equip students with up-to-date skills. By the end of the course, participants will gain the expertise to develop dynamic and entertaining web apps employing JavaScript.",
        prerequisites:'Basic understanding of HTML and CSS. Familiarity with programming concepts such as variables, functions, and control structures. Proficiency in using a text editor or IDE for writing code. A passion for web development and problem-solving',
        certification:'Earn certification as a JavaScript Developer. Validate your proficiency in web development, including programming fundamentals, DOM manipulation, asynchronous programming, and modern JavaScript features. Showcase your skills and advance your career in the dynamic world of web development.',
        outline_heading_1:'Object-Oriented Programming (OOP)',
        outline_heading_2:'Problem Solving',
        outline_heading_3:'Application Development',
        outline_heading_4:'Database Connectivity',
        outline_heading_5:'Web Development',
        outline_heading_6:'Concurrency and Multithreading',
        outline_detail_1:'Mastery over OOP principles like classes, objects, inheritance, polymorphism, and encapsulation.',
        outline_detail_2:'Ability to solve complex problems efficiently through algorithmic thinking and data structures.',
        outline_detail_3:'Skills to develop desktop, web, and mobile applications using Java frameworks like Spring, JavaFX, and Android.',
        outline_detail_4:'Knowledge of JDBC for connecting Java applications to databases like MySQL, Oracle, or PostgreSQL.',
        outline_detail_5:'Proficiency in building web applications using Java-based technologies like Servlets, JSP, and frameworks like Spring MVC.',
        outline_detail_6:'Understanding of concurrent programming concepts for developing efficient, multithreaded applications.',
        skill_1:'Scripting and System Administration',
        skill_2:'Machine Learning and AI',
        skill_3:'Backend Development',
        skill_4:'Automation',
        skill_5:'Data Science',
        skill_6:'Web Development',
        course_faq_name:'Java Script Training FAQs',
        faq_question_1:'What is JavaScript, and why is it important in web development?',
        faq_question_2:'What are some common applications of JavaScript?',
        faq_question_3:'What are JavaScript frameworks and libraries, and how do they differ?',
        faq_question_4:'What are closures in JavaScript, and why are they important?',
        faq_question_5:'How can I start learning JavaScript, and what resources are available?',
        faq_answer_1:"JavaScript is a versatile programming language used primarily for creating interactive and dynamic content on web pages. It's essential in web development as it allows developers to enhance user experience by adding functionality, interactivity, and responsiveness to websites.",
        faq_answer_2:"JavaScript is widely used for frontend web development to create dynamic user interfaces, form validation, interactive maps, and animations. It's also used for backend development with Node.js, serverless functions, and building cross-platform mobile and desktop applications.",
        faq_answer_3:"JavaScript frameworks like React.js, Angular, and Vue.js provide pre-built structures and components to streamline web development. Libraries like jQuery offer utilities and functions to simplify common tasks. Frameworks typically dictate the overall structure of an application, while libraries provide specific functionalities.",
        faq_answer_4:`Closures are functions that retain access to variables from their containing scope even after the scope has closed. They're important in JavaScript for creating private variables, implementing callbacks, and managing state in asynchronous operations.`,
        faq_answer_5:"Beginners can start learning JavaScript through online tutorials, interactive coding platforms, books, and courses offered by educational websites and coding bootcamps. Practicing coding challenges, building projects, and participating in online communities can also enhance learning and skill development.",
    },
    {
        id:3,
        categories:'php',
        course_detail_image:course_images.programing_3,
        course_overview:`The course gives an in-depth review of PHP, an effective server-side programming language widely used in web development. 
        Learners will study the essentials of PHP programming, including topics such as factors, data types, 
        control structures, operations, and array. They will delve into dynamic web page generation, form processing, 
        and session administration while developing practical skills in building interactive and dynamic 
        websites. Topics like as database integration with MySQL, OOP (object-oriented programming) 
        principles, and current PHP frameworks like Lumen or Symfony may also be discussed. Simulations 
        and projects will improve theoretical understanding, helping students build robust and scalable 
        applications for the web. By the end of the course, participants will acquire the expertise to 
        construct fluid and feature-rich websites using PHP.`,
        prerequisites:`Basic understanding of HTML, CSS, and programming concepts such as variables, 
        control structures, and functions. Familiarity with a text editor or IDE for writing code.`,
        certification:`Earn certification as a PHP Developer. Validate your proficiency in server-side 
        web development, database integration, and dynamic website creation. Showcase your skills in PHP programming and advance your career in web development and related fields.`,
        outline_heading_1:'Object-Oriented Programming (OOP)',
        outline_heading_2:'Problem Solving',
        outline_heading_3:'Application Development',
        outline_heading_4:'Database Connectivity',
        outline_heading_5:'Web Development',
        outline_heading_6:'Concurrency and Multithreading',
        outline_detail_1:'Mastery over OOP principles like classes, objects, inheritance, polymorphism, and encapsulation.',
        outline_detail_2:'Ability to solve complex problems efficiently through algorithmic thinking and data structures.',
        outline_detail_3:'Skills to develop desktop, web, and mobile applications using Java frameworks like Spring, JavaFX, and Android.',
        outline_detail_4:'Knowledge of JDBC for connecting Java applications to databases like MySQL, Oracle, or PostgreSQL.',
        outline_detail_5:'Proficiency in building web applications using Java-based technologies like Servlets, JSP, and frameworks like Spring MVC.',
        outline_detail_6:'Understanding of concurrent programming concepts for developing efficient, multithreaded applications.',
        skill_1:'Server-Side Web Development',
        skill_2:'Database Integration',
        skill_3:'Frameworks',
        skill_4:'Security Practices',
        skill_5:'Version Control',
        skill_6:'Troubleshooting and Debugging',
        course_faq_name:'PHP Training FAQs',
        faq_question_1:`What is PHP, and what is it used for? `,
        faq_question_2:`How do I start learning PHP? `,
        faq_question_3:`How do I start learning PHP? `,
        faq_question_4:`How does PHP compare to other server-side languages like Python or Ruby?`,
        faq_question_5:`Is PHP still relevant in today's web development landscape? `,
        faq_answer_1:`PHP is a server-side scripting language used for web development to create dynamic 
        and interactive web pages. It's commonly used for building websites, web applications, and content 
        management systems.`,
        faq_answer_2:`Beginners can start learning PHP by exploring online tutorials, courses, and 
        documentation available on websites like PHP.net. Practicing coding exercises, building projects, 
        and seeking help from online communities can also aid in learning. `,
        faq_answer_3:`Popular PHP frameworks include Laravel, Symfony, and CodeIgniter. These frameworks 
        provide pre-built components, MVC architecture, and other features that streamline web development, 
        enhance productivity, and maintain code consistency. `,
        faq_answer_4:`PHP is specialized for web development and is particularly strong in areas like 
        database integration and content management systems. While Python and Ruby are versatile 
        languages used in various domains, PHP remains a popular choice for web development due to 
        its specific focus and extensive ecosystem.`,
        faq_answer_5:`Yes, PHP remains highly relevant in web development, powering a significant 
        portion of websites and web applications on the internet. It continues to evolve with updates 
        and new features, and its large community ensures ongoing support and development.`,
    },
    {
        id:4,
        categories:'python',
        course_detail_image:course_images.programing_4,
        course_overview:`This in-depth tutorial offers an in-depth introduction to the language Python, 
        a versatile and powerful programming language widely used in different disciplines, including 
        web development, data mining, machine learning, among automation. Students will study the basics 
        of Python programming, including participants such as factors data types, structures for control, 
        functions, and packages. They will investigate objects-oriented programming ( also known as O
        fundamentals, including classes, inheritance, polymorphism, and encapsulation. Practical activities 
        and projects will enhance theoretical knowledge, enabling students to build practical abilities in 
        dealing with real-world challenges. Subjects such as file management, exception handling, working 
        with databases, and web programming using frameworks like Python or Flask may also be discussed. 
        By the end of the course, participants will gain the expertise to construct strong, scalable, and 
        efficient applications using Python.`,
        prerequisites:`Basic understanding of programming concepts such as variables, control structures, 
        and functions. Familiarity with a text editor or IDE for writing and running code. A passion for 
        learning and problem-solving`,
        certification:`Earn certification as a Python Developer. Validate your expertise in programming 
        fundamentals, object-oriented programming, data manipulation, and application development. 
        Showcase your skills and advance your career in various domains such as web development, data 
        science, and automation.`,
        outline_heading_1:'Object-Oriented Programming (OOP)',
        outline_heading_2:'Problem Solving',
        outline_heading_3:'Application Development',
        outline_heading_4:'Database Connectivity',
        outline_heading_5:'Web Development',
        outline_heading_6:'Concurrency and Multithreading',
        outline_detail_1:'Mastery over OOP principles like classes, objects, inheritance, polymorphism, and encapsulation.',
        outline_detail_2:'Ability to solve complex problems efficiently through algorithmic thinking and data structures.',
        outline_detail_3:'Skills to develop desktop, web, and mobile applications using Java frameworks like Spring, JavaFX, and Android.',
        outline_detail_4:'Knowledge of JDBC for connecting Java applications to databases like MySQL, Oracle, or PostgreSQL.',
        outline_detail_5:'Proficiency in building web applications using Java-based technologies like Servlets, JSP, and frameworks like Spring MVC.',
        outline_detail_6:'Understanding of concurrent programming concepts for developing efficient, multithreaded applications.',
        skill_1:'Scripting and System Administration',
        skill_2:'Machine Learning and AI',
        skill_3:'Backend Development',
        skill_4:'Automation',
        skill_5:'Data Science',
        skill_6:'Web Development',
        course_faq_name:'Python Training FAQs',
        faq_question_1:`What is Python, and why is it popular?`,
        faq_question_2:`Is Python easy to learn for beginners?`,
        faq_question_3:`What are some common applications of Python?`,
        faq_question_4:`Which IDEs or text editors are recommended for Python development?`,
        faq_question_5:`How can I start learning Python, and what resources are available?`,
        faq_answer_1:`Python is a high-level, versatile programming language known for its simplicity, 
        readability, and extensive libraries. It's popular for web development, data analysis, machine 
        learning, automation, and more.`,
        faq_answer_2:`Yes, Python is often recommended for beginners due to its clear syntax and 
        readability. Its straightforward nature makes it accessible for newcomers to programming.`,
        faq_answer_3:`Python is used for various purposes, including web development, data analysis, 
        scientific computing, machine learning, artificial intelligence, automation, scripting, and 
        backend development. `,
        faq_answer_4:`Popular IDEs for Python development include PyCharm, Visual Studio Code, and 
        Jupyter Notebook. Text editors like Sublime Text and Atom are also commonly used, along with the 
        Python interpreter in command-line environments.`,
        faq_answer_5:`Beginners can start learning Python through online tutorials, interactive coding 
        platforms, books, courses offered by educational websites and coding bootcamps, and community-driven 
        resources such as forums and documentation. Practice, projects, and participation in coding communities 
        are also beneficial.`,
    },
    {
        id:5,
        categories:'c++',
        course_detail_image:course_images.programing_5,
        course_overview:`This thorough course explains the fundamentals of C and C++ programming languages. 
        Covering fundamental concepts like kinds of data, control structures, functions, & pointers, it 
        gives a firm basis in knowing the basic principles of coding. Learners will delve into 
        object-oriented programming (OOP) with C++, learning about lessons, polymorphism, inheritance, 
        and isolation. Practical tasks and initiatives will enhance theoretical knowledge, strengthening 
        problem-solving skills and algorithmic thinking. Through practical training, learners will achieve 
        competency in managing memory, file handling, and debugging techniques. At the end of the course, 
        students will possess the skills essential to develop effective, robust, and scalable programs 
        employing C and C++.`,
        prerequisites:`Basic understanding of programming concepts like variables, control structures, 
        and functions. Familiarity with a text editor or IDE for writing and compiling code. 
        A willingness to learn and problem-solve.`,
        certification:`Become a certified C/C++ professional. Validate your expertise in programming 
        fundamentals, memory management, object-oriented programming, and system-level development 
        with our industry-recognized certification`,
        outline_heading_1:'Object-Oriented Programming (OOP)',
        outline_heading_2:'Problem Solving',
        outline_heading_3:'Application Development',
        outline_heading_4:'Database Connectivity',
        outline_heading_5:'Web Development',
        outline_heading_6:'Concurrency and Multithreading',
        outline_detail_1:'Mastery over OOP principles like classes, objects, inheritance, polymorphism, and encapsulation.',
        outline_detail_2:'Ability to solve complex problems efficiently through algorithmic thinking and data structures.',
        outline_detail_3:'Skills to develop desktop, web, and mobile applications using Java frameworks like Spring, JavaFX, and Android.',
        outline_detail_4:'Knowledge of JDBC for connecting Java applications to databases like MySQL, Oracle, or PostgreSQL.',
        outline_detail_5:'Proficiency in building web applications using Java-based technologies like Servlets, JSP, and frameworks like Spring MVC.',
        outline_detail_6:'Understanding of concurrent programming concepts for developing efficient, multithreaded applications.',
        course_faq_name:'C++ Training FAQs',
        faq_question_1:`What is the difference between C and C++?`,
        faq_question_2:`What are the main advantages of learning C and C++?`,
        faq_question_3:`Do I need any prior programming experience to learn C and C++?`,
        faq_question_4:`What are some common applications of C and C++?`,
        faq_question_5:`Are there any recommended IDEs or text editors for C and C++ development?`,
        faq_answer_1:`C is a procedural programming language, while C++ is an extension of C with added 
        features, including object-oriented programming (OOP) capabilities.`,
        faq_answer_2:`Learning C and C++ provides a strong foundation in programming fundamentals, 
        memory management, and understanding of how computers work at a low level. Additionally, C++'s 
        OOP features make it suitable for building complex and scalable software systems.`,
        faq_answer_3:`While prior programming experience can be helpful, it's not strictly necessary. 
        Beginners can start learning C and C++ with dedication and willingness to learn.`,
        faq_answer_4:`C and C++ are used in various fields, including systems programming, embedded 
        systems, game development, operating systems, high-performance computing, and more.`,
        faq_answer_5:`Some popular IDEs for C and C++ development include Visual Studio, Eclipse, 
        Code::Blocks, and CLion. Text editors like Sublime Text, Atom, and Vim are also commonly used.`,

    },

    {
        id:6,
categories:`Programming`,
course_detail_image:course_images.programing_1,
Course_name:`Swift Programming Fundamentals`,
Course_overview:`This course is designed to introduce participants to Swift, Apple's powerful and modern programming language used for iOS, macOS, watchOS, and tvOS development. Whether you're new to programming or have some experience with other languages, this course will cover essential Swift concepts, syntax, and best practices. By the end of the course, you'll have the foundational knowledge needed to start building your own applications using Swift.`,
Certification:`Earn a Swift Programming Fundamentals certification demonstrating skills in Swift syntax, object-oriented programming (OOP), UIKit development, error handling, closures, and app deployment basics.`,
Prerequisite:`Basic computer literacy, familiarity with operating systems (macOS preferred), and a keen interest in learning programming concepts and mobile app development.`,
Outline_heading_1:`Module 1: Introduction to Swift`,
Outline_detail_1:`Overview of Swift programming language
Setting up Xcode IDE for Swift development
Variables, constants, and data types in Swift`,
Outline_heading_2:`Module 2: Control Flow and Functions`,
Outline_detail_2:`Conditional statements (if-else, switch)
Loops (for-in, while)
Functions and parameter handling in Swift`,
Outline_heading_3:`Module 3: Object-Oriented Programming (OOP) in Swift`,
Outline_detail_3:`Classes, objects, and instances
Properties and methods
Inheritance, polymorphism, and encapsulation`,
Outline_heading_4:`Module 4: Swift Collections and Error Handling`,
Outline_detail_4:`Arrays, dictionaries, and sets
Optionals and nil handling
Error handling using try-catch`,
Outline_heading_5:`Module 5: iOS App Development Basics`,
Outline_detail_5:`Introduction to UIKit framework
Building user interfaces (UI) with Interface Builder
Handling user interactions and events`,
Outline_heading_6:`Module 6: Advanced Topics in Swift`,
Outline_detail_6:`Closures and completion handlers
Protocol-oriented programming (POP)
Memory management and ARC (Automatic Reference Counting)`,
Outline_heading_7:`Module 7: App Deployment and Distribution`,
Outline_detail_7:`Overview of app deployment process for iOS devices
Testing and debugging apps in Xcode
App Store submission guidelines`,
Outline_heading_8:`Module 8: Project Work`,
Outline_detail_8:`Apply learned concepts to develop a simple iOS app
Incorporate UI design, data management, and user interaction features`,


Skill_1:`Swift Syntax`,
Skill_2:`Object-Oriented Programming`,
Skill_3:`UIKit Development`,
Skill_4:`Error Handling`,
Skill_5:`Closures`,
Skill_6:`
App Deployment Basics`,
Course_faq_name:`Swift Programming Fundamentals`,
Faq_question_1:`Is Swift difficult for beginners?`,
Faq_answer_1:`Swift is beginner-friendly with modern syntax and powerful features.`,
Faq_question_2:`Do I need a Mac to learn Swift?`,
Faq_answer_2:`Yes, Swift development requires a Mac computer to use Xcode, Apple's IDE.`,
Faq_question_3:`What can I create after learning Swift?`,
Faq_answer_3:`You can create iOS, macOS, watchOS, and tvOS applications.`,
Faq_question_4:`How long does it take to learn Swift?`,
Faq_answer_4:`The time varies, but basic proficiency can be achieved in a few months.`,
Faq_question_5:`Can I get a job with Swift programming skills?`,
faq_answer_5:`Yes, Swift developers are in demand for iOS app development roles.`,
    },

{
    id:7,
Categories:`programming `,
course_detail_image:course_images.programing_1,
Course_name:`c#`,
Course_overview:``,
Certification:`This certification validates proficiency in C# programming, covering essential concepts and practical skills. Participants demonstrate competence in topics such as object-oriented programming, .NET Framework, LINQ, asynchronous programming, error handling, and database integration. Earn this certification to showcase your expertise in C# development.`,
Prerequisite:`Basic understanding of programming concepts, familiarity with any programming language, and knowledge of fundamental computer science principles such as variables, data types, control structures, and algorithms.`,
Outline_heading_1:`Introduction to C#`,
Outline_detail_1:`Overview of C# language
History and development
Setting up development environment`,
Outline_heading_2:`Basic Syntax and Data Types`,
Outline_detail_2:`Variables and constants
Primitive data types
Operators and expressions`,
Outline_heading_3:`Control Structures`,
Outline_detail_3:`Conditional statements (if-else, switch)
Looping structures (for, while, do-while)
Exception handling (try-catch)`,
Outline_heading_4:`Object-Oriented Programming`,
Outline_detail_4:`Classes and objects
Inheritance and polymorphism
Encapsulation and abstraction`,
Outline_heading_5:`Arrays and Collections`,
Outline_detail_5:`Arrays and lists
ArrayLists and generic collections
Working with dictionaries and queues
`,
Outline_heading_6:`Working with Files and Streams`,
Outline_detail_6:`Reading and writing text files
Serialization and deserialization
Handling file input and output`,
Outline_heading_7:`Introduction to LINQ`,
Outline_detail_7:`LINQ basics and syntax
Querying collections with LINQ
LINQ to SQL and LINQ to XML`,
Outline_heading_8:`Asynchronous Programming`,
Outline_detail_8:`Understanding asynchronous programming
Using async and await keywords
Handling asynchronous tasks and callbacks`,
Outline_heading_9:`Working with Databases`,
Outline_detail_9:`Connecting to databases (SQL Server, SQLite)
Performing CRUD operations
Using Entity Framework for ORM`,
Outline_heading_10:`Advanced Topics`,
Outline_detail_10:`Delegates and events
Generics and type constraints
Multithreading concepts`,


Skill_1:`Object-Oriented Programming`,
Skill_2:`.NET Framework`,
Skill_3:`LINQ`,
Skill_4:`Asynchronous Programming`,
Skill_5:`Error Handling`,
Skill_6:`Database Integration`,
Course_faq_name:`C#`,
Faq_question_1:`What is C#?`,
Faq_answer_1:`C# is a modern, versatile programming language developed by Microsoft for building a variety of applications on the .NET platform.`,
Faq_question_2:`What are the key features of C#?`,
Faq_answer_2:`Key features include strong typing, scalability, language interoperability, and rich library support.`,
Faq_question_3:`How is memory managed in C#?`,
Faq_answer_3:`C# uses automatic memory management via garbage collection, which deallocates memory that is no longer needed.`,
Faq_question_4:`How does C# handle exceptions?`,
Faq_answer_4:`C# uses try-catch blocks for exception handling, allowing developers to gracefully manage and recover from runtime errors.`,
Faq_question_5:`What is LINQ in C#?`,
faq_answer_5:`LINQ (Language Integrated Query) is a powerful feature that allows querying data from different data sources using a uniform syntax within C#.`,
},

{
    id:8,
categories:`Programming`,
course_detail_image:course_images.programing_1,
Course_name:`Kotlin Programming`,
Course_overview:`This comprehensive course covers the fundamentals of Kotlin programming, suitable for beginners and those transitioning from Java. Topics include Kotlin syntax, object-oriented and functional programming concepts, Android app development with Kotlin, coroutines, and using Kotlin's standard library.`,
Certification:`This certifies that [Name] has successfully completed the "Kotlin Programming Essentials" course, demonstrating proficiency in Kotlin syntax, object-oriented programming, functional programming concepts, Android app development with Kotlin, coroutines, and utilizing Kotlin's standard library.`,
Prerequisite:`Basic understanding of programming concepts, familiarity with object-oriented programming principles, and experience with Java or another programming language. No prior Kotlin knowledge required but beneficial.`,
Outline_heading_1:`Introduction to Kotlin`,
Outline_detail_1:`Overview of Kotlin language
Kotlin vs. Java and other languages
Setting up Kotlin development environment`,
Outline_heading_2:`Kotlin Basics`,
Outline_detail_2:`Variables and data types
Control flow: if-else, loops, when expression
Functions and lambdas
Nullable types and safe calls`,
Outline_heading_3:`Object-Oriented Programming in Kotlin`,
Outline_detail_3:`Classes and objects
Properties and fields
Constructors and initialization
Inheritance and interfaces
Overriding methods and properties`,
Outline_heading_4:`Functional Programming with Kotlin`,
Outline_detail_4:`Higher-order functions
Function types and lambdas
Inline functions and reified types
Functional programming patterns`,
Outline_heading_5:`Collections and Generics`,
Outline_detail_5:`Kotlin collection types: lists, sets, maps
Immutable collections and mutable collections
Working with collections: filter, map, reduce
Introduction to generics and type variance`,
Outline_heading_6:`Coroutines and Asynchronous Programming`,
Outline_detail_6:`Understanding coroutines
Asynchronous programming with suspend functions
Handling concurrency and parallelism`,
Outline_heading_7:`nteroperability with Java`,
Outline_detail_7:`Using Kotlin with existing Java code
Java interoperability features
Kotlin annotations for Java interoperability`,
Outline_heading_8:`Android Development with Kotlin (Optional)`,
Outline_detail_8:`Overview of Kotlin for Android
Building Android apps with Kotlin`,
Outline_heading_9:`Best Practices and Advanced Topics`,
Outline_detail_9:`Kotlin idioms and best practices
Advanced features: delegation, sealed classes, inline classes
Tips for efficient Kotlin programming`,
Outline_heading_10:`Project Work and Exercises`,
Outline_detail_10:`Implementing small projects and exercises using Kotlin
Hands-on assignments to reinforce learning`,
Skill_1:`Kotlin Syntax`,
Skill_2:`Object-Oriented Programming in Kotlin`,
Skill_3:`Functional Programming Concepts`,
Skill_4:`Android App Development with Kotlin`,
Skill_5:`Kotlin Coroutines`,
Skill_6:`Kotlin Standard Library Usage`,
Course_faq_name:`Kotlin Programming`,
Faq_question_1:`Q: What is Kotlin?`,
Faq_answer_1:`A: Kotlin is a modern programming language used for developing applications on the Java Virtual Machine (JVM) and Android. It combines object-oriented and functional programming features.`,
Faq_question_2:`Q: Why should I learn Kotlin?`,
Faq_answer_2:`A: Kotlin offers concise syntax, null safety, interoperability with Java, and is officially supported for Android development, making it a valuable skill for software developers.`,
Faq_question_3:`Q: Is Kotlin difficult to learn?`,
Faq_answer_3:`A: Kotlin is relatively easy to learn, especially if you have prior programming experience. Its syntax is concise and intuitive, and it offers many modern programming features.`,
Faq_question_4:`Q: How can I use Kotlin for Android development?`,
Faq_answer_4:`A: Kotlin is fully interoperable with Java and can be used to develop Android apps. Android Studio supports Kotlin, and many new Android projects are being written in Kotlin`,
Faq_question_5:`Q: Where can I apply Kotlin programming skills?`,
faq_answer_5:`A: Kotlin skills are in demand for Android app development, backend development, web development, and more. It is widely used in the industry due to its modern features and Java compatibili`,
},

    // Marketing // Marketing // Marketing // Marketing // Marketing // Marketing // Marketing // Marketing // Marketing // Marketing 
    {
        id:30,
        categories: `Marketing`,
        course_detail_image:course_images.marketing_1,
        course_name:`Email marketing`,
        course_overview:`This Email Marketing course equips participants with essential skills to harness the power of email for effective customer engagement and business growth. Covering foundational principles, list building, compelling content creation, automation, compliance, analytics, and advanced strategies, students learn to craft personalized campaigns that drive results. Practical exercises, case studies, and a final project ensure hands-on application of learned concepts. Ideal for marketers, entrepreneurs, and small business owners seeking to maximize their email marketing potential, this course delivers actionable insights and strategies to succeed in today's digital landscape`,
        certification:`Upon successful completion of the course and final project, participants will receive a certification in Email Marketing, showcasing their proficiency in the subject matter
        
        *Duration:*
        The course is designed to be completed over  1 Month Additionally, participants will be expected to dedicate time outside of class for assignments and project work.`,
        prerequisite:`There are no formal prerequisites for this course; however, a basic understanding of digital marketing concepts and familiarity with using email platforms would be beneficial.
        
        *Delivery Method:*
        The course will be delivered through a combination of instructor-led lectures, interactive discussions, hands-on exercises, and practical assignments. Participants will have access to learning materials, resources, and support through an online learning platform.`,
        outline_heading_1:`Module 1: Introduction to Email Marketing`,
        outline_heading_2:`Module 2: Building an Email List`,
        outline_heading_3:`Module 3: Crafting Compelling Emails`,
        outline_heading_4:`Module 4: Email Automation`,
        outline_heading_5:`Module 5: Email Deliverability and Compliance`,
        outline_heading_6:`Module 6: Email Analytics and Optimization`,
        outline_heading_7:`Module 7: Advanced Email Marketing Strategies`,
        outline_heading_8:`Module 8: Email Marketing Case Studies`,
        outline_heading_9:`Module 9: Creating an Email Marketing Strategy`,
        outline_heading_10:`Module 10: Email Marketing Tools and Resources-Mailchimp, Brevo Etc`,
        
        
        outline_detail_1:`- Understanding the importance of email marketing
        - Overview of email marketing tools and platforms
        - Exploring the benefits and challenges of email marketing`,
        outline_detail_2:`- Strategies for building an email list
        - Creating lead magnets and opt-in forms
        - Best practices for collecting email addresses`,
        outline_detail_3:`- Writing effective subject lines
        - Designing visually appealing email templates
        - Optimizing email content for engagement and conversion`,
        outline_detail_4:`- Introduction to email automation
        - Setting up automated email sequences
        - Utilizing triggers and segmentation for personalized campaigns
        
        `,
        outline_detail_5:`- Understanding email deliverability factors
        - Best practices for improving email deliverability
        
        `,
        outline_detail_6:`- Tracking key email marketing metrics
        - Analyzing campaign performance
        - A/B testing for optimization
        `,
        outline_detail_7:`- Email personalization techniques
        - Lifecycle email marketing strategies
        - Integrating email with other marketing channels
        
        `,
        outline_detail_8:`- Real-world examples of successful email marketing campaigns
        - Analyzing case studies for insights and inspiration
        
        `,
        outline_detail_9:`- Developing a comprehensive email marketing strategy
        - Setting goals and KPIs
        - Creating a content calendar and campaign schedule`,
        outline_detail_10:`- Overview of popular email marketing tools
        - Resources for staying updated on email marketing trends
        - Q&A and troubleshooting session`,
        
        skill_1:`Email List Building`,
        skill_2:`Content Creation`,
        skill_3:`Automation Mastery`,
        skill_4:`Analytics Interpretation`,
        skill_5:`Compliance Understanding`,
        skill_6:`Strategy Development`,
        course_faq_name:`Email marketing`,
        faq_question_1:`Do I need prior experience in marketing to enroll in this course?`,
        faq_question_2: `What email marketing platforms will be covered in the course?`,
        faq_question_3:`How will the course address GDPR and other compliance regulations?`,
        faq_question_4:`an I expect hands-on experience with email marketing tools during the course?`,
        faq_question_5:`Q: Will I receive support and feedback from instructors throughout the course?`,
        faq_answer_1:`No prior marketing experience is required. This course is suitable for beginners and professionals alike who want to learn the fundamentals and advanced strategies of email marketing.`,
        faq_answer_2:`The course covers a range of email marketing platforms and tools, including popular options like Mailchimp, Constant Contact, and HubSpot, among others. However, the focus is on teaching fundamental principles applicable across various platforms.`,
        faq_answer_3:`Compliance with GDPR and other regulations is an essential aspect of email marketing covered extensively in the course. Students will learn best practices for obtaining consent, managing subscriber preferences, and ensuring compliance with relevant laws.`,
        faq_answer_4:`Yes, the course includes practical exercises and assignments where students will have the opportunity to work with email marketing tools, create campaigns, and analyze results. This hands-on experience will reinforce learning and skill development.`,
        faq_answer_5:`Absolutely. Instructors will be available to provide support, guidance, and feedback on assignments and projects. Additionally, there will be opportunities for Q&A sessions and discussions to address any questions or concerns students may have.`,
        
    },
        
        {
        id:31,
        categories: `Marketing`,
        course_detail_image:course_images.marketing_2,
        course_name:`Digital marketing`,
        course_overview:`This Digital Marketing course provides a comprehensive understanding of key digital marketing concepts and strategies, including SEO, SEM, social media marketing, content marketing, and analytics. Participants will learn to develop effective digital marketing campaigns that drive traffic, engagement, and conversions across various online channels.`,
        prerequisite:`Basic knowledge of marketing principles and familiarity with using internet and social media platforms are recommended. No prior experience in digital marketing is required.`,
        certification:`Upon successful completion of the course and passing the final assessment, participants will receive a Digital Marketing Certification, demonstrating their proficiency in implementing digital marketing strategies and tactics.`,
        outline_heading_1:`Week 1: Introduction to Digital Marketing`,
        outline_heading_2:`Week 2: Website Essentials`,
        outline_heading_3:`Week 3: Search Engine Optimization (SEO)`,
        outline_heading_4:`Week 4: Search Engine Marketing (SEM)`,
        outline_heading_5:`Week 5: Social Media Marketing`,
        outline_heading_6:`Week 6: Content Marketing`,
        outline_heading_7:`Week 7: Email Marketing`,
        outline_heading_8:`Week 8: Analytics and Strategy Optimization`,
        outline_heading_9:`Final Project`,        
        outline_detail_1:`Overview of digital marketing landscape
        - Importance and benefits of digital marketing`,
        outline_detail_2:`- Overview of digital marketing landscape
        - Importance and benefits of digital marketing`,
        outline_detail_3:`- Basics of website development and design
        - Website optimization for user experience and conversion`,
        outline_detail_4:`- Introduction to search engine marketing
        - Creating and optimising Google Ads campaigns`,
        outline_detail_5:`- Platforms overview: Facebook, Instagram, Twitter, LinkedIn
        - Content creation and engagement strategies`,
        outline_detail_6:`- Content strategy development
        - Blogging, video marketing, and other content formats`,
        outline_detail_7:`- Building an email list
        - Crafting effective email campaigns and automation`,
        outline_detail_8:`- Introduction to digital analytics tools
        - Analyzing campaign performance and optimizing strategies`,
        outline_detail_9:`- Students will develop a comprehensive digital marketing plan integrating concepts learned throughout the course, including website optimization, SEO, SEM, social media, content, email marketing, and analytics.`,
        
        skill_1:`SEO and SEM`,
        skill_2:`Social Media Marketing`,
        skill_3:`Content Strategy`,
        skill_4:`Analytics Interpretation`,
        skill_5:`Campaign Planning`,
        skill_6:`Conversion Optimization`,
        course_faq_name:`Digital marketing`,
        faq_question_1:`Is prior experience in marketing required for this course?`,
        faq_question_2:`What type of certification will I receive upon completion?`,
        faq_question_3:`Can I take this course if I have limited technical skills?`,
        faq_question_4:`How long does it take to complete the course?`,
        faq_question_5:`Will I have access to course materials after completion?`,
        faq_answer_1:`No, while basic marketing knowledge is beneficial, this course is suitable for beginners with no prior experience in digital marketing.`,
        faq_answer_2:`You will receive a Digital Marketing Certification, indicating your proficiency in implementing various digital marketing strategies and tactics.`,
        faq_answer_3:` Yes, this course is designed to be accessible to individuals with varying levels of technical expertise.`,
        faq_answer_4:`The duration of the course varies, but typically it can be completed within 2 months.`,
        faq_answer_5:`Yes, participants will have access to course materials and resources for a specified period after completing the course.`,
    },

// Design 
    {
    id:32,
    categories:`Design`,
    course_detail_image:course_images.design_2,
    course_name:`Canva + Photoshop`,
    course_overview:`Dive into the world of digital creativity with our Graphic Design course, featuring Canva and Photoshop. Over one month, you’ll master design fundamentals, explore visual storytelling, and create compelling content. Perfect for beginners and intermediate designers, this course offers hands-on experience, ensuring you emerge as a proficient graphic designer ready to tackle industry challenges.`,
    certification:`Upon successful completion, receive a certificate validating your skills in graphic design, recognized by industry professionals and peers, enhancing your portfolio and job prospects.`,
    prerequisite:`No prior experience needed. Access to a computer with internet and a passion for learning graphic design are the only requirements.`,
    outline_heading_1:`Week 1: Introduction to Graphic Design`,
    outline_detail_1:`
    Day 1: Overview of Graphic Design
    Day 2: Introduction to Canva: Interface and Tools
    Day 3: Basic Design Elements and Principles
    Day 4: Creating Simple Designs in Canva
    Day 5: Review and Practice Assignments`,
    outline_heading_2:`Week 2: Exploring Canva`,
    outline_detail_2:`Day 6: Advanced Canva Features
    Day 7: Designing with Templates
    Day 8: Typography and Color Theory in Canva
    Day 9: Branding and Marketing Materials
    Day 10: Review and Practice Assignments`,
    outline_heading_3:`Week 3: Mastering Photoshop`,
    outline_detail_3:`Day 11: Introduction to Photoshop: Interface and Tools
    Day 12 Basic Photo Editing and Manipulation
    Day 13 Layering and Masking Techniques
    Day 14 Text Effects and Graphic Elements
    Day 15 Review and Practice Assignments`,
    outline_heading_4: `Week 4 Integration and Project Work`,
    outline_detail_4:`Day 16 Combining Canva and Photoshop Skills',
    Day 17:Workflow Optimization
    Day 18: Final Project Briefing
    Day 19-20: Final Project Work
    Day 21: Final Review and Course Completion`,
    skill_1:`Design Principles`,
    skill_2:`Canva Proficiency`,
    skill_3:`Photoshop Techniques`,
    skill_4:`Visual Branding`,
    skill_5:`Content Creation`,
    skill_6:`Professional Workflow`,
    course_faq_name:`FAQs`,
    faq_question_1:`Do I need prior design experience?.`,
    faq_answer_1:`No, the course is tailored for all skill levels`,
    faq_question_2:`Is the software provided? Canva is free`,
    faq_answer_2:` Photoshop requires a subscription.`,
    faq_question_3:`Are there any additional costs?`,
    faq_answer_3:`Apart from Photoshop, no other costs are involved.`,
    faq_question_4:`Can I get a job after this course?`,
    faq_answer_4:` Yes, the skills are marketable and in demand.`,
    faq_question_5:`Is online support available?`,
    faq_answer_5:`Yes, tutors provide guidance throughout the course.`,
},

{
    id:33,
    categories:`Design`,
    course_detail_image:course_images.design_1,
    course_name:`Canva for 1 month`,
    course_overview:`Canva Essentials (1-Month Program)`,
    certification:`This certification recognizes expertise in Canva, a graphic design platform. Holder has demonstrated skills in creating stunning visuals, designing presentations, editing images, and using templates effectively. This credential validates proficiency in Canva tools and design principles`,
    prerequisite:`Upon completion, participants will be proficient in using Canva for creating professional-quality graphics, presentations, and documents, and will possess essential design skills applicable to various personal and professional project`,
    outline_heading_1:`Week 1: Introduction to Canva and Basic Design Principles`,
    outline_detail_1:`Overview of Canva's interface and features
    Understanding design fundamentals (layout, color, typography)
    Creating simple graphics and social media posts`,
    outline_heading_2:`Week 2: Advanced Design Techniques`,
    outline_detail_2:`Exploring advanced features in Canva (layers, effects, grids)
    Designing custom graphics for different purposes (flyers, infographics)
    Introduction to image editing tools and techniques`,
    outline_heading_3:`Week 3: Presentation and Document Design`,
    outline_detail_3:`Creating engaging presentations and slides using Canva
    Designing documents such as resumes, reports, and newsletters
    Utilizing Canva's templates and layouts effectively`,
    outline_heading_4:`Week 4: Branding and Collaboration`,
    outline_detail_4:`Implementing branding elements (logos, colors, fonts) in Canva designs
    Collaborating with teams and clients using Canva's sharing and collaboration tools
    Final project: Design a comprehensive portfolio showcasing various Canva skills`,
    skill_1:`Graphic Design`,
    skill_2:`Presentation Design`,
    skill_3:`Image Editing`,
    skill_4:`Template Utilization`,
    skill_5:`Typography Skills`,
    skill_6:`Branding and Layout Design`,
    course_faq_name:`FAQs`,
    faq_question_1:`1. What is Canva?`,
    faq_answer_1:`Canva is a user-friendly graphic design platform that allows users to create a wide range of visuals, including social media graphics, presentations, posters, and more.`,
    faq_question_2:`2. How do I get started with Canva?`,
    faq_answer_2:`Simply sign up for a free account on Canva's website or app to access a wide variety of templates and design tools.`,
    faq_question_3:`3. What types of designs can be created using Canva?`,
    faq_answer_3:`Canva supports the creation of designs for social media, marketing materials, presentations, invitations, resumes, and more.`,
    faq_question_4:`4. Is Canva suitable for beginners?`,
    faq_answer_4:`Yes, Canva's intuitive interface and vast library of templates make it easy for beginners to create professional-looking designs quickly.`,
    faq_question_5:`5. Can I use Canva for commercial purposes?`,
    faq_answer_5:`Yes, Canva offers both free and paid plans that cater to personal and commercial use, providing access to additional features and resources for professional design projects`,
},
        

    // (ID 100-129 ) Microsoft// //Microsoft// //Microsoft// //Microsoft// //Microsoft// //Microsoft//  //Microsoft// //Microsoft// //Microsoft//  
{
    id:100,
    categories:'Trainng AI 102T00 Designing Implementing a Microsoft Azure AI Solution',
    course_name: "Trainng AI 102T00 Designing Implementing a Microsoft Azure AI Solution",
    course_detail_image:course_images.microsoft_365,
    course_overview:"AI-102 Training Overview Software developers who wish to create AI-infused apps that make use of Microsoft Bot Framework, Azure Cognitive Services, and Azure Cognitive Search should read Designing & Implementing an Azure AI Solution. Python or C# will be the programming language used in the course.Time: Four Days",
    prerequisites: 'Professionals who wish to attend this training must possess the following:• Proficiency in Python or C# • Understanding of Microsoft Azure and the Azure site • Comfort with JSON and REST programming conceptsTo learn Python or C#,finish the free Prior to the course, take your first steps using the Python or C# paths.If you are new to AI and would want an overview of what Azure can do with AI, you might want to take the Azure AI Fundamentals certification before this one',
    certification:'Exam AI-102: Designing & Implementing a Microsoft Azure AI Solution is one of the required exams.',
    outline_heading_1:'Get ready to create AI applications on Azure.',
    outline_heading_2:'Produce and utilize cognitive services.',
    outline_heading_3:'Using the Speech service to create speech-enabled apps',
    outline_heading_4:'Create a solution to a question.',
    outline_heading_5:'Use the computer vision service to read text from images and documents.',
    outline_detail_1:`You will be able to do the following after completing this module: define artificial intelligence; 
    • Comprehend terms related to AI; 
    • Comprehend issues for AI engineers; 
    • Comprehend issues for responsible AI; 
    • Comprehend the capabilities of Azure Machine Learning; 
    • Comprehend the capabilities of Azure Cognitive Services; 
    • Comprehend the capabilities of the Azure Bot Service; 
    • Comprehend the capabilities of Azure Cognitive Search.`,
    outline_detail_2:`The availability of Cognitive Services resources through an Azure membership.Determine the locations, keys, and endpoints needed to access a Cognitive Services resource.Making use of a REST API to access cognitive services.Making use of an SDK to access cognitive services.Safe Mental Health ServicesManage network security for cognitive services; take authentication into account.Observe cognitive services; track charges associated with them; generate warnings; view metrics; and control diagnostic logging.Cognitive services can be deployed in containers, containers can be created for reuse, containers can be secured, containers can be deployed to, and containers may be used to consume cognitive services.Making sense of text using the Language serviceLanguage detection; key phrase extraction; sentiment analysis; entity extraction; and entity extraction with linkageUse the Translator service to translate text.Provide a resource for translators; comprehend language identification, translation, and transliteration; outline bespoke translations; and specify translation alternatives`,
    outline_detail_3:`• Setting up an audio format and voices; utilizing Speech Synthesis Markup Language (SSML); utilizing the Speech-to-Text API for speech recognition; 
    • utilizing the Text-to-Speech API for speech synthesis;
    • providing an Azure resource for the Speech serviceUse the speech service to translate speech.Make voice translation resources available on Azure.Produce speech to text translation.Compile verbatim translations.Create a Language Understanding model;
    • Describe intents, utterances, and entities; Use patterns to distinguish between similar utterances; 
    • Provide Azure resources for Language Understanding;Utilize prefabricated entity componentsDevelop, test, publish, and evaluate a model for language understandingLaunch and utilize an app for language understandingUtilize a language-understanding app in a container; 
    • Recognize the capabilities of the app; 
    • Process the predictions made by the app;`,
    outline_detail_4:`Create a knowledge base, comprehend question answers, compare question answers to language understanding, implement multi-turn conversations, test and publish knowledge bases, consume knowledge bases, and engage in active learning.Make a bot that can respond to inquiries.Using the Bot Framework SDK to create a bot; 
    • comprehending the fundamentals of bot design; building a bot with the SDK; 
    • Deploying a bot to AzureUsing the Bot Framework Composer to Create a BotComprehending dialogues;
    • Arranging the flow of a discussion; creating a bot using the Bot Framework Composer; 
    • Designing the user experienceExamine pictures, offer a computer vision resource, examine pictures;
    • Create an intelligently cropped thumbnail.Use the Video Analyzer for Media widgets and APIs to: 
    • Analyze videos; 
    • Describe the capabilities of Video Analyzer for Media; 
    • Extract unique insights;Sort photos; 
    • Set up Azure resources for Custom Vision; 
    • Recognize how to classify imagesLearn how to classify imagesIdentify items in photos; 
    • Provide Azure resources for Custom Vision; Gain knowledge of object detection; 
    • Develop an object detector; 
    • Take into account alternatives for image labeling Detect, analyze, and recognize faces; 
    • Identify choices for face detection, analysis, and identification; 
    • Comprehend face analysis concerns; 
    • Detect faces using the Computer Vision service; 
    • Comprehend the Face services capabilities compare and match detected faces;
    • Put face recognition to use`,
    outline_detail_5:`Using the Computer Vision service with SDKs and the REST API;
     • Reading text from photos using the Read API; 
     • Creating an application that can read handwritten and printed textUsing Form Recognizers Optical Character Recognition (OCR) capabilities with SDKs, REST API, and Form Recognizer Studio, you can: 
     • Extract data from forms; • Determine how layout service, prebuilt models, and custom service can automate processes; 
     • Develop and test custom models.Construct a Cognitive Search Solution for AzureConstruct a search application; 
     • Build an Azure Cognitive Search SolutionThe process of developing a bespoke skill for Azure Cognitive Search, putting it into use, and integrating it into a skillset for Azure Cognitive SearchUtilize Azure Cognitive Search to create a knowledge store; Construct a knowledge store from an Azure Cognitive Search pipeline; 
     • Access data in projections within a knowledge store`,
    skill_1:'Advanced Machine Learning Techniques',
    skill_2:'Create and oversee a solution using Azure Cognitive Services (10–15%)',
    skill_3:'Apply solutions for computer vision (20–25%)',
    skill_4:'Use solutions for natural language processing (25–30%)',
    skill_5:'Put knowledge mining solutions into practice (15–20%)',
    skill_6:'Use conversational AI programs (10–15%)',
    course_faq_name:'FAQs',
    faq_question_1:'What does it mean to build and implement an AI solution for Microsoft Azure?',
    faq_question_2:'What does Azure AI stand for?',
    faq_question_3:'What role does Azure play in AI?',
    faq_question_4:'What is the purpose of Azure Websites Migration Assistant tool?',
    faq_question_5:'What are the categories of web applications that Azure can be used to deploy?',
    faq_answer_1:'A: AI-102 Software developers who wish to create AI-infused apps that make use of Microsoft Bot Framework, Azure Cognitive Search, and Azure Cognitive Services should read Designing & Implementing an Azure AI Solution. Python or C# will be the programming language used in the course.',
    faq_answer_2:'A fully managed service to help protect your virtual machines from remote access. Firewall for Web Applications. a web application firewall (WAF) service that is cloud-native and offers strong protection for online applications. Firewall in Azure. Use cloud-native network security to safeguard the resources on your Azure Virtual Network.',
    faq_answer_3:'A: Use Azure Machine Learning and Azure Databricks to quickly and simply construct, train, and deploy your machine learning models. Utilize the most recent tools, such as Jupyter and Visual Studio Code, in conjunction with frameworks, such as TensorFlow, Scikit-Learn, and PyTorch on Azure.',
    faq_answer_4:`A: The user can check your IIS installation with the aid of the migration assistant program. The user may identify which website is suitable for cloud migration with its help. Generally, it includes parts that need to be supported or moved to the Azure platform.`,
    faq_answer_5:"A: SQL Azure may be used to deploy web apps written in ASP.Net, PHP, and WCF.",
},

{
id:101,
categories: ``,
course_detail_image:course_images.microsoft_power_platform,
course_name:`PL-500T00: Microsoft Power Automate RPA Developer`,
course_overview:`Candidates for this course automate time-consuming & repetitive operations by utilising Microsoft Power Automate. They assess solution requirements, make process documentation, & design, develop, troubleshoot, & evaluate solutions. Candidates work with corporate stakeholders to optimise & automate business procedures. They work with administrators to implement solutions in live settings and provide solution support.
Duration: 4 Days`,
certification:`Candidates for this exam automate time-consuming & repetitive operations by using Microsoft Power Automate. They assess solution requirements, make process documentation, & design, develop, troubleshoot, & evaluate solutions.
Candidates improve and automate business procedures in partnership with business stakeholders. In order to deploy solutions to production environments, they interact with administrators. They also provide solution assistance.
Candidates should also be comfortable with JSON, desktop, and Cloud processes, integrating solutions with REST and SOAP services, and analyzing data in a programming language, such as JavaScript, HTML, VBScript, Visual Basic for Applications (VBA), or Microsoft Excel. They should also have familiarity with monitoring and analyzing the health of flows and the Microsoft Power Platform toolbox (process advisor, AI Builder, Power Apps, Dataverse, & Power Virtual Agents).`,
prerequisite: `Candidates should be familiar with JSON, Cloud flows, and Azure services, as well as how to integrate solutions with REST and SOAP services and analyze data using Microsoft Excel, Visual Basic for Applications (VBA), VBScript, HTML, JavaScript, one or more programming languages, & the Microsoft Power Platform suite of tools (AI Builder, Power Apps, Dataverse, & Power Virtual Agents).`,
outline_heading_1:`Begin your initial steps with Power Automate for desktop `,
outline_heading_2:`Power Automate for desktop development essentials`,
outline_heading_3:`Make your first Power Automate for desktop flow`,
outline_heading_4:`Define input & output parameters in Power Automate`,
outline_heading_5:`predetermined inputs & capturing application data for output.`,
outline_heading_6:`Integrate desktop flows with Outlook connector in Power Automate for desktop`,
outline_heading_7:`Connect a cloud flow to desktop flows in Power Automate for desktop`,
outline_heading_8:`Use AI Builder to process invoice forms in Power Automate`,
outline_heading_9:`Use the Teams connector in Power Automate`,
outline_heading_10:`Run a Power Automate for desktop flow in unattended mode`,
outline_detail_1:`● Explore the console & the flow designer.
● Understand how to use the key features of Power Automate for the desktop platform.
● Make & operate a flow.`,
outline_detail_2:`● Make a flow from scratch using the flow designer 
● Understand how to use actions & variables
 ● Understand the testing & debugging features of the flow designer 
● Configure the error-handling properties of individual actions 
● Comprehend the use of UI elements & images
 ● Use subflows to compartmentalise & reuse parts of a flow`,
outline_detail_3:`● Understand Power Automate for desktop flows.
● Understand how to make a fresh desktop flow.
● Record actions that are performed in the desktop-based Contoso Invoicing program.
● Run the new desktop flow through its paces.`,
outline_detail_4:`● Understand how to construct a desktop flow with predetermined inputs.
● Record actions performed in the Contoso invoicing desktop application by using your`,
outline_detail_5:`● Run a test run of a new desktop flow with a different set of inputs.`,
outline_detail_6:`● Make a new solution to bundle the whole Contoso invoice processing system.
● Make a new cloud flow that commences the invoicing process through an email receipt.
● Integrate a desktop flow into a cloud flow. 
● Test the new cloud flow.`,
outline_detail_7:`● Make a cloud flow.
● Configure the on-premises data gateway to enable the cloud flow to run the desktop flow on your device.
● Add the desktop flow to the new cloud flow.
● Test the new cloud flow.`,
outline_detail_8:`● Build a new AI model for form processing.
● Train the new AI model to process Contoso Invoice forms.
● Integrate the new AI model into an existing cloud pipeline.
● Test the modified cloud flow & accompanying AI Builder-based forecasts.`,
outline_detail_9:`● Integrate a Microsoft Teams-based approval model & the related conditional logic into an existing cloud flow.
● Integrate the desktop flow for submitting the invoice information into the approval procedure.
● Integrate Microsoft Outlook-based approval/rejection messages into the invoice process.
● Test the end-to-end invoice processing cloud flow.`,
outline_detail_10:`● Run a basic desktop flow in solo mode.
● Run a desktop flow & cloud flow scenario in solo mode.
● Understand best practices & setup for unattended desktop flows.`,
skill_1:`● Design solutions (25–30%)`,
skill_2:`● Develop solutions (40–45%)`,
skill_3:`● Deploy & manage solutions (30–35%)`,
skill_4:`● Automation Logic Design`,
skill_5:`● Process Mapping Proficiency`,
skill_6:`● RPA Bot Development`,
course_faq_name:`PL-500T00Microsoft Power Automate RPA Developer`,
faq_question_1:`Q: Can Power Automate conduct RPA?`,
faq_question_2:`Q: Is Power Automate a good RPA tool?`,
faq_question_3:`Q: Which is better, UiPath or Power Automate?`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_1:`Power Automate provides both digital process automation & RPA to bridge what you can automate between modern, API-based services & the websites & desktop apps for which you don't have an API-based connector.`,
faq_answer_2:`It's an amazing cloud-based automated system that, in my perspective, has excellent potential. It connects effectively with so many cloud Microsoft platforms and other Cloud-based systems.`,
faq_answer_3:`UiPath & Power Automate are both powerful automation tools, however they have various be a better option.`,
faq_answer_4:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_answer_5:`A: We would always urge you join the live session to practise & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed exclusively for personal enjoyment & NOT for distribution or commercial purpose.`,
},

{
id:102,
categories: ``,
course_detail_image:course_images.microsoft_power_platform,
course_name:`PL-400T00: Microsoft Power Platform Developer`,
course_overview: `The Microsoft Power Platform streamlines, automates, and transforms business tasks and processes to assist organizations in improving their operations. Students will learn how to create Power Apps, automate flows, and extend the platform to meet business requirements and resolve challenging business issues in this course.`,
Duration: `5 Days (heading 3)RESTful Web Services, ASP.NET, Power BI, JavaScript, JSON, TypeScript, C#, HTML, and DotNET.`,
certification:`Candidates should have an introductory knowledge of the Power Platform
Candidates should have development experience with Microsoft Azure, Microsoft 365,  such as application upgrades, a perso experience with Microsoft Azure, Microsoft 365, RESTful Web Services, ASP.NET, nalized user experience, system integrations, data conversions, personalized process automation, and personalized visualizations. Candidates will learn how to use Power Platform services and will gain a thorough understanding of their capabilities, limitations, and restrictions. Candidates should have developmentPower BI, JavaScript, JSON, TypeScript, C#, HTML, and DotNET.`,
prerequisite:`Candidates should have an introductory knowledge of the Power Platform.
Candidates should have development experience with Microsoft Azure, Microsoft 365,  such as application upgrades, a personalized experience with Microsoft Azure, Microsoft 365, RESTful Web Services, ASP.NET, user experience, system integrations, data conversions, personalised process automation, and personalised visualisations. Candidates will learn how to use Power Platform services and will gain a thorough understanding of their capabilities, limitations, and restrictions. Candidates should have developmentPower BI, JavaScript, JSON, TypeScript, C#, HTML, and.NET.`,
outline_heading_1:`Introduction to model-driven apps & Dataverse`,
outline_heading_2:`Begin with model-driven apps in Power Apps`,
outline_heading_3:`Manage tables in Dataverse`,
outline_heading_4:`Make & manage columns within a table in Dataverse`,
outline_heading_5:`Working with choices in Dataverse`,
outline_heading_6:`Make a relationship between tables in Dataverse`,
outline_heading_7:`Define & make business rules in Dataverse`,
outline_heading_8:`Make & define calculation or rollup columns in Dataverse`,
outline_heading_9:`Get started with Power Apps canvas apps`,
outline_heading_10:`Customise a canvas app in Power Apps`,
outline_detail_1:`●	Tables in Dataverse.
●	The various kinds of tables that Dataverse offers.
●	Creating a custom table.
●	Enabling attachments within a table.
●	Which licensing requirements apply to use every kind of table?`,
outline_detail_2:`●	Learning what a column is in Dataverse.
●	Learning about the types of columns that are available in Dataverse.
●	Add a column to a table.
●	Knowing what a primary name column is in Dataverse.
●	Identify restrictions that are associated with columns.
●	Make an auto-numbering column.
●	Make an alternate key.`,
outline_detail_3:`●	Learning about choices.
●	Explore the standard options.
●	Make a new choice or modify an existing one.`,
outline_detail_4:`●	You should segment the data used by your solutions into many tables.
●	Why do you need to relate one table to another?
●	How to build relationships between tables.
●	Select the proper relationship type when you're building solutions with Dataverse.`,
outline_detail_5:`●	Define business rules in Dataverse.
●	Make & manage business rules in Dataverse.`,
outline_detail_6:`●	Define a rollup column.
●	Make a rollup column.
●	Identify a calculation column.
●	Make a calculation column.`,
outline_detail_7:`●	Define business rules in Dataverse.
●	Make & manage business rules in Dataverse.`,
outline_detail_8:`●	Define a rollup column.
●	Make a rollup column.
●	Identify a calculation column.
●	Make a calculation column.`,
outline_detail_9:`●	Examine the ways in which Power Apps can streamline your company.
●	Learning how to use different technologies to perform other tasks in Power Apps.
●	Learning about the different ways to build an app in Power Apps.
●	Make your first app from data in an Excel workbook.`,
outline_detail_10:`●	Change the layout of a gallery.
●	Change the data that a control shows.
●	Change the order in which fields appear.
●	Change the control with which a user provides information.
●	Explore controls on each screen of an app.
●	Format a number as a price.
●	Color prices are based on their values.
●	Explore formulas in a generated app.`,
skill_1:`Configure Microsoft Dataverse (10—15%)`,
skill_2:`Make & configure Power Apps (5—10%)`,
skill_3:`Configure business process automation (5—10%)`,
skill_4:`Extend the user experience (15—20%)`,
skill_5:`Extend the platform (20—25%)`,
skill_6:`Develop integrations (5—10%)`,
course_faq_name:`FAQs`,
faq_question_1:`What does a Power Platform developer do?`,
faq_question_2:`What is Microsoft Power Apps for a developer?`,
faq_question_3:`What skills are required for a PowerApps developer?`,
faq_question_4:`Is Power Platform the same as Dynamics 365?`,
faq_question_5:`What is the infrastructure required to attend your training program?`,
faq_answer_1:`Microsoft Power Platform Developer Associates create, create, secure, and troubleshoot Power Platform solutions. They put into practice parts of the solution, like application improvements, customized user experiences, system integrations, data conversions, customized process automation, and customized visualizations.`,
faq_answer_2:`The Power Apps Developer Plan gives you a free development environment to build & test with Power Apps, Power Automate, & Microsoft Dataverse. The plan enables you to: Make apps & flows without writing code with full-featured Power Apps & Power Automate development tools.`,
faq_answer_3:`Knowledge, Skills &/or Abilities Required:
 
 Strong. Net development experience with familiarity with C#, ASP.Net MVC, Entity Framework, JavaScript, HTML, & CSS. Strong foundational knowledge of Office 365 platform including Exchange, Intune, Azure AD, & Azure ecosystem.`,
faq_answer_4:`Both Power Apps & Dynamics 365 are built on the Power Platform. Dynamics 365 for Sales, Service & Marketing are all natively built with the sole purpose of working as a dedicated CRM database. More on that later. At the same time, Power Apps can be applied to solve a wide range of business process problems.`,
faq_answer_5:`To attend the training session, you should have operational Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
},

{
    id:103,
    categories: ``,
course_detail_image:course_images.microsoft_power_platform,
course_name:`PL-300T00: Microsoft Power BI Data Analyst`,
course_overview:`This course covers the many methodologies & best practices that are in accordance with business & technical requirements for modeling, visualizing, & analyzing data using Power BI. The course will teach how to access & Data can be processed from a variety of data sources, including relational and non-relational data. Finally, this course will go over how to manage. & install reports & dashboards for sharing & content distribution.
Duration: 3 Days`,
certification:`The Power BI data analyst produces meaningful insights by using accessible data & applying subject expertise. The Power BI data analyst engages with key stakeholders across verticals to Find business requirements, cleans & transforms the Data, & then designs & creates data models using Power BI. The Power BI data analyst creates substantial corporate value through easy-to-comprehend data visualizations, enables others to execute self-service analytics, & deploys & configures solutions for consumption. Participants for this test should be adept in utilizing Power Query & writing expressions by using DAX.`,
prerequisite:`Successful Data Analysts begin in this position with prior experience dealing with data in the Cloud.

Specifically:

● Understanding key data concepts.
           
● Understanding of working with relational data in the Cloud.
             
● Understanding of working with non-relational data in the Cloud.
             
● Understanding of data analysis & visualization concepts.
Audience Profile (heading 3)
This course is meant for data experts & business intelligence professionals who want to learn how to execute accurate data analysis with Power BI. This course is also aimed for professionals who produce reports that show data from data platform technologies that operate both in the Cloud and on-premises.`,
outline_heading_1:`Discover data analysis`,
outline_heading_2:`Get data in Power BI`,
outline_heading_3:`Clean, transform, & load data in Power BI`,
outline_heading_4:`Design a data model in Power BI`,
outline_heading_5:`Introduction to building metrics using DAX in Power BI`,
outline_heading_6:`Optimize a model for performance in Power BI `,
outline_heading_7:`Make a data-driven tale with Power BI reports`,
outline_heading_8:`Make dashboards in Power BI`,
outline_heading_9:`Perform analytics in Power BI`,
outline_heading_10:`Work with AI visualizations in Power BI`,
outline_detail_1:`● Learn about the jobs in data. 
● Understand the tasks of a data analyst.`,
outline_detail_2:`● Find & connect to a data source 
● Get information from a relational database, such as Microsoft SQL Server.
● Get data from applications 
● Get data from Azure Analysis Services
● Select a storage mode 
● Obtain data from a file like Microsoft Excel 
● Fix performance issues
● Resolve data import errors`,
outline_detail_3:`● Resolve discrepancies, unexpected or null values, & data quality issues. ● Data profiling helps you to understand more about a certain column before using it.
● Apply user-friendly value replacements.
● Evaluate & transform column data types.
● Apply data shape adjustments to table structures. ● Combine queries.
● Apply user-friendly naming conventions to columns & queries.
● Edit M code in the Advanced Editor.`,
outline_detail_4:`● Build temporary measures.
● Make computed columns.
● Use DAX to build steps.
● Discover how context influences DAX measures.
● Use the CALCULATE feature to adjust filters.
● Implement time intelligence by using DAX.`,
outline_detail_5:`● Review the application of metrics, relationships, & visuals.
● Use variables to increase performance & troubleshooting.
● Improve performance by reducing cardinality levels.
● Optimize DirectQuery models with table-level storage.
● Make & maintain aggregations.`,
outline_detail_6:`● Add visualization items to reports.
● Choose a striking visualization.
● Format & configure visualizations.
● Import a custom graphic.
● Add a R or Python graphic.`,
outline_detail_7:`● Design a report layout.
● Add buttons, bookmarks, & selections.
● Design report navigation.
● Use basic interactions.
● Use sophisticated interactions & drill through.
● Configure conditional formatting.
● Apply slicing, filtering, & sorting.
● Publish & export reports.
● Comment on reports.
● Use a Performance analyzer to optimize statements.
● Optimize reports for mobile use.`,
outline_detail_8:`● Configure data classification.
● Set a mobile view.
● Pin a live report page to a dashboard.
● Add real-time dataset visualizations to your dashboards.
● Add a theme to your dashboard's graphics.`,
outline_detail_9:`● Explore statistical summary.
● Find outliers with Power BI graphics.
● Group & bin data for analysis.
● Apply clustering algorithms.
● Conduct time series analysis.
● Use the Analyze function.
● Use advanced analytics custom visualizations.
● Review Quick insights.
● Apply AI Insights.`,
outline_detail_10: `● Use the Q&A graphic
● Find crucial elements using the Key influencers graphic.
● Use the Decomposition Tree image to break down a measure.`,
skill_1:`● Prepare the data (15-20%)`,
skill_2:`● Model the data (30-35%)`,
skill_3:`● Visualize & analyze the data (30-35%)`,
skill_4:`● Deploy & maintain assets (10-15%)`,
skill_5:`● Data Visualization Proficiency`,
skill_6:`● Advanced Data Modeling Techniques`,
course_faq_name:`PL-300T00 Microsoft Power BI Data Analyst`,
faq_question_1:`What is Power used for?`,
faq_question_2:`How can I acquire Power BI?`,
faq_question_3:`Is power BI worth learning?`,
faq_question_4:`What is the future of power bi?`,
faq_question_5:`What is the infrastructure required to attend your training program?`,
faq_answer_1:`Microsoft Power BI is a business intelligence platform that generally gives nontechnical business users with numerous tools for aggregating, analyzing, visualizing & sharing data. Users can easily download an application for Windows 10, which is commonly called Power BI Desktop, & native mobile apps for Windows, & roid & iOS devices.`,
faq_answer_2:`To get Power BI Desktop, go to the Power BI Desktop download page & select get Free. Also, for download alternatives, pick See download or language options. From the Power BI service, users may also download Power BI Desktop. Select Power BI Desktop after selecting the Download button in the top menu bar.`,
faq_answer_3:`Power BI is a fantastic tool where even beginners can generate valuable dashboards & insights. This suggests that the investment is not worth the investment for many Power BI customers.`,
faq_answer_4:`The demand for BI (Business Intelligence) & Decision Making will not fall shortly. No other BI tool will overcome Power BI's dominance in the next ten years. Also, Power BI is simple to use as well as it is fantastic for data visualization. Moreover, the product is a Microsoft product.`,
faq_answer_5:`To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
},


{
    id:104,
    categories: ``,
course_detail_image:course_images.microsoft_365,
course_name:`PL-200T00: Microsoft Power Platform Functional Consultant`,
course_overview:`Microsoft Power Platform Functional Consultant (PL-200) Certification course is meant for developers & system architects who want to increase their understanding of the setup of Microsoft Power Platform. The certification training does not require prior software development or coding skills. Business intelligence specialists & With the Microsoft Power Platform, solution architects can design solutions successfully without experience. Developers can also profit from this certification course because it is more easier & faster to build solutions without coding. To join up for the Microsoft Power Platform Functional Consultant course immediately, look at the dates below.

Duration: 4 DAYS`,
certification:`Skills measured:
● Configure Microsoft Dataverse 
● Create apps by using Microsoft Power Apps 
● Create & manage process automation 
● Describe Microsoft Power Virtual Agents 
● Import & display data by using dataflows and Power BI
 ● Define an environmental strategy

Certification Path:

Required exams: PL-200`,
prerequisite:`• Students who want to apply for the Microsoft Power Platform Functional Consultant (PL-200) Certification course must possess professional IT job experience or background academic knowledge in a related sector. 
• Education in software engineering, coding, & fundamental knowledge of IT procedures & workflows is recommended.
• students are also recommended to have some working understanding of Microsoft Power Platform & its components, but it is unnecessary.
• Candidates must comprehend numerous best practices & key principles of organizational and cybersecurity.
• Candidates are expected to know Microsoft Dataverse or data modeling.
Audience Profile:

The task of establishing a Microsoft Power Platform functional consultant & customizing apps, automation, and solutions. They act as the interface between users & the implementation team. The functional consultant supports the utilization of solutions within a company. The available consultant may undertake discovery, engage subject matter experts & stakeholders, collect requirements, & map requirements to features. It implements components of a solution, including application upgrades, custom user experiences, data conversions, system connectors, custom process automation, & simple visualizations.`,
outline_heading_1:`Introduction to Microsoft Power Platform`,
outline_heading_2:`Work with Dataverse`,
outline_heading_3:`Make the model-driven apps with Power Apps`,
outline_heading_4:`Make the canvas apps using Power Apps`,
outline_heading_5:`Make the portals with Power Apps`,
outline_heading_6:`Introduction to the automation`,
outline_heading_7:`Build the Power Automate cloud flows`,
outline_heading_8:`Build Power Automate desktop flows`,
outline_heading_9:`Build business process flows`,
outline_heading_10:`Build chatbots using Power Virtual Agents`,
outline_detail_1:`The learner will acquire background information on the Microsoft Power Platform in this module. & its four major components: Power Apps, Power Automate, Power BI, & Power Virtual Agents.`,
outline_detail_2:`In this topic, participants will learn about constructing a data model in Microsoft Dataverse, using tabular reporting options, including importing data, & configuring security. Students will also learn about constructing easy AI with AI Builder.`,
outline_detail_3:`In this lesson, participants will discover the business value of Power Apps model-driven apps. Candidates will next learn how to configure & create them, including user experience considerations.`,
outline_detail_4:`In this module, learners will understand the business value of Power Apps canvas apps. They will next learn how to configure and design them, including user experience considerations.`,
outline_detail_5:`In this lesson, learners will discover the business value of Power Apps portals. They will then learn how to access Dataverse data in a portal & how portal authentication works.`,
outline_detail_6:`In this topic, learners will learn about business rules & when they can be applied. Candidates will also get an overview of Power Automate.`,
outline_detail_7:`In this subject, learners will study the principles of cloud flows, including trigger flows. They will develop two flows, including an approval flow.`,
outline_detail_8:`In this topic, users will learn what desktop flows are and how they are produced. Participants will also discover how desktop flows are employed & how to use process advisers to understand better areas to optimize operations.`,
outline_detail_9:`In this lesson, participants will understand the benefits of business process flows & how to use business process flow designer. these will also practice creating business process flows & know how these may be automated.`,
outline_detail_10:`In this topic, participants will learn how to automate client interactions with a chatbot using Virtual Power Agents.`,
skill_1:`● Configure Microsoft Dataverse`,
skill_2:`● Create apps by using Microsoft Power Apps `,
skill_3:`● Create & manage process automation `,
skill_4:`● Describe Microsoft Power Virtual Agents `,
skill_5:`● Import & display data by using dataflows and Power BI`,
skill_6:`● Define an environmental strategy`,
course_faq_name:`PL-200T00: Microsoft Power Platform Functional Consultant`,
faq_question_1:`Once I make my payment online, can I cancel it?`,
faq_question_2:`Can I read the trainer's profile before the training?`,
faq_question_3:`Is my information secure?`,
faq_question_4:``,
faq_question_5:``,
faq_answer_1:`Students can request a refund if they do not intend to enroll in the course.`,
faq_answer_2:`Yes, you can.`,
faq_answer_3:`We constantly employ the finest standards in Internet security. Any data that is retained is not shared with third parties.`,
faq_answer_4:``,
faq_answer_5:``,
},



{
    id:105,
categories: ``,
course_detail_image:course_images.microsoft_365,
course_name:`PL-900T00: Microsoft Power Platform Fundamentals`,
course_overview:`In today's day and age of integrating emerging technologies combined with automation, it is vital for enterprises to link numerous systems to help evaluate necessary data, design solutions, and automate processes. Microsoft Power Platform (PL-900) solution allows organizations to connect numerous Microsoft Apps to offer a smoother experience for customers and corporations to deliver higher overall value. Check out the dates below to enroll for the Microsoft Power Platform Fundamentals certification course today.
 
Duration: 1 DAY`,
certification:`Skills measured: 
● Describe the business value of Microsoft Power Platform 
● Identify the core components of the Microsoft Power Platform 
● Demonstrate the capabilities of Power BI 
● Demonstrate the capabilities of Power Apps 
● Demonstrate the capabilities of Power Automate
● Demonstrate about the business value of Power Virtual Agents`,
prerequisite:`There are no prerequisites to take the Microsoft Power Platform Fundamentals program or sit for the PL-900 certification exam. However, it would be beneficial for learners to undergo official Microsoft Power Platform Fundamentals certification training from a recognized training partner.`,
outline_heading_1:`Introduction to the Microsoft Power Platform`,
outline_heading_2:`Introduction to the Microsoft Dataverse`,
outline_heading_3:`Get Started with Power Apps`,
outline_heading_4:`Get Started with Power Automate`,
outline_heading_5:`Get Started with Power BI`,
outline_heading_6:`Introduction to Power Virtual Agents`,
outline_detail_1:`Learn about all the components of Microsoft Power Platform, ways to connect data, & how enterprises can leverage this technology to create business solutions.
Lessons`,
outline_detail_2:`Dataverse allows students to explore into strong, scalable data solutions in the cloud. Learn what makes Dataverse tick & how it can integrate with Microsoft Power Platform so as to generate distinctive and efficient business solutions.`,
outline_detail_3:`Learn about the value & capabilities of Power Apps & ways in which other organizations have exploited this technology to develop simple applications for their business.`,
outline_detail_4:`Learn how students can employ Power Automate to boost corporate efficiency & productivity.`,
outline_detail_5:`Learn how organizations can utilize Power BI to swiftly clean, display, & comprehend data to enable better-informed decisions.`,
outline_detail_6:`Learn about the benefits & capabilities of Power Virtual Agents & the ways organizations may employ this technology to develop chatbot solutions for their business.`,
skill_1:`● Describe the business value of Microsoft Power Platform `,
skill_2:`● Identify the core components of the Microsoft Power Platform`,
skill_3:`● Demonstrate the capabilities of Power BI`,
skill_4:`● Demonstrate the capabilities of Power Apps`,
skill_5:`● Demonstrate the capabilities of Power Automate`,
skill_6:`● Demonstrate about the business value of Power Virtual Agents`,
course_faq_name:`PL-900T00 Microsoft Power Platform Fundamentals`,
faq_question_1:`Do I have to go through the course content before class?`,
faq_question_2:`Can I read the trainer's profile before the training?`,
faq_question_3:`Can I pay via the website?`,
faq_question_4:``,
faq_question_5:``,
faq_answer_1:`It is encouraged but not mandatory. Being acquainted with the basic course material will help students and the trainer to move at the desired pace during courses. You can get courseware for most vendors`,
faq_answer_2:`Yes, you can.`,
faq_answer_3:`Yes, students can pay from the course website.`,
faq_answer_4:``,
faq_answer_5:``,
},

{
    id:106,
categories: ``,
course_detail_image:course_images.microsoft_365,
course_name:`Training MS-220T00: Troubleshoot Microsoft Exchange Online`,
course_overview:`This program trains you to solve a broad range of problems that can develop in a company using Microsoft Exchange Online. In this tutorial, you’ll troubleshoot many areas of Microsoft Exchange Online, including recipient difficulties, mail flow, compliance, & cloud/on-premises hybrid configurations.

Duration: 1 Day`,
certification:`Certification Professionals for this exam detect, diagnose, & resolve issues with Microsoft Exchange Online, hybrid Exchange environments & related components, technologies, & services.

Professionals collaborate with customers & other stakeholders to grasp the intricacies of any concerns. They also interact with administrators & peers that work with other similar technologies.

Professionals should have knowledge with PowerShell & the Exchange PowerShell module. The support engineer should also have substantial expertise deploying, administering, & troubleshooting Microsoft Exchange Online environments.`,
prerequisite:`Before attending this training, professionals must have the following:
 
• Experience with PowerShell & the Exchange PowerShell module.
• Significant experience deploying, administering, & troubleshooting Exchange environments

Audience Profile
The audience for this training is current or prospective support engineers who work with customers & other stakeholders to grasp the intricacies of any Microsoft Exchange Online issues. They also communicate with administrators & peers that work with other relevant technologies to troubleshoot & resolve issues.`,
outline_heading_1:`Troubleshoot difficulties with mail flow`,
outline_heading_2:`Troubleshoot message filtering in inboxes & Microsoft Defender for Office 365`,
outline_heading_3:`Troubleshoot compliance & retention issues`,
outline_heading_4:`Troubleshoot encryption, auditing, & journaling`,
outline_heading_5:`Troubleshoot desktop Outlook clients`,
outline_heading_6:`Troubleshoot difficulties with mobile devices`,
outline_heading_7:`Troubleshoot Exchange Online configuration difficulties`,
outline_heading_8:`Troubleshoot hybrid & migration issues`,
outline_detail_1:`• Identifying & troubleshooting message delivery issues.
• Determining why Exchange Online is routing messages wrongly.
• Identify incorrectly setup mail records in DNS.
• Troubleshoot difficulties with SMTP mail transfer.`,
outline_detail_2:`• Diagnosing difficulties caused by misconfigured or corrupted mailbox rules.
• Deciding why message attachments are impeding message delivery.
• Analyzing why Exchange Online Protection has taken action on a message.
• Identifying improperly set spam filter policies.`,
outline_detail_3:`• Determining why eDiscovery doesn’t yield the items you anticipated.
• Diagnosing which in-place & eDiscovery holds apply to a certain item.
• Delete & purge items throughout an Exchange Online organization.
• Determining why objects are not retained as expected.
• Troubleshoot Messaging Records management.`,
outline_detail_4:`• Finding out why Exchange Online can’t encrypt or decode communications as expected.
• Ensuring that sensitive activities on items & mailboxes establish a secure audit log.
• Discovering why items are not reaching the Exchange Online journal.`,
outline_detail_5:`• Ensuring Outlook clients can connect to Exchange Online.
• Diagnosing Outlook client authentication issues.
• Resolving difficulties with calendaring, resource booking, & delegation.`,
outline_detail_6:`• Resolving any issues that mobile devices face when connecting to Exchange Online.
• Diagnosing difficulties with ActiveSync devices`,
outline_detail_7:`• Diagnose difficulties that happen when supplying recipients & other objects.
• Resolve issues that affect mailboxes, distribution lists, & other recipients.
• Ensuring that address lists include all the intended recipients & just the intended recipients.
• Fixing typical problems that affect your entire organization.
• Ensuring that users can access public folders without difficulties.`,
outline_detail_8:`• Enable mail to flow between Exchange Online & Exchange Server recipients correctly 
• Fixing difficulties with free/busy information, public folders, & recipient synchronization in hybrid setups.
• Troubleshoot issues that happen during migration.`,
skill_1:`• Troubleshoot mail flow issues (20–25%)`,
skill_2:`• Troubleshoot compliance & retention issues (25–30%)`,
skill_3:`• Troubleshoot mail client difficulties (20–25%)`,
skill_4:`• Troubleshoot Exchange Online configuration difficulties (15–20%)`,
skill_5:`• Troubleshoot hybrid & migration concerns (10–15%)`,
skill_6:`• Exchange Online Troubleshooting`,
course_faq_name:``,
faq_question_1:`What does Microsoft Exchange Online do?`,
faq_question_2:`What is the difference between Outlook & Exchange Online?`,
faq_question_3:`What are the varieties of Exchange Server?`,
faq_question_4:`What is the infrastructure required to attend your training program?`,
faq_question_5:`What if I miss a lesson on a specific day?`,
faq_answer_1:`Microsoft Exchange Online is a hosted messaging system that delivers email, calendar, contacts, & tasks from PCs, the web, & mobile devices.`,
faq_answer_2:`Microsoft Exchange is a server application & an email server solution. This means that Exchange is a dedicated network resource management platform. Outlook is an email program installed on your desktop. Outlook is designed to send & receive emails, & it maintains in sync with Exchange.`,
faq_answer_3:`The Server license is sold in two server editions - Standard Edition & Enterprise Edition. Client Access licenses (CALs) - Exchange also comes in two CAL variants, which are referred to as a Standard CAL & an Enterprise CAL.`,
faq_answer_4:`To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs`,
faq_answer_5:`We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},

{
    id:107,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`Training MS-600T00: Building Applications & Solutions with Microsoft 365 Core Services`,
course_overview:`Building applications & solutions with Microsoft 365 core services training is developed for those who aspire to the Microsoft Teams Application Developer role. In this training, professionals will learn how to build collaboration solutions leveraging four central elements of the Microsoft 365 platform: working with Microsoft Graph, implementing Microsoft identity, extending & customizing Microsoft 365 with SharePoint Framework, & building applications for Microsoft Teams. In Implementing Microsoft identity, professionals will learn to implement Microsoft identity, including registering an application, defining permissions to consume an API, implementing authentication, & developing a service to access Microsoft Graph. In Work with Microsoft Graph, professionals will grasp how to examine query parameters, access user data, access files, manage a group lifecycle, & optimize network traffic with Microsoft Graph. In Extend & customize Microsoft 365 with SharePoint Framework, professionals will learn about SharePoint Framework web elements, extensions, & how to package & deploy an SPFx solution. In Build apps for Microsoft Teams.`,
certification:`Microsoft Teams application developers design, construct, test, & maintain modern enterprise-grade applications & solutions using Microsoft Teams that are designed for the productivity & collaboration demands of enterprises using the Microsoft 365 platform.
Professionals for this exam are adept in designing collaborative solutions using the Microsoft identity platform, Microsoft Graph, SharePoint Framework, & Microsoft Power Platform to build bespoke apps that enhance Microsoft Teams, SharePoint, & Viva Connections.
Professionals should have a working expertise of designing solutions with Microsoft Teams, Microsoft Power Platform, Data-verse for Teams, and/or SharePoint Framework across all phases of software development. They should have a fundamental understanding of modern web development technologies, source control & CI/CD methodologies, Azure computing, data, & messaging workloads, & the Microsoft identity platform, including OAuth2, OData, OpenID Connect, & permission/consent principles.`,
prerequisite:`Before attending this seminar, professionals should have the following:

1-2 years experience as a developer. This program assumes professionals know how to code & have a basic understanding of REST APIs, OAuth2, JSON, OData, OpenID Connect, and Microsoft identities, including Azure AD & Microsoft accounts, Azure AD B2C, & permission/consent principles.
It is recommended that professionals have some expertise developing solutions on Microsoft Teams and/or SharePoint Framework across all phases of software development.

Audience Profile
Professionals in this class are interested in the Microsoft 365 development platform or in completing the Microsoft Teams Application Developer Associate certification test. Professionals should also have 1-2 years of experience as a developer. This program assumes professionals know how to code & have a basic understanding of REST APIs, OAuth2, JSON, OData, OpenID Connect, and Microsoft identities, including Azure AD & Microsoft accounts, Azure AD B2C, & permission/consent principles.`,
outline_heading_1:`Getting Started with Microsoft Identity`,
outline_heading_2:`Application kinds in Microsoft identity`,
outline_heading_3:`Permissions & Consent Framework`,
outline_heading_4:`Secure custom APIs with Microsoft Identity`,
outline_heading_5:`Work with users, groups, & roles in bespoke apps & APIs`,
outline_heading_6:`Optimize data use when utilizing Microsoft Graph with query parameters`,
outline_heading_7:`Optimize network traffic with Microsoft Graph`,
outline_heading_8:`Access User Data from Microsoft Graph`,
outline_heading_9:`Manage Group Lifecycle with Microsoft Graph`,
outline_heading_10:`Access Files with Microsoft Graph`,
outline_detail_1:`Explain the different types of tokens used in Microsoft Identity solutions
Compare & contrast the different account kinds
Compare & contrast the supported Microsoft Identity Topologies
Creating a bespoke web API that is secured using Microsoft identity
Creating a bespoke web app that calls a custom web API secured using Microsoft identity
Creating a custom daemon app that calls a custom web API secured with Microsoft identity`,
outline_detail_2:`Compare & contrast different grant types (flows) in the OAuth 2.0 protocol
Evaluate the authentication protocol to employ based on the app situation
Implement multiple authentication mechanisms in different application kinds`,
outline_detail_3:`Compare & contrast different permission types provided by the Microsoft identity platform
Compare & contrast the difference between static & dynamic consent in user permissions
Create an app that uses dynamic consent for incrementally gaining permissions as needed from people`,
outline_detail_4:`Creating a bespoke web API that is secured using Microsoft identity
Creating a bespoke web app that calls a custom web API secured using Microsoft identity
Creating a custom daemon app that calls a custom web API secured with Microsoft identity`,
outline_detail_5:`Create a bespoke ASP.NET web app that is secured using Microsoft identity
Demonstrate how to retrieve security groups as a claim in the token & utilize them in the app
Demonstrate how to use app roles to provide users access to an app`,
outline_detail_6:`Demonstrate how to alter REST inquiries with query parameters
Create queries that expand complicated entities
Demonstrate how to search for material with Microsoft Graph
Optimize Microsoft Graph queries with batching`,
outline_detail_7:`Explain how Microsoft Graph maintains resource health
Identify when Microsoft Graph throttles queries
Decide the proper pattern to treat throttled requests
Create queries that mitigate throttling circumstances`,
outline_detail_8:`Demonstrate how to retrieve a list of users
Demonstrate how to retrieve details, including a profile picture, of a user
Demonstrate how to handle the lifecycle of a user from creation to deletion`,
outline_detail_9:`Demonstrate how to acquire information about a group
Demonstrate how to acquire information about a user's groups
Demonstrate how to manage the lifespan of a group`,
outline_detail_10:`Demonstrate how to retrieve a list of users
Demonstrate how to retrieve details, including a profile picture, of a user
Demonstrate how to handle the lifecycle of a user from creation to deletion`,
skill_1:`Design collaborative app solutions & existing app integration (5–10%)`,
skill_2:`Implement Microsoft identity (15–20%)`,
skill_3:`Build apps using Microsoft Graph (10–15%)`,
skill_4:`Extend & adapt Microsoft 365 using SharePoint Framework (15–20%)`,
skill_5:`Extend Microsoft Teams (30–35%)`,
skill_6:`Test, deploy, monitor, & maintain modern enterprise-grade software solutions`,
course_faq_name:`Required exams: Exam MS-600: Building Applications & Solutions with Microsoft 365 Core Services`,
faq_question_1:`What are the services provided by Office 365?`,
faq_question_2:`What's the difference between Office 365 Home & Business?`,
faq_question_3:`What are the benefits of Microsoft 365?`,
faq_question_4:`What is the infrastructure required to attend your training program?`,
faq_question_5:`What if I miss a lesson on a specific day?`,
faq_answer_1:`The task management, email service, calendar application, as well as contacts manager have generally incorporated business as well as enterprise Office 365 subscriptions which are under the Outlook on the web brand. It normally comprises Outlook Mail, Outlook People, Outlook Calendar & also Outlook Tasks.`,
faq_answer_2:`The key differences between the two programs are the user base & the amount of people offered. Office 365 Home is fundamentally built for an individual user who mainly wishes to execute the basic operations & on the other hand, Office 365 Business is particularly for entrepreneurs & SMEs.`,
faq_answer_3:`The seven major advantages of Office 365 include the following:
Users Can Work From Anywhere.
Provides Robust Security & Reliability
Subscription-Based Payment Is Available
Teams & Users Can Easily Collaborate
You Receive Access to the Latest Programs
You Can Work With What You Know
Office 365 Features Mix & Match Plans`,
faq_answer_4:`To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs. `,
faq_answer_5:`We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use`,
},


{
    id:108,
categories: `Microsoft`,
course_detail_image:course_images.microsoft_365,
course_name:`MS-900T01: Microsoft 365 Fundamentals`,
course_overview:`This training presents an overview of Microsoft 365, which is an integrated cloud platform combining industry-leading productivity tools together with intelligent cloud services, & world-class security. You'll study core knowledge on the considerations & advantages of embracing cloud services & the Software as a Service - SaaS, cloud model, with a specific focus on Microsoft 365 cloud service offerings. You'll begin by learning about cloud principles, including an overview of cloud computing. You will be introduced to Microsoft 365 & understand how Microsoft 365 technologies enhance collaboration, boost productivity, & optimize communications. The training then analyzes how security, compliance, privacy, & trust are handled in Microsoft 365, & it concludes with a review of Microsoft 365 subscriptions, billing, licenses, & support.

Duration: 1 Day`,
certification:`This exam is aimed for professionals wishing to demonstrate core level understanding of Software as a Service (SaaS) solutions to facilitate productivity on-site, at home, or a combination of both. Professionals may have familiarity of cloud-based solutions or may be new to Microsoft 365. Professionals can use this test to prepare for other Microsoft certifications, but it isn't a prerequisite for any of the linked certifications.

Professionals should be able to recommend Microsoft 365 solutions that meet common organizational IT concerns. They should comprehend how Microsoft 365 technologies boost productivity, facilitate collaboration, & optimize communications.

Professionals should also be able to offer solutions for endpoint & application management, desktop virtualization, automated operating system rollout, & comprehensive reporting & analytics. They should be knowledgeable with Microsoft 365 license, deployment & migration assistance, & support choices for enterprises wishing to maximize their investment in the cloud.`,
prerequisite:`Professionals do not need to have prior experience with Microsoft 365 before attending this program; however, a fundamental familiarity with computer technology & cloud computing is assumed.

Audience Profile
This program is meant for professionals wishing to demonstrate foundational level understanding of cloud based technologies to improve productivity & collaboration on-site, at home, or a combination of both. Professionals may have familiarity of cloud-based solutions or may be new to Microsoft 365.`,
outline_heading_1:`Describe cloud computing`,
outline_heading_2:`Describe the benefits of adopting cloud services`,
outline_heading_3:`What is Microsoft 365?`,
outline_heading_4:`Describe productivity solutions of Microsoft 365`,
outline_heading_5:`Describe collaborative solutions of Microsoft 365`,
outline_heading_6:`Describe endpoint modernization, management ideas, & deployment options in Microsoft 365`,
outline_heading_7:`Describe the analytics capabilities of Microsoft 365`,
outline_heading_8:`Describe the services & identity kinds of Azure AD`,
outline_heading_9:`Describe the access management capabilities of Azure AD`,
outline_heading_10:`Describe threat protection using Microsoft 365 Defender`,
outline_detail_1:`• Define cloud computing.
• Describe the shared responsibility model.
• Define cloud models, including public, private, & hybrid.
• Identifying appropriate use cases for each cloud model.
• Describing the consumption-based model.
• Compare cloud pricing models.`,
outline_detail_2:`• Describe the benefits of high availability & scalability in the cloud.
• Describe the benefits of reliability & predictability in the cloud.
• Describe the benefits of security & governance in the cloud
• Describing the benefits of manageability in the cloud.`,
outline_detail_3:`• Describe Office 365, Microsoft 365, & Windows 365
• Describe how Microsoft 365 supports professionals for hybrid & flexible work
• Create a Microsoft 365 trial organization`,
outline_detail_4:`• Describe how the capabilities of Microsoft 365 can enhance productivity
• Describe how Microsoft 365 Apps enable people craft appealing content in real-time
• Describe how the capabilities of the work management tools optimize operations
• Describe other Microsoft 365 productivity apps`,
outline_detail_5:`• Describe how the collaborative tools of Microsoft 365 enhance synergy in the workplace
• Describe how Microsoft Teams helps enhance teamwork
• Describe how Microsoft Viva helps firms establish flourishing work environments
• Describe how Yammer communities can help create connections inside your organization`,
outline_detail_6:`• Describe the endpoint contemporary management capabilities of Microsoft 365
• Describe the differences between Windows 365 & Azure Virtual Desktop
• Describe the deployment & release models for Windows-as-a-Service
• Describe the deployment techniques & update channels for Microsoft 365 Apps`,
outline_detail_7:`• Describe how Viva Insights enable people & companies work smarter & achieve balance
• Describing the possibilities of the Microsoft 365 admin center & user-portal
• Describing the reports accessible in the Microsoft 365 admin center & other admin centers`,
outline_detail_8:`• Describe what Azure AD performs.
• Describe the sorts of identities Azure AD provides.`,
outline_detail_9:`• Describe Conditional Access in Azure AD.
• Describe the benefits of Azure AD roles & role-based access control.`,
outline_detail_10:`• Describe the Microsoft 365 Defender service.
• Describe how Microsoft 365 Defender delivers comprehensive protection against complex assaults.
• Describe & explore Microsoft 365 Defender portal.`,
skill_1:`Describe cloud principles (10–15%)`,
skill_2:`Cloud Collaboration`,
skill_3:`Describe fundamental Microsoft 365 services & principles (50–55%)`,
skill_4:`Microsoft 365 Administration`,
skill_5:`Describe security, compliance, privacy, & trust in Microsoft 365 (15–20%)`,
skill_6:`Describe Microsoft 365 cost & support (10–15%)`,
course_faq_name:`MS-900: Microsoft 365 Fundamentals`,
faq_question_1:`What is the purpose of Microsoft Office 365?`,
faq_question_2:`What is included with Microsoft 365?`,
faq_question_3:`Why does Office 365 take forever to install?`,
faq_question_4:`What is the infrastructure required to attend your training program?`,
faq_question_5:`What if I miss a lesson on a specific day?`,
faq_answer_1:`Microsoft 365 is the productivity cloud designed to help you pursue your passion & operate your business. Multiple apps including Word, PowerPoint, Excel, and Microsoft 365 basically bring together best-in-class productivity tools with strong cloud services, device management as well as sophisticated security in one connected experience.`,
faq_answer_2:`With Microsoft 365 subscription options, users will get the fully installed Office software, which includes Word, Outlook, Excel, PowerPoint, Publisher, & Access (Publisher, as well as Access, are available on PC only).`,
faq_answer_3:`Office 365 is an online program & its installation depends on how fast your internet is. If the time duration is growing, it usually signifies your internet is slow. You can check your internet speed on some websites. Open the site & click on BEGIN TEST.`,
faq_answer_4:`To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs.`,
faq_answer_5:`We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},

{
    id:109,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS-700T00: Managing Microsoft Teams`,
course_overview:`The Managing Microsoft Teams program is aimed for those who aspire to the Microsoft 365 Teams Admin role. A Microsoft Teams administrator prepares to deploy, configure, & administer Microsoft Teams to focus on efficient & effective collaboration & communication in a Microsoft 365 environment. In this course, you will learn about various Teams management tools, security & compliance features for Teams, network requirements for Teams deployment, as well as different Teams settings & rules for managing collaboration & communication experience in Teams.

Duration: 4 Days`,
certification:`Professionals for this exam are Microsoft Teams administrators that enable secure, efficient, & effective internal & external collaboration & communication capabilities with Microsoft Teams & Microsoft 365.
Professionals for this exam must be able to plan, install, configure, & manage teams, channels, chat, applications, calling, meetings, & events in Microsoft Teams. They basically manage the operational parts of Teams Phone & meeting room devices.
Professionals for this exam cooperate with the Microsoft Teams voice engineer as well as owners of other workloads, including networking, identity, licensing, security, compliance, information management, & user adoption.
Professionals for this test have experience with Microsoft 365, including Microsoft 365 Groups, SharePoint, OneDrive, & Exchange. They must also grasp how Microsoft Teams interfaces with third-party, custom, & other Microsoft apps.`,
prerequisite: `• A proficient understanding of essential functional experience with Microsoft 365 services.

• Proficient knowledge of general IT practices, especially using PowerShell.

Audience Profile
Professionals in this class are interested in Microsoft Teams or in passing the Microsoft Teams Administrator Associate certification test. A Microsoft Teams administrator prepares to deploy, configure, & administer Microsoft Teams to focus on efficient & effective collaboration & communication in a Microsoft 365 environment. A Microsoft Teams administrator should be able to plan, install, & manage teams, chat, applications, channels, meetings, audio/video conferencing, live events, calling, & Teams certified devices. A Microsoft Teams administrator is experienced in integrating Microsoft Teams with SharePoint, Exchange, OneDrive, Microsoft 365 Groups, & other Microsoft, third-party, & custom apps. A Microsoft Teams administrator knows & interacts with other workloads & responsibilities, such as Network, Voice, Identity, Access, Devices, Licensing, Security, Compliance, Information management, & User Adoption.`,
outline_heading_1:`Explore Microsoft Teams`,
outline_heading_2:`Plan & deploy Microsoft Teams`,
outline_heading_3:`Implement lifecycle management & governance for Microsoft Teams`,
outline_heading_4:`Monitor your Microsoft Teams environment`,
outline_heading_5:`Manage access for external users`,
outline_heading_6:`Implement security for Microsoft Teams`,
outline_heading_7:`Implement compliance for Microsoft Teams`,
outline_heading_8:`Plan & configure network settings for Microsoft Teams`,
outline_heading_9:`Create & manage teams`,
outline_heading_10:`Manage collaboration experiences for chat & channels`,
outline_detail_1:`• Describe Microsoft Teams' key workloads 
• Describe how Microsoft 365 connects with Teams 
• Describe how Microsoft Power Platform integrates with Teams 
• Explain the architecture of Microsoft Teams & dependant services
• Describe how Microsoft Teams interacts with SharePoint, One Drive & Exchange 
• Describe Teams certified solutions`,
outline_detail_2:`• Describe distinct Teams admin roles
• Explain which administration tools are available for Teams 
• Plan for Teams rollout 
• Understand the need of a user adoption plan when implementing Teams 
• Identify required licensing for Teams 
• Deploy Teams clients`,
outline_detail_3:`• Describe the lifecycle management for Teams 
• Plan governance features for Teams 
• Configure Microsoft 365 Groups governance features 
• Plan the Teams policy assignment for different scenarios 
• Create & manage policy packages 
• Control Teams preview features for users 
• Create & manage team templates & templates policies`,
outline_detail_4:`• Analyze consumption patterns by monitoring Microsoft 365 & Teams usage reports 
• Interpret Microsoft Adoption Score 
• Manage user access by using Azure AD access reviews 
• Assess network readiness by utilizing Microsoft 365 network connectivity dashboard 
• Manage Teams notifications & alerts to monitor Teams capabilities & receive alerts 
• Manage feedback policies`,
outline_detail_5:`• Explain external access & guest access.
• Describe how to handle external access & guest access.
• Manage external cooperation in Azure Active Directory.
• Manage external collaboration in Microsoft 365.
• Manage external access in Microsoft Teams.
• Manage guest access in Microsoft Teams.
• Manage file sharing in SharePoint`,
outline_detail_6:`• Configure Conditional Access & multi factor authentication for Microsoft Teams.
• Configure Safe Attachments for Microsoft Teams.
• Configure Safe Links for Microsoft Teams.
• Examine the Defender for Cloud Apps.
• Describe security reports for Microsoft Teams.
• Plan & configure stronger encryption policies.`,
outline_detail_7:`• Describe how sensitivity labels operate & how to configure them.
• Describe how to create retention policies.
• Generate Data Loss Prevention rules to protect against data leaking.
• Deploy communication compliance for reviewing communication.
• Activate the scoped directory search in Teams.
• Describe how information barrier policies function & how to build them.
• Create & manage eDiscovery cases & supervision policies.
• Create & manage compliance recording policies.
• Configure alert policies for occurrences in Microsoft Teams.`,
outline_detail_8:`• Explain the network requirements of Microsoft Teams.
• Work with the Network Planner tool.
• Utilise the Network Test Companion.
• Describe the required network ports & protocols.
• Configure reporting labels for Microsoft Teams.
• Implement Quality of Service in your surroundings.`,
outline_detail_9:`• Create new teams 
• Create teams from existing resources 
• Create an org-wide team 
• Manage team settings 
• Manage users in a team 
• Configure dynamic membership for teams 
• Archive, restore from the archive, delete, & restore deleted teams`,
outline_detail_10:`• Manage the development of private channels 
• Control the email integration of teams 
• Organize the file-sharing functions from the Teams client 
• Creating & changing communications policies
• Understanding how to set up channel moderation in teams`,
skill_1:`• Plan & manage a Microsoft Teams environment (40-45%)`,
skill_2:`• Plan & handle chat, cooperation, meetings, & calls (25-30%)`,
skill_3:`• Manage calling & meetings (15-20%)`,
skill_4:`• Monitor, report, & troubleshoot a Microsoft Teams environment (10-15%)`,
skill_5:`• Identity and Access Management`,
skill_6:`• Threat Detection & Response Management`,
course_faq_name:`MS 700T00 Managing Microsoft Teams`,
faq_question_1:`Q: What is Microsoft collaboration?`,
faq_question_2:`Q: How do you collaborate in Microsoft teams?`,
faq_question_3:`Q: How do I restrict editing in Microsoft teams?`,
faq_question_4:`Q: Is Microsoft teams a collaboration tool?`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_1:`A: The Microsoft Collaborate (MS Collaborate) portal delivers tools & services to streamline technical collaboration within the Microsoft ecosystem. MS Collaborate mainly provides Sharing of engineering system work items which includes bugs, feature requests, etc., and also the ability to manage users of the system.`,
faq_answer_2:`A: Work on a file collaboratively - • In a team discussion or in the Files tab, pick More options next to the file.
• Choosing if you want to edit the file in Teams, on your desktop, or online.
• Selecting Start conversation to add a message about the file.
• Typing your message or @mention someone & select Send.`,
faq_answer_3:`A: To do so, simply click "Restrict Editing" under the "Protect" area of the 'Review' tab again to open the "Restrict Editing" pane, then click 'Stop Protection' at the bottom of the pane. On the "Unprotect Document" dialog box, enter the password you used when you protected the document & click "OK."`,
faq_answer_4:`A: Microsoft Teams is a collaboration program that basically helps your workforce keep organized in a decent manner & have chats from any device. Users can easily utilize Microsoft Teams to have instant interactions with members of their workforce or guests outside their company. Users can also make phone calls, host meetings & also can share files.`,
faq_answer_5:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs.`,
},


{
    id:110,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS-500T00: Microsoft 365 Security Administration`,
course_overview:`In this course, you will learn how to safeguard user access to your organization's resources. The training covers user password protection, multifactor authentication, how to allow Azure Identity Protection, how to set-up & utilize Azure AD Connect, & introduces you to conditional access in Microsoft 365. You'll learn about threat protection solutions that help defend your Microsoft 365 environment. Specifically, you'll learn about threat vectors & Microsoft's security solutions to mitigate threats. You'll learn about Secure Score, Azure Advanced Threat Protection, Exchange Online Protection, Windows Defender Advanced Threat Protection, & threat management. In training, you'll learn about information protection technologies that help safeguard your Microsoft 365 environment. The seminar examines information rights controlled content, message encryption, labeling, policies & procedures that promote data loss prevention & information protection. Lastly, you will learn about archiving & retention in Microsoft 365, as well as data governance & how to do content searches & investigations. This training covers data retention policies.`,
certification:`Professionals for this exam plan, implement, manage, & monitor security & compliance solutions for Microsoft 365 & hybrid environments. The Microsoft 365 security administrator proactively protects identity & access, provides threat prevention, controls information protection, & enforces compliance. The Microsoft 365 security administrator collaborates with the Microsoft 365 enterprise administrator, business stakeholders, & other workload administrators to create & implement security initiatives.

Professionals for this exam have functional knowledge with Microsoft 365 workloads & with Microsoft Azure Active Directory (Azure AD), part of Microsoft Entra. They have built security for Microsoft 365 settings, including hybrid environments. They have a working grasp of Windows clients, Windows servers, Active Directory, & PowerShell.`,
prerequisite:`Learners should start this training already having the following skills:

• Basic conceptual understanding of Microsoft Azure.

• Experience with Windows 10 devices.

• Experience with Office 365.

• Basic understanding of authorization & authentication.

• Basic understanding of computer networks.

• Working understanding of managing mobile devices.`,
outline_heading_1:`Explore identity synchronization`,
outline_heading_2:`Manage secure user access in Microsoft 365`,
outline_heading_3:`Protect against threats with Microsoft Defender for Endpoint`,
outline_heading_4:`Protect against harmful attacks & illegal access with Microsoft Edge`,
outline_heading_5:`Understand Microsoft 365 encryption`,
outline_heading_6:`Understand app management using Microsoft Endpoint Manager`,
outline_heading_7:`Manage device compliance`,
outline_heading_8:`Remediate hazards with Microsoft Defender for Office 365`,
outline_heading_9:`Query, view, & monitor data in Microsoft Sentinel`,
outline_heading_10:`Create & manage sensitive information types`,
outline_detail_1:`• Describe the Microsoft 365 authentication & provisioning options

• Explain directory synchronization

• Explain how Azure AD Connect facilitates coexistence between your on-premises Active Directory system & Microsoft 365`,
outline_detail_2:`• Manage user passwords

• Describe pass-through authentication

• Enable multifactor authentication

• Describe self-service password management

• Implement Azure AD Smart Lockout

• Implement entitlement packages in Azure AD Identity Governance

• Implement conditional access policies

• Create & perform an access review
• Create a Microsoft Defender for the Endpoint environment

• Onboard devices are to be monitored by Microsoft Defender for Endpoint

• Configure Microsoft Defender for Endpoint environment settings`,
outline_detail_3:`• Create a Microsoft Defender for the Endpoint environment

• Onboard devices are to be monitored by Microsoft Defender for Endpoint

• Configure Microsoft Defender for Endpoint environment settings`,
outline_detail_4:`• Describe how Microsoft Edge is built for secure browsing

• Use Microsoft Defender SmartScreen & Application Guard to protect against malicious attacks & unauthorized access.

• Manage Microsoft Edge security options with policies & controls in Microsoft Endpoint Manager
`,
outline_detail_5:`• Explaining how encryption mitigates the danger of unlawful data disclosure.

• Describing Microsoft data-at-rest & data-in-transit encryption solutions.

• Explaining Microsoft 365 utilizes service encryption to secure user data at the application layer.

• Understand the distinctions between Microsoft maintained keys & customer managed keys for usage with service encryption.
`,
outline_detail_6:`• Understand how your organization's apps can be set & protected.

• Understand the app management lifecycle.

• Learn about the data protection framework using app protection policies.`,
outline_detail_7:`•Plan for device compliance by outlining the rules & parameters that must be configured on a device for it to be considered compliant

• Configure conditional users & groups for deploying profiles, policies, & apps

• Create Conditional Access policies to implement automatic access control decisions for accessing your cloud apps

• Monitor registered devices to control their Intune activities & compliance status.`,
outline_detail_8:`• Define the capabilities of Microsoft Defender for Office 365.

• Understand how to mimic assaults within your network.

• Explain how Microsoft Defender for Office 365 may remediate vulnerabilities in your environment.
`,
outline_detail_9:`• Visualize security data with Microsoft Sentinel Workbooks.

• Understand how queries work.

• Explore workbook capabilities.

• Create a Microsoft Sentinel Workbook.`,
outline_detail_10:`• Recognize the difference between built-in & custom sensitivity labels

• Configure sensitive information kinds with exact data match-based classification

• Implement document fingerprinting

• Create custom keyword dictionaries`,
skill_1:`• Implement & manage identity & access (25-30%)`,
skill_2:`• Implement & monitor threat protection (30-35%)`,
skill_3:`• Implement & monitor information protection (15-20%)`,
skill_4:`• Manage compliance in Microsoft 365 (20-25%)`,
skill_5:`•Identity and Access Management`,
skill_6:`•Threat Detection & Response Management`,
course_faq_name:`MS-500: Microsoft 365 Security Administration`,
faq_question_1:`Q: What is Azure Security?`,
faq_question_2:`Q: How do you install security in Azure?`,
faq_question_3:`Q: How can I monitor Azure?`,
faq_question_4:`Q: What is Microsoft Azure technology?`,
faq_question_5:`Q: Will I obtain a training completion certificate?`,
faq_answer_1:`A: Azure Security Center is a single infrastructure security management system that increases the security posture of user data centers & also provides enhanced threat protection across user hybrid workloads in the cloud doesn't matter whether they're in Azure or not, as well as on-premises.`,
faq_answer_2:`A: To implement security in Azure, user can utilize the following steps:-

• Get ongoing protection with richer insights from Azure Security Center.

• Simplify security with built-in controls

• Manage identification & control access.

• Secure your network.

• Safeguard data.

• Key, secrets & certificate management.

• Get centralized visibility & prevent attacks.`,
faq_answer_3:`A: View performance directly from an Azure virtual machine scale set. To access straight from an Azure virtual machine scale set, various steps are required &, i.e., In the portal of Azure, a user selects Virtual machine scale sets. From the list, the user can quickly choose a VM & in the Monitoring section, click Insights to display the Performance tab.`,
faq_answer_4:`A: Microsoft Azure, generally referred to as Azure, is a cloud computing service built by Microsoft for building, testing, deploying, & managing applications & services through Microsoft-managed data centers.`,
faq_answer_5:`A: The training completion certification will be granted to all the professionals who have completed the training program & the project assignment given by your teacher. 

You can use the certificate in your future job interviews would undoubtedly enable you to land your ideal job.`,
},

{
    id:111,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS 203T00 Microsoft 365 Messaging`,
course_overview:`In this training, professionals will learn how to plan & implement an operating system deployment strategy with the help of modern deployment methods, as well as how to implement an update strategy. Professionals will be introduced to key components of modern management & co-management strategies. This training also covers what it takes to incorporate Microsoft Intune into your organization. Professionals will also learn about methods for the deployment & management of apps & browser-based applications. Professionals will be introduced to the fundamental concepts of security in modern management, including identities, authentication, access, & compliance policies. Professionals will be introduced to technologies such as Azure Active Directory, Azure Information Protection & Microsoft Defender for Endpoint, & how to leverage them to protect devices & data.
Duration: 5 Days`,
certification:`Professionals for this exam are message administrators that plan, install, configure, troubleshoot, & secure the messaging infrastructure in hybrid & cloud enterprise environments.
Messaging administrators are responsible for managing recipients, mailboxes, transport, mail flow, threat protection, compliance, administrative roles, migrations, & client connectivity.
Messaging administrators interact with other workload administrators & the Microsoft 365 enterprise administrator to design a secure hybrid or cloud-only messaging topology that satisfies the business objectives of an enterprise.
The communications administrator must have a working grasp of authentication types, licensing, integration with Microsoft 365 applications, & Microsoft Azure Active Directory (Azure AD), part of Microsoft Entra.`,
prerequisite:`This program is meant for those who are aspiring to the Microsoft 365 Messaging Administrator role.

Audience Profile
The Messaging Administrator planned deploys, configures, troubleshoots, & secures the messaging infrastructure in a hybrid & cloud enterprise context. Responsibilities include managing recipients, mail flow, mailboxes, transport, administrative roles, threat prevention, compliance, migrations, & client connectivity. In order to design a secure hybrid topology that fulfills the business objectives of a modern firm, the Messaging Administrator must engage with other workload administrators & the Microsoft 365 enterprise administrator. The Messaging Administrator must have a working grasp of authentication types, licensing, & integration with Microsoft 365 applications.`,
outline_heading_1:`Examine the enterprise desktop`,
outline_heading_2:`Explore Azure Active Directory`,
outline_heading_3:`Manage identities in Azure Active Directory`,
outline_heading_4:`Manage device authentication`,
outline_heading_5:`Enroll devices using Microsoft Endpoint Configuration Manager`,
outline_heading_6:`Enroll devices using Microsoft Intune`,
outline_heading_7:`Implement device profiles`,
outline_heading_8:`Monitor device profiles`,
outline_heading_9:`Manage user profiles`,
outline_heading_10:`Implement mobile application management`,
outline_detail_1:`• Describe the benefits of Modern Management.
• Explain the enterprise desktop life-cycle model.
• Describe considerations for planning hardware strategies.
• Describe the steps in planning OS & app deployment.
• Describe considerations for post-deployment & retirement.`,
outline_detail_2:`• Describing Azure AD.
• Comparing Azure AD to Active Directory Domain Services (AD DS).
• Describing how Azure AD is used as a directory for cloud apps.
• Describing Azure AD Premium P1 & P2.
• Describe Azure AD Domain Services.`,
outline_detail_3:`• Describe RBAC & user roles in Azure AD.
• Create & manage users in Azure AD.
• Create & manage groups in Azure AD.
• Use Windows PowerShell cmdlets to manage Azure AD.
• Describe how you can synchronize objects from AD DS to Azure AD.`,
outline_detail_4:`• Describe Azure AD join.
• Describe Azure AD join prerequisites, limitations & benefits.
• Join device to Azure AD.
• Manage devices joined to Azure AD.`,
outline_detail_5:`• Describe Microsoft Endpoint Manager.
• Understand the advantages of managing a client with Configuration Manager.
• Deploy the Configuration Manager client.
• Monitor the Configuration Manager client.
• Manage Configuration Manager devices.`,
outline_detail_6:`• Prepare Microsoft Intune for device enrollment.
• Configure Microsoft Intune for automatic enrollment.
• Explain how to enroll Windows, Android & iOS devices in Intune.
• Explain when & how to use Intune Enrollment Manager.
• Understand how to monitor & perform remote actions on enrolled devices.`,
outline_detail_7:`• Describing the various types of device profiles in Intune.
• Explaining the difference between built-in & custom profiles.
• Create & manage profiles.`,
outline_detail_8:`• Monitor the assignments of profiles.
• Understand how profiles are synchronized & how to manually force synchronization.
• Use PowerShell to execute & monitor scripts on devices.`,
outline_detail_9:`• Explain the various user profile types that exist in Windows.
• Describe how a user profile works.
• Configure user profiles to conserve space.
• Explain how to deploy & configure Folder Redirection.
• Explain Enterprise State Roaming.
• Configure Enterprise State Roaming for Azure AD devices.`,
outline_detail_10:`• Explain Mobile Application Management
• Understand application considerations in MAM
• Explain how to use Configuration Manager for MAM
• Use Intune for MAM
• Implement & manage MAM policies`,
skill_1:`• Deploy Windows client (25-30%)`,
skill_2:`• Manage identity & access (10-15%)`,
skill_3:`• Manage compliance policies & configuration profiles (10-15%)`,
skill_4:`• Manage, maintain, & protect devices (25-30%)`,
skill_5:`• Manage apps (10-15%)`,
skill_6:`• Message Routing Optimization`,
course_faq_name:`MS 203T00 Microsoft 365 Messaging`,
faq_question_1:`Q: What will we study in this training?`,
faq_question_2:`Q: What is a mailbox database?`,
faq_question_3:`Q: How can I migrate a mailbox from one database to another?`,
faq_question_4:`Q: What is database portability?`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_1:`• Understand the modem messaging architecture 
• Deploy & manage modem messaging architecture 
• Create & manage exchange recipients 
• Manage Email addresses, lists & resources
• Plan for Mailbox databases 
• Create & administer Mailbox databases 
• administer authentication for Messaging 
• Configure organisational settings 
• Configure organisational sharing`,
faq_answer_2:`A: A mailbox database is a unit of granularity where mailboxes are created & stored. A mailbox database is commonly kept as an Exchange database (.edb) file. In Exchange 2016 & 2019, each mailbox database has its own particular attributes, which users may easily adjust easily.`,
faq_answer_3:`A: Use the EAC to create a local move request:-
• In the EAC, go to Recipients > Migration > click Add & after that, pick Move to a different database.
• The New local mailbox move wizard opens. 
• On the Move configuration page, configure these options
• On the Start, the batch page, configure settings.`,
faq_answer_4:`A: Database portability is simply a feature that enables a Microsoft Exchange Server 2013 mailbox database to be relocated to or mounted on any other Mailbox server in a similar firm running Exchange 2013, which has databases with the same database schema version.`,
faq_answer_5:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs.`,
},



{
    id:112,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MD 101T00 Managing Modern Desktops`,
course_overview:`In this training, professionals will learn how to plan & implement an operating system deployment strategy with the help of modern deployment methods, as well as how to implement an update strategy. Professionals will be introduced to key components of modern management & co-management strategies. This training also covers what it takes to incorporate Microsoft Intune into your organization. Professionals will also learn about methods for the deployment & management of apps & browser-based applications. Professionals will be introduced to the fundamental concepts of security in modern management, including identities, authentication, access, & compliance policies. Professionals will be introduced to technologies such as Azure Active Directory, Azure Information Protection & Microsoft Defender for Endpoint, & how to leverage them to protect devices & data.
Duration: 5 Days`,
certification:`Professionals for this exam are IT professionals who have subject matter expertise in installing, configuring, & maintaining Windows clients within a domain infrastructure. Responsibilities include protecting & maintaining the reliability of the Windows device & the data stored on the device. Professionals for this exam administer Windows clients in an Active Directory environment. Professionals for this may collaborate with the Microsoft 365 security administrator, Microsoft 365 enterprise administrator, Microsoft 365 security administrator, & Windows Server administrators to implement a modern desktop & device strategy that meets the business needs of an organization.
Professionals for this exam must be familiar with device management, client computer networking, policy-based management, & cloud-based solutions.`,
prerequisite:`The Modern Desktop Administrator should be familiar with M365 work-loads & must have strong skills & experience in deploying, configuring, & maintaining Windows 10 & later, & non Windows devices. The MDA role focuses on cloud services rather than on-premises management technologies. It's recommended professionals complete training MD-100, Windows Client, prior to taking this training.
Audience Profile
The Modern Desktop Administrator deploys, manages, configures, secures, & monitors devices & client applications in an enterprise environment. Responsibilities include managing identity, access, policies, updates, & apps. The MDA collaborates with the M365 Enterprise Administrator to design & implement a device strategy that meets the business needs of a modern organization. The Modern Desktop Administrator should be familiar with M365 workloads & must have strong skills & experience in deploying, configuring, & maintaining Windows 10 & later & non-Windows devices. The MDA role focuses on cloud services rather than on-premises management technologies.`,
outline_heading_1:`Examine the enterprise desktop`,
outline_heading_2:`Explore Azure Active Directory`,
outline_heading_3:`Manage identities in Azure Active Directory`,
outline_heading_4:`Manage device authentication`,
outline_heading_5:`Enroll devices using Microsoft Endpoint Configuration Manager`,
outline_heading_6:`Enroll devices using Microsoft Intune`,
outline_heading_7:`Implement device profiles`,
outline_heading_8:`Monitor device profiles`,
outline_heading_9:`Manage user profiles`,
outline_heading_10:`Implement mobile application management`,
outline_detail_1:`• Describe the benefits of Modern Management.
• Explain the corporate desktop life-cycle paradigm.
• Describe factors for planning hardware strategies.
• Describe the steps in planning OS & app rollout.
• Describe considerations for post-deployment & retirement.`,
outline_detail_2:`• Describing Azure AD.
• Comparing Azure AD to Active Directory Domain Services (AD DS).
• Describing how Azure AD is used as a directory for cloud apps.
• Describing Azure AD Premium P1 & P2.
• Describe Azure AD Domain Services.`,
outline_detail_3:`• Describe RBAC & user roles in Azure AD.
• Create & manage users in Azure AD.
• Create & administer groups in Azure AD.
• Use Windows PowerShell cmdlets to handle Azure AD.
• Describe how you can synchronize objects from AD DS to Azure AD.`,
outline_detail_4:`• Describe Azure AD join.
• Describe Azure AD join prerequisites, limits & benefits.
• Join device to Azure AD.
• Manage devices joined to Azure AD.`,
outline_detail_5:`• Describe Microsoft Endpoint Manager.
• Understand the advantages of managing a client with Configuration Manager.
• Deploy the Configuration Manager client.
• Monitor the Configuration Manager client.
• Manage Configuration Manager devices.`,
outline_detail_6:`• Prepare Microsoft Intune for device enrollment.
• Configure Microsoft Intune for automatic enrollment.
• Explain how to enroll Windows, Android & iOS devices in Intune.
• Explain when & how to use Intune Enrollment Manager.
• Understand how to monitor & take remote actions on registered devices.`,
outline_detail_7:`• Describing the many sorts of device profiles in Intune.
• Explaining the distinction between built-in & custom profiles.
• Create & manage profiles.`,
outline_detail_8:`• Monitor the assignments of profiles.
• Understand how profiles are synchronized & how to manually force synchronization.
• Use PowerShell to execute & monitor scripts on devices.`,
outline_detail_9:`• Explain the various user profile kinds that exist in Windows.
• Describe how a user profile works.
• Configure user profiles to conserve space.
• Explain how to deploy & configure Folder Redirection.
• Explain Enterprise State Roaming.
• Configure Enterprise State Roaming for Azure AD devices.`,
outline_detail_10:`• Explain Mobile Application Management • Understand application considerations in MAM • Explain how to utilize Configuration Manager for MAM • utilize Intune for MAM • Implement & administer MAM policies`,
skill_1:`• Deploy Windows client (25-30%)`,
skill_2:`• Manage identity & access (10-15%)`,
skill_3:`• Manage compliance policies & configuration profiles (10-15%)`,
skill_4:`• Manage, maintain, & protect devices (25-30%)`,
skill_5:`• Desktop Deployment Proficiency`,
skill_6:`• Manage apps (10-15%)`,
course_faq_name:`MD 101T00 Managing Modern Desktops`,
faq_question_1:`Q: What is the modern desktop?`,
faq_question_2:`Q: What is a modern desktop Microsoft?`,
faq_question_3:`Q: What is a Microsoft-managed desktop?`,
faq_question_4:`Q: What is a modern company?`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_1:`A: We define the Modern Desktop experience as one that comes with the latest productivity tools to enable users to work wherever they need to while remaining in a secure, protected state. It is deployed & managed in a simple manner to minimize overhead & is always current.`,
faq_answer_2:`A: A modern desktop is secure, productive, & efficient. Through this, users can explore the benefits of a modern desktop & also user can learn about how to make the shift to Windows 10 as well as Microsoft 365 Apps.`,
faq_answer_3:`A: Microsoft Managed Desktop is basically a cloud-based service that mainly brings together Microsoft 365 Enterprise (including Windows 10 Enterprise & Office 365 Enterprise) & easily adds these features: User device deployment, IT service management & operations, Security monitoring & response & User support.`,
faq_answer_4:`A: A modern company uses technology as a catalyst for change. A modern company knows execution trumps everything else. Every employee in a modern company is an entrepreneur & very crucial responsible for growth. Every team inside a modern company experiments rapidly with new products as well as business models.`,
faq_answer_5:`A: To attend the training session, you should have operational Desktops or Laptops with the required specification, along with a good internet connection to access the labs.`,
},

{
    id:113,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MD 100T00 Windows Client`,
course_overview:`In this training, professionals will learn how to maintain & configure Windows computers in an organizational context. Professionals will build abilities that include understanding how to install, customize, & update Windows 10 & later operating systems. Professionals will learn how to manage storage, files, & devices as well as how to configure network connectivity for Windows. Professionals will also learn how to safeguard the Windows OS & protect the data on the device. Finally, pros will learn how to manage & troubleshoot Windows clients.

Duration: 5 Days`,
certification:`Professionals for this exam are IT professionals who have subject matter experience in installing, configuring, & managing Microsoft Windows clients inside a domain infrastructure. Responsibilities include preserving & maintaining the reliability of the Windows device & the data saved on the device. Professionals for this exam administer Windows clients in a Microsoft Azure Active Directory (Azure AD), part of the Microsoft Entra environment. Professionals for this exam may collaborate with the Microsoft 365 enterprise administrator, the Microsoft 365 security administrator, & Windows Server administrators to build a contemporary desktop & device strategy that fits the business demands of an organization.
Professionals for this test must be knowledgeable with device administration, client computer networking, policy-based management, & cloud-based solutions.`,
prerequisite:`• Basic awareness of computer networks & hardware fundamentals.
• Basic understanding of OS & Application fundamentals.
• Experience in using the Windows OS.

Audience Profile
Professionals for this exam are IT professionals who perform installation, configuration, general local management & maintenance of Windows 10 & later core services. Professionals may also be conversant with enterprise situations & cloud-integrated services.`,
outline_heading_1:`Explore the Windows client`,
outline_detail_1:`• Describe Windows 10 & Windows 11.
• Explain the new features accessible in Windows.
• Explain the benefits of adopting the Windows client for small & medium-sized enterprises.
• Discuss administering the Windows client in an enterprise environment.
• Describe the elements of the new Windows 10 & 11 user interfaces`,
outline_heading_2:`Examine Windows client editions & requirements`,
outline_detail_2:`• Explain the differences between the different editions of Windows.
• Select the most suited Windows device for your needs.
• Describe the minimum recommended hardware requirements for installing Windows 10 & 11.`,
outline_heading_3:`Evaluate Windows client installation techniques`,
outline_detail_3:`• Describe the alternatives available for installing & deploying the Windows client. -Understand the procedure of installing the Windows client.
• Describe the methods of activation for the Windows client.
• Describe the factors to consider in a new machine deployment.`,
outline_heading_4:`Upgrade & transfer Windows clients`,
outline_detail_4:`• Describe the process of upgrading to a newer version of Windows.
• Describe the considerations to consider when considering to upgrade Windows.
• Describe the process of transitioning to Windows.
• Understand the concerns while deciding between the upgrade & migration processes.
• Upgrade a Windows device.`,
outline_heading_5:`Evaluate deployment methods`,
outline_detail_5:`• Describing the many methods of automatic desktop deployments.
• Describing popular technologies used to accomplish automated procedures.
• Describing how to exploit virtualization in Windows clients.
• Configure a Hyper-V virtual machine.`,
outline_heading_6:`Explore authentication`,
outline_detail_6:`• Configure a service account.
• Set up a local account.
• Use Credential Manager to manage credentials.
• Describe & configure Windows Hello.`,
outline_heading_7:`Manage users & groups`,
outline_detail_7:`• Describe Active Directory Domain Services.
• Describe Azure Active Directory.
• Describe the distinctions between Active Directory Domain Services & Azure Active Directory.
• Describe Active Directory Distribution & Security groups.
• Describe the authentication process in Active Directory.`,
outline_heading_8:`Configure User Account Control`,
outline_detail_8:`• Describe User Account Control (UAC).
• Explain how UAC works.
• Explain how to adjust UAC notification settings.`,
outline_heading_9:`Implement device registration`,
outline_detail_9:`• Describe the issues that BYOD poses.
• Describe Device Registration & its usage.
• Describe how Device Registration works.
• Describe the infrastructure needs for Device Registration.
• Describe how to register & enroll a device.`,
outline_heading_10:`Explore typical configuration options`,
outline_detail_10:`• Use advanced startup options.
• Customize desktop settings.
• Describe the various display options in Windows.
• Configure display options.
• Describe how to control mobile-device settings in Windows.
• Describe power plans.
• Configure power choices.`,
outline_heading_11:`Explore advanced configuration methods`,
outline_detail_11:`• Deploy customized Start menu setups
• Describe activation mechanisms
• Describe Active Directory
• Describe the benefits of using Group Policy
• Configure & implement a Group Policy Object
• Describe Windows PowerShell
• Explain how to use Windows PowerShell cmdlets`,
outline_heading_12:`Manage drivers & device peripherals`,
outline_detail_12:`• Explain the use & relevance of device drivers.
• Explain how to manage device peripherals.
• Describe Windows printer functionality.
• Describe printing components.
• Describe the benefits of Type 4 printer drivers.
• Describe how to manage client-side printing.
• Describe how to manage print server properties.
• Install & share a printer.`,
outline_heading_13:`Explore name resolution`,
outline_detail_13:`• Describe the name resolution.
• Describe DNS.
• Explain how to troubleshoot name resolution.
• Configure & test name resolution settings in Windows.`,
outline_heading_14:`Configure wireless network connectivity`,
outline_detail_14:`• Describe wireless technologies.
• Configure wireless settings on Windows.
• Discuss the considerations for installing wireless networks within enterprises.`,
outline_heading_15:`Explore remote access`,
outline_detail_15:`• Describe how to utilize VPNs to connect to a remote network.
• Explain how Always On VPN can let remote users connect.
• Discuss the considerations of permitting remote access for your users.`,
outline_heading_16:`Employ remote management`,
outline_detail_16:`• Describe Remote Desktop in Windows.
• Enable & use Remote Assistance, including Easy Connect.
• Describe Azure Remote Desktop Services.`,
outline_heading_17:`Manage storage on Windows clients`,
outline_detail_17:`• Describe the many ways to access storage.
• Explain the distinction between network-attached storage (NAS) & storage area networks (SANs).
• Compare MBR & GUID partition table (GPT) drives.
• Describe how to transform a basic disk to a dynamic disk.
• Describe the tools available for managing disks.
• Describe a basic volume.
• Describe mirrored, spanned, & striped volumes.
• Create volumes, resize, & manage volumes.`,
outline_heading_18:`Maintain disks & volumes`,
outline_detail_18:`• Explaining the key characteristics of the Storage functionality in Windows clients.
• Describing how to use the Storage capability.
• Describing how files stored on disks could fracture.
• Describing how to defragment volumes.
• Explaining folder compression.
• Describe how to compress directories.
• Describe what disk quotas are.
• Describe how to configure disk quotas.`,
outline_heading_19:`Implement Storage Spaces`,
outline_detail_19:`• Explaining what the Storage Spaces function is.
• Describing the features of Storage Spaces.
• Discussing about which scenarios to employ Storage Spaces.
• Showing how to configure Storage Spaces.`,
outline_heading_20:`Explore Windows client file systems`,
outline_detail_20:`• Describing the features of the FAT file system.
• Explaining the features of the NTFS file system.
• Describing the features of the ReFS file system.
• Working with the file systems available in Windows client.`,
outline_heading_21:`Configure & manage file access`,
outline_detail_22:`• Describe the tools for managing files & folders.
• Describe how to configure file & folder permissions.
• Describe the notion of permission inheritance.
• Implement criteria to limit file & folder access.
• Secure files & folders by employing file permissions.
• Use the effective permissions function.
• Describe how copying & moving files & folders effect permissions`,
outline_heading_23:`Configure & manage shared folders`,
outline_detail_23:`• Describe shared folders.
• Describe ways for sharing folders.
• Describe the effect of combining file permissions & sharing permissions.`,
outline_heading_24:`Manage user files`,
outline_detail_24:`• Describe Windows Libraries.
• Describe the capabilities of OneDrive.
• Configure Windows to synchronize files & settings with OneDrive.
• Describe Work Folders & how they contrast to OneDrive.
• Configure a client to use Work folders.`,
outline_heading_25:`Provide apps to users`,
outline_detail_25:`• Differentiate between the sorts of programs in Windows.
• Describe manual app installation.
• Explain the ways for automating the installation of desktop apps.`,
outline_heading_26:`Manage Universal Windows Platform apps`,
outline_detail_26:`• Describe the Universal Windows applications, Microsoft Store, & Microsoft Store for Business.
• Explain how to manage & restrict access to Microsoft Store.
• Explaining how to overcome issues connected to Universal Windows apps.
• Explaining how to configure allocated access to a Universal Windows program.
• Describing the method of utilizing AppLocker to control Universal Windows programs.`,
outline_heading_27:`Manage the Microsoft Edge browser`,
outline_detail_27:`• Describe the characteristics of Microsoft Edge.
• Configure & utilize Microsoft Edge.
• Discuss the suitable browser to use in your organization.
• List the productivity features in Microsoft Edge.`,
outline_heading_28:`Explore the Windows servicing model`,
outline_detail_28:`• Describe the Windows client service model.
• Describe the different Windows service channels.
• Describe the available ways for deploying updates to Windows`,
outline_heading_29:`Update Windows clients`,
outline_detail_29:`• Describe the available techniques for applying updates to Windows.
• Explain the Windows Update configuration options.
• Explain the Group Policy Object (GPO) settings available for configuring Windows Update.
• Configure Windows Update.
• Describe how to use Windows Server Update Services (WSUS) to provide updates to Windows.`,
outline_heading_30:`Explore malware & threat protection`,
outline_detail_30:`• Describe malware.
• Understand the sources of malware.
• Describe strategies of reducing malware.
• Identify typical network-related security threats.
• Describe the means by which you might minimize these frequent security threats.
• Describe tools for safeguarding the user's identification.
• Describe tools for safeguarding data on Windows.
• Describe tools for safeguarding Windows devices.`,
outline_heading_31:`Explore Microsoft Defender`,
outline_detail_31:`• Use Microsoft Defender to detect & quarantine malware.
• Describe the purpose of a firewall.
• Describe Windows Defender Firewall capabilities.
• Explain network-location characteristics.
• Explain the expanded capability of Windows Defender Firewall with Advanced Security.`,
outline_heading_32:`Explore device encryption features`,
outline_detail_32:`• Describe the benefits of drive encryption using BitLocker
• Describe the benefits of an Encrypted File System`,
outline_heading_33:`Explore connection security rules`,
outline_detail_33:`• Describe the purpose & functionality of IPsec.
• Describe how to configure IPsec.
• Describe connection security rules.
• Explain authentication choices.
• Monitor connections.`,
outline_heading_34:`Explore advanced protection mechanisms`,
outline_detail_34:`• Describe the features & use of the Security Compliance Toolkit
• Describe the characteristics of AppLocker
• Describe techniques of safeguarding data in the enterprise
• Describe the benefits & features of Microsoft Defender for Endpoint`,
outline_heading_35:`Explore troubleshooting approaches`,
outline_detail_35:`• Describe the Enterprise Desktop Support Technician (EDST) work function.
• Describe the desktop support environment.
• Describe how to interact with end consumers.
• Explain the essential steps & terminology of a troubleshooting methodology`,
outline_heading_35:`Explore Windows architecture`,
outline_detail_35:`• Describe how varied form factors & hardware options affect supporting the device.
• Explain the Windows operating system architecture.
• Describe the Windows registry
• Use the Registry Editor program to alter Windows settings.`,
outline_heading_36:`Explore support & diagnostic tools`,
outline_detail_36:`• Explain how to use the Task Manager tool.
• Use Event Viewer to discover problems.
• Explain how to use the Reliability Monitor.
• Use the Diagnostics & Recovery Toolset.
• Use the Steps Recorder to record specifics of a problem.
• Use the Microsoft Management Console
• Understand the Windows Registry`,
outline_heading_37:`Monitor & troubleshoot Windows client performance`,
outline_detail_37:`• Describe important system components in Windows.
• Describe performance monitoring tools in Windows.
• Explain how to construct a performance baseline.
• Explain how to maximize disk & memory performance in a Windows machine.`,
outline_heading_38:`Employ file recovery in Windows client`,
outline_detail_38:`• Explain the Windows file recovery methods & tools.
• Use File History to restore files.
• Describe & use the Previous Versions functionality.
• Describe how to configure & use the Azure Backup tool.
• Troubleshoot File Recovery Options.
• Explain how & when to utilize the Reset this PC tool.
• Explain how to use provisioning packages.`,
outline_heading_39:`Explore application troubleshooting`,
outline_detail_39:`• Describe desktop app deployment strategies.
• Discuss desktop app deployment concerns.
• Describe Microsoft 365.
• Describe the difference between Microsoft 365 apps & desktop apps.
• Resolve desktop app deployment concerns.
• Troubleshoot Windows Installer difficulties.
• Describe how to utilize AppLocker to control apps.
• Control desktop program installation using AppLocker policies`,
outline_heading_40:`Troubleshoot Windows startup`,
outline_detail_40:`• Describe the Windows starting architecture.
• Explain the repair & recovery options available in Windows.
• Describe the available sophisticated startup recovery tools.
• Explore the advanced starting environment.
• Describe the System Restore process in Windows.
• Access the Windows System Restore program to fix the starting environment.
• Describe volume activation considerations.
• Describe the role of the BCD shop.
• Describe BCD configuration settings.`,
outline_heading_41:`Troubleshoot operating system service issues`,
outline_detail_41:`• Describe operating system services.
• Identify failing services by utilizing Windows tools.
• Explain how to use tools & utilities to disable services.
• Explain how to resolve locked-out accounts.`,
outline_heading_42:`Troubleshoot device driver failures`,
outline_detail_42:`• Describe the role & importance of device drivers.
• Manage & troubleshoot device drivers.
• Explain the difference between the staging & installation of device drivers.
• Restrict installation of device drivers by using Group Policy.`,
outline_heading_43:`Explore physical hardware troubleshooting`,
outline_detail_43:`• Describe hardware-related problems.
• Describe the considerations for using USB devices.
• Describe considerations for using wireless devices.
• Explain how you can utilize built-in diagnostic tools to acquire hardware information.
• Determine how to effectively approach hardware challenges.
• Apply the instructions for debugging hardware-related problems.`,
outline_heading_44:`Troubleshoot physical failures on Windows clients`,
outline_detail_44:`• Apply device-replacement considerations.
• Identify the most vulnerable hardware items.
• Apply the recommendations for replacing hardware.
• Diagnose memory disorders.
• Diagnose & troubleshoot disk problems.
• Explaining the features of the NTFS file system.
• Describing the features of the ReFS file system.
• Working with the file systems available in Windows client.
• Customize desktop settings.
• Describe the various display options in Windows.
• Configure display options.
• Describe how to control mobile-device settings in Windows.
• Describe power plans.
• Configure power choices.`,
skill_1:`• Install & setup Windows (20–25%)`,
skill_2:`• Configure & manage connectivity & storage (15–20%)`,
skill_3:`• Maintain Windows (30–35%)`,
skill_4:`• Protect devices & data (25–30%)`,
skill_5:`• Remote Desktop Services`,
skill_6:`• Windows Client Deployment Techniques`,
course_faq_name:`MD 100T00 Windows Client`,
faq_question_1:`Q: What is the difference between a Windows client & Windows Server?`,
faq_answer_1:`A: A server OS basically runs on a specified server. A client OS generally works on various client devices, such as PCs, laptops, etc. This type of OS is developed in a way that it functions on any server. This type of OS is created in a way that it functions within a desktop.`,
faq_question_2:`Q: What is Windows client administration?`,
faq_answer_2:`A: Microsoft-Windows-Client-Administration. The professionals learn how to personalize the Windows Client GUI and monitor and & manage systems that run Windows Client Operating System & secure Window Client Operating System & protect Windows Client OS resources.`,
faq_question_3:`Q: What is the aim of the client system?`,
faq_answer_3:`A: Client Systems means any communication line, modem connection, or other facilities, software, hardware, Mobile Devices, or equipment provided & utilized by You to transmit or receive any information.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},

{
    id:114,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS 900T01 Microsoft 365 Fundamentals`,
course_overview:`This training presents an overview of Microsoft 365, which is an integrated cloud platform combining industry-leading productivity tools together with intelligent cloud services, & world-class security. You'll study core knowledge on the considerations & advantages of embracing cloud services & the Software as a Service - SaaS, cloud model, with a specific focus on Microsoft 365 cloud service offerings. You'll begin by learning about cloud principles, including an overview of cloud computing. You will be introduced to Microsoft 365 & understand how Microsoft 365 technologies enhance collaboration, boost productivity, & optimize communications. The program then evaluates how security, compliance, privacy, & trust are managed in Microsoft 365, & it closes with an overview of Microsoft 365 subscriptions, billing, licensing, & support.
`,
certification:`This exam is aimed for professionals wishing to demonstrate core level understanding of Software as a Service (SaaS) solutions to facilitate productivity on-site, at home, or a combination of both. Professionals may have familiarity of cloud-based solutions or may be new to Microsoft 365. Professionals can use this test to prepare for other Microsoft certifications, but it isn't a prerequisite for any of the linked certifications.

Professionals should be able to recommend Microsoft 365 solutions that meet common organizational IT concerns. They should comprehend how Microsoft 365 technologies boost productivity, facilitate collaboration, & optimize communications.

Professionals should also be able to offer solutions for endpoint & application management, desktop virtualization, automated operating system rollout, & comprehensive reporting & analytics. They should be knowledgeable with Microsoft 365 license, deployment & migration assistance, & support choices for enterprises wishing to maximize their investment in the cloud.`,
prerequisite:`Professionals do not need to have prior experience with Microsoft 365 before attending this program; however, a fundamental familiarity with computer technology & cloud computing is assumed.

Audience Profile
This program is meant for professionals wishing to demonstrate foundational level understanding of cloud based technologies to improve productivity & collaboration on-site, at home, or a combination of both. Professionals may have familiarity of cloud-based solutions or may be new to Microsoft 365.`,
outline_heading_1:`• Cloud Productivity Management`,
outline_detail_1:`• Define cloud computing.
• Describe the shared responsibility model.
• Define cloud models, including public, private, & hybrid.
• Identifying relevant use cases for each cloud type.
• Describing the consumption-based model.
• Compare cloud pricing models.`,
outline_heading_2:`Describe the benefits of adopting cloud services`,
outline_detail_2:`• Describe the benefits of high availability & scalability in the cloud.
• Describe the benefits of reliability & predictability in the cloud.
• Describe the benefits of security & governance in the cloud 
• Describing the benefits of manageability in the cloud.`,
outline_heading_3:`What is Microsoft 365?`,
outline_detail_3:`• Describe Office 365, Microsoft 365, & Windows 365 
• Describe how Microsoft 365 empowers workers for hybrid & flexible work 
• Create a Microsoft 365 trial organization`,
outline_heading_4:`Describe productivity solutions of Microsoft 365`,
outline_detail_4:`• Describe how the capabilities of Microsoft 365 can boost productivity 
• Describe how Microsoft 365 Apps help people craft compelling content in real-time 
• Describe how the capabilities of the work management tools optimize operations 
• Describe additional Microsoft 365 productivity apps`,
outline_heading_5:`Describe collaboration solutions of Microsoft 365`,
outline_detail_5:`• Describe how the collaboration tools of Microsoft 365 promote synergy in the workplace • Describe how Microsoft Teams helps boost teamwork 
• Describe how Microsoft Viva helps organizations create thriving work cultures 
• Describe how Yammer communities can help foster connections within your organization`,
outline_heading_6:`Describe endpoint modernization, management ideas, & deployment options in Microsoft 365`,
outline_detail_6:`• Describe the endpoint modern management capabilities of Microsoft 365 
• Describe the differences between Windows 365 & Azure Virtual Desktop 
• Describe the deployment & release models for Windows-as-a-Service 
• Describe the deployment techniques & update channels for Microsoft 365 Apps`,
outline_heading_7:`Describe the analytics capabilities of Microsoft 365 • Describe how Viva Insights enable people & organizations work smarter & achieve balance`,
outline_detail_7:`• Describing the capabilities of the Microsoft 365 admin center & user-portal • Describing the reports accessible in the Microsoft 365 admin center & other admin centers`,
outline_heading_8:`Describe the services & identity kinds of Azure AD`,
outline_detail_8:`• Describe what Azure AD does. 
• Describe the sorts of identities Azure AD supports.`,
outline_heading_9:`Describe the access management capabilities of Azure AD`,
outline_detail_9:`• Describe Conditional Access in Azure AD. 
• Describe the benefits of Azure AD roles & role-based access control.`,
outline_heading_10:`Describe threat protection using Microsoft 365 Defender`,
outline_detail_10:`• Describe the Microsoft 365 Defender service. 
• Describe how Microsoft 365 Defender delivers comprehensive protection against complex assaults.
• Describe & explore Microsoft 365 Defender portal.`,
outline_heading_11:`Describe the security capabilities of Microsoft Sentinel`,
outline_detail_11:`• Describe the security ideas for SIEM & SOAR.
• Describing how Microsoft Sentinel delivers integrated threat management.
• Describing the pricing models of Microsoft Sentinel.`,
outline_heading_12:`Describe the compliance management capabilities in Microsoft Purview`,
outline_detail_12:`• Describe the Microsoft Purview compliance portal. 
• Describe Compliance Manager.
• Describe the use & benefits of compliance score.`,
outline_heading_13:`Describe the Service Trust Portal & privacy at Microsoft`,
outline_detail_13:`• Describing the offerings of the Service Trust Portal. 
• Describe Microsoft's Privacy standards.
• Describe Microsoft Priva.`,
outline_heading_14:`Describe Microsoft 365 pricing, licensing, & billing options`,
outline_detail_14:`• Describe the pricing models available for Microsoft cloud services 
• Describe billing management features such as billing frequency & ways of payment 
• Describe the distinctions between basic license & add-on licensing`,
outline_heading_15:`Describe support offers for Microsoft 365 services`,
outline_detail_15:` • Describe the support offerings available for Microsoft 365 & how to initiate a support request • Describe service level agreement (SLA) concepts 
• Identify methods to track service health through the Microsoft 365 admin center 
• Describe how organizations can submit feedback on Microsoft 365 products & services`,
skill_1:`• Describe cloud principles (10–15%)`,
skill_2:`• Describe cloud principles (10–15%)`,
skill_3:`• Describe fundamental Microsoft 365 services & principles (50–55%)`,
skill_4:`• Describe security, compliance, privacy, & trust in Microsoft 365 (15–20%)`,
skill_5:`• Describe Microsoft 365 cost & support (10–15%)`,
skill_6:`• Cloud Productivity Management`,
course_faq_name:`MS 900T01 Microsoft 365 Fundamentals`,
faq_question_1:`Q: What is the purpose of Microsoft Office 365?`,
faq_answer_1:`A: Microsoft 365 is the productivity cloud designed to help you pursue your passion & operate your business. Multiple apps including Word, PowerPoint, Excel, and Microsoft 365 basically bring together best-in-class productivity tools with strong cloud services, device management as well as sophisticated security in one connected experience.`,
faq_question_2:`Q: What is included with Microsoft 365?`,
faq_answer_2:`A: With Microsoft 365 subscription options, users will get the fully installed Office software, which includes Word, Outlook, Excel, PowerPoint, Publisher, & Access (Publisher, as well as Access, are available on PC only).`,
faq_question_3:`Q: Why does Office 365 take forever to install?`,
faq_answer_3:`A: Office 365 is an online program & its installation depends on how fast your internet is. If the time duration is growing, it usually signifies your internet is slow. You can check your internet speed on some websites. Open the site & click on BEGIN TEST.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs. `,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},


{
    id:115,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS 720T00 Microsoft Teams Voice Engineer`,
course_overview:`In this training, you will learn how to plan, develop, configure, maintain, & troubleshoot an integrated communications solution for a business using Microsoft Teams. The session will cover Teams Phone with Calling Plans, Direct Routing, Operator Connect, & Teams Phone Mobile, moreover, as Teams devices, audio/video conferencing, & voice migration. Professionals will learn troubleshooting approaches & how to address typical phone & voice problems.

Duration: 3 Days`,
certification:`Professionals for this exam are Microsoft Teams voice engineers that plan, build, configure, maintain, & troubleshoot integrated communications solutions within a business. They should be able to transform business needs into technological architecture & designs for communication solutions.
Professionals are conversant with communications technologies & have familiarity with Microsoft Teams, Microsoft 365, & PowerShell. They must be able to setup & configure Microsoft Teams Phone with PSTN access through Operator Connect, Direct Routing, & Microsoft Teams Calling Plans. Professionals also handle devices certified for Microsoft Teams, audio/video conferencing, & voice migration.
Professionals cooperate with phone carriers & third-party manufacturers to deliver sophisticated voice features in Microsoft Teams. They also collaborate with administrators for various tasks, like networking, licensing, identification, security, & compliance.`,
prerequisite:`Before attending this program, professionals must have an intermediate mastery of the following topics from MS-700: Managing Microsoft Teams:
 
 
• Plan & design a Microsoft Teams environment • Manage chat, teams, channels, & apps • Manage calls & meetings • Monitor & troubleshoot a Microsoft Teams environment

Audience Profile
The Microsoft teams voice engineer develops, constructs, configures, maintains, & troubleshoots an integrated communications system at a company. The Microsoft Teams Voice Engineer should be able to transform business needs into technical architecture & designs for communication solutions. The Microsoft Teams Voice Engineer is knowledgeable with telecommunication technologies & has experience in Microsoft Teams, Microsoft 365, & PowerShell. They must be able to setup & configure Microsoft Teams Phone with PSTN access through Operator Connect, Direct Routing, & Teams Calling Plans. The Microsoft Teams Voice Engineer manages Teams-certified devices, voice migration, & audio/video conferencing. The Microsoft Teams Voice Engineer engages with phone carriers & third-party partners to allow advanced voice features in the Microsoft Teams. The Microsoft Teams Voice Engineer also works with administrators for various workloads, including networking, identity, licensing, security, & compliance. To get the Microsoft Teams Voice Engineer certification, professionals must pass Exam MS-700: Managing Microsoft Teams in addition to the MS-720 exam.`,
outline_heading_1:`Plan for Teams Phone`,
outline_detail_1:`• Plan for a basic Teams Phone deployment.
• Plan for enhanced Teams Phones calling options.
• Plan for Teams phones & gadgets.`,
outline_heading_2:`Plan & optimize network performance for Teams Phone`,
outline_detail_2:`• Describe Teams network requirements 
• Evaluate organizational bandwidth requirements using the Teams Network planner 
• Assess the network with the Teams Network Assessment Tool 
• Optimize network & WiFi for media flow 
• Optimize media flow with QoS`,
outline_heading_3:`Migrate voice services from Skype for Business Server to Teams`,
outline_detail_3:`• Understand how Skype & Teams interact 
• Plan deployment of Skype for Business Server Hybrid 
• Describe the conditions required for migration 
• Configure Skype for Business Server Hybrid 
• Migrate users & devices to Teams`,
outline_heading_4:`Configure Teams Phone`,
outline_detail_4:`• Configure emergency calling addresses 
• Manage Microsoft Calling Plan numbers 
• Setup & manage Operator Connect & Teams Phone Mobile
• Understand Teams Phone policies & setup options 
• Configure Audio Conferencing & Communication Credits`,
outline_heading_5:`Configure auto attendants & call queues`,
outline_detail_5:`• Describe the distinctions between auto attendants & call queues
• Designing an auto attendant to gather information from callers.
• Explaining how to set up auto attendants to implement your approach.
• Demonstrate how to plan call queues to manage several callers.
• Describe how to configure call queues to alert agents & route calls.
• Explain how to plan to license auto attendants & call queues.`,
outline_heading_6:`Configure & deploy Direct Routing`,
outline_detail_6:`• Explain how to configure & connect a Session Border Controller (SBC).
• Describe the method for installing simple & complex voice routing.
• Describe how to supply users, implement number translation, and establish, & assign dial plans.
• Explain how to configure & link a Survivable Branch Appliance (SBA).`,
outline_heading_7:`Extend Teams Phone with additional services`,
outline_detail_7:`• Describe how Teams Phone interacts with other Microsoft services.
• Configure & integrate third-party policy-based compliance recording.
• Configure & integrate third-party contact center solutions.
• Design & register Voice Bots for custom-developed solutions.`,
outline_heading_8:`Configure & manage voice users`,
outline_detail_8:`• Enable users for Teams Phone using Calling Plans, & Teams Phone Mobile or Operator Connect if it's configured for your tenancy.
• Enable users for Direct Routing.
• Manage per-user parameters for Teams Phone.`,
outline_heading_9:`Configure, deploy, & manage Teams devices`,
outline_detail_9:`• Understand situations for using Teams phones, Teams displays, & Microsoft Teams Rooms with voice services.
• Differentiate between the numerous choices for deployment & management of devices.
• Deploy Teams phones & Teams displays.
• Configure Teams Phone for Microsoft Teams Rooms.
• Manage Teams devices in the Teams admin center & Teams Rooms Pro Management Portal.`,
outline_heading_10:`Monitor & troubleshoot Teams Phone`,
outline_detail_10:`• Diagnose & debug phone number assignment • Diagnose & troubleshoot Microsoft Teams client issues
• Diagnose & troubleshoot call failures & quality issues
• Report on & troubleshoot Teams calls with the Call Quality Dashboard (CQD) • Diagnose & troubleshoot Direct Routing difficulties
• Troubleshoot & monitor Teams devices`,
skill_1:`• Plan & configure Microsoft Teams Phone (25-30%)`,
skill_2:`• Optimize network performance (5-10%)`,
skill_3:`• Plan & configure Direct Routing (15-20%)`,
skill_4:`• Configure & administer Microsoft Teams Phones for voice users (15-20%)`,
skill_5:`• Plan & configure Microsoft Teams clients & devices (5-10%)`,
skill_6:`• Monitor & troubleshoot Microsoft Teams Phones (15-20%)`,
course_faq_name:`MS 720T00 Microsoft Teams Voice Engineer`,
faq_question_1:`Q: What is Microsoft team voice?`,
faq_answer_1:`A: Teams Voice gives modern calling options such as voicemail to email, call history, call blocking, call transferring, call forwarding, & much more. Teams Voice will allow NIU employees to take their 753 or 752 numbers with them wherever they are working. Microsoft 365`,
faq_question_2:`Q: What is MS 720?`,
faq_answer_2:`A: Professionals for this exam are Microsoft Teams voice engineers that plan, build, configure, maintain, & troubleshoot integrated communications solutions within a business.`,
faq_question_3:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_3:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs.`,
faq_question_4:`Q: What if I miss a lesson on a specific day?`,
faq_answer_4:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},



{
    id:116,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MS 101T00 Microsoft 365 Mobility Security`,
course_overview:`This training covers three central elements of Microsoft 365 enterprise administration – Microsoft 365 compliance management, Microsoft 365 security management, & Microsoft 365 device management. In Microsoft 365 security management, you'll examine all the common types of threat vectors & data breaches facing organizations today, & you'll learn how Microsoft 365's security solutions address these security threats. You'll be introduced to the Microsoft Secure Score & to Azure Active Directory Identity Protection. You'll then understand how to manage the Microsoft 365 security services, including Safe Attachments, Exchange Online Protection, & Safe Links. Finally, you'll be introduced to the various reports that monitor your security health. You'll then transition from security services to threat intelligence, specifically using Microsoft Defender for Cloud Apps, Microsoft 365 Defender, & Microsoft Defender for Endpoint. With your Microsoft 365 security components now firmly in place, you'll examine the key components of Microsoft 365 compliance management.`,
certification:`Professionals for this test are Microsoft 365 enterprise administrators who have expert-level skills in evaluating, migrating, planning, deploying, & managing Microsoft 365. They perform Microsoft 365 tenant-level planning, implementation, & administration of cloud & hybrid enterprise environments. Professionals for this exam have subject matter expertise in Microsoft 365 endpoints, security, & compliance. The enterprise administrator functions as the integrating hub for all Microsoft 365 workloads. This role coordinates across various Microsoft 365 workloads & advises the architects & workload administrators. Professionals for this exam have functional experience with all Microsoft 365 workloads & Microsoft Azure Active Directory (Azure AD), part of Microsoft Entra, & have administered at least one of these. They also have a working knowledge of server administration, networking, DNS, & PowerShell.`,
prerequisite:`Before attending this training, professionals must have the following: 
• Completed a role-based administrator training such as Messaging, Teamwork, Security & Compliance, or Collaboration. 
• A proficient understanding of DNS & basic functional experience with Microsoft 365 services. 
• A proficient understanding of general IT practices. Audience Profile This training is created for persons who aspire to the Microsoft 365 Enterprise Admin role & have accomplished one of the Microsoft 365 role-based administrator certification paths. `,
outline_heading_1:`Examine threat vectors & data breaches`,
outline_detail_1:`• Describing techniques hackers use to compromise user accounts through email 
• Describing techniques hackers use to gain control over resources
• Describe techniques hackers use to compromise data 
• Mitigate an account breach 
• Prevent an elevation of privilege attack 
• Prevent data exfiltration, data deletion, & data spillage`,
outline_heading_2:`Exploring the Zero Trust security model`,
outline_detail_2:`• Describing the Zero Trust approach to security in Microsoft 365 
• Describing the principles & components of the Zero Trust security model 
• Describing the five steps to implementing a Zero Trust security model in your organization 
• Explaining Microsoft's story & strategy around Zero Trust networking`,
outline_heading_3:`Explore security solutions in Microsoft 365 Defender`,
outline_detail_3:`• Identifying the features of Microsoft Defender for Office 365 that increase email security in a Microsoft 365 deployment 
• Explaining Microsoft Defender for Identity identifies, detects, & investigates advanced threats, compromised identities, & malicious insider actions directed at your organization. 
• Explaining Microsoft Defender for Endpoint helps enterprise networks prevent, detect, investigate, & respond to advanced threats. 
• Describing Microsoft 365 Threat Intelligence can be beneficial to your organization's security officers & administrators. 
• Describing Microsoft Cloud App Security enhances visibility & control over your Microsoft 365 tenant via three core areas.`,
outline_heading_4:`Examine Microsoft Secure Score`,
outline_detail_4:`• Describe the benefits of Secure Score & what kind of services can be analyzed • Describing how to collect data using the Secure Score API 
• Describing how to use the tool to identify gaps between your current state & where you would like to be regarding security 
• Identifying actions that will increase your security by mitigating risks 
• Explaining where to look to determine the threats each action will mitigate & the impact it has on users`,
outline_heading_5:`Examine Privileged Identity Management`,
outline_detail_5:`• Describing Privileged Identity Management enables you to manage, control, & monitor access to important resources in your organization. 
• Configuring Privileged Identity Management for use in your organization 
• Describing Privileged Identity Management audit history enables you to see all the user assignments & activations within a given time period for all privileged roles. 
• Explaining Microsoft Identity Manager helps organizations manage the users, credentials, policies, & access within their organizations & hybrid environments. 
• Explaining Privileged Access Management provides granular access control over privileged admin tasks in`,
outline_heading_6:`Microsoft 365 Examine Azure Identity Protection`,
outline_detail_6:` • Describe Azure Identity Protection (AIP) & what kind of identities can be protected • Enabling the three default protection policies in AIP 
• Identify the vulnerabilities & risk events detected by AIP 
• Planning your investigation in protecting cloud-based identities 
• Planning how to protect your Azure Active Directory environment from security breaches`,
outline_heading_7:`Examine Exchange Online Protection`,
outline_detail_7:`• Describing Exchange Online Protection analyzes email to provide anti-malware pipeline protection. 
• Listing several mechanisms used by Exchange Online Protection to filter spam & malware. • Describing other solutions administrators may implement to provide extra protection against phishing & spoofing 
• Understanding how EOP provides protection against outbound spam`,
outline_heading_8:`Examine Microsoft Defender for Office 365`,
outline_detail_8:`• Describing the Safe Attachments feature in Microsoft defender for Office 365 blocks zero-day malware in email attachments & documents. 
• Describing the Safe Links feature in Microsoft defender for Office 365 protects users from malicious URLs embedded in emails & documents that point to malicious websites. 
• Create outbound spam filtering policies. 
• Unblock users who violate spam filtering policies so they can resume sending emails.`,
outline_heading_9:`Manage Safe Attachments`,
outline_detail_9:`• Creating & modifying a Safe Attachments policy using Microsoft 365 Defender • Creating a Safe Attachments policy by using PowerShell 
• Configuring a Safe Attachments policy 
• Describing how a transport rule can disable a Safe Attachments policy 
• Describing the end-user experience when an email attachment is scanned & found to be malicious`,
outline_heading_10:`Manage Safe Links`,
outline_detail_10:`• Creating & modifying a Safe Links policy using Microsoft 365 Defender • Creating a Safe Links policy using PowerShell 
• Configuring a Safe Links policy 
• Describing how a transport rule can disable a Safe Links policy 
• Describing the end-user experience when Safe Links identifies a link to a malicious website embedded in an email, & a link to a malicious file hosted on a website`,
outline_heading_11:`Explore threat intelligence in Microsoft 365 Defender`,
outline_detail_11:`• Describing threat intelligence in Microsoft 365 is powered by the Microsoft Intelligent Security Graph. 
• Creating alerts that can identify malicious or suspicious events. 
• Understand how Microsoft 365 Defender's Automated investigation & response process works 
• Describing threat hunting enables security operators to identify cybersecurity threats. • Describing how Advanced hunting in Microsoft 365 Defender pro-actively inspects events in your network to locate threat indicators & entities.`,
outline_heading_12:`Implementing app protection by using Microsoft Defender for Cloud Apps`,
outline_detail_12:`• Describing Microsoft Defender for Cloud Apps provides improved visibility into network cloud activity & increases the protection of critical data across cloud applications. 
• Explaining how to deploy Microsoft Defender for Cloud Apps. • Controlling your cloud apps with file policies. 
• Managing & responding to alerts that were generated by those policies. • Configure & troubleshoot Cloud Discovery. Implementing endpoint protection by using Microsoft Defender for Endpoint 
• Describing how Microsoft Defender for Endpoint helps enterprise networks prevent, detect, investigate, & respond to advanced threats 
• Onboarding supported devices to Microsoft Defender for Endpoint. • Implementing the Threat & Vulnerability Management module to effectively identify, assess, & remediate endpoint weaknesses. 
• Configuring device discovery to help find unmanaged devices connected to your corporate network. 
• Lowering your organization's threat & vulnerability exposure by remediating issues based on prioritized security recommendations.`,
outline_heading_13:`Examine governance & compliance solutions in Microsoft Purview`,
outline_detail_13:`• Enable & disable an archive mailbox in the Microsoft Purview compliance portal & through Windows PowerShell. 
• Running diagnostic tests on an archive mailbox. 
• Learning retention labels can be used to allow or block actions when documents & emails are declared records. 
• Create your file plan for retention & deletion settings & actions 
• Determining when items must be marked as records by importing an existing plan (if you already have one) or creating new retention labels. 
• Restore deleted data in Exchange Online & SharePoint Online`,
outline_heading_14:`Explore archiving & records management in Microsoft 365`,
outline_detail_14:`• Enable & disable an archive mailbox in the Microsoft Purview compliance portal & through Windows PowerShell. 
• Running diagnostic tests on an archive mailbox. 
• Learning retention labels can be used to allow or block actions when documents & emails are declared records.
• Create your file plan for retention & deletion settings & actions 
• Determining when items must be marked as records by importing an existing plan (if you already have one) or creating new retention labels. 
• Restore deleted data in Exchange Online & SharePoint Online.`,
outline_heading_15:`Explore retention in Microsoft 365`,
outline_detail_15:`• Explain how retention policies & retention labels work. 
• Identify the capabilities of both retention policies & retention labels. 
• Selecting the appropriate scope for a policy depending on business requirements. 
• Explaining the principles of retention. 
• Identifying the differences between retention settings & eDiscovery holds. 
• Restrict retention changes by using a preservation lock. `,
outline_heading_16:`Explore Microsoft Purview Message Encryption`,
outline_detail_16:`• Describing the features of Microsoft Purview Message Encryption. 
• Explain how Microsoft Purview Message Encryption works & how to set it up.
• Define mail flow rules that apply branding & encryption templates to encrypt email messages. 
• Adding organizational branding to encrypted email messages. 
• Explaining the extra capabilities provided by Microsoft Purview Advanced Message Encryption.`,
outline_heading_17:`Explore compliance in Microsoft 365`,
outline_detail_17:`• Describing how Microsoft 365 helps organizations manage risks, protect data, & remain compliant with regulations & standards. 
• Planning your beginning compliance tasks in Microsoft Purview. 
• Managing your compliance requirements with Compliance Manager. 
• Managing compliance posture & improvement actions using the Compliance Manager dashboard. 
• Explaining an organization's compliance score is determined.`,
outline_heading_18:`Implement Microsoft Purview Insider Risk Management`,
outline_detail_18:`• Describing insider risk management functionality in Microsoft 365. 
• Developing a plan to implement the Microsoft Purview Insider Risk Management solution. 
• Creating insider risk management policies. 
• Managing insider risk management alerts & cases.`,
outline_heading_19:`Creating information barriers in Microsoft 365`,
outline_detail_19:`• Describing how information barriers can restrict or allow communication & collaboration among specific groups of users. 
• Describe the components of an information barrier & how to allow information barriers. 
• Understanding information barrier modes help strengthen who can be added or removed from a OneDrive account, 
• Microsoft Team & SharePoint site. 
• Describing information barriers prevent users or groups from communicating & collaborating in Microsoft Teams, OneDrive, & SharePoint.`,
outline_heading_20:`Exploring Data Loss Prevention in Microsoft 365`,
outline_detail_20:`• Describing how Data Loss Prevention (DLP) is managed in Microsoft 365 
• Understanding DLP in Microsoft 365 uses sensitive information types & search patterns 
• Describing how Microsoft Endpoint DLP extends the DLP activity monitoring & protection capabilities. 
• Describe what a DLP policy is & what it contains 
• View DLP policy results using both queries & reports`,
outline_heading_21:`Implement Data Loss Prevention policies`,
outline_detail_21:` • Creating a data loss prevention implementation plan. Implementing Microsoft 365's default DLP policy. 
• Creating a custom DLP policy from a DLP template & from scratch. 
• Create email notifications & policy tips for users when a DLP rule applies. 
• Creating policy tips for users when a DLP rule applies 
• Configuring email notifications for DLP policies`,
outline_heading_22:`Implement data classification of sensitive information`,
outline_detail_22:`• Explain the benefits & pain points of creating a data classification framework. 
• Identifying how data classification of sensitive items is handled in Microsoft 365. 
• Understanding how Microsoft 365 uses trainable classifiers to protect sensitive data. 
• Creating & then retraining custom trainable classifiers. 
• Analyzing the results of your data classification efforts in Content explorer & Activity explorer. 
• Implementing Document Fingerprinting to protect sensitive information being sent through Exchange Online.`,
outline_heading_23:`Explore sensitivity labels`,
outline_detail_23:`• Describe how sensitivity labels let you classify & protect an organization's data 
• Identifying the common reasons why organizations use sensitivity labels • Explaining what a sensitivity label is & what it can do for an organization 
• Configuring a sensitivity label's scope 
• Explaining why the order of sensitivity labels in your admin center is important 
• Describing what label policies can do`,
outline_heading_24:`Implement sensitivity labels`,
outline_detail_24:`• Describe the overall process of creating, configuring, & publish sensitivity labels 
• Identifying the administrative permissions that should be assigned to compliance teammates to implement sensitivity labels. 
• Developing a data classification framework that provides the foundation for your sensitivity labels • Creating & configuring sensitivity labels 
• Publishing sensitivity labels by creating a label policy 
• Identifying the differences between removing & deleting sensitivity labels`,
outline_heading_25:`Searching for content in the Microsoft Purview compliance portal`,
outline_detail_25:`• Describing how to use content search in the Microsoft Purview compliance portal. 
• Designing & create a content search. 
• Preview the search results. 
• View the search statistics. 
• Export the search results & search report. 
• Configure search permission filtering.`,
outline_heading_26:`Manage Microsoft Purview Audit (Standard)`,
outline_detail_26:`• Describe the differences between Audit (Standard) & Audit (Premium) 
• Identifying the core features of the Audit (Standard) solution. 
• Set up & implement audit log searching using the Audit (Standard) solution. 
• Export, configure, & view audit log records. 
• Use audit log searching for troubleshooting common support issues.`,
outline_heading_27:` Manage Microsoft Purview Audit (Premium)`,
outline_detail_27:`• Describe the differences between Audit (Standard) & Audit (Premium). 
• Set up & implement Microsoft Purview Audit (Premium). 
• Create audit log retention policies. 
• Perform forensic investigations of compromised user accounts.`,
outline_heading_28:`Manage Microsoft Purview eDiscovery (Standard)`,
outline_detail_28:`• Describing how Microsoft Purview eDiscovery (Standard) builds on the basic search & export functionality of Content search. 
• Describing the basic workflow of eDiscovery (Standard). 
• Creating an eDiscovery case. 
• Creating an eDiscovery hold for an eDiscovery case. 
• Search for content in a case & then export that content. 
• Close, reopen, & delete a case.`,
outline_heading_29:`Manage Microsoft Purview eDiscovery (Premium)`,
outline_detail_29:`• Describing how Microsoft Purview eDiscovery (Premium) builds on eDiscovery (Standard). 
• Describing the basic workflow of eDiscovery (Premium). 
• Create & manage cases in eDiscovery (Premium). 
• Manage custodians & non-custodial data sources. 
• Analyze case content & use analytical tools to reduce the size of search result sets.`,
outline_heading_30:`Explore device management using Microsoft Endpoint Manager `,
outline_detail_30:`• Describing the device management capabilities found in Microsoft Endpoint Manager. 
• Describing how Windows devices can be co-managed in Endpoint Manager using Configuration Manager & Intune. 
• Manage devices using Configuration Manager. 
• Manage devices using Microsoft Intune. 
• Create device profiles in Microsoft Intune.`,
outline_heading_31:` Prepare your Windows devices for Co-management`,
outline_detail_31:`• Describe the prerequisites for using Co-management 
• Configure Microsoft Endpoint Configuration Manager for Co-management 
• Enroll Windows 10 devices to Intune.`,
outline_heading_32:`Planning mobile application management in Microsoft Intune`,
outline_detail_32:`• Describing the basic functionality of mobile application management in Microsoft Intune. 
• Assessing your app requirements & add apps into Intune. 
• Protecting company data by using app protection policies.
• Implementing app configuration policies in Intune to eliminate app setup problems. 
• Troubleshooting app protection policy deployment in Intune. StartSave`,
outline_heading_33:`Examine Windows client deployment scenarios`,
outline_detail_33:`• Explaining the Windows as a Service model continually provides new capabilities & updates while maintaining a high level of hardware & software compatibility. 
• Explaining how the modern Windows 10/11 deployment model combines both traditional on-premises & cloud services to deliver a streamlined, cost-effective deployment experience. • Explaining the dynamic Windows 10/11 deployment model can change the existing version of Windows 10/11 that is included on a device to a customized version that is used in your company without reinstalling Windows. 
• Explaining how the traditional Windows 10/11 deployment model is image-based & uses an organization's on-premises infrastructure.`,
outline_heading_34:`Explore Windows Autopilot, deployment models`,
outline_detail_34:`• Describe the Windows Autopilot deployment requirements. 
• Creating & assigning a Windows Autopilot profile. 
• Explaining how the Autopilot self-deployment model deploys Windows 10 & 11 with little or no user interaction.
• Explaining the Autopilot pre-provisioned deployment model allows end-users to provision new devices by using the pre-installed OEM image & drivers. 
• Explaining the Autopilot user-driven deployment model enables new Windows 10 & 11 devices to be changed from their initial factory state without requiring the IT person to ever touch the device. 
• Deploying BitLocker encryption for Autopiloted devices.`,
outline_heading_35:`Plan your Windows client Subscription Activation strategy`,
outline_detail_35:`• Describing Windows 10/11 Enterprise E3 subscriptions can be purchased via the Cloud Service Provider channel 
• Configuring Virtual Desktop Access for automatic subscription activation on virtual machines. 
• Explaining Windows 10/11 Enterprise licenses can be deployed automatically & without device restart.`,
outline_heading_36: `Explore Mobile Device Management`,
outline_detail_36:` • Describing the two MDM authority solutions included in Microsoft 365 - Microsoft Intune & Basic Mobility & Security 
• Compare the basic features in Microsoft Intune & Basic Mobility & Security 
• Describing the policy settings for mobile devices in Microsoft Intune & Basic Mobility & Security 
• Explain how email & document access are controlled on devices managed by MDM`,
outline_heading_37:`Deploy Mobile Device Management`,
outline_detail_37:` • Activate & deploy Mobile Device Management services in Microsoft 365 • Configuring domains for MDM by adding DNS records to be used by clients to Autodiscover when enrolling devices 
• Obtaining an APNS certificate to enroll & manage iOS devices 
• Managing device security policies that can control password settings, encryption settings, & settings that control the use of device features 
• Defining a corporate device enrollment policy that can limit enrollment & enable multi-factor authentication`,
outline_heading_38:`Enroll devices in Mobile Device Management`,
outline_detail_38:`• Enrolling devices to mobile device management in Microsoft Intune. 
• Exploring the use of Azure AD joined & hybrid Azure AD joined devices. 
• Explaining how users can enroll their personal devices. 
• Describing best practices & capabilities for each device enrollment method. 
• Set up enrollment for Windows devices.`,
outline_heading_39:`Managing device compliance`,
outline_detail_39:`• Planning for device compliance by defining the rules & settings that should be configured on a device for it to be considered compliant. 
• Configuring conditional users & groups for deploying profiles, policies, & apps 
• Creating Conditional Access policies to implement automated access control decisions for accessing your cloud apps 
• Monitoring enrolled devices to control their Intune activities & compliance status.`,
outline_heading_40:`Implementing endpoint security in Microsoft Intune`,
outline_detail_40:`• Describing how Microsoft Intune enables organizations to protect their data & devices. 
• Understanding how endpoint security in Microsoft Intune focuses on device security & risk mitigation. 
• Managing devices with endpoint security in Intune. 
• Using security baselines to configure Windows devices in Intune. 
• Implementing attack surface reduction rules to reduce an organization's attack surface.`,
skill_1:` • Plan & implement device services (35-40%)`,
skill_2:`• Manage security & threats by using Microsoft 365 Defender (25-30%)`,
skill_3:` • Manage Microsoft 365 compliance (30-35%)`,
skill_4:` •Cloud Security Management`,
skill_5:`• Device Compliance Monitoring`,
skill_6:`• Threat Detection and Response`,
course_faq_name:`MS 101T00 Microsoft 365 Mobility Security`,
faq_question_1:` Q: What is Microsoft 365 certified?`,
faq_answer_1:`A: Administrators of Microsoft Teams basically configure, deploy as well as manage Office 365 workloads for multiple Microsoft Teams, which mainly focus on efficient & effective collaboration & also on communication in an enterprise atmosphere`,
faq_question_2:`Q: What is Microsoft Enterprise Mobility?`,
faq_answer_2:` A: Microsoft Enterprise Mobility + Security (EMS) is an intelligent mobility management & security platform. It mainly helps to protect & secure use company & empowers its employees to work in new & flexible ways.`,
faq_question_3:`Q: What is the difference between MDM & EMM?`,
faq_answer_3:` A: MDM manages all the features of the device, while EMM manages the entire device. EMM mainly provides app customization, policy compliance, data & document security & incorporates various network directory services.`,
faq_question_4:`Q: What is included in Microsoft 365 e3?`,
faq_answer_4:`A: Following are included in Microsoft 365 e3:- 
• Microsoft 365 E3 includes 
• Word 
• Excel 
• PowerPoint 
• Outlook 
• OneNote 
• SharePoint 
• OneDrive 
• Microsoft Teams`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training session, you should have operational Desktops or Laptops with the required specification, along with a good internet connection to access the labs.`,
},

{
    id:117,
categories: ``,
course_detail_image:course_images.microsoft_365,
course_name:`MS-100T00: Microsoft 365 Identity & Services`,
course_overview:`This training covers three fundamental parts of Microsoft 365 enterprise administration - Microsoft 365 tenant & service management, Microsoft 365 management, & Microsoft 365 identity management. In Microsoft 365 tenancy & service management, you'll cover all the key components that must be planned for when designing your Microsoft 365 tenant. Once this planning step is complete, you'll understand and configure your Microsoft 365 tenant, including your component services, organizational profile, tenant subscription options, user accounts & licenses, & security groups. Finally, you'll learn how to manage your tenant, which covers the setting of tenant responsibilities & controlling your tenant's health & services. With your Microsoft 365 tenancy firmly in place, you will study the important components of Microsoft 365 management. It begins with an overview of Microsoft 365 product capability, including SharePoint Online, Exchange Online, Microsoft Teams, Microsoft Power Platform, & device management. You'll then shift to installing Microsoft 365, primarily focused on enabling Office client connectivity.`,
certification:`Professionals for this exam have expert-level abilities in analyzing, planning, moving, deploying, & administering Microsoft 365. They perform Microsoft 365 tenant-level planning, implementation, & administration of Cloud & hybrid enterprise settings. Professionals for this exam have subject matter experience in Microsoft 365 applications, infrastructure, & identity.
The enterprise administrator works as an integrating hub for all Microsoft 365 tasks. This role coordinates across numerous Microsoft 365 workloads & advises the architects & workload admins.
Professionals for this exam have functional familiarity with all Microsoft 365 workloads & Microsoft Azure Active Directory (Azure AD), part of Microsoft Entra, & have administered at least one of these. They also have a working knowledge of server administration, networking, DNS, & PowerShell.`,
prerequisite:`Before attending this training, professionals must have the following:
• Completed a role-based administrator training such as Messaging, Teamwork, Security & Compliance, or Collaboration.
• A solid understanding of DNS & fundamental functional familiarity with Microsoft 365 services.
• A solid understanding of general IT practices.

Audience Profile
This program is developed for those who aspire to the Microsoft 365 Enterprise Admin job & have finished one of the Microsoft 365 role-based administrator certification paths.`,
outline_heading_1:`Plan your tenancy in Microsoft 365`,
outline_detail_1:`• Determining which Microsoft 365 subscription offerings best meet your organization's requirements.
• Identifying which Microsoft 365 component services fulfill your company needs.
• Identifying the steps to successfully create a tenant for Microsoft 365.`,
outline_heading_2:`Plan on-premises infrastructure for Microsoft 365`,
outline_detail_2:`• Preparing your organization to transfer its on-premises implementation to Microsoft 365.
• Estimating your network's bandwidth.
• Describing best practices to employ when integrating your network into Microsoft 365.
• Identifying the deployment techniques your firm can employ when integrating Microsoft 365 services.
• Preparing your Active Directory forest for directory synchronization.`,
outline_heading_3:`Plan your identity & authentication solution for Microsoft 365`,
outline_detail_3:`• Describing authentication behavior for Office client apps when they connect to Exchange Online, SharePoint Online, & Skype for Business Online.
• Explain how multifactor authentication is supported in a Microsoft 365 deployment.
• Create a plan for directory synchronization & Azure AD Connect Pass-through authentication.
• Exploring how password hash synchronization facilitates cloud authentication.
• Describe the difficulties, benefits, & best practices of establishing federated authentication using Active Directory Federation Services.
• Plan for Azure AD single sign-on.`,
outline_heading_4:`Plan supporting infrastructure for Microsoft 365`,
outline_detail_4:`• Determining the optimal email migration or coexistence strategy for your firm.
• Creating a file storage & collaboration approach that is resilient & reliable.
• Preparing your organization to achieve the greatest experience using Microsoft Teams.`,
outline_heading_5:`Plan hybrid needs for Microsoft 365 deployment`,
outline_detail_5:`• Identifying essential issues when planning a hybrid Exchange setup.
• Understanding planning your choices will effect your hybrid deployment.
• Describing the primary planning options for establishing a hybrid SharePoint setup.
• Identifying the major features of a hybrid deployment.
• Identifying the infrastructure needs for putting up a hybrid Skype for a Business environment.`,
outline_heading_6:`Plan your transfer to Microsoft 365`,
outline_detail_6:`• Planning your organization's migration strategy to Microsoft 365.
• Analyzing your organization's Active Directory & plan any necessary clean-up utilizing the ID Fix tool.
• Determining which migration approach to utilize to move your organization's mail, calendar, & contact information to the Cloud.
• Describe the performance & network challenges to consider while preparing your organization's migration strategy.`,
outline_heading_7:`Configure Microsoft 365 experience`,
outline_detail_7:`• Configuring your company's organization profile, which is crucial for setting up your company's tenant.
• Maintain minimum subscription requirements for your firm.
• Manage your services & add-ins by assigning more licenses, purchasing more storage, & so on.
• Creating a checklist that allows you to confirm your Microsoft 365 tenant that satisfies your business needs.`,
outline_heading_8:`Manage user accounts & licenses in Microsoft 365`,
outline_detail_8:`• Identify which user identity model is most appropriate for your organization.
• Creating user accounts from both the Microsoft 365 admin center & Windows PowerShell.
• Manage user accounts & licenses in Microsoft 365.
• Recovering deleted user accounts in Microsoft 365.
• Performing mass user maintenance in Azure Active Directory`,
outline_heading_9:`Manage groups in Microsoft 365`,
outline_detail_9:`• Describing the many sorts of groups accessible in Microsoft 365.
• Creating & managing groups using the Microsoft 365 admin center & Windows PowerShell.
• Create & administer groups in Exchange Online & SharePoint Online.`,
outline_heading_10:`Add a custom domain in Microsoft 365`,
outline_detail_10:`• Identifying the elements that must be considered when adding a custom domain to Microsoft 365.
• Planning the DNS zones utilized in a bespoke domain.
• Planning the DNS record requirements for a custom domain.
• Adding a custom domain to your Microsoft 365 implementation.`,
outline_heading_11:`Configure client connectivity to Microsoft 365`,
outline_detail_11:`• Describing how Outlook utilizes Autodiscover to connect an Outlook client to Exchange Online.
• Identifying the DNS records needed for Outlook & other Office-related clients to automatically locate the services in Microsoft 365 via the Autodiscover procedure.
• Describing the connectivity protocols that enable Outlook to connect to Microsoft 365.
• Identifying the tools that can help you troubleshoot connectivity difficulties in Microsoft 365 implementations.`,
outline_heading_12:`Configure administrative roles in Microsoft 365`,
outline_detail_12:`• Describing the Azure RBAC authorization model used in Microsoft 365.
• Describing the most prevalent Microsoft 365 admin positions.
• Identifying the basic duties done by users, according to the common Microsoft 365 admin roles
• Delegating admin roles to partners.
• Managing permissions with administrative units in Azure Active Directory.
• Elevating privileges to access admin centers by using Azure AD Privileged Identity Management.`,
outline_heading_13:`Manage tenant health & services in Microsoft 365`,
outline_detail_13:`• Monitoring your organization's Microsoft 365 service health in the Microsoft 365 admin center.
• Developing an incident response plan to cope with incidents that may arise with Microsoft 365 service.
• Requesting assistance from Microsoft to address technical, pre-sales, billing, & subscription support difficulties.`,
outline_heading_14:`Deploy Microsoft 365 Apps for enterprise`,
outline_detail_14:`• Describing the Microsoft 365 Apps for enterprise capability.
• Configure the Readiness Toolkit.
• Planning a deployment strategy for Microsoft 365 Apps for the enterprise.
• Completing a user-driven installation of Microsoft 365 Apps for the organization.
• Identifying the processes for managing centralized installations of Microsoft 365 Apps for the company.
• Deploying Microsoft 365 Apps for enterprise with the Office Deployment Toolkit.
• Deploying Microsoft 365 Apps for an enterprise utilizing group policies.
• Describing how to handle Microsoft 365 Apps for enterprise updates.
• Determining which update channel & application method applies to your organization.`,
outline_heading_15:`Manage secure user access in Microsoft 365`,
outline_detail_15:`• Managing user passwords
• Describing pass-through authentication
• Enabling multifactor authentication
• Describing self-service password management
• Implementing Azure AD Smart Lockout
• Implementing entitlement packages in Azure AD Identity Governance
• Implementing conditional access policies
• Create & perform an access review`,
outline_heading_16:`Analyzing your Microsoft 365 workplace data using Microsoft Viva Insights`,
outline_detail_16:`• Identifying how Microsoft Viva Insights might help enhance collaborative practices in your workplace.
• Discovering the sources of data used in Microsoft Viva Insights.
• Explaining the high-level information offered through Microsoft Viva information.
• Creating custom analysis with Microsoft Viva Insights.
• Summarizing tasks & concerns for setting up Microsoft Viva Insights & maintaining privacy.`,
outline_heading_17:`Exploring Exchange Online as a Microsoft 365 Enterprise Administrator`,
outline_detail_17:`• Describing how mail flows across a Microsoft 365 deployment
• Describing the most popular recipient categories that are accessible in Exchange Online
• Managing anti-malware & anti-spam rules in Exchange Online
• Describe migration & cohabitation methods & comprehend the distinctions between them
• Selecting the proper mail migration plan for your organization
• Determining when you want to switch the DNS MX record for a domain in the Microsoft 365 migration to point to Exchange Online.
• Describing the many techniques to transfer mailboxes to Microsoft 365 in a hybrid Exchange setting`,
outline_heading_18:`Exploring SharePoint Online as a Microsoft 365 Enterprise Administrator`,
outline_detail_18:`• Determining the box permission levels that your organization must employ in SharePoint Online when developing sites & segregating & restricting access to content that is regarded confidential or proprietary
• Planning a migration strategy to transfer file shares to SharePoint Online & OneDrive
• Identifying the choices accessible in SharePoint Online to retain & retrieve content that has either been lost or misplaced in your organization's intranet.
• Describing the numerous choices that enable anti-malware security in SharePoint Online
• Planning a hybrid SharePoint Online deployment`,
outline_heading_19:`Exploring Microsoft Teams as a Microsoft 365 Enterprise Administrator`,
outline_detail_19:`• Describing basic Teams functionality & the infrastructure that supports its goals
• Describing how Teams compares to the other collaboration tools in Microsoft 365
• Providing Teams access by managing user licenses in the Microsoft 365 admin center & PowerShell
• Describing the capabilities given by Guess access in Microsoft Teams
• Describing the audio conferencing capabilities that is available in Microsoft Teams
• Assign an audio conferencing license & a conference ID to each user to support audio conferencing
• Managing user settings for audio conferencing
• Selecting a voice solution utilizing Microsoft Teams Phone
• Managing your organizational settings in Microsoft Teams`,
outline_heading_21:`Exploring Microsoft Power Platform as a Microsoft 365 Enterprise Administrator`,
outline_detail_21:`• Describing what the Microsoft Power Platform is
• Identifying the components that make up the Microsoft Power Platform product family
• Implementing the Microsoft Power Platform Center of Excellence starting kit
• Describing the main features of the Microsoft Power Platform admin center
• Describing the Microsoft Power Platform security model`,
outline_heading_22:`Creating apps as a Microsoft 365 Enterprise Administrator using Power Apps`,
outline_detail_22:`• Describing Power Apps capability, including its business impact & major components
• Managing the Power Apps environment in the Power Apps admin center & with Windows PowerShell
• Describing how Power Apps links to data sources
• Creating, testing, & monitoring a canvas Power App
• Run canvas & mobile Power Apps`,
outline_heading_23:`Creating flows as a Microsoft 365 Enterprise Administrator with Power Automate`,
outline_detail_23:`• Describing what Power Automate is & how it is utilized by companies
• Describing the sorts of flows, you may design & the basic structure of flows
• Administer Power Automate licensing & environments
• Describe how flows connect to data sources
• Build a basic flow with Power Automate
• Run a flow in Power Automate, Teams, & SharePoint`,
outline_heading_24:`Create reports & dashboards as a Microsoft 365 Enterprise Administrator with Power BI`,
outline_detail_24:`• Describe what Power BI is & how it'sit's utilized by companies
• Describing how Power BI interacts to data sources
• Build & share a basic Power BI report & dashboard
• Administer Power BI permissions, license, & row level security`,
outline_heading_25:`Preparing for identity synchronization to Microsoft 365`,
outline_detail_25:`• Identifying the actions necessary to configure your Azure Active Directory infrastructure.
• Planning directory synchronization to synchronize your on-premises Active Directory items to Azure AD.
• Identifying the benefits of Azure AD Connect sync & Azure AD Connect Cloud Sync
• Choosing which directory synchronization best meets your environment & company needs.`,
outline_heading_26:`Implement directory synchronization tools`,
outline_detail_26:`• Configure Azure AD Connect requirements
• Set up Azure AD Connect & Azure AD Connect Cloud Sync.
• Monitoring synchronization services with Azure AD Connect Health`,
outline_heading_27:`Manage synchronized identities`,
outline_detail_27:`• Ensuring your users synchronize efficiently
• Deploying Azure AD Connect
• Manage groups using directory synchronization
• Using Azure AD Connect Sync Security Groups to assist preserve directory synchronization
• Configuring object filters for directory synchronization
• Troubleshoot directory synchronization using various troubleshooting tasks & tools`,
outline_heading_28:`Implement applications in Azure AD`,
outline_detail_28:`• Registering an application or service within your Azure AD tenant
• Updating an application within the Azure AD consent framework
• Modifying the settings of a single-tenant application to make it a multi-tenant application
• Managing OAuth application requests
• Monitoring app governance for OAuth-enabled apps`,
outline_heading_29:`Configure Azure AD Application Proxy`,
outline_detail_29:`• Describing the advantages of Azure AD Application Proxy, the type of applications that function with it, & how it works
• Identify Azure AD Application Proxy prerequisites
• Install & register a connector & ensure that it is installed appropriately
• Publish an on-premises app for remote access & evaluating the published app to ensure its functionality right`,
outline_heading_30:`Explore solutions for external access`,
outline_detail_30:`• Identifying the features of external access using Azure Active Directory B2B collaboration.
• Explaining the difference between Microsoft 365 external access & Azure Active Directory B2B collaboration.
• Explain the attributes of a collaborative user.
• Demonstrate Azure Active Directory B2B collaboration.
• Identify the distinctions between external access & guest access using Microsoft Teams.
• Describe usage scenarios for Customer Lockbox request`,
skill_1:`Deploy & maintain a Microsoft 365 tenancy (15-20%)`,
skill_2:`Plan & manage user identity & roles (30-35%)`,
skill_3:`Manage access & authentication (20-25%)`,
skill_4:`Plan Microsoft 365 workloads & applications (20-25%)`,
skill_5:`Multi-factor Authentication (MFA) Implementation`,
skill_6:`Directory Synchronization Techniques`,
course_faq_name:`FAQs`,
faq_question_1:`Q: What is Microsoft Identity?`,
faq_answer_1:`A: Microsoft identity platform is an advancement of the Azure Active Directory- Azure AD developer platform. It generally allows developers to build applications which is sign in users and get tokens to use APIs like Microsoft Graph or APIs that developers have built with the unified Microsoft identity platform.`,
faq_question_2:`Q: What is the difference between Office 365 & Microsoft Azure?`,
faq_answer_2:`A: Microsoft Azure is Infrastructure in the Cloud. It is only a processor, disk & RAM & users are still needed to upload & patch the program. Microsoft Office 365 is a SaaS- Software as a Service that is mostly managed & also periodically refreshed by Microsoft.`,
faq_question_3:`Q: What is the difference between a Microsoft account & an Office 365 account?`,
faq_answer_3:`A: The Microsoft account may be used to log into a Windows 8 computer & you can access the online file storage using Skydrive, & a lot of other services. The Office 365 account is essentially used for logging into the user email (either in Outlook or in webmail) & also numerous other Office 365 services.`,
faq_question_4:`Q: What is the purpose of Office 365?`,
faq_answer_4:`A: Microsoft 365 is the productivity cloud designed to help you pursue your passion & operate your business. More than numerous apps like Word, Power, Point Excel, as well Microsoft 365 brings together best-in-class productivity tools with device management, powerful cloud services, as well as enhanced security in one connected experience.`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training program, you should have operable Desktops or Laptops with the required specification, coupled with a good internet connection to access the labs. `,
},

{
    id:118,

categories: ``,
course_detail_image:course_images.microsoft_dynamic_365,
course_name:`MB-210T01 Microsoft Dynamics 365 Sales`,
course_overview:`Microsoft Dynamics 365 Sales is a complete solution for managing customer and potential customer interactions. 
Dynamics 365 Sales allows businesses to track data against sales targets, automate best practices, learn from data, and much more. 
Join our globally known specialists as they walk you from lead to opportunity to closed contract. 
You will discover how to empower sales people to be their most productive selves by utilizing the application's available automation and customization capabilities. 

Duration: 2 Days 
`,
certification:`Participants for this test are Microsoft Dynamics 365 functional consultants with sales experience. They are in charge of implementing solutions that fulfill an organization's business requirements, no matter how big or little. 
Candidates must also identify possible process efficiencies, & improvements that can be realized by employing Dynamics 365 Sales & more extended Microsoft Power Platform features.
This could involve supporting a sales lifecycle in working effectively, & assisting the sales team in achieving revenue targets, implementing business plans, & fulfill an organization's objectives. 
Candidates are in charge of establishing and expanding the core sales functionality in order to provide scalable customer solutions that assist, automate, and speed the company's sales process. 
Where connectors, business process flows, visualizations, and bespoke applications are necessary, the applicant will utilize their Microsoft Power Platform experience & finish the solution architecture using common Dynamics 365 customer interaction capabilities. 
Professionals should have good business understanding & first-person industry experience. 
Students should grasp the sales process of the customer as well as the basic capabilities of Dynamics 365 Sales to discover where the two align & diverge. 
Professionals should also be proficient in the Microsoft Power Platform & model-driven app configuration & know about Microsoft 365 services.`,
prerequisite:`Familiarity with business apps & the desire to adapt & apply them for your firm. 

Audience Profile  

A Dynamics 365 Functional Consultant is in responsible of completing gathering requirements, discovering, engaging subject matter experts & stakeholders, translating requirements, & customizing the solution & apps. 
The Functional Consultant installs a solution using out-of-the-box features, codeless extensibility, and application & service integrations.`,
outline_heading_1:`Set up & setup Dynamics 365 Sales`,
outline_detail_1:`● Learn about the core features of Dynamics 365 Sales & how it may help your firm. 
● Configure important parameters that businesses should customize when setting up the application. 
● Set up the included security roles for sales users. Manage leads with Dynamics 365 Sales `,
outline_heading_2:`2.Examine the lead qualification process in Dynamics 365 from beginning to end`,
outline_detail_2:`● Identify the many choices that are available for creating & identifying leads 
● Use business process flows to manage the lead lifecycle 
● Qualify & disqualify leads Manage opportunities with Dynamics 365 Sales 
• Identify instances where alternatives can be employed 
● Create & describe opportunities 
● Work with relevant opportunity data`,
outline_heading_3:`Manage an opportunity throughout its lifecycle Leverage Embedded Intelligence in Dynamics 365 Sales `,
outline_detail_3:`● Deploy & set up sophisticated relationship cards 
● Use the Auto Capture tool to identify possibly relevant communications`,
outline_heading_4:`Set up & consume email tracking Manage & organize your product catalog with Dynamics 365 Sales `,
outline_detail_4:`● Set up unit groups to help control how products are sold 
● Create product family hierarchies that include product bundles & individual products 
● Set up product pricing data by creating price lists & price list items`,
outline_heading_5:`Use discount lists to provide volume discount pricing Process sales orders with Dynamics 365 Sales `,
outline_detail_5:`● Define quote records for opportunity records 
● Work a quote through its whole lifespan 
● Add & update quotation product line items`,
outline_heading_6:`Deliver quotes to customers Manage relationships with relationship selling in Dynamics 365 Sales`,
outline_detail_6:`● Discover best practices 
● Work with sales playbooks
● Manage relationship health with Sales Insights 
`,
outline_heading_7:`Recognize aspects of Sales Navigator Analyze Dynamics 365 sales data `,
outline_detail_7:`● Review analytical alternatives 
● Describe searching tools
● Utilize Power BI to view data
● Analyze data.`,
outline_heading_8:`Define & track individual goals in Dynamics 365 Sales & Customer Service`,
outline_detail_8:`● Create & maintain personal objectives
● Use Parent & Child objectives`,
outline_heading_9:`Monitor objective progress Use goal metrics in Dynamics 365 Sales & Customer Service`,
outline_detail_9:`● Determine which metrics are needed to develop efficient customer service goals
● Modify Fiscal Year settings to align goal progress
● Define Goal Metrics for an Organization
● Create custom Roll-up columns & Queries`,
skill_1:`Opportunity Tracking`,
skill_2:`Customer Relationship Management (CRM)`,
skill_3:`Sales Pipeline Management`,
skill_4:`Configure Dynamics 365 Sales (35–40%)`,
skill_5:`Manage core sales tables (40–45%) `,
skill_6:`Configure extra tools & services (10–15%) `,
course_faq_name:`FAQs`,
faq_question_1:`Q : Is Microsoft Dynamics hard to learn?`,
faq_answer_1:`A: Microsoft Dynamic is one of the leading CRM software, which is normally competing with the other available software in the industry, namely Salesforce, SAP, Oracle, etc. It's easy to learn, & application nature separates it from its peers.`,
faq_question_2:`Q: What is the difference between Dynamics AX & Dynamics 365?`,
faq_answer_2:`Q: What is the difference between Dynamics AX & Dynamics 365?
A: While Dynamics 365 & Dynamics AX are very comparable, Dynamics 365 is a web- & cloud-based system, whereas AX is mostly a desktop-based application with some web & mobile add-ons.`,
faq_question_3:`Q: What are the new features in Dynamics 365?`,
faq_answer_3:`A: New Features of Microsoft Dynamics 365 are: App Modules. Site Map Designer. Relationship Insights Relevance Search Mobile Capabilities Dynamics 365 for Outlook Visual Process Designer Editable Grids.`,
faq_question_4:`Q: How do I install Microsoft Dynamics? `,
faq_answer_4:`A: choose Start, choose Administrative Tools, & then click Computer Management. Expand System Tools commonly used to expand Local Users & Groups, & then expand Groups. Right-click Administrators, & then click Properties. 
To add the user account of the user who is installing Microsoft Dynamics CRM user has to click Add, & after that, click OK.`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
},

{
    id:119,
categories: ``,
course_detail_image:course_images.microsoft_dynamic_365,
course_name:`MB-310T00: Microsoft Dynamics 365 Finance`,
course_overview:`This seminar covers the financial elements of Dynamics 365. - setup &employ essential financial components, accounts payable, accounts receivable, collections, budgeting, fixed assets, &extra capabilities.
Duration: 4 Days`,
certification:`Candidates for this exam assess business requirements & translate those requirements into fully realized business processes & solutions that adopt industry-recommended practices. They have a basic comprehension of accounting fundamentals. &a greater grasp of financial operations as they relate to core finance, manufacturing, retail, and supply chain management.
Candidates for this exam typically focus on one or more Dynamics 365 Finance feature sets, such as core finance, general ledger, tax, cost accounting, cost management, fixed assets, budgeting, revenue recognition, and consolidation. & elimination, credit & collections, cash & bank management, & expense management. They grasp the links between Finance & production, retail, & supply chain management.`,
prerequisite:`Before attending this course, students must have the following:

● Basic accounting skills ● Core abilities in Dynamics 365 Core Audience Profile (heading 3)
A Functional Consultant for Dynamics 365 for Finance and Operations is in responsible of doing discovery, obtaining requirements, and enlisting subject matter experts. & stakeholders, interpreting requirements, &configuring the solution &applications. The Functional Consultant creates a solution using out-of-the-box features, codeless flexibility, and application &service linkages.`,
outline_heading_1:`Configure the chart of accounts in Dynamics 365 Finance.`,
outline_detail_1:`● Find the essential components for a chart of accounts ● Define &setup the chart of accounts
● Define &configure the financial dimensions &dimension sets
● Configure the chart of accounts, the major accounts, the categories, the templates, the ledger account alias, and the balance control. ● Configure account structures &advanced rules`,
outline_heading_2:`Examine the general ledger in Microsoft Dynamics 365 Finance.`,
outline_detail_2:`● Explain charts of accounts, including key accounts &categorization.
● Explain financial dimensions &dimension set concepts.
● Explain periodic financial processes.
● Configure ledger allocations &accruals in Dynamics 365 Finance
● Configure &use accruals. 
● Configure &use allocations.`,
outline_heading_3:`Make fiscal calendars, years, &periods in Dynamics 365 Finance`,
outline_detail_3:`● Differentiate between ledger &fiscal calendars.
● Configure &set up fiscal calendars, years, &periods.
● Make &utilize date intervals.
● Perform routine general ledger operations in Dynamics 365 Finance.
● Journal controls.
● General ledger processes
● Balance control accounts.
● Voucher templates.
● Ledger settlements.`,
outline_heading_4:`Configure ledgers &journals in Dynamics 365 Finance`,
outline_detail_4:`● Different sorts of journals &their setup.
● How to configure ledger &posting setup.
● How to configure &utilize journals.
● How to reverse journal posting.
● How to set up batch transfer rules.`,
outline_heading_5:`Intercompany accounting in Dynamics 365 Finance`,
outline_detail_5:`● Establish intercompany accounting. 
● Intercompany accounting is used to post journals between two different legal companies.`,
outline_heading_6:`Configure currencies in Dynamics 365 Finance`,
outline_detail_6:`● Different forms of currencies in Finance.
● The notion of triangulation money.
● How to set up legal entity currencies &conversions.
● How to configure exchange rate providers.
● How to import exchange rates from providers.`,
outline_heading_7:`Configure cash & bank management in Dynamics 365 Finance`,
outline_detail_7:`● Make &configure banks.
● Make &modify bank layouts.
● Configure cash &bank management parameters.
● Set up vendor &customer banks.
● Configure cash flow estimates &reports.`,
outline_heading_8:`Configure accounts receivable in Dynamics 365 Finance`,
outline_detail_8:`● Configure Accounts receivable components.
● Make &maintain a customer.
● Configure customer payments.
● Set up payment calendars &calendar rules.
● Set up customer posting profiles.
● Configure accounts receivable charges.`,
outline_heading_9:`Configure electronic reporting in Dynamics 365 Finance`,
outline_detail_9:`● Configure electronic reporting
● Use barcode data sources to generate barcode images
● Add a new electronic reporting provider & identify it as active
● Create a specified format for electronic document
● Make electronic reporting configurations`,
outline_heading_10:`Configure &execute recurring procedures in Dynamics 365 Finance`,
outline_detail_10:`● Configure financial period close.
● Reconcile banks.
● Perform periodic closing &year closing processes.
● Prepare periodic filings.
● Configure &operate with vendor 1099 processes.
● Run foreign currency revaluation processes.
● Perform financial consolidation &elimination processes.
● Process end-of-year tax reporting.`,
outline_heading_11:`Configure taxes in Dynamics 365 Finance`,
outline_detail_11:`● Learn about regional tax reporting capabilities.
● Configure tax components.
● Begin using cost accounting in Dynamics 365 Finance.      
● Cost objects.
● The Cost control workspace.
● How to produce a cost accounting report using the Cost accounting Get started wizard.
● How to set up cost accounting.`,
outline_heading_12:`Configure &work with inventory management in Dynamics 365 Supply Chain Management`,
outline_detail_12:`● Understand incoming &outbound inventory processes.
● Configure inventory parameters.
● Configure tracking number groupings.
● Configure inventory breakdown.
● Configure inventory &warehouse journals.
● Configure inventory dimensions.
● Configure inventory costs.
● Understand inventory closing &adjustments.
● Archive inventory transactions.
● Use movement journals.
● Use inventory adjustment journals.
● Use transfer journals.
● Use counting &tag counting notebooks.
● Use item arrival journals.
● Work with reason codes for inventory counts.`,
outline_heading_13:`Get familiar with the costing sheet in Dynamics 365 Supply Chain Management.`,
outline_detail_13:`● Set up the costing sheet.
● Perform BOM calculation & assess expenses by using the costing sheet.`,
outline_heading_14:`Configure Accounts payable in Dynamics 365 Finance`,
outline_detail_14:`● Configure accounts payable components.
● Make & maintain a vendor.
● Configure vendor payments.
● Set up vendor posting profiles.
● Configure accounts payable charges.`,
outline_heading_15:`Accounts payable daily procedures are carried out in Dynamics 365 Finance.`,
outline_detail_15:`● Record invoices & process payments.
● Manage & apply prepayments.`,
outline_heading_16:`Work with accounting distributions, invoice validations & settlements in Dynamics 365 Finance`,
outline_detail_16:`● Settle transactions & undo settlements.
● Distribute funds by employing accounting distributions.
● Configure invoice validation policies.`,
outline_heading_17:`Configure & manage vendor collaboration in Dynamics 365 Supply Chain Management`,
outline_detail_17:`● Set up vendor collaborations.    
● Maintain vendor certifications.
● Work with the Vendor collaborative workspace.
● Utilize a procure-to-pay procedure that incorporates vendor collaboration.`,
outline_heading_18:`Configure & manage the order-to-cash process in Dynamics 365 Supply Chain Management`,
outline_detail_18:`● Perform the order-to-cash process.
● Configure & use commissions.
● Configure customer &item search.
● Make & confirm sales quotations.
● Make & confirm sales orders.
● Print & post-picking lists.
● Generate & post a sales order invoice.
● Configure order hold codes.
● Process customer returns.
● Work with sales order reservations.`,
outline_heading_19:`Perform daily accounts receivable procedures in Dynamics 365 Finance.`,
outline_detail_19:`● Make free text invoices & record customer payments.
● Distribute funds by employing accounting distributions.
● Configure recurring invoices.
● Use reversals in Accounts receivable.
● Understand revenue recognition.`,
outline_heading_20:`Configure & process post-dated checks in Dynamics 365 Finance`,
outline_detail_20:`● Process post-dated checks.
● Set up post-dated checks.
● Register & post a post-dated check for a customer.
● Settle a post-dated cheque for a customer.
● Register & post a post-dated check for a vendor.
● Settle a post-dated cheque for a vendor`,
outline_heading_21:`Configure credit & collections in Dynamics 365 Finance`,
outline_detail_21:`● Configure credit & collections components.
● Configure aging reports, customer pools, collection agents, & aging period definitions.
● Set up & manage interests.
● Set up & manage collection letters.`,
outline_heading_22:`Process credit & collections in Dynamics 365 Finance`,
outline_detail_22:`● How to use customer account statements.
● How to process collections.
● How to use the credit management feature.
● Get started with Dynamics 365 Project Operations expense management.    
● Discover Expense management features.
● Learn about Expense management skills.
● Examine the implications for using the Expense management module in Project Operations.`,
outline_heading_23:`Make & process expense reports in Dynamics 365 Project Operations`,
outline_detail_23:`● Review expense report policies & per diem restrictions.
● Make & keep an expense report.
● Learn about intercompany expenses.
● Learn how to publish, approve, & delegate expense reports.
● Discover reporting alternatives for expense management.`,
outline_heading_24:`Set up & configure Expense management in Dynamics 365 Project Operations`,
outline_detail_24:`● Understand how to turn on the Expense reports re-imagined functionality.
● Discover the Expense management workspace.
● Review the Expense management parameters.
● Learn about workflow functionality for expense management.
● Set up expense policies, & analyze policies using the Audit workbench.
● Set up mileage expense options.
● Set up credit card transaction processing & import credit transactions.`,
outline_heading_25:`Working with travel requisitions in Dynamics 365 Project Operations.`,
outline_detail_25:`● Make a travel requisition.
● Review trip requisition choices.
● Learn about the travel requisition approval process.`,
outline_heading_26:`Get started with task management in Dynamics 365 Project Operations`,
outline_detail_26:`● Learn how to make &submit time entries.
● Learn about the two sorts of expense deployment models.
● Learn how to collect, submit, recall, & remove cost items.`,
outline_heading_27:`In Dynamics 365 Finance, configure fixed asset management.`,
outline_detail_27:`● Set up fixed assets parameters.
● Configure fixed assets components.
● Enable fixed assets integration with purchasing.
● Make fixed assets.`,
outline_heading_28:`Manage fixed assets in Dynamics 365 Finance`,
outline_detail_28:`● Perform fixed asset acquisition 
● Perform fixed asset depreciation
● Perform fixed asset disposal`,
outline_heading_29:`Configure & apply basic budgeting & budget control in Dynamics 365 Finance`,
outline_detail_29:`● Configure fundamental budgeting components.
● Configure budget control components.
● Use inquiries & reports.
● Make & maintain budget register entries.`,
outline_heading_30:`Configure & use budget planning in Dynamics 365 Finance`,
outline_detail_30:`● Configure budget planning components
Work with analytics & reporting in finance & operations apps
● Different sorts of reports & queries in finance & operations apps 
● Financial Reporting 
● Configuring finance & operations apps for Microsoft Power Platform
● Generating or consuming queries & reports
● Learn the advantages of Supply Chain Management.
● Get an introduction of distribution &trading features in Supply Chain Management`,
outline_heading_31:`Explore accounts payable & accounts receivable in Dynamics 365 Finance`,
outline_detail_31:`● Explain fundamental accounts payable components, including vendors, purchase orders, vendor bills.
● Explain vendor payments & settlements, including three-way matching ideas.
● Explain fundamental accounts receivable components, including customers, customer invoices, free text invoices. 
● Explain credit & collection processes.`,
skill_1:`● Set up & arrange finance management (40—45%)`,
skill_2:`● Implement & manage accounts payable & costs (10—15%)`,
skill_3:`● Implement accounts receivable, credit, collections, & revenue recognition (15—20%)`,
skill_4:`● Financial Reporting and Analysis in Dynamics 365 Finance`,
skill_5:`● Manage fixed assets (10—15%)`,
skill_6:`● Manage to budget (10—15%)`,
course_faq_name:`MB-310T00Microsoft Dynamics 365 Finance`,
faq_question_1:`Q What is the difference between Dynamics AX & Dynamics 365?`,
faq_answer_1:`A: Despite the similarities between Dynamics 365 and Dynamics AX, Dynamics 365 is a web- and cloud-based system, whereas AX is largely a desktop-based application with certain web- and mobile add-ons`,
faq_question_2:`A: Despite the similarities between Dynamics 365 and Dynamics AX, Dynamics 365 is a web- and cloud-based system, whereas AX is largely a desktop-based application with certain web- and mobile add-ons`,
faq_answer_2:`A: C/AL (Client/Server Application Language) was the programming language used in C/SIDE, the Client/Server Integrated Development Environment in Microsoft Dynamics NAV (Formerly known as Navision Attain) and Microsoft Dynamics 365 Business Central until (and including) version 14.`,
faq_question_3:`Q: Which is better, Salesforce or Microsoft Dynamics?`,
faq_answer_3:`A: Customer feedback on Microsoft Dynamics vs. Salesforce is mostly mixed. Neither tool shines out as a clear winner over the other. Salesforce looks to be superior in task management when limited to sales activities, although Dynamics offers wider customisation and overall administration capability.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, owing to some contingency, if you have to skip the class, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed exclusively for personal enjoyment & NOT for distribution or commercial purpose.`,
},
{
    id:120,
categories: ``,
course_detail_image:course_images.microsoft_dynamic_365,
course_name:`MB-240T00: Microsoft Dynamics 365 Field Service`,
course_overview:`Understand how to effectively configure Dynamics 365 for Field Service implementation to optimize the tools & capabilities available to efficiently manage a mobile workforce.
Duration: 1 Day`,
certification:`Participants for this exam are Microsoft Dynamics 365 Field Service functional consultants. They are in charge of putting solutions in place that manage and optimize the resources needed to fulfill the field service lifecycle.
These professionals carry out field service procedures designed in partnership with internal and external teams. The Connected Field Service (IoT) solution & the mobile app are installed as part of this partnership, along with any other adaptations that may be required. Together with the primary customer support application, they are in responsible of setting up and deploying the Field support app.
Configuration options for resources, traits, incident types, inventory integration, service agreements, resource planning, work orders, inspections, service tasks, field service user roles, and field service setting, and the Connected Field Service (IoT) solution are all requirements for the position, & the Dynamics 365 Field Service mobile app. They must also know how to customize the components of the Field Service app.
`,
prerequisite:`Audience Profile 
This program is aimed for IT professionals who have experience offering Field Service solutions to large-scale clients or are interested in doing so.`,
outline_heading_1:`Configure Microsoft Dynamics 365 Field Service`,
outline_detail_1:`• Find critical service configuration considerations 
• Users & security roles 
• Define work order types, statuses & sub statuses 
• Define incident & service task types • Define Field Service products, services, & pricing choices
• Define service territories 
• Configure Field Service account preferences`,
outline_heading_2:`Configure bookable resources in Dynamics 365 Field Service `,
outline_detail_2:`• Find the numerous types of resources available 
• Define supporting resource components such as traits, roles, & organizational units 
• Setup an individual bookable resource 
• Geocode resources, starting & ending places, & viewing in the map • Define pools, crews, & amenities`,
outline_heading_3:`Schedule crews, facilities, & resource pooling in Dynamics 365 Field Service`,
outline_detail_3:`• Define & use resource pools in scheduling scenarios 
• Use the facility scheduling option to arrange particular facilities for work • Define & schedule crews using the schedule board`,
outline_heading_4:`Work order management, agreements, inventory, & purchasing in Dynamics 365 Field Service`,
outline_detail_4:`• Find the work order lifecycle 
• Find the numerous options available for making work orders 
• Use linked data such as service accounts, incidents, products & services, & other objects with work orders
• Leverage entitlements 
• Make & manage agreements 
• Make & manage service level agreements (SLAs) for work orders 
• Define & manage inventory & warehouses`,
outline_heading_5:`Incident categories in Dynamics 365 Field Service`,
outline_detail_5:`• Understand the purpose of incident types in Dynamics 365 Field Service.
• Understand how to build incident types.
• Make service tasks & use them in an incident type.
• Add items & services to an incident type.
• Define the duration of an incident type.
• Use incident kinds on work orders.`,
outline_heading_6:`Inspections in Dynamics 365 Field Service`,
outline_detail_6:`• Understand the purpose of reviews in Dynamics 365 Field Service.
• Understand how to make inspections.
• Associate appraisals with work orders & customer assets.
• Perform checks by utilizing the Field Service mobile app`,
outline_heading_7:`Manage scheduling parameters in Dynamics 365 for Field Service`,
outline_detail_7:`• Find circumstances where the numerous field service scheduling choices could be used 
• Navigate, filter, & alter fundamental schedule board parameters
• Manually plan & reschedule tasks on the schedule board 
• Use the resource availability search (schedule assistant) to categorize items on the schedule board`,
outline_heading_8:`Customize the schedule board in Dynamics 365 Field Service`,
outline_detail_8:`• Define each pane in the schedule board 
• Edit schedule board views & tooltips
• Edit map pane • Schedule board alerts
• Extend the schedule board with JavaScript & HTML`,
outline_heading_9:`Deploying Resource Scheduling Optimization`,
outline_detail_9:`• Set up & install Resource Scheduling Optimization.
• Understand the configuration options & set optimization targets, scopes, & timeframes.`,
outline_heading_10:`Get started with the Dynamics 365 Field Service Mobile application`,
outline_detail_10:`• Understand how to deploy & operate the mobile application.
• Understand Service assets.
• Use online & offline data.`,
outline_heading_11:`Customize & setup the Dynamics 365 Field Service Mobile application`,
outline_detail_11:`• Customize & edit the mobile application.
• Configure offline data & barcodes.
• Enable location tracking & push notifications.`,
outline_heading_12:`Integrate Dynamics 365 Remote Assist with Dynamics 365 Field Service`,
outline_detail_12:`• Launch Remote Assist mobile from a booking.
• Review pertinent work order details.
• Post-call information.`,
outline_heading_13:`Customer assets in Dynamics 365 Field Service`,
outline_detail_13:`• Discover the purpose of client assets in Field Service.
• Understand how to make customer assets.
• Understand how to correlate customer assets with work orders.
• Understand how to configure customer asset hierarchies.
• Understand how to use available sites.`,
outline_heading_14:`Set up Field Service to make work orders using IoT data`,
outline_detail_14:`• Understand the installation & setup of Connected Field Service.
• Read device data.
• Set device notifications.
• Understand about utilizing the smartphone app`,
outline_heading_15:`Make bespoke apps for Dynamics 365 Field Service`,
outline_detail_15:`• Understand how to leverage Microsoft Power Platform to customize Dynamics 365 Field Service.
• Understand how to develop Microsoft Power Apps applications for Dynamics 365 Field Service.
• Understand how to deploy the customer experiences homepages portal for Field Service.
• Discover how to customize Field Service.
• Understand how to set up & customize schedule boards.
• Understand how to enable a custom table for scheduling.`,
outline_heading_16:`Gather client feedback with Dynamics 365 client Voice`,
outline_detail_16:`• Understand how to improve relationships with your consumers by evaluating feedback data.
• Understand how to use templates in Customer Voice to generate projects, surveys, & emails.
• Discover how to collect feedback with Microsoft Power Automate, Omnichannel for Customer Service, & Customer Service Insights.
• Find out how to use Customer Voice to understand your client's needs & requirements.`,
skill_1:`Control the Dynamics 365 Field Service mobile app (5-10%)`,
skill_2:`Schedule & dispatch work orders (20-25%)`,
skill_3:`Manage work orders (20-25%)`,
skill_4:`Configure field service applications (20-25%)`,
skill_5:`Manage inventory & purchasing (1-5%)`,
skill_6:`Managed Connected Field Service & customer assets (5–10%)`,
course_faq_name:`FAQs`,
faq_question_1:`Q  How do I setup field services in Dynamics 365?`,
faq_answer_1:`A: You may also get Field Service via this Dynamics 365 ordering page. Users have to add a Field Service trial to an existing Dynamics 365 organization by selecting the option of Settings > Microsoft AppSource. After that user has to look for Field Service trial & then select Dynamics 365 Field Service trial, & at last select Free Trial.`,
faq_question_2:` Q: How can I upgrade field services in Dynamics 365?`,
faq_answer_2:`A: Go to Admin. Then go to the Dynamics 365 Admin Center. After that, choose & highlight the environment which has the Field Service app user wants to update. In the list of solutions, consumers can simply identify & choose the Field Service app.`,
faq_question_3:` Q: What are the new features in Dynamics 365?`,
faq_answer_3:`A: New CRM Features in Dynamics 365 Version 9.0:-
• Unified Client Interface. 
• Web Client Refresh
• Multi-Select Option Sets
• Activity Timeline
• LinkedIn Sales Navigator`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed exclusively for personal enjoyment & NOT for distribution or commercial purpose.`,
},



{
    id:121,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:` MB-230T01: Microsoft Dynamics 365 Customer Service`,
course_overview:`Microsoft Dynamics 365 client Service provides an opportunity for every firm to achieve client success. Using tools like automatic case creation and queue management frees up your time to focus on areas where you can have a greater effect directly with your clients. Join our global specialists as they lead you through the process of creating cases, connecting with consumers, and resolving those situations. After you've resolved those situations, you can utilize data analysis to gather understanding of the relevant facts to help students tackle comparable cases faster or avoid new ones altogether.
Duration: 4 Days`,
certification:`Candidates for the exam are functional consultants with experience in customer service. They are in responsible of implementing omnichannel solutions that prioritize service, quality, dependability, efficiency, and customer happiness.
Candidates work with solution architects to build and implement service management methods. Candidates collaborate with customer engagement administrators to implement and improve customer service components such as knowledge management, feedback, and integrated services.
Candidates must possess a solid foundation in applied customer service knowledge, including a comprehensive understanding of cases, knowledge management, unified routing & queues, entitlements, resource allocation, service-level agreements (SLAs), visualizations, connected services, Power Virtual Agents, Customer Service workspaces, Customer Service Insights, Customer Voice, and Omnichannel for Customer Service. The functional consultant should have a good awareness of the customer support application's function within the Dynamics 365 family of apps, as well as a fundamental understanding of solution architecture & quality assurance.`,
prerequisite:`The purpose of this training is to increase the customer service knowledge of functional consultants who deal with Dynamics 365 Customer Service or available consultants who work with other Dynamics 365 apps.
Audience Profile 
A Functional Consultant for Dynamics 365 Customer Engagement is in charge of doing discovery, identifying needs, and recruiting subject matter experts. & stakeholders, translating requirements, & designing the solution & apps. The Functional Consultant creates a solution using out-of-the-box features, codeless flexibility, and application & service integrations.`,
outline_heading_1:`Get started with Dynamics 365 Customer Service`,
outline_detail_1:`• Gain information about the elements of Dynamics 365 Customer Service • Gain knowledge of how to enhance Dynamics 365 Customer Service to boost your customer interactions
Managing cases using Dynamics 365 Customer Service Hub
• Examine the case resolution process in Dynamics 365 from beginning to end 
• Identify the numerous choices that are available for creating & describing cases 
• Use business process flows to lead agents through case resolution 
• Review when & how parent/child situations are used 
• Identify when similar topics should be merged 
• Work with case status reason transitions, & configure custom status transitions`,
outline_heading_2:`Use Microsoft Dynamics 365 Customer Service queues to handle case workloads`,
outline_detail_2:`• Identify the many types of Microsoft Dynamics 365 lines that are available • Make a queue, & define queue members 
• Assign, choose, & release queue items 
• Define routing rule sets to move things to specific queues 
• Set up other Dynamics 365 tables so that they may be utilized with queues`,
outline_heading_3:`Make or update records automatically in the Customer Service Hub`,
outline_detail_3:`• Gain understanding about automatic record production & review cases where you can use it.
• Utilize the Customer Service Hub app to go over the process of defining rules.
• Check rule items, how they're employed within a practice, & the procedure for selecting them within a practice.
• Gain understanding on how to utilize Microsoft Power Automate to make the record in the application.
• Gain a grasp of establishing the Power Automate pipeline to manually map a contact.
• Check how to use the activity monitor to evaluate the status of rules that are being processed.`,
outline_heading_4:`Get started with unified routing for Dynamics 365 Customer Service`,
outline_detail_4:`• Gain information of basic routing.
• Set up cooperative routing.
• Make & maintain user setup.
• Gain knowledge about queues & workstreams`,
outline_heading_5:`Make & manage entitlements in Microsoft Dynamics 365 Customer Service`,
outline_detail_5:`• Make & define entitlement records 
• Control when an entitlement is applied 
• Define specific entitlement terms & supported channels 
• Define entitlements that are product-specific or contact-specific 
• Work with multiple entitlement records for a particular customer 
• Use entitlement templates to quickly make new entitlements`,
outline_heading_6:`Make knowledge management solutions in Dynamics 365 Customer Service`,
outline_detail_6:`• Identify instances where knowledge articles can be used 
• Make & define knowledge articles in Dynamics 365 
• Manage the knowledge article lifecycle 
• Control article version, translation, & publishing options`,
outline_heading_7:`Use knowledge articles to handle Dynamics 365 Customer Service cases`,
outline_detail_7:`• Configure Dynamics 365 Entities for use with Knowledge of Management 
• Define & control Article display options on entities 
• Search for & use Articles to resolve Dynamics 365 cases
• View & manage Knowledge Article Analytics`,
outline_heading_8:`Make a survey project with Dynamics 365 Customer Voice`,
outline_detail_8:`• Gain understanding about Dynamics 365 Customer Voice & how it can benefit an enterprise.
• Do your first project.
• Discover the high-level features & functionality of a project`,
outline_heading_9:`Make customer surveys with Dynamics 365 Customer Voice`,
outline_detail_9:`• Do your first survey with a theme & personalized branding.
• Gain knowledge of when to utilize branching rules to assist respondents through a survey.
• Personalize surveys with variables`,
outline_heading_10:`Send Dynamics 365 Customer Voice surveys`,
outline_detail_10:`• Gain understanding about how to make email templates to send using Dynamics 365 Customer Voice.
• Gain an overview of how to email surveys straight from Dynamics 365 Customer Voice.
• Discover the numerous methods of sending surveys, including direct links & QR codes.
• Send survey reminders & obtain expertise on how to analyze the survey distribution report.`,
outline_heading_11:`Automate Dynamics 365 Customer Voice surveys with Power Automate`,
outline_detail_11:`• Gain understanding about Power Automate's role when setting up projects & surveys in Dynamics 365 Customer Voice.
• Discover the purpose of using variables to further customize surveys.
• Make a flow in Power Automate to make & send survey invitations to clients.`,
outline_heading_12:`Set up Customer Service Scheduling`,
outline_detail_12:`• Get started with Customer Service scheduling.
• Gain knowledge of how to set up Customer Service schedule.
• Discover how to define resources.`,
outline_heading_13:`Schedule services with Customer Service Scheduling`,
outline_detail_13:`• Gain knowledge about how to define services.
• Discover how to establish fulfillment preferences.
• Gain a grasp of how to make & arrange service activities.`,
outline_heading_14:`Enhance agent productivity with Customer Service workspace`,
outline_detail_14:`• View & update records. 
• Work with cases.`,
outline_heading_15:`Make personalized experiences for agents with the App profile manager in Customer Service`,
outline_detail_15:`• Gain understanding about the App profile management feature, when it's available, & how to access it.
• Make custom profiles with the user interface.
• Define which channels & productivity features are available within a form.
• Configure session templates.
• Make application tab templates.
• Make notification templates & use notification fields.
• Review correlate session & notification templates with workstreams.`,
outline_heading_16:`Getting started with Omnichannel for Customer Service`,
outline_detail_16:`• Gain information regarding agent experience & managing sessions.
• Work with chats & customer summaries.`,
outline_heading_17:`Route & distribute tasks with unified routing in Dynamics 365 Customer Service`,
outline_detail_17:`• Set up the components involved in the classification & assignment of job items.
• Classify job items & route tasks to staff.
• Set up queues to spread work.
• Run diagnostics.`,
outline_heading_18:`Deploy an SMS channel in Omnichannel for Dynamics 365 Customer Service`,
outline_detail_18:`• Configure an SMS channel.
• Configure phone numbers & messaging accounts such as TeleSign & Twilio.
• Configure SMS workstream & workstream settings.
• Route SMS communications.
• Set up extra parameters`,
outline_heading_19:`Deploy chat widgets with Omnichannel for Dynamics 365 Customer Service`,
outline_detail_19:`• Make a chat channel.
• Allow chat transcripts & file downloads.
• Work with pre-chat & post-chat surveys.
• Embed a chat widget in a gateway.`,
outline_heading_20:`Make creative assist solutions in Omnichannel for Dynamics 365 Customer Service`,
outline_detail_20:`• Write adaptable cards that render content for an intelligent assist bot.
• Build an intelligent aid bot.
• Make a bot user.
• Add an intelligent aid bot to a workstream.
• Enable the Productivity pane.`,
outline_heading_21:`Get started with Customer Service Insights`,
outline_detail_21:`• Gain understanding about Customer Service Insights & what they may give for an organization.
• Examine how to configure Customer Service Insights for your firm.
• gather knowledge about deploying the Power BI app for Customer Service Insights to gather insights.
• Discover the different dashboards that are accessible.`,
outline_heading_22:`Make visuals for Dynamics 365 Customer Service`,
outline_detail_22:`• Review the data reporting & visualization options in Dynamics 365 Customer Service.
• Discover how to build charts.
• Gain understanding on how to use & develop tier 1 & tier 2 interactive dashboards.
• Discover how to utilize Microsoft Power BI to view Dynamics 365 Customer Service data.`,
outline_heading_23:`Get started with Connected Customer Service for Dynamics 365 & Azure IoT`,
outline_detail_23:`• Gain information about the Connected Customer Service Add-in for Microsoft Dynamics 365.
• Choose a deployment type.
• Install the Connected Customer Service Add-in.
• Gain information about customer assets & IoT notifications.`,
outline_heading_24:`Register & manage devices with Connected Customer Service for Dynamics 365 & Azure IoT`,
outline_detail_24:`• Gain understanding about how to register IoT devices.
• Discover how to make IoT alerts & convert alerts to cases.
• Gain understanding about the required security roles for Connected Customer Service.
• Discover how to manage interactions with IoT devices.
• Gain information about issuing commands to IoT devices.`,
outline_heading_25:`Make bespoke apps for Dynamics 365 Customer Service`,
outline_detail_25:`• Gain knowledge about how to leverage Microsoft Power Platform to customize Dynamics 365 Customer Service.
• Gain an understanding of how to develop apps with Microsoft Power Apps for Customer Service.
• Gain a grasp of how to deploy a self-service portal for Customer Service.
• Discover how to tailor case management.`,
outline_heading_26:`Integrate a Power Virtual Agents bot with Omnichannel for Customer Service`,
outline_detail_26:`• Make an Azure Active Directory application.
• Configure the Power Virtual Agents bot.
• Configure Omnichannel for Customer Service.`,
skill_1:`• Implement Microsoft Power Platform (5–10%)`,
skill_2:`• Manage cases & Knowledge Management (25–30%)`,
skill_3:`• Implement scheduling (5–10%)`,
skill_4:`• Manage entitlements & SLAs (5–10%)`,
skill_5:`• Manage analytics & insights (10–15%)`,
skill_6:`• Manage cases & Knowledge Management (25–30%)`,
course_faq_name:`MB-230T01: Microsoft Dynamics 365 Customer Service`,
faq_question_1:`What is customer service hub Dynamics 365?`,
faq_answer_1:`A: The Customer Service Hub is a targeted, interactive interface designed to ease your day-to-day case & knowledge management activities. It normally offers you all your critical facts in one area & after that, helps you focus on the key actions which want your attention.`,
faq_question_2:`Q: What is the difference between Microsoft Dynamics GP & SL?`,
faq_answer_2:` A: Since Dynamics GP may be described to as a typical ERP while Dynamics SL is a project-based ERP, these are actually the only two things they have in common. Dynamics SL often integrates multiple accounting tools with project management software for worldwide capability.`,
faq_question_3:`Q: What are CRM technologies?`,
faq_answer_3:`A: Customer relationship management (CRM) is a tool for managing all your company's relationships & interactions with customers & future customers. A CRM system helps many firms stay connected to customers, reduce procedures, and boost profitability.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed exclusively for personal enjoyment & NOT for distribution or commercial purpose.`,
},


{
    id:122,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-220T00Microsoft Dynamics 365 Marketing`,
course_overview:`This course will go over how to configure marketing applications to drive business success. Lead management, marketing forms & pages, segmentation, & email marketing messages will also be discussed. All of these pieces are linked together by interactive customer journey design, which includes event and survey configuration.
Duration: 4 Days`,
certification:`Candidates for this test are functional consultants for Microsoft Dynamics 365 Marketing. Candidates must implement solutions to attract and convert leads, build brand recognition, standardize multichannel messaging, and give marketing insights.
Candidates are in charge of configuring the core marketing application, which includes email marketing, setup, interactive customer journeys, real-time journeys, lead nurturing with personalized experiences, LinkedIn integration, events, Customer Voice surveys, segmentation, scoring, and ensuring compliance with data privacy and security regulations.
Candidates should be conversant with marketing principles and the role of the Marketing application in the Dynamics 365 family of products, including integration with Dynamics 365 and Microsoft 365 applications. Candidates should be conversant with industry terminology, priorities, collaborative initiatives, standards, processes, and best practices.`,
prerequisite:`Knowledge of the Dynamics 365 platform & a comprehension of basic marketing ideas. Power Platform experience, especially in model-driven applications, is also desired.
Audience Profile
This course is aimed for IT and marketing professionals who wish to use Dynamics 365 Marketing to support their company.`,
outline_heading_1:`Set up & manage Dynamics 365 Marketing`,
outline_detail_1:`• Register for a free trial of Dynamics 365 Marketing.
• Purchase & install up Dynamics 365 Marketing
• Manage marketing environments
• Keep the marketing app up-to-date
• Uninstall Dynamics 365 Marketing services`,
outline_heading_2:`Advanced configuration for Dynamics 365 Marketing`,
outline_detail_2:`• Access the settings area inside Dynamics 365 Marketing
• View versions, quota constraints, & usage limits.
• Update default settings for landing pages, emails, customer journeys, & more.
• Manage customer engagement settings, including compliance settings, audience`,
outline_heading_3:`configuration, push notification & SMS provider setup, social media account administration, & more.`,
outline_detail_3:`• Turn on the Microsoft Teams collaboration & chat functionality for usage within the marketing app.
• Set up the connections to webinar providers & modify default settings for events.
• Define how to form submissions matched to existing contacts or leads, configure lead scoring, & enable the Microsoft Dynamics 365 Connector for LinkedIn Lead Gen for your environment.
• Select which entities to sync to Dynamics 365 Marketing, link your Dynamics 365 Marketing environment to Microsoft Azure Blob Storage, & connect Customer Insights to your Dynamics 365 Marketing environment`,
outline_heading_4:`Review domain authentication, email best practices, & GDPR in Dynamics 365 Marketing`,
outline_detail_4:`• Authenticate your domain in Dynamics 365 Marketing
• Build & preserve your send reputation 
• Prepare Dynamics 365 for GDPR compliance`,
outline_heading_5:`Manage assets & content settings in Dynamics 365 Marketing`,
outline_detail_5:`• Upload photographs to the file library & use images in marketing content.
• Create keywords & associate them with ideas.
• Incorporate content block elements in marketing content.
• Review, change, & create content settings.`,
outline_heading_6:`Manage customers in Dynamics 365 Marketing`,
outline_detail_6:`• Manage contacts & accounts in Dynamics 365 Marketing.
• Identify the basic concepts of account-based marketing.
• Score & qualify leads.`,
outline_heading_7:`Manage forms & pages in Dynamics 365 Marketing`,
outline_detail_7:`• Create & publish marketing forms.
• Add documents to Dynamics 365 Marketing pages & externally hosted pages.
• Set up form captures.
• Build marketing forms & marketing page templates.`,
outline_heading_8:`Manage emails, segments, & journeys in Dynamics 365 Marketing`,
outline_detail_8:`• Create & publish a marketing email.
• Construct components within Dynamics 365 Marketing.
• Leverage segments created under Customer Insights.
• Design & publish a customer journey.
• Run an A/B test in outbound marketing.
• Build marketing email, feature, & customer journey templates.`,
outline_heading_9:`Manage subscription centers & double opt-in in Dynamics 365 Marketing`,
outline_detail_9:`• Create a subscriber list.
• Build a subscription center form.
• Design a subscription center marketing page.
• Identify a subscription center in content settings.
• Select a content settings record in a customer journey.`,
outline_heading_10:`Manage website traffic, redirect URLs, & social postings in Dynamics 365 Marketing`,
outline_detail_10:`• Monitor website visits.
• Track visitors to your marketing pages.
• Create redirect URLs.
• Schedule & post messages on social media`,
outline_heading_11:`Create an on-site event in Dynamics 365 Marketing`,
outline_detail_11:`• Create an in-person event in Dynamics 365 Marketing.
• Manage sessions, speakers, & sponsorships.
• Configure registration options & set up event passes.
• Capture extra information about the event, including financial details & event team members.
• Customize the calendar content for the event.
• Manage event logistics.
• Publish the event & view the event website.`,
outline_heading_12:`Create a webinar event in Dynamics 365 Marketing`,
outline_detail_12:`• Configure webinar settings for On24 & other third-party webinar providers.
• Create a webinar event using On24 or other third-party webinar providers.
• Create a webinar event using Microsoft Teams.
• Build the registration form for a virtual event.`,
outline_heading_13:`Promote events, handle registration & check-ins, & monitor results in Dynamics 365 Marketing`,
outline_detail_13:`• Use emails, segments, & journeys to promote your events.
• View & manage event registrations.
• View & manage event attendance.
• Follow up after the event.`,
outline_heading_14:`Advanced event management features in Dynamics 365 Marketing`,
outline_detail_14:`• Create & manage recurring events.
• Create an event template.`,
outline_heading_15:`Evaluate marketing initiatives using analytics in Dynamics 365 Marketing`,
outline_detail_15:`• Review & evaluate results via Dynamics 365 Marketing insights.`,
outline_heading_16:`Review the marketing schedule in Dynamics 365 Marketing`,
outline_detail_16:`• Review entity calendars 
• Review form calendars 
• Create & personalize marketing calendars`,
skill_1:`• Create & administer marketing forms & pages (5-10%)`,
skill_2:`• Manage leads, contacts, & accounts (5-10%)`,
skill_3:`• Manage customer journeys (20-25%)`,
skill_4:`• Create & manage marketing email campaigns (10-15%)`,
skill_5:`• Configure Dynamics 365 Customer Voice (5-10%)`,
skill_6:`• Manage events & webinars (10-15%)`,
course_faq_name:`MB-220T00: Microsoft Dynamics 365 Marketing`,
faq_question_1:`Q: What are the benefits of Dynamics 365?`,
faq_answer_1:`A: The commercial benefits of Microsoft Dynamics 365 are: 
Added Flexibility: with Applications that fit all types of industries & businesses. …
Productive: Dynamics 365 promotes seamless productivity by combining familiar tools like Outlook & Excel & surfacing them in the context of company processes, roles & tasks.`,
faq_question_2:`Q: What is Dynamics 365 for marketing?`,
faq_answer_2:`Dynamics 365 for Marketing enables you identify visitors to your online platform by tracking activities such as opening emails, forwarding forms from your marketing web pages, & registering up for events. The marketing module helps boost clients' digital interaction, thereby nurturing leads.`,
faq_question_3:`Q: Which features are available in Dynamics 365 marketing?`,
faq_answer_3:`Key aspects of the lead nurturing component of Microsoft Dynamics 365 Marketing include: • Customer journey designer & orchestration.
• Dynamics Customer Insights integration.
• Dynamic segmentation & multiple lead scoring.
• Account-based marketing.
• Lead lifecycle with automated business process flows.`,
faq_question_4:`Q: Why Dynamics 365 for marketing?`,
faq_answer_4:`Dynamics 365 for Marketing is a powerful Marketing Automation solution that helps businesses to complete the buyer's journey from Prospect to Customer by creating a relationship. D365 for Marketing is simple to use, tightly linked with Dynamics 365 for Sales and other modules and comes with analytics built in.`,
faq_question_5:`Q: What is the difference between Microsoft 365 & Dynamics 365?`,
faq_answer_5:`In addition, Microsoft 365 is a service bundle that contains Office 365 & various additional services, such as Windows 10 Enterprise. Microsoft Dynamics 365 Service is an all-in-one solution for enterprises for small & midsize firms that links people & processes as never before`,
},

{
    id:123,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-210T01 Microsoft Dynamics 365 Sales`,
course_overview:`Microsoft Dynamics 365 Sales is a complete solution for managing customer and potential customer interactions. 
Dynamics 365 Sales allows businesses to track data against sales targets, automate best practices, learn from data, and much more. 
Join our globally known specialists as they walk you from lead to opportunity to closed contract. 
You will discover how to empower sales people to be their most productive selves by utilizing the application's available automation and customization capabilities. 

Duration: 2 Days`,
certification:`Participants for this test are Microsoft Dynamics 365 functional consultants with sales experience. They are in charge of implementing solutions that fulfill an organization's business requirements, no matter how big or little. 
Candidates must also identify possible process efficiencies, & improvements that can be realized by employing Dynamics 365 Sales & more extended Microsoft Power Platform features.
This could involve supporting a sales lifecycle in working effectively, & assisting the sales team in achieving revenue targets, implementing business plans, & fulfill an organization's objectives. 
Candidates are in charge of establishing and expanding the core sales functionality in order to provide scalable customer solutions that assist, automate, and speed the company's sales process. 
Where connectors, business process flows, visualizations, and bespoke applications are necessary, the applicant will utilize their Microsoft Power Platform experience & finish the solution architecture using common Dynamics 365 customer interaction capabilities. 
Professionals should have good business understanding & first-person industry experience. 
Students should grasp the sales process of the customer as well as the basic capabilities of Dynamics 365 Sales to discover where the two align & diverge. 
Professionals should also be proficient in the Microsoft Power Platform & model-driven app configuration & know about Microsoft 365 services.`,
prerequisite:`Familiarity with business apps & the desire to adapt & apply them for your firm. 

Audience Profile  

A Dynamics 365 Functional Consultant is in responsible of completing gathering requirements, discovering, engaging subject matter experts & stakeholders, translating requirements, & customizing the solution & apps. 
The functional consultant installs a solution using out-of-the-box features, codeless extensibility, and application & service integrations.`,
outline_heading_1:`Set up & setup Dynamics 365 Sales`,
outline_detail_1:`● Learn about the core features of Dynamics 365 Sales & how it may help your firm. 
● Configure important parameters that businesses should customize when setting up the application. 
● Set up the included security roles for sales users. Manage leads with Dynamics 365 Sales`,
outline_heading_2:`Examine the lead qualification process in Dynamics 365 from beginning to end`,
outline_detail_2:`● Identify the many choices that are available for creating & identifying leads 
● Use business process flows to manage the lead lifecycle 
● Qualify & disqualify leads Manage opportunities with Dynamics 365 Sales 
● Identify instances where alternatives can be employed 
● Create & describe opportunities 
● Work with relevant opportunity data`,
outline_heading_3:`Manage an opportunity throughout its lifecycle Leverage Embedded Intelligence in Dynamics 365 Sales 
`,
outline_detail_3:`● Deploy & set up sophisticated relationship cards 
● Use the Auto Capture tool to identify possibly relevant communications `,
outline_heading_4:`Set up & consume email tracking Manage & organize your product catalog with Dynamics 365 Sales`,
outline_detail_4:`● Set up unit groups to help control how products are sold 
● Create product family hierarchies that include product bundles & individual products 
● Set up product pricing data by creating price lists & price list items`,
outline_heading_5:`Use discount lists to provide volume discount pricing Process sales orders with Dynamics 365 Sales`,
outline_detail_5:`● Define quote records for opportunity records 
● Work a quote through its whole lifespan 
● Add & update quotations for product line items`,
outline_heading_6:`Deliver quotes to customers Manage relationships with relationship selling in Dynamics 365 Sales`,
outline_detail_6:`● Discover best practices 
● Work with sales playbooks
● Manage relationship health with Sales Insights`,
outline_heading_7:`Recognize aspects of Sales Navigator Analyze Dynamics 365 sales data`,
outline_detail_7:`● Review analytical alternatives 
● Describe searching tools
● Utilize Power BI to view data
● Analyze data.`,
outline_heading_8:`Define & track individual goals in Dynamics 365 Sales & Customer Service`,
outline_detail_8:`● Create & maintain personal objectives
● Use Parent & Child objectives`,
outline_heading_9:`Monitor objective progress Use goal metrics in Dynamics 365 Sales & Customer Service`,
outline_detail_9:`● Determine which metrics are needed to develop efficient customer service goals
● Modify Fiscal Year settings to align goal progress
● Define Goal Metrics for an Organization
● Create custom Roll-up columns & Queries`,
skill_1:`● Configure extra tools & services (10–15%)`,
skill_2:`● Manage core sales tables (40–45%)`,
skill_3:`● Configure Dynamics 365 Sales (35–40%) `,
skill_4:`● Lead Generation and Qualification`,
skill_5:`● Sales Pipeline Management`,
skill_6:`● Customer Relationship Management (CRM)`,
course_faq_name:`MB-210T01 Microsoft Dynamics 365 Sales`,
faq_question_1:`Q : Is Microsoft Dynamics hard to learn?`,
faq_answer_1:`A: Microsoft Dynamic is one of the leading CRM software, which is normally competing with the other available software in the industry, namely Salesforce, SAP, Oracle, etc. It's easy to learn, & application nature separates it from its peers.`,
faq_question_2:`Q: What is the difference between Dynamics AX & Dynamics 365?`,
faq_answer_2:`A: While Dynamics 365 & Dynamics AX are very comparable, Dynamics 365 is a web- & cloud-based system, whereas AX is mostly a desktop-based application with some web & mobile add-ons.`,
faq_question_3:`Q: What are the new features in Dynamics 365?`,
faq_answer_3:`A: New Features of Microsoft Dynamics 365 are: App Modules. Site Map Designer. Relationship Insights Relevance Search Mobile Capabilities Dynamics 365 for Outlook Visual Process Designer Editable Grids.`,
faq_question_4:`Q: How do I install Microsoft Dynamics?`,
faq_answer_4:`A: choose Start, choose Administrative Tools, & then click Computer Management. Expand System Tools commonly used to expand Local Users & Groups, & then expand Groups. Right-click Administrators, & then click Properties. 
To add the user account of the user who is installing Microsoft Dynamics CRM user has to click Add, & after that, click OK.`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training program, you should have functioning desktops or laptops with the required specifications and a good internet connection to access the labs.`,
},


{
    id:124,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-500T00 Microsoft Dynamics 365Finance Operations Apps Developer`,
course_overview:`In this course, we explore the duties required to completing the function of the developer in Dynamics 365 Finance & Operations Apps. The Dynamics 365 Finance & Operations apps developer is a crucial technical resource that implements & expands the program to satisfy business needs.
Duration: 5 Days `,
certification:`Participants for this exam are developers that deal with financial & operations apps in Microsoft Dynamics 365 to install & enhance applications that meet the requirements of a business. Candidates give completely realized solutions employing established application coding standards, expandable features, & external integrations.
Candidates develop business logic by using X++, Make & modify finance & operations app reports & workspaces, customize user interfaces, provide endpoints & APIs to support Microsoft Power Platform apps & external systems, perform testing, monitor performance, analyze & manipulate data, Make technical designs & application details, & apply permission policies & security requirements.
Candidates should have thorough understanding & experience using the underlying framework, data structures, & objects related with the financial & operational solutions.
Candidates should have familiarity with products that include Visual Studio, Azure DevOps Lifecycle Services (LCS) tools, Postman, GitHub, Microsoft 365, & SQL Server Controlment Studio.`,
prerequisite:`Audience Profile 
Microsoft Dynamics 365 Finance & Operations Apps Developers`,
outline_heading_1:`Get exposed to the finance & operations apps`,
outline_detail_1:`• Discover the range of finance & operation software you may use for enterprise resource planning.
• Review use cases for finance & operations apps.`,
outline_heading_2:`Explore the ecosystem & core components of finance & operations apps`,
outline_detail_2:`• Explore the Dynamics 365 ecosystem.
• Gain understanding about the primary features of finance & operations apps.
• Gain understanding about on-premises & cloud implementation choices.
• Explore the development & deployment process for finance & operations apps.
`,
outline_heading_3:`Critical differences between Dynamics AX 2012 & finance & operations apps`,
outline_detail_3:`• How Microsoft helps customers update.
• The Microsoft Cloud.
• Architectural & operational differences between AX 2012 & finance & operations apps.`,
outline_heading_4:`Explore the technological architecture of finance & operations apps`,
outline_detail_4:`• Differentiate between on-premises & cloud architecture of finance & operations.
• Gain knowledge about the purposes & relationships of packages, models, & elements.
• Gain information about the application components & architecture.
• Detail metadata Controlment & source control methods.`,
outline_heading_5:`Apply application lifecycle Controlment in finance & operations apps`,
outline_detail_5:`• Define ALM.
• Gain understanding about the different ALM techniques.
• Build models.
• Make a project plan for constructing & testing solutions.
• Make processes for release Controlment, change Controlment, & risk Controlment.`,
outline_heading_6:`Control finance & operations software applications by leveraging Lifecycle Services`,
outline_detail_6:`• Performing support responsibilities.
• Provisioning & managing environments.
• Managing asset libraries.
• Executing the code upgrading procedure between versions of finance & operations apps.`,
outline_heading_7:`Start designing finance & operations apps by utilizing Visual Studio`,
outline_detail_7:`• Make & build projects & deployable packages in Visual Studio.
• Use the Application Explorer to Control components.
• Changes to the data dictionary should be synchronized with the application database.
• Work in the Element Designer to Make elements.`,
outline_heading_8:`Control source code by utilizing version control in finance & operations apps`,
outline_detail_8:`• Connect your developer environment to an Azure DevOps project.
• Use best practices for version control.
• Control & do code reviews.`,
outline_heading_9:`Build enhanced data types & enumerations for finance & operations apps`,
outline_detail_9:`• Identify the different sorts of revisions.
• Gain information about the use of base enumerations (base enums).
• Identify how EDT's & basic enums are viewed in the finance & operations apps user interface.
• Make a basic enumeration.`,
outline_heading_10:`Build tables in finance & operations apps`,
outline_detail_10:`• Define the use of tables in finance & operations apps.
• Make a new table in Visual Studio.
• Control table properties.
• Add fields & field groups to a table.
• Make an index & a table relation.
• Gain information of table procedures.`,
outline_heading_11:`Extend elements in finance & operations apps`,
outline_detail_11:`• Make extensions to customize financial & operations apps.
• Extend a table.
• Make a form extension.`,
outline_heading_12:`Build forms & optimize form performance in finance & operations apps`,
outline_detail_12:`• Make a new form.
• Apply a form pattern.
• Add a data source to a record.
• Add grids, fields, & groups to a record.
• Understand form methods.
• Discover the varieties of menu items.
• Make a menu item & add it to a record.
• Run a setup & evaluate its functionality.
• Gain information about browser-based & Performance Timer technologies that optimize form performance.`,
outline_heading_13:`Make classes in finance & operations apps`,
outline_detail_13:`• Gain information about the use of classes in designing financial & operations apps.
• Make a new class that is added to a project.
• Add methods to a class.
• Review the types of methods that can be used in a class.`,
outline_heading_14:`Explore extensions & the extension framework in finance & operations apps`,
outline_detail_14:`• Gain understanding about the rationale for generating extensions.
• Decide when to use the form for seeking extensibility.
• Gain understanding about the possibility of intrusive customizations.
• Develop code that will extend the capabilities of financial & operations apps.
• Apply the SysOperation Sandbox framework.`,
outline_heading_15:`Configure your user interface in Finance & Operations apps`,
outline_detail_15:`• Add a menu item to the favorites
• Explain how various roles view different dashboards
• Add a KPI to a dashboard workspace
• Modify the fields in a form
• Modify the fields in a report`,
outline_heading_16:`Build workspaces in finance & operations apps`,
outline_detail_16:`• Design & construct key performance indicators (KPIs).
• Make workspace elements.
• Use Report Definition Language (RDL) to Make bespoke reports.
• Apply reporting components into workspaces`,
outline_heading_17:`Get started with programming using X++ in finance & operations apps`,
outline_detail_17:`• Identify components of classes.
• Make an instance of a class.
• Make objects in a constructor.
• Manipulate data by utilizing X++.
• Identify the different sorts of conditional statements.
• Write conditional statements.
• Write loop statements.
• Use iterative statements.
• Identify exception handling statements.
• Write exception handling statements.
• Gain information about the use & function of cost values.`,
outline_heading_18:`Develop object-oriented code in finance & operations apps`,
outline_detail_18:`• Discover the importance of object-oriented programming for finance & operations apps.
• Gain awareness of the issue of abstract classes & inheritance in object-oriented programming.
• Gain expertise about interfaces.
• Use the Chain of Comm& (CoC).
• Gain knowledge of how scoping is applied in X++ programming.
• Determine when to use references & values.`,
outline_heading_19:`Build reports for finance & operations apps`,
outline_detail_19:`• Enable Business document Controlment.
• Design, Make, & edit SSRS reports.
• Make & alter a Power BI report utilizing finance & operations apps data.
• Make & update an Excel report by using finance & operations apps data.
• Gain knowledge of how to compose SQL statements by using query objects.`,
outline_heading_20:`Apply the Data Controlment package API for finance & operations apps`,
outline_detail_20:`• Import & export APIs between finance & operational apps cloud deployments & on-premises installations.
• Import & export APIs between on-premises implementations.
• Use Get Execution SummaryStatus to get the status & availability of APIs.
• Make wrapper classes using C# & X++`,
outline_heading_21:`Explore the test framework & tools in finance & operations apps`,
outline_detail_21:`• Capabilities & benefits of the unit test framework.
• Capabilities & benefits of Task Recorder.
• Benefits of the Best Practices tool.`,
outline_heading_22:`Perform user acceptance testing in finance & operations apps`,
outline_detail_22:`• Test cases & business requirements
• Best methods for recording test cases
• Task recorder
• How to Make a BPM library
• How to synchronize & configure your test plan in Azure DevOps
• How to run test cases manually & automatically
• The Regression suite automation tool (RSAT)`,
outline_heading_23:`Explore reporting tools in finance & operations apps`,
outline_detail_23:`• Gain information about the capabilities of various reporting tools in finance & operations apps.
• Determine the proper report kinds to utilize.
• Make & edit a report data source.
• Gain information regarding reporting & security standards.
• Deploy reports with PowerShell.
• Deploy reports with Visual Studio.`,
outline_heading_24:`Compare reporting & analytics in finance & operations apps with Dynamics AX 2012`,
outline_detail_24:`• Reporting & Power BI analysis utilized with AX 2012.
• The reporting options are available in finance & operations apps.
• Data export services in finance & operations apps.
• How to upgrade your data warehouse.
• How to update your analytics & reports.`,
outline_heading_25:`Identify data integration trends & scenarios in finance & operations apps`,
outline_detail_25:`• Identify integration web application programming interfaces.
• Explore available data integration scenarios.
• Identify the fundamental differences between synchronous & asynchronous integrations.`,
outline_heading_26:`Apply data integration principles & solutions for finance & operations apps`,
outline_detail_26:`• Make a data entity.
• Enable data Controlment capabilities.
• Expose Open Data Protocol (OData) endpoints with data entities.
• Find custom service endpoints.
• Learn about the various external online services.
• Consume external web services.
• Connect to your data via the Microsoft Excel add-in.
• Connect to your data with Microsoft Power Apps.`,
outline_heading_27:`outline_heading_27:`,
outline_detail_27:`• Set up a data project.
• Set up a recurring data job.
• Define authorization for integrations by using OAuth.
• Monitor the status & availability of entities.
• Develop data transformations.
• Use Microsoft Dataverse to synchronize entities between Dynamics 365 applications.
• Use composite data entities.
• Integrate hybrid data entities.
• Export composite data entities.
• Gain information about Azure Data Lake & Entity Store, & how to modify data in Azure Data Lake
• Gain information of Microsoft Power Platform convergence.`,
outline_heading_28:`Connect to Microsoft Power Platform services with finance & operations apps`,
outline_detail_28:`• Connect your finance & operations apps data with Power Automate.
• Connect to your finance & operations apps data using Power Apps.
• Gain understanding about the Common Data Model to bring data from many systems & applications together.`,
outline_heading_29:`Dual-write application for Dynamics 365 solutions`,
outline_detail_29:`• In this module, you will:
• Gain understanding about the Dual-write application workshop
• Prepare for the Dual-write application workshop
• Gain knowledge about how to conduct the Dual-write application workshop
• Follow up following the Dual-write application workshop`,
outline_heading_30:`Work with data Controlment in finance & operational apps.`,
outline_detail_30:`• Find the suitable pattern for a given scenario to use the Data Controlment platform for integration using data entities.
• Work with the Data Controlment workspace.
• Export data from a legal entity.
• Import data into a legal entity.
• Work with data import & export jobs.
• Clean up the staging tables.
• Work with database movement operations.
• Work with the data-sharing mechanism.`,
outline_heading_31:`Work with performance & monitoring tools in finance & operations apps`,
outline_detail_31:`• Diagnose performance issues by utilizing the Trace parser.
• Explore load testing by using the Performance SDK.
• Monitor performance by utilizing the SQL Insights dashboard.
• Monitor server Health indicators in Microsoft Dynamics Lifecycle Services.`,
outline_heading_32:`Apply role-based security in finance & operations apps`,
outline_detail_32:`• Make & edit roles, duties, privileges, & permissions.
• Review the role-based security structure.
• Use & enforce permissions policies.
• Define the extensible data security framework (XDS).
• Apply for security permissions.
• Stay compliance with user licensing requirements.`,
outline_heading_33:`Plan & apply security in finance & operational apps`,
outline_detail_33:`• Understand the security architecture of finance & operations apps
• Gain expertise about encryption in finance & operations apps
• Control users
• Control security
• Apply segregation of duties
• Run security reports
• Stay compliant with user licensing requirements`,
outline_heading_34:`Apply fundamental performance optimization in finance & operations apps`,
outline_detail_34:`• Discover what temporary tables are & when to utilize them.
• Gain information about what set-based statements & row-based operations are, & when to utilize each.
• Understand & show several strategies for optimizing bespoke queries.
• Gain understanding about the other caching methods that are possible & when each should be used.`,
skill_1:`• Apply to report (10-15%)`,
skill_2:`• Integrate & Control data solutions (10-15%)`,
skill_3:`• Develop & test code (10-15%)`,
skill_4:`• Design & develop AOT elements (20-25%)`,
skill_5:`• Apply developer tools (10-15%)`,
skill_6:`• Plan architecture & solution design (5-10%)`,
course_faq_name:`MB-500T00 Microsoft Dynamics 365Finance Operations Apps Developer`,
faq_question_1:`Q: What if I miss a lesson on a specific day?`,
faq_answer_1:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, if, owing to some contingency, you have to skip the lesson, Digipaath will aid you with the recorded session of that particular day. `,
faq_question_2:`What is the infrastructure required to attend your training program?`,
faq_answer_2:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_question_3:`What is mb200 certification?`,
faq_answer_3:`This exam tests your ability to do the following technical activities & this largely comprises performing discovery, planning, & analysis of Control entities & data; applying security; Control user experience design applying ply integration; & perform solutions deployment & testing.`,
faq_question_4:`Q: How can I put a canvas app within the model-driven app?`,
faq_answer_4:`A: Canvas Apps can be incorporated within Model Driven Apps as PCF controllers. The first step to create a canvas app would be to place a text field on the form. Next, the user has to add a rule to this field & after that, choose the Canvas app. Set this as the control for browser, phone & tablet view.`,
faq_question_5:`Q: How do I embed PowerApps in dynamics 365 for finance & operations?`,
faq_answer_5:`A: One of the best things about Dynamics 365 for Finance & Operations (D365FO) is the ability to embed PowerApps, especially in a workspace. To embed a PowerApp in a workspace, go to the Options menu point in the ribbon & pick Personalize this form. Clicking on the button allows you to add a PowerAp`,
},

{
    id:125,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-300T00Microsoft Dynamics 365Core Finance Operations`,
course_overview:`This course covers the following core aspects in Dynamics 365: common core features, core configuration, data migration, & preparing to go live.
Duration: 2 Days`,
certification:`Candidates for this exam acquire & analyze business requirements & turn those specifications into fully realized business procedures and solutions that meet best practices. In order to implement and configure applications to satisfy business objectives, they are a critical resource.
Participants should be able to implement shared features used by finance & operations apps. The participants should be able to use Lifecycle Services (LCS), Azure DevOps, & other Microsoft tools & platforms to control the application lifecycle management (ALM) process. They should also have the awareness that Finance & The Microsoft Power Platform solutions and external systems are integrated with operations apps, & tools.
Participants for this test often specialize in one or more feature sets of finance & operations apps, including Finance, supply chain management, manufacturing, commerce, project operations, & human resources.`,
prerequisite:`Before attending this course, students must have the following:

• General understanding of Microsoft Windows • the ability to perform basic processing and navigation using Dynamics 365 for Finance & Operations • Working as a functional or technical consultant who has gathered information using Dynamics Finance Apps & analyze business requirements • General knowledge of basic mobile device features & navigation
• The professionals should be able to take a synchronous, virtual course & express feedback in a pleasant, constructive manner
Audience Profile (heading 3)
Consultants (functional & technical) acquire & assess business requirements, & integrate those requirements into fully realized business processes & solutions that apply industry-recommended practices. They act as a vital resource in implementing &configuring apps to fulfill the needs of organizations. Dynamics 365 finance should be able to use shared features that consultants can build. & operations apps. They should also grasp how Dynamics 365 financial and operations apps interface with third-party systems and technologies, such as Microsoft Power Platform. The consultant employs (LCS) Lifecycle Services, Azure DevOps, & other Microsoft tools &platforms that make the process of application lifecycle management (ALM) easier. By reading the product release notes, studying the product roadmaps, and attending conferences, consultants maintain their education or continue to gain expertise by using Microsoft Gain knowledge of Microsoft Docs, instructor-led courses, blogs, & user communities. They should also pursue role-based certifications & examinations to guarantee their knowledge is acknowledged.`,
outline_heading_1:`Introduction to Microsoft Dynamics 365`,
outline_detail_1:`• Explore Dynamics 365 apps.
• Discover business value in Dynamics 365 applications & how they assist digital transformation.
• Gain awareness about the role of artificial intelligence & mixed reality in Dynamics 365.`,
outline_heading_2:`Navigate finance & operations apps`,
outline_detail_2:`• How workspaces drive productivity 
• Filtering techniques 
• Searching in finance & operations apps 
• Comparing views 
•  The view & edit modes`,
outline_heading_3:`Get started with Dynamics 365 Finance`,
outline_detail_3:`• The benefits of Dynamics 365 Finance. 
• An overview of features in Finance.`,
outline_heading_4:`Get started with production control in Dynamics 365 Supply Chain Management `,
outline_detail_4:`• Discrete, lean, & process manufacturing methodologies 
• Unified Manufacturing 
• How to configure production control for unified manufacturing 
• Capacity planning 
• Integration between the General ledger & the Production control modules 
• Production pools 
• How to work with allocation keys 
• IoT intelligence & insights into Supply Chain Management`,
outline_heading_5:`Get aid & obtain understanding of more about Finance & operations apps`,
outline_detail_5:`• Microsoft Gain knowledge about documentation 
• In-product help 
• How to personalize assistance topics 
• Protecting the privacy & personal data of your customers`,
outline_heading_6:`Get started with Lifecycle Services for finance & operations apps`,
outline_detail_6:`• Understand the functionality & tools of Lifecycle Services for finance & operations apps 
• Reuse existing assets, copy & share data 
• Understand Task recorder functionality 
• Make a business process model
 • Find & analyze gaps using the Business Process Modeler 
• Run the Issue Search tool & analyze the results`,
outline_heading_7:`Design & plan the implementation of finance & operations apps`,
outline_detail_7:`• Prepare documentation for gathered requirements by using techniques in Lifecycle Services.
• Define use case scenarios.
• Gain knowledge on how to participate in the creation of Business requirement documents (BRD).
• Understand the value of building & maintaining documented processes.
• Participate in phase-based planning procedures & solution design.
• Explain the future state business processes based on a proposed solution.
• Determine when to construct vs buy.
• Make Functional Design Documents (FDD).`,
outline_heading_8:`Translate Dynamics 365 apps & documents using Dynamics 365 Translation Service`,
outline_detail_8:`• Gain knowledge of the capabilities & features of Dynamics 365 Translation Service.
• Submit translation requests for user interface & documentation files of supported Dynamics 365 products.
• Review & amend a translation request's output to regenerate new translations.
• Use the Align tool to make translation memory for future translation requests.`,
outline_heading_9:`Plan & configure the global address book in finance & operations apps`,
outline_detail_9:`• What is the global address book?
• How the world address book functions.
• How to prepare for other address books in addition to the global address book.`,
outline_heading_10:`Plan & implement legal entities in finance & operational apps`,
outline_detail_10:`• Make a legal entity & produce numerical sequences.
• Make a numerical sequence.
• Make organization structures.
• Assign purposes & policies to hierarchies.
• Configure calendars & date intervals.`,
outline_heading_11:`Plan & deploy security in finance & operational apps`,
outline_detail_11:`• Understand the security architecture of finance & operations apps 
• Gain understanding of encryption in finance & operations apps 
• Manage users 
• Manage security 
• Apply segregation of duties 
• Run security reports 
• Stay compliant with user licensing requirements`,
outline_heading_12:`Set up batch jobs in finance & operations apps`,
outline_detail_12:`• Batch processing 
• Batch processing & batch servers 
• Planning for batch server topology 
• Planning for batch server management 
• Batch processing of reports 
• Copying a batch job 
• Active batch periods 
• The batch job manager security role 
• The Run by feature 
• How to unlock a journal`,
outline_heading_13:`Work with workflows in finance & operations apps`,
outline_detail_13:`• Use the workflow architecture, elements, & actions 
• Make & set up workflows 
• Configure workflow properties 
• Configure a manual task in a workflow 
• Configure an automated task in a workflow 
• Configure an approval process in a workflow 
• Assign users to workflow elements 
• Configure an approval step in a workflow 
• Use work items functionality`,
outline_heading_14:`Design & create mobile apps for finance & operations apps`,
outline_detail_14:`• The Microsoft Dynamics 365 Unified Operations mobile app 
• Use cases for mobile apps 
• How to build & operate with workplaces`,
outline_heading_15:`Personalize finance & operations apps`,
outline_detail_15:`• Explain & apply user options
• Differentiate between customisation & personalization 
• Make, export, & import personalization 
• Make & manage record templates 
• Use Saved views`,
outline_heading_16:`Implement common integration features in finance & operations apps`,
outline_detail_16:`• Perform Microsoft Office integration in finance & operations apps 
• Configure email (SMTP/Exchange) 
• Make & maintain email templates 
• Set up network printing 
• Set up & enable Power BI 
• Integrate data from multiple sources into Microsoft Dataverse`,
outline_heading_17:`Feature management in finance & operations apps`,
outline_detail_17:`• Gain awareness of the Feature management workspace in finance & operations apps.
• Discover when & how to enable features, including how to disable & reschedule features.
• Gain understanding on how to check for feature changes & assign roles.`,
outline_heading_18:`Prepare data for migration to finance & operations apps`,
outline_detail_18:`• Choose a data integration (import/export) approach.
• Find frequent migration situations & tools in finance & operations apps.
• Understand the Bring your own database (BYOD) feature.
• Find applicable (old) systems.
• Find & import static data that is common amongst businesses.
• Make & review test plans for data migration.
• Find & extract source data.
• Find appropriate data entities & elements.
• Generate field mapping between source & target data structures.
• Test a data migration & evaluate output.
• Support the transition between the existing & moved systems.`,
outline_heading_19:`Work with data management in finance & operations apps.`,
outline_detail_19:`• Find the suitable pattern for a given scenario to use the Data management platform for integration by leveraging data entities.
• Work with the Data management workspace.
• Export data from a legal entity.
• Import data into a legal entity.
• Work with data import & export jobs.
• Clean up the staging tables.
• Work with database movement operations.
• Work with the data-sharing mechanism.`,
outline_heading_20:`Perform user acceptability testing in finance & operations apps`,
outline_detail_20:`• Test cases & business requirements 
• Best practices for recording test cases 
• Task recorder 
• How to make a BPM library 
• How to synchronize & configure your test plan in Azure DevOps 
• How to run test cases manually & automatically 
• The Regression suite automation tool (RSAT)`,
outline_heading_21:`Prepare to go live with finance & operations apps`,
outline_detail_21:`• Prepare for go-live 
• Completion of the Microsoft Dynamics Lifecycle Services Methodology
• Perform (UAT) User acceptability testing for your solution 
• Understand the FastTrack Go-live assessment 
• Request the production environment`,
outline_heading_22:`Work with analytics & reporting in finance & operations apps `,
outline_detail_22:`• Different sorts of reports & inquiries in finance & operations apps 
• Financial Reporting 
• Configuring finance & operations apps for Microsoft Power Platform 
• Generating & consuming inquiries & reports`,
outline_heading_23:`Configure electronic reporting in Dynamics 365 Finance`,
outline_detail_23:`• Configure electronic reporting 
• Use barcode data sources to generate barcode images 
• Add a new electronic reporting provider & designate it as active 
• Generate an electronic document in a certain format 
• Make electronic reporting setups`,
skill_1:`• Validate & support solutions (15–20%)`,
skill_2:`• Financial Management in Dynamics 365`,
skill_3:`• Manage finance & operations data (20–25%)`,
skill_4:`• Configure administrative features & workflows (30–35%)`,
skill_5:`• enhance apps by using Microsoft Power Platform technologies (20–25%)`,
skill_6:`• Explain financial & operations apps,`,
course_faq_name:`MB-300T00 Microsoft Dynamics 365Core Finance Operations`,
faq_question_1:`Q: What are Finance & operations?`,
faq_answer_1:`A: An organization's finance operations director is usually responsible for directing the finance/accounting function & team. They often give strategic direction as well as oversight of front-end revenue cycle work methodologies & outcomes. Often, they oversee revenue rules & procedures, which largely comprise billing for revenue generating.`,
faq_question_2:`Q: What is the difference between Microsoft Dynamics AX & 365?`,
faq_answer_2:`A: Dynamics 365 is a package with unified CRM & ERP systems & contains a Dynamics AX suite. While Dynamics 365 & Dynamics AX are relatively similar, Dynamics 365 is a web- & cloud-based system, whereas AX is mostly a desktop-based application with certain web & mobile add-ons.`,
faq_question_3:`Q: What does the operations department do?`,
faq_answer_3:`A: Your operations department is responsible for making sure that customer orders are filled on schedule. This role mostly comprises monitoring production rhythms & also syncing manufacturing deadlines with availability as well as supply of materials essential for production.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?
`,
faq_answer_4:`A: To attend the training program, you should have operating Desktops or Laptops with the required specifications, coupled with a good internet connection to access the laboratories.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, owing to some contingency, if you have to skip the lesson, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed solely for personal enjoyment & NOT for distribution or any commercial use.`,
},

{
    id:126,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`PL-600T00: Microsoft Power Platform Solution Architect`,
course_overview:`The solution architect ensures the design, implementation, deployment & adoption of an overall solution. The solution architect ensures that the solution satisfies the customer's present and future needs. In this course, professionals will learn about the decisions a solution architect makes while creating a solution, including those relating to security, integrations, the Power Apps architecture, and the Power Automate architecture, & more. This program is meant to introduce you to the Solution Architect role.
Duration: 3 Days `,
certification:`Candidates for the exam are solution architects who lead successful deployments & focus on how solutions address enterprises' larger business and technical needs. This position demands the capacity to detect chances to solve company difficulties.
Candidates should have familiarity with the Microsoft Power Platform, associated Microsoft cloud solutions, Dynamics 365 customer interaction apps, and third-party technologies. Throughout an engagement, they employ their skills and experience. Candidates participate in proactive and preventative work to maximize the value of the customer's investment and improve organizational health.
Candidates should have familiarity with Microsoft Power Platform across functional and technical disciplines. They should be able to aid with design decisions in the areas of development, configuration, integration, infrastructure, security, licensing, availability, storage, and change management. This role balances the business demands of a project while meeting functional and non-functional requirements.`,
prerequisite:`Audience Profile Senior Consultants (both functional and technical) who desire to become Solution Architects or newly appointed Solution Architects.`,
outline_heading_1:`Becoming a solution architect for Dynamics 365 & Microsoft Power Platform`,
outline_detail_1:`• Understand what is expected of a solution architect.
• Understand the responsibilities of a solution architect during the project phases.
• Discover customer requirements as a Solution Architect for Dynamics 365 and Microsoft Power Platform.
• Understand initial customer discovery.
• Discover customer communication tactics.
• As a Microsoft Power Platform Solution Architect & Dynamics 365, I propose a solution.
• Identify solution components.
• Develop & validate a demo.`,
outline_heading_2:`Worked with requirements as a solution architect for Microsoft Power Platform & Dynamics 365`,
outline_detail_2:`• Understand leading requirement capture sessions.
• Understand functional & non-functional needs.`,
outline_heading_3:`Perform fit gap analysis`,
outline_detail_3:`• Determine the feasibility of needs.
• Refine needs from proof of concept insights.
• Categorize business requirements & undertake a fit-gap analysis.
• Evaluate Dynamics 365 & Microsoft Power Platform apps.`,
outline_heading_4:`Solution Architect series: Apply project governance for Power Platform & Dynamics 365`,
outline_detail_4:`• Understand the solution architect’s role in project governance.
• Find ways for maintaining a project on track.
• Investigate possibilities that could lead to a project's failure.`,
outline_heading_5:`Solution Architect series: Power Platform architecture`,
outline_detail_5:`• Review the important Power Platform architecture components.
• Explore how the platform's design & restrictions might influence solution architectures.
• Review how to communicate with the customer on how the platform can suit their needs.
• Model data for Power Platform solutions in the Solution Architect Series
• Understand data modeling influencers.
• Discover multiple data model strategies.
• Understand data kinds & data linkages.`,
outline_heading_6:``,outline_detail_6:``,
outline_heading_7:`Solution Architect series: Evaluate Power Platform Analytics & AI`,
outline_detail_7:`• Discover the planning & assessing requirements.
• Understand operational reporting.
• Review the capabilities of Microsoft Power BI & how to use it with Power Platform components.
• Understand how to employ pre-built insights & custom AI.`,
outline_heading_8:`Solution Architect series: Explore Power Apps architecture`,
outline_detail_8:`• Explore possibilities for applications & how to determine where to start.
• Explore app composition possibilities.
• Understand how to use components as part of your app architecture.
• Discover when to incorporate Power Apps portals as an app in your architecture
• Solution Architect series: Power Platform application lifecycle management planning
• Understand Microsoft's goal & the solution architect's role in ALM.
• Review environment plans.
• Define a solution structure for your deliverables.`,
outline_heading_9:`Explore Power Automate architecture`,
outline_detail_9:`• Review possibilities for automation & custom logic.
• Review considerations for employing triggers & ordinary behaviors.
• Search how to use business process flows to guide users through business processes.
• Solution architect series: Power Platform solution security models
• Discover the architect’s role in security modeling.
• Understand how to control access to environments & resources.
• Understand how to control access to data in Dataverse.
• Solution Architect series: Integrate with Power Platform
• Understand the solution architect's role in integrations.
• Understand integration & why it's vital.
• Discover Power Platform features that help enables integration.
• Use the power of Microsoft Azure.`,
outline_heading_10:`Solution architect series: Explore Virtual Power Agents`,
outline_detail_10:`• Explore alternatives for chatbots & how to determine where to start.
• Explore situations for chatbots.
• Understand the components of Power Virtual Agents.`,
outline_heading_11:`Solution architect series: Explore robotic process automation`,
outline_detail_11:`• Review the solution architect's role with testing & go-live.
• Understand the data migration procedure & deployment.
• Understand the testing process.
• Discover how to plan for go-live.`,
skill_1:`• Perform solution envisioning & requirement analysis (35–40%)`,
skill_2:`• Architect a solution (40–45%)`,
skill_3:`• Implement the solution (15–20%)`,
skill_4:`• Power Automate Design`,
skill_5:`• Dataverse Modeling`,
skill_6:`• Power BI Reporting`,
course_faq_name:`PL-600T00 Microsoft Power Platform Solution Architect`,
faq_question_1:`What is the Power Platform?`,
faq_answer_1:`A: The ''Power Platform'' is a collective moniker for three Microsoft products: Power BI, PowerApps & Power Automate (formerly known as Flow).`,
faq_question_2:`Q: What does a solution architect do?`,
faq_answer_2:`A: A Solution Architect is entrusted with turning preset pieces into a design architect's use as a blueprint. A Solution Architect often strives to balance several facets of the project, from safety to design.`,
faq_question_3:`Q: What is the next role available after "solution architect"?`,
faq_answer_3:`A: A product manager would be the one who would be involved in getting the product to the market & solution architect would be interested in implementing the product should a customer opt to enforce it. There is some form of complementing talents in both positions.`,
faq_question_4:`Q: Is Solutions Architect an excellent job?`,
faq_answer_4:`A: Multiple business analysts with strong technological skills are shifting into solutions architect roles & this can be a wonderful career path for a person. If you like technology enough to stay up with the latest platforms, tools, and technologies, a solution architect could be a terrific career choice.`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
},


{
    id:127,

categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-920T00 Microsoft Dynamics 365 Fundamentals (ERP)`,
course_overview:`Do you want to understand more about Dynamics 365? A complete introduction to Dynamics 365's financial and operational functionalities will be offered via this course. Professionals will learn about the core elements of the finance & operations software as well as the idea of enterprise resource management (ERM). Then, we'll walk through each app in turn, covering Dynamics 365 Project Operations' ERP features as well as Dynamics 365 Supply Chain Management, Finance, Commerce, and Human Resources. There will be lectures and practical labs in this course.

People in diverse professions & at various stages in their careers can be advantageous from this fundamentals course. This includes business stakeholders & those who want to be exposed to the finance & operations capabilities of Dynamics 365, students, new graduates, & people changing careers who seek to leverage Dynamics 365 to go to the next level.
Duration: 1 Day`,
certification:`This test covers the features & capabilities of Dynamics 365 finance & operations apps.
As an exam applicant, you should have general understanding of or appropriate professional experience with an Information Technology (IT) environment. You should also have a solid understanding of financial principles & business operations.`,
prerequisite:`Professionals should have a solid understanding of financial principles & business operations. A type of cloud computing is helpful but isn't necessary.
Audience Profile`,
outline_heading_1:`Get exposed to the finance & operations apps`,
outline_detail_1:`• Discover the range of finance & operation software you may use for enterprise resource planning.
• Review use cases for finance & operations apps`,
outline_heading_2:`Create reports & combine financial & operations apps`,
outline_detail_2:`• Explain built-in reporting capabilities.
• Explain possibilities for evaluating data.
• Explain choices for modifying data with Microsoft products.
• Explain use cases for & integration capabilities with other Microsoft products.`,
outline_heading_3:`Explore Microsoft Dynamics 365 Finance fundamental capabilities`,
outline_detail_3:`• Explain finance skills & characteristics.
• Explain use cases for legal entities.
• Explain how Dynamics 365 Finance manages regional tax reporting requirements.
• Explain cost accounting concepts.
• Explain journaling basics.`,
outline_heading_4:`Explore general ledger in Microsoft Dynamics 365 Finance`,
outline_detail_4:`• Explain charts of accounts, including main versions & classification.
• Explain financial dimensions & dimension set concepts.
• Explain periodic financial processes.`,
outline_heading_5:`Explore accounts payable & accounts receivable in Dynamics 365 Finance`,
outline_detail_5:`• Explain fundamental accounts payable components, including vendors, purchase orders, & vendor invoices.
• Explain vendor payments & settlements, including three-way matching ideas.
• Explain fundamental accounts receivable details, including customers, customer invoices, & free text invoices.
• Explain credit & collection methods.`,
outline_heading_6:`Explore expenditure management, fixed asset management & budgeting in Dynamics 365 Finance`,
outline_detail_6:`• Explain cash & bank management concepts 
• Explain expense management, including cash advances & mobile workspaces
• Explain better asset management, & fixed asset generation, purchase, & depreciation • Explain budgeting capabilities
• Find out what broad capabilities Dynamics 365 Supply Chain Management has.
• Explain product master records & product variants.
• Explain master planning.
• Explain trade agreements & discounts.`,
outline_heading_7:`Explore warehousing & inventory capabilities`,
outline_detail_7:`• Explain pricing methodologies, including standard costing, weighted averages, last-in-first-out (LIFO), & first-in-first-out (FIFO) 
• Explain use cases for Warehouse Management System (WMS) & warehouse design options, including layout, stocking limitations, & location profiles
• Explain purchase orders, item arrival logs, & cross-docking
• Explain inventory concepts including reservations, picking & packing, replenishment, shipments, counting, & inventory on-h&
• Explain quality management capabilities`,
outline_heading_8:`Explore manufacturing techniques in Dynamics 365 Supply Chain Management`,
outline_detail_8:`• Explain manufacturing kinds.
• Explain manufacturing strategies, including make-to-order (MTO), make-to-stock (MTS), & configure-to-order (CTO).
• Explain item tracking & tracing techniques.
• Explain tools that can be used to manage shop floors.
• Explain master planning.`,
outline_heading_9:`Explore Dynamics 365 Commerce capabilities`,
outline_detail_9:`• Explain commerce skills.
• Explain retail channels & channel management ideas.
• Explain distributed order management (DOM).
• Explain the product assortment.`,
outline_heading_10:`Explore Microsoft Dynamics 365 business-to-business e-commerce, Store Commerce & Clienteling features`,
outline_detail_10:`• Understand the B2B digital commerce capabilities in the web channel.
• Explain loyalty & clienteling ideas.
• Explain how Dynamics 365 Commerce leverages Dynamics 365 Customer Insights.
• Explain how Dynamics 365 Fraud Protection prevents fraudulent actions in the system.`,
outline_heading_11:`Explore Dynamics 365 Human Resources basic capabilities`,
outline_detail_11:`• Explore workers & positions.
• Explore compensation & benefits capabilities.
• Explore compliance features.
• Explore performance management capabilities.`,
outline_heading_12:`Explore staff management capabilities in Dynamics 365 Human Resources`,
outline_detail_12:`• Explain self-service capabilities.
• Explain leave & absence capabilities.
• Explain staff training & certification features.
• Identify integration options between Dynamics 365 Human Resources & LinkedIn.`,
outline_heading_13:`Identify project life cycle management features in Dynamics 365 Project Operations`,
outline_detail_13:`• Explain the project lifecycle, including project invoicing & revenue recognition.
• Explain project-based services.
• Explain project sales skills.
• Explain project contract capabilities.
• Explain project pricing capabilities.`,
outline_heading_14:`Explore the project planning & execution capabilities of 365 Project Operations`,
outline_detail_14:`• Explain project planning capabilities.
• Explain project execution capabilities.
• Explain the work breakdown structure.
• Explain project invoicing capabilities.
• Explain project cost tracking capabilities.`,
skill_1:`• Explain Dynamics 365 Supply Chain Management (25-30%)`,
skill_2:`• Explain shared features (10-15%)`,
skill_3:`• Explain Project Operations (5-10%)`,
skill_4:`• Explain Dynamics 365 Human Resources (10-15%)`,
skill_5:`• Explain Dynamics 365 Commerce (5-10%)`,
skill_6:`• Explain Dynamics Finance (25-30%)`,
course_faq_name:`MB-920T00: Microsoft Dynamics 365 Fundamentals (ERP)`,
faq_question_1:`Q: What is Microsoft Dynamics 365 utilized for?`,
faq_answer_1:`A: Microsoft Dynamics 365 is a cloud-based business applications platform that blends components of customer relationship management (CRM) & enterprise resource planning (ERP), along with productivity tools & artificial intelligence technology.`,
faq_question_2:`Q: What is dynamic 365?`,
faq_answer_2:`A: Dynamics 365 is a bundle of intelligent business software that mainly helps you run your complete organization & deliver more substantial results with the use of predictive, AI-driven insights.`,
faq_question_3:`Q: What must you offer for the first setup of dynamics 365 Marketing?`,
faq_answer_3:`A: To start the setup wizard, you must complete all the following requirements: You must already have a Microsoft 365 tenant. Presence of an unconfigured Dynamics 365 Marketing app for users. A security role (like Global admin or Service support admin) that essentially allows you to modify the target Dynamics 365 instance.`,
faq_question_4:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_4:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs.`,
faq_question_5:`Q: What if I miss a lesson on a specific day?`,
faq_answer_5:`A: We would always urge you join the live session to practice & resolve the doubts promptly & receive greater value from your investment. However, owing to some contingency, if you have to skip the class, Digipaath will help you with the recorded session of that particular day. However, those recorded sessions are not designed exclusively for personal enjoyment & NOT for distribution or commercial purpose.`,
},



{
    id:128,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`MB-910T00: Microsoft Dynamics 365 Fundamentals (CRM)`,
course_overview:`Do you desire to learn more about Dynamics 365? A detailed introduction to Dynamics 365's client engagement features will be offered in this session. You will become familiar with the idea of customer engagement, the essential features of customer engagement apps, and each of the customer engagement applications, including Dynamics 365 Marketing, Dynamics 365 Customer Service, Dynamics 365 Sales, and Dynamics 365 Field Service. Both lectures and practical laboratories are part of this course.
Duration: 1 Day`,
certification:`This exam covers the customer engagement features & capabilities of Dynamics 365 apps. Candidates for this exam should have a fundamental awareness of business operations, customer engagement principles, and basic computer abilities.`,
prerequisite:`Students should have a foundational understanding of customer interaction principles & corporate processes. A type of cloud computing is helpful but isn't necessary.


Audience Profile
This fundamentals course can benefit people in a range of professions and stages of their employment. This includes IT experts, business stakeholders, & others who want to learn about Dynamics 365's customer interaction capabilities, as well as professionals, new graduates, and people changing careers who want to use Dynamics 365 to better their careers.`,
outline_heading_1:`Navigate the Dynamics 365 customer engagement apps`,
outline_detail_1:`• Explain use cases for Dynamics 365 
• Explain what Microsoft Dataverse is & how it is used 
• Examine the numerous Dynamics 365 CRM Apps 
• Review methods for accessing applications 
• Locate & filter data in Dynamics 365`,
outline_heading_2:`Explore everyday Dynamics 365 operations & integration choices`,
outline_detail_2:`• Work with clients & manage activities 
• Explore different reporting options 
• Explain the numerous integration options available`,
outline_heading_3:`Explore Dynamics 365 Marketing `,
outline_detail_3:`• Identify use cases for Dynamics 365 Marketing
• Explain email marketing 
• Explain how to target clients with segments 
• Explain how to design & exploit customer journeys in Dynamics 365 Marketing 
• Explain lead generation & qualification`,
outline_heading_4:`Explore other marketing capabilities & apps`,
outline_detail_4:`• Examine the event management capabilities available 
• Explain Dynamics 365 Customer Voice 
• Explain Dynamics 365 Customer Insights`,
outline_heading_5:`Explore Dynamics 365 Sales`,
outline_detail_5:`• Explain Dynamics 365 Sales features & functionality 
• Review a typical sales lifecycle 
• Explain leads & the qualification process 
• Examine the opportunity management process 
• Explain sales order processing with Dynamics 365 Sales`,
outline_heading_6:`Use sales capabilities`,
outline_detail_6:`• Explain Dynamics 365 Sales forecasting 
• Explain LinkedIn Sales Navigator 
• Explain Dynamics 365 Sales Insights`,
outline_heading_7:`Explore Dynamics 365 Customer Service`,
outline_detail_7:`• Identify use cases for Dynamics 365 Customer Service 
• Explain the case lifecycle 
• Examine how to manage Queues
• Explain Service-Level agreements (SLAs) & entitlements`,
outline_heading_8:`Examine knowledge management in Dynamics 365 Customer Service & related apps`,
outline_detail_8:`• Explain Knowledge Management 
• Explain Omnichannel for Customer service
• Explain the Customer Service Historical Analytic Reports`,
outline_heading_9:`Explore Dynamics 365 Field Service`,
outline_detail_9:`• Explain field service 
• Examine the lifecycle of a work order 
• Explain inspections`,
outline_heading_10:`Explain the scheduling process`,
outline_detail_10:`• Explain the scheduling capabilities available in Dynamics 365 Field Service
• Schedule qualified resources 
• Explain the asset management skills available 
• Explain the link between Customer Assets & Connected Field Service`,
skill_1:`• Explain Dynamics 365 Customer Service (15-20%)`,
skill_2:`• Explain Dynamics 365 Field Service (15-20%)`,
skill_3:`• Explain shared features (20-25%)`,
skill_4:`• Explain Dynamics 365 Sales (15-20%)`,
skill_5:`• Explain Dynamics 365 Marketing (15-20%)`,
skill_6:`• Service Management with Dynamics 365`,
course_faq_name:`MB-910T00 Microsoft Dynamics 365 Fundamentals (CRM)`,
faq_question_1:`Q: What is dynamic o365?`,
faq_answer_1:`A: Dynamics 365 is a bundle of intelligent business software that mainly helps you run your complete organization & deliver more substantial results with the use of predictive, AI-driven insights.`,
faq_question_2:`Q: What must you offer for the first setup of dynamics 365 Marketing?`,
faq_answer_2:`A: To start the setup wizard, you must complete all the following requirements: You must already have a Microsoft 365 tenant. Presence of an unconfigured Dynamics 365 Marketing app for users. A security role (like Global admin or Service support admin) that essentially allows you to modify the target Dynamics 365 instance.`,
faq_question_3:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_3:`A: To attend the training program, you should have functioning Desktops or Laptops with the required specification and a good internet connection to access the labs. 

Participants can easily access these laboratories over Cloud with the use of a remote desktop connection. 
Digipaath virtual laboratories allow you to learn from anywhere in the world & in any time zone.`,
faq_question_4:`Q: What are CRM technologies?`,
faq_answer_4:`A: Customer relationship management (CRM) is essentially a system used for managing all your company's relationships as well as interactions with customers & future customers. A CRM system especially helps firms stay connected to clients, streamline procedures, & also to boost profitability.`,
faq_question_5:`Q : What is Microsoft Dynamics 365 utilized for ?`,
faq_answer_5:`A: Microsoft Dynamics 365 is a platform for cloud-based business applications that integrates productivity software, artificial intelligence capabilities, and components of enterprise resource planning (ERP) and customer relationship management (CRM).`,
},


{
    id:129,
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`PL-100T00: Microsoft Power Platform App Maker`,
course_overview:`This program will teach you how to use the Microsoft Power Platform to construct apps utilizing low-code building strategies to expedite, automate, and transform corporate functions and processes. A preview 1-day Applied Workshop may be included in this course. By building a complete solution to a problem for a hypothetical organization, you will be able to exercise your App Maker skills in this class. The solution will combine a Power Apps canvas app, a Microsoft Dataverse database, and Power Automate flows.


When the App Maker has significant understanding of the solution business area, they design solutions to expedite, automate, and change tasks and processes for themselves and their team. They possess core abilities in requirements analysis, process analysis, user experience design, and data modeling. The App Maker Makes & enforces business processes, arranges the digital collection of information, enhances the efficiency of repeatable tasks, & automates business procedures.`,
certification:`The applicant for this exam envisions & Makes business solutions to simplify & automate duties & procedures for themselves & their team. The knowledge of the data they are dealing with, the difficulties they need to handle, & the essential techniques & app experiences. Even if the individual lacks formal IT knowledge, they are comfortable using technology to handle business difficulties.

The candidate is self-directed & focused on solving difficulties professionals meet as part of their primary job tasks. They can connect to and use features of apps like Microsoft Teams, Microsoft Power Platform, and Microsoft 365 apps & services, & third-party solutions. The candidate has a desire to the knowledge of the capabilities & limitations of various tools & comprehension of how to apply them.`,
prerequisite:`● Basic data modeling, requirements analysis, user experience design, and process Analysis skills.
● A personal growth mentality & are comfortable using technology to address business problems.
● Knowledge of the operational need & having a vision of the intended outcome. They approach
● Problems with phased & iterative techniques.`,
outline_heading_1:`Introduction to Microsoft Power Platform`,
outline_detail_1:`● Acquire understanding of the components & functions of Microsoft Power Platform
● Find when to employ each Microsoft Power Platform component application to Make business solutions
● Knowledge of connectors
● Acquire knowledge about the advantages of using Microsoft Power Platform to Make business solutions`,
outline_heading_2:`How to Create your first model-driven app with Dataverse`,
outline_detail_2:`● Discover the value & important aspects of Dataverse.
● Acquire knowledge of the matter & important characteristics of model-driven apps.
● Explore sample model-driven template apps.`,
outline_heading_3:`Make tables in Dataverse`,
outline_detail_3:`● Make tables with Dataverse
● Import data into a Dataverse database`,
outline_heading_4:`Get started with model-driven apps in Power Apps`,
outline_detail_4:`● Acquire knowledge about model-driven app design
● Make a model-driven app`,
outline_heading_5:`Make & manipulate columns within a table in Dataverse`,
outline_detail_5:`● Acquire knowledge of what a column is in Dataverse.
● Acquire understanding of the types of columns that are available in Dataverse.
● Add a column to a table.
● Acquire a knowledge of what a primary name column is in Dataverse.
● Identify constraints that are connected with columns.
● Make an auto-numbering column.
● Make an alternate key.`,
outline_heading_6:`Get started with security roles in Dataverse`,
outline_detail_6:`● Acquire knowledge of security roles & apply them to users in an environment.
● Acquire knowledge on how to add users to a domain.
● Understanding of security concepts in Dataverse
● Identify default security roles.
● Make a custom role.
● Make a custom security role & assign it to entities & users.
● Acquire knowledge on how to configure Dataverse teams for security
● Acquire understanding on how to configure Dataverse group teams for security`,
outline_heading_7:`Document & test your Power Apps application`,
outline_detail_7:`● Acquire understanding of about the many types of test plans & components of an effective test plan
● Identify & discuss optimization tools & performance tweaks
● Acquire understanding about the benefits of documenting your application`,
outline_heading_8:`Get started with Microsoft Dataverse for Teams`,
outline_detail_8:`● Acquire understanding on how to design apps, workflows, chatbots, & dashboards.
● Acquire understanding of how to setup Dataverse for Teams & then Make a table for your data.`,
outline_heading_9:`Build your first app with Power Apps & Dataverse for Teams`,
outline_detail_9:`● Make an app by utilizing the hero template.
● Customize & publish your app.
● Install templates.`,
outline_heading_10:`Navigation in a canvas app in Power Apps`,
outline_detail_10:`● Knowledge of how navigation works in a canvas app
● Use the Navigate & Back functionalities
● Knowledge of the numerous ways these functions can be invoked`,
outline_heading_11:`How to construct the UI in a canvas app with Power Apps`,
outline_detail_11:`● Knowledge of the basics of constructing the UI through themes, icons, control modification, & pictures.
● Use personalization in a canvas app
● Understanding of the distinctions between the Tablet & Mobile form factors`,
outline_heading_12:`Use & knowledge of Controls in a canvas app in Power Apps`,
outline_detail_12:`● Knowledge of how to use controls in a canvas app
● Use the different sorts of controls
● Understanding of how Galleries & Forms related to controls`,
outline_heading_13:`Get started with Power Automate`,
outline_detail_13:`● Make a flow that automatically saves email attachments
● Acquire understanding about how to Make a button flow to send yourself a reminder`,
outline_heading_14:`Use the Admin center to manage environments & data policies in Power Automate`,
outline_detail_14:`● Acquire knowledge on regarding the Admin center
● Acquire understanding on how to import & export flows
● Acquire understanding about how to exchange flows`,
outline_heading_15:`Introduction to Microsoft Power Platform security & governance`,
outline_detail_15:`● Access Microsoft Power Platform admin experiences.
● Make simple environment & tenant-wide DLP policies.
● Discover & discover the distinctions between the Business data group & the No-business data group.
● Acquire awareness of the distinctions between out-of-the-box Power Automate Admin experiences & Microsoft Power Platform Center of Excellence toolkit.`,
outline_heading_16:`Build your first workflow using Power Automate & Dataverse for Teams`,
outline_detail_16:`● Create a flow to respond to changes in Teams.
● Extend an app in Microsoft Power Apps to activate a flow.
● Schedule a flow to complete routine chores.`,
outline_heading_17:`Get started developing using Power BI`,
outline_detail_17:`● Acquire knowledge of how Power BI services & applications operate together.
● Discover how Power BI can make your business more efficient.
● Acquire understanding about how to Make attractive visuals & reports.`,
outline_heading_18:`Get data using Power BI Desktop`,
outline_detail_18:`● Explore the data-centric features & tools of Power BI.
● Explore techniques to find data.`,
outline_heading_19:`● Import a Microsoft Dataverse dataset into Power BI.
● Make a new Power BI report based on the dataset.
● Edit a report.
● Share a message with others in Teams channels, chats, or meetings`,
outline_detail_19:`● Discover how AI Builder may help you boost the effectiveness of your business.
● Acquire understanding about how to leverage different AI technologies to enhance your processes.
● Make your first model.
● Acquire understanding about how to use your models in Power Apps & Power Automate`,
outline_heading_20:`Make a chatbot using Power Virtual Agents & Dataverse for Teams`,
outline_detail_20:`● Acquire knowledge about how to make a chatbot.
● Discover how the chatbot may call Microsoft Power Automate tasks to retrieve data from Dataverse for Teams.
● Publish the chatbot to your team or the complete company.`,
skill_1:`● Workflow Automation`,
skill_2:`● Design business solutions (20–25%)`,
skill_3:`● Analyze & visualize data (10–15%)`,
skill_4:`● Make business solutions (60–65%)`,
skill_5:`● Power BI Reporting`,
skill_6:`●Workflow Automation`,
course_faq_name:`PL-100T00 Microsoft Power Platform App Maker`,
faq_question_1:`What is Microsoft app Maker?`,
faq_answer_1:`The App Maker makes & enforces business procedures, arranges the digital collection of information, enhances the efficiency of repeatable actions, & automates business processes. The Power Platform's Maker features are used by App Maker to handle business concerns.`,
faq_question_2:`What is a Power platform maker?`,
faq_answer_2:`Software for corporate information, app development, and app connectivity is provided under the Microsoft Power Platform brand. For expressing logic across the Power Platform, Microsoft designed the low-code programming language Power Fx. Furthermore, it includes GitHub and Teams connectivity.`,
faq_question_3:`What is the difference between PowerApps & Power Platforms?`,
faq_answer_3:`The PowerApps is a platform for generating applications that is a part of the wider Microsoft Power Platform and whose capabilities are built on and leverage Azure cloud services. They are all natively developed on the platform, including Dynamics 365 for Sales, Service, Field Service, Marketing, and Talent.`,
faq_question_4:`What is Microsoft Power Platform used for?`,
faq_answer_4:`It combines low-code application development, workflow automation, AI bot development, & data analytics with broad connection through Microsoft Dataverse—all built to work with the secure, trusted Microsoft cloud services like Azure, Dynamics 365, & Microsoft 365`,
faq_question_5:`Q: What is the infrastructure required to attend your training program?`,
faq_answer_5:`A: To attend the training program, you should have functioning desktops or laptops with the required specification and a good internet connection to access the labs.`,
},



//aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS //aws// AWS // AWS 

{
    id:150,
categories: `AWS`,
course_detail_image:course_images.aws_1,
course_name:`Advanced Architecting on AWS`,
course_overview: `In this course, each module illustrates a situation with an architectural issue to be solved. Professionals will analyze available AWS services & features as solutions to the challenge. Professionals will get insights by engaging in problem-based discussions & learning about the AWS services that professionals could utilize to meet the difficulties. Over three days, the training goes beyond the foundations of a cloud infrastructure & covers subjects to fulfill varied needs of AWS clients. Course modules emphasize managing multiple AWS accounts, hybrid connectivity & devices, networking with an emphasis on AWS Transit Gateway connectivity, automation tools for continuous integration/continuous delivery (CI/CD), security & distributed denial of service (DDoS) protection, data lakes & data stores, edge services, container services, migration options, & managing costs. The course closes by presenting professionals with scenarios & challenging them to choose the best answers.`,
certification:`Earn your Advanced Architecting on AWS certification and demonstrate mastery in designing scalable, cost-efficient, and secure AWS architectures. Validate your expertise in advanced cloud solutions, including multi-tier applications, high availability, and fault tolerance. Distinguish yourself as a trusted AWS professional capable of architecting complex systems to meet the evolving needs of modern businesses.`,
prerequisite:`We recommend that registrants of this course have-

● Knowledge & experience with fundamental AWS services from the Compute, Storage, Networking, & 

● AWS Identity & Access Management (IAM) categories

● Accomplished the Architecting on AWS classroom training OR

● Accomplished the AWS Certified Solutions Architect – Associate certification OR

● Have at least one year of experience operating AWS workloads

`,
outline_heading_1:`Reviewing Architecting Concepts`,
outline_heading_2:` Single to Multiple Accounts`,
outline_heading_3:` Hybrid Connectivity`,
outline_heading_4:`Specialized Infrastructur `,
outline_heading_5:`Connecting Networks`,
outline_heading_6:`Containers`,
outline_heading_7:`Continuous Integration/Continuous Delivery (CI/CD)`,
outline_heading_8:`High Availability & DDoS Protection`,
outline_heading_9:`Securing Data`,
Outline_heading_10:`Large Scale Data Stores`,
Outline_heading_11:`Large-Scale Applications`,
Outline_heading_12:`Optimizing Cost`,
Outline_heading_13:`Migrating Workloads`,
Outline_heading_14:`Capstone Project`,


outline_detail_1:`● Group Exercise- Review Architecting on AWS basic best practices

● Lab 1- Securing Amazon S3 VPC Endpoint Communications`,
outline_detail_2:`● AWS Organizations for multi-account access & permissions

● AWS SSO to ease access & authentication across AWS accounts & third-party services

● AWS Control Tower

● Permissions, access, & authentication `,
outline_detail_3:`● AWS Client VPN authentication & control

● AWS Site-to-Site VPN

● AWS Direct Connect for hybrid public & private connections

● Increasing bandwidth & decreasing cost

● Basic, high, & maximal resilience

● Amazon Route 53 Resolver DNS resolution`,
outline_detail_4:`● AWS Storage Gateway solutions

● On-demand VMware Cloud on AWS

● Extending cloud infrastructure services with AWS Outposts

● AWS Local Zones for latency-sensitive workloads

● Your 5G network with & without AWS Wavelength`,
outline_detail_5:`● Simplifying private subnet connections

● VPC isolation with shared services VPC

● Transit Gateway Network Manager & VPC Reachability Analyzer

● AWS Resource Access Manager

● AWS PrivateLink & endpoint services

● Lab 2- Configuring Transit Gateways`,
outline_detail_6:`● Container solutions compared to virtual computers

● Docker benefits, components, solutions architecture, & versioning

● Container hosting on AWS to cut cost

● Managed container services- Amazon Elastic Container Service (Amazon ECS) & Amazon 

● Elastic Kubernetes Service (Amazon EKS)

● AWS Fagate

● Lab 3- Deploying an Application with Amazon EKS on Fargate`,
outline_detail_7:`
● CI/CD solutions & Impact

● CI/CD automation using AWS CodePipeline

● Deployment models

● AWS CloudFormation StackSets to improve deployment management`,
outline_detail_8:` Common DDoS assaults tiers

● AWS WAF 

● AWS WAF web access regulates lists (ACLs), real-time logs, metrics, & security automation

● AWS Shield Advanced services & AWS DDoS Response Team (DRT) services

● AWS Network Firewall & AWS Firewall Manager to secure accounts at scale`,
outline_detail_9:`● What cryptography is, why pros would use it, & how to utilize it

● AWS KMS

● AWS CloudHSM architecture

● FIPS 140-2 Level 2 & Level 3 encryption

● Secrets Manager`,
outline_detail_10:`● Amazon S3 data storage management, including storage class, analytics, inventory, & Policies

● Data lake vs. data warehouse- Differences, benefits, & examples

● AWS Lake Formation solutions, security, & control

● Lab 4- Setting Up a Data Lake with Lake Formation`,
outline_detail_11:`● What edge services are & why professionals would utilize them

● Improve performance & mitigate risk with Amazon CloudFront

● Lambda@Edge

● AWS Global Accelerator- IP addresses, intelligent traffic allocation, & health checks

● Lab 5- Migrating an On-Premises NFS Share Using AWS DataSync & Storage Gateway`,
outline_detail_12:`● On-premises & cloud acquisition/deprecation cycles

● Cloud cost management tools, including reporting, control, & tagging

● Examples & analysis of the five pillars of cost optimization
`,
outline_detail_13:`● Business drivers & the procedure for migration

● Successful customer practices

● The 7 Rs to migrate & modernize

● Migration tools & services from AWS

● Migrating databases & huge data stores

● AWS Schema Conversion Tool (AWS SCT)`,
outline_detail_14:`● Use Online Course Supplement (OCS) to explore use cases, examine data, & answer 

● architecting design queries concerning Transit Gateway, hybrid connectivity, migration, & cost optimization`,

skill_1:`Cloud Infrastructure Design`,
skill_2:`Scalability Planning`,
skill_3:`Cost Optimization Strategies`,
skill_4:`Advanced Networking Configuration`,
skill_5:`Security Architecture Implementation`,
skill_6:`High Availability Solutions`,
course_faq_name:`Advanced Architecting on AWS`,
faq_question_1:`Q- What is architecting on AWS?`,
faq_question_2:`Q- What is a 3-tier architecture AWS?`,
faq_question_3:`Q- What are the various techniques of data storage in AWS?`,
faq_question_4:`What is the use of the ec2 instance?`,
faq_question_5:`Q- What is the role of AWS security?`,
faq_answer_1:`Ans- This course focuses on the fundamentals of developing IT infrastructure on the AWS platform. Professionals will learn how to maximize the AWS Cloud by understanding AWS services & how they fit into cloud-based solutions.`,
faq_answer_2:`Ans- The three-tier architecture is the most prevalent implementation of a multi-tier architecture & comprises of a single presentation tier, logic tier, & data tier.`,
faq_answer_3:`There are three techniques of data storage, namely: –

§ Object storage

§ File storage

§ Block storage`,
faq_answer_4:` Ans- EC2 is a serice that lets corporate clients to run application applications in the computing environment.`,
faq_answer_5:` Ans- AWS security provides chances to protect the data, check out security-related behavior & receive automatic responses.`,
},




{
    id:151,
categories: ` AWS `,
course_detail_image:course_images.aws_2,
course_name:`Architecting On AWS`,
course_overview:`Architecting on AWS is for solution-design engineers, solutions architects, solution-design engineers, & developers searching for a grasp of AWS architecting. In this course, professionals will learn to discover services & features to construct durable, secure, & highly available IT solutions on the AWS Cloud. Architectural solutions change depending on industry, types of applications, & business size. AWS Authorized Instructors stress optimum practices using the AWS Well-Architected Framework, & lead professionals through the process of developing perfect IT solutions based on real-life scenarios. The modules stress on networking, account security, computation, databases, storage, monitoring, automation, containers, serverless architecture, edge services, & backup & recovery. At the end of the course, professionals will practice designing a solution & apply what they have learned.`,
certification:`
Earn your Architecting on AWS certification to demonstrate expertise in designing and deploying scalable, secure, and fault-tolerant systems on the Amazon Web Services platform. Validate your ability to architect solutions that meet customer requirements and maximize the value of AWS services. Stand out in the cloud computing field with this prestigious certification.`,
prerequisite:`We recommend that attendees of this course have- 
● Completed AWS Cloud Practitioner Essentials, or AWS Technical Essentials 
● Working knowledge of distributed systems 
● Familiarity with general networking concepts 
● Familiarity with IP addressing 
● Working knowledge of multi-tier architectures 
● Familiarity with cloud computing concepts`,
outline_heading_1:`Module 1- Architecting Fundamentals `,
outline_heading_2:`Module 2- Account Security`,
outline_heading_3:`Module 3- Networking 1 `,
outline_heading_4:`Module 4- Compute `,
outline_heading_5:`
Module 5- Storage`,
outline_heading_6:`Module 6- Database Services`,
outline_heading_7:`Module 7- Monitoring & Scaling 
`,
outline_heading_8:`Module 8- Automation `,
outline_heading_9:`Module 9- Containers `,
Outline_heading_10:`Module 10- Networking `,
Outline_heading_11:`Module 11- Serverless`,
Outline_heading_12:`Module 12- Edge Services `,
Outline_heading_13:`Module 13- Backup & Recovery`,

outline_detail_1:`• AWS services 
• AWS infrastructure
 • AWS Well-Architected Framework 
• Hands-on lab: Explore & interact with the AWS Management Console & AWS Command Line Interface`,
outline_detail_2:` • Principals & identities 
• Security policies 
• Managing numerous accounts`,
outline_detail_3:`• IP addressing 
• VPC fundamentals
 • VPC traffic security`,
outline_detail_4:`• Compute services 
• EC2 instances 
• Storage for EC2 instances
• Amazon EC2 pricing options
• AWS Lambda 
• Hands-On Lab: Build your Amazon VPC infrastructure`,
outline_detail_5:` • Storage services 
• Amazon S3 
• Shared file systems 
• Data migration tools`,
outline_detail_6:`Module 6- Database Services
 • Database services 
• Amazon RDS
 • Amazon DynamoDB 
• Database caching 
• Database migration tools
• Hands-on Lab: Create a database layer in your Amazon VPC infrastructure`,
outline_detail_7:`• Monitoring 
• Alarms & events 
• Load balancing 
• Autoscaling 
• Hands-on Lab: Configure high availability in your Amazon VPC• AWS CloudFormation 
• Infrastructure management`,
outline_detail_8:`• Microservices 
• Containers 
• Container services`,
outline_detail_9:`• Microservices 
• Containers 
• Container services`,
Outline_detail_10:`2 • VPC endpoints
 • VPC peering 
• Hybrid networking
 • AWS Transit Gateway`,
outline_detail_11:`• What is serverless?
• Amazon API Gateway
 • Amazon SQS 
• Amazon SNS
 • Amazon Kinesis
 • AWS Step Functions
• Hands-on Lab: Build a serverless architecture`,

Outline_detail_12:`• Edge fundamentals
 • Amazon Route 53
 • Amazon CloudFront
 • DDoS protection 
• AWS Outposts 
• Hands-On Lab: Configure an Amazon CloudFront distribution with an Amazon S3 origin`,
outline_detail_13:`• Disaster planning
 • AWS Backup
 • Recovery strategies 
• Hands-on Lab: Capstone lab — Build an AWS Multi-Tier architecture. Participants evaluate the concepts & services covered in class & design a solution based on a scenario. The lab environment delivers incomplete solutions to foster analysis & reflection. Participants deploy a highly available architecture. The instructor is accessible for consultation.`,

skill_1:` Cloud Infrastructure Design`,
skill_2:`Scalability Planning`,
skill_3:`Security Architecture`,
skill_4:`AWS Service Integration`,
skill_5:`Cost Optimization Strategies`,
skill_6:`High Availability Planning`,
course_faq_name:`Architecting On AWS `,
faq_question_1:`Q- What is architecting on AWS?`,
faq_question_2:`Q- What is a 3 tier architecture AWS?`,
faq_question_3:`Q- What are the various techniques of data storage in AWS?`,
faq_question_4:` Q- What is the use of ec2 instances?`,
faq_question_5:`Q- What is the role of AWS security?`,
faq_answer_1:`Ans- This course focuses on the fundamentals of developing IT infrastructure on the AWS platform. You will learn how to maximize the AWS Cloud by studying AWS services & how they integrate into cloud-based solutions.`,
faq_answer_2:`Ans- The three-tier architecture is the most prevalent implementation of a multi-tier architecture & comprises of a single presentation tier, logic tier, & data tier.`,
faq_answer_3:` Ans- There are three methods of data storages namely: –
§ Object storage
§ File storage
§ Block storage`,
faq_answer_4:`
 Ans- EC2 is a service that lets corporate clients to run application applications in the computing environment.  `,
faq_answer_5:` Ans- AWS security provides the chances to protect the data, check out security-related behavior & obtain automatic responses.`,
},


{
    id:152,
categories: `AWS`,
course_detail_image:course_images.aws_3,
course_name:`Architecting on AWS accelerator`,
course_overview:`Architecting on AWS - Accelerator is created for audiences who can absorb & understand new knowledge at a rapid speed. This course covers several areas of how to architect for the cloud across five days. It covers information from the Architecting on AWS & Advanced Architecting on AWS courses to give a streamlined education in cloud architecture. Architectural solutions change depending on industry, business size, and types of applications. AWS Authorized Instructors focuses best practices utilizing the AWS Well- Architected Framework & walks you through the process of developing excellent IT solutions based on real- world circumstances. The modules emphasize on computation, database, networking, security, monitoring, automation, storage, containers, serverless architecture, edge services, and backup and recovery. They also highlight optimization, the virtues of loose coupling applications and serverless elements, developing for robustness, and recognizing expenses. Utilizing hands-on laboratories, you will use knowledge from lectures to increase abilities.`,
certification:`
Achieve mastery in architecting on AWS with our intensive Accelerator program. Gain hands-on experience and expert guidance to design scalable, resilient, and cost-effective solutions on the AWS platform. Enhance your skills in cloud architecture, optimization, and security practices. Elevate your career with our comprehensive certification program. Join us and become a certified Architecting on AWS professional in just 60 days.`,
prerequisite:`We recommend that registrants of this course have-

● Not attended the Architecting on AWS course

● Familiarity with AWS cloud computing, which can be studied in AWS Cloud Practitioner Essentials or

● AWS Technical Essentials

● Familiarity with TCP/IP networking principles such as routing, VPNs, segments, subnets/gateways, and user permissions

● Familiarity with and understanding of multi-tier designs and distributed systems`,
outline_heading_1:`Module 1- Architecting Fundamentals`,
outline_heading_2:`Module 2- Account Security`,
outline_heading_3:`Module 3- Networking 1`,
outline_heading_4:`Module 4- Compute`,
outline_heading_5:`Module 5- Storage`,
outline_heading_6:`Module 6- Database Services`,
outline_heading_7:`Module 7- Monitoring and Scaling`,
outline_heading_8:`Module 8- Automation`,
outline_heading_9:`Module 9- Containers`,
Outline_heading_10:`Module 10- Networking 2`,
outline_heading_11:`Module 11- Serverless`,
outline_heading_12:`Module 12- Edge Services`,
outline_heading_13:`Module 13- Backup and Recovery`,
outline_heading_14:`Module 14- Single to Multiple Accounts`,
outline_heading_15:`Module 15- Hybrid Connectivity`,
outline_heading_16:`Module 16- Securing Data`,
outline_heading_17:`Module 17- Connecting Networks`,
outline_heading_18:`Module 18- High Availability and DDoS`,
outline_heading_19:`Module 19- Migrating Workloads`,
outline_heading_20:`Module 20- Optimizing Cost`,

outline_detail_1:`● AWS services

● AWS infrastructure

● AWS Well-Architected Framework

● Hands-On Lab- Explore and interact with the AWS Management Console and AWS Command Line Interface
`,
outline_detail_2:`
● Principals and identities

● Security policies

● Managing several accounts
`,
outline_detail_3:`
● IP addressing

● VPC fundamentals

● VPC traffic security`,
outline_detail_4:`● Compute services

● EC2 instances

● Storage for EC2 instances

● Amazon EC2 price possibilities

● AWS Lambda

● Hands-On Lab- Build your Amazon VPC infrastructure`,
outline_detail_5:`● Storage services

● Amazon S3

● Shared file systems

● Data migration tools`,
outline_detail_6:`
● Database services

● Amazon RDS

● Amazon DynamoDB

● Database caching

● Database migration tools

● Hands-on Lab- Create a database layer in your Amazon VPC infrastructure`,
outline_detail_7:`● Monitoring

● Alarms and events

● Load balancing

● Auto scaling

● Hands-on Lab- Configure high availability in your Amazon VPC`,
outline_detail_8:`● AWS CloudFormation

● Infrastructure management`,
outline_detail_9:`
● Microservices

● Containers

● Container services
`,
Outline_detail_10:`● VPC endpoints

● VPC peering

● Hybrid networking

● AWS Transit Gateway`,
outline_detail_11:`● Disaster planning

● AWS Backup

● Recovery techniques

● Hands-on Lab- Build an AWS multi-tier architecture
`,
outline_detail_12:`● Disaster planning

● AWS Backup

● Recovery techniques

● Hands-on Lab- Build an AWS multi-tier architecture
`,
outline_detail_13:`● Disaster planning

● AWS Backup

● Recovery techniques

● Hands-on Lab- Build an AWS multi-tier architecture`,

outline_detail_14:`● Multi-account techniques

● AWS SSO

● AWS Control Tower`,
outline_detail_15:`● AWS Client VPN

● AWS Site-to-Site VPN

● AWS Direct Connect

● Amazon Route 53 Resolver

● Hands-on Lab- Securing Amazon S3 VPC endpoint communications

`,
outline_detail_16:`● Cryptography

● AWS KMS

● AWS CloudHSM

● AWS Secrets Manager
`,
outline_detail_17:`● AWS Transit Gateway

● AWS Resource Access Manager

● AWS PrivateLink

● Hands-On Lab- Configuring AWS transport gateways`,
outline_detail_18:`● AWS WAF

● AWS Shield Advanced

● AWS Firewall Manager`,
outline_detail_19:`● Migration procedure

● Migration tools

● Migrating databases

● Hands-on Lab- Migrating an on-premises NFS share using DataSync and Storage Gateway`,
outline_detail_20:`● Cloud cost management

● Cost management tools

● Cost optimization`,

skill_1:`Cloud Infrastructure Design`,
skill_2:`Scalability Planning`,
skill_3:`Security Architecture`,
skill_4:`Cost Optimization Strategies`,
skill_5:`High Availability Solutions`,
skill_6:`Automation and Orchestration`,
course_faq_name:`Architecting on AWS accelerator`,
faq_question_1:`Q- What is architecting a solution?`,
faq_question_2:`
Q- What is terraforming in AWS?`,
faq_question_3:` Q- What are the various techniques of data storage in AWS?`,
faq_question_4:` Q- What is the use of ec2 instances?`,
faq_question_5:`Q- What is the role of AWS security?`,
faq_answer_1:`Ans- A solutions architect crafts the overarching technical vision for a particular solution to a business challenge. A solutions architect makes the overarching technical vision for a particular solution to a business challenge. They describe, design, and manage the solution.`,
faq_answer_2:`
 Ans- There are three methods of data storages namely –

§ Object storage

§ File storage

§ Block storage`,
faq_answer_3:`
 Ans- There are three methods of data storages namely –

§ Object storage

§ File storage

§ Block storage`,
faq_answer_4:`
 Ans- EC2 is a service that lets corporate clients to run application applications in the computing environment.`,
faq_answer_5:`Ans- AWS security provides the opportunities to protect the data, check out security-related behavior and obtain automatic answers.`,
},



{
    id:153,
categories: `AWS`,
course_detail_image:course_images.aws_1,
course_name:`Running containers on amazon elastic Kubernetes service`,
course_overview:`Amazon EKS makes it simple for professionals to run Kubernetes on AWS without forcing them to install, operate, & maintain their own Kubernetes control plane. In this course, professionals will study container management & orchestration for Kubernetes using Amazon EKS.
Professionals will construct an Amazon EKS cluster, configure the infrastructure, deploy the cluster, & then attach applications to their collection. Professionals will manage container images using Amazon Elastic Container Registry (ECR) & learn how to automate application deployment. Professionals will deploy applications utilizing CI/CD tools. Professionals will learn how to monitor & scale their environment by using metrics, logging, tracing, & horizontal/vertical scaling. Professionals will learn how to build & manage a large container environment by creating efficiency, cost, & resiliency. Professionals will configure AWS networking services to support the cluster & learn how to safeguard their Amazon EKS environment.
Course Level- Intermediate`,
certification:`Demonstrate proficiency in deploying and managing containers on Amazon EKS. Validate skills in configuring Kubernetes clusters, deploying applications, and scaling resources effectively. Gain expertise in utilizing EKS features for high availability, security, and efficient container orchestration. This certification verifies competency in optimizing containerized workloads on AWS infrastructure.`,
prerequisite:`We recommend that registrants of this course have- 
• Completed Introduction to Containers 
• Completed Amazon Elastic Kubernetes Service (EKS) Primer 
• Completed AWS Cloud Practitioner Essentials (or comparable real-world experience)
• Basic Linux administration experience 
• Basic network administration experience 
• Basic knowledge of containers & microservices`,
outline_heading_1:`Module 1- Kubernetes Fundamentals `,
outline_heading_2:`Module 2- Amazon EKS Fundamentals `,
outline_heading_3:`Module 3- Building an Amazon EKS Cluster`,
outline_heading_4:`Module 4- Deploying Applications to Your Amazon EKS Cluster `,
outline_heading_5:`Module 5- Configuring Observability in Amazon EKS`,
outline_heading_6:`Module 6- Balancing Efficiency, Resilience, & Cost Optimization in Amazon EKS `,
outline_heading_7:`• Module 7- Managing Networking in Amazon EKS`,
outline_heading_8:`Module 8- Managing Authentication & Authorization in Amazon EKS`,
outline_heading_9:`Module 9- Implementing Secure Workflows`,
outline_heading_10:`Module 10- Managing Upgrades with Amazon EKS`,
outline_detail_1:`● Container orchestration 
● Kubernetes objects 
● Kubernetes internals`,
outline_detail_2:`● Introduction to Amazon EKS 
● Amazon EKS control plane
 ● Amazon EKS data plane 
● Fundamentals of Amazon EKS security 
● Amazon EKS API`,
outline_detail_3:`
 ● Configuring your environment 
● Creating an Amazon EKS cluster 
● Demo- Deploying a cluster 
● Deploying worker nodes 
● Demo- Completing a cluster configuration 
● Preparing for Lab 1- Building an Amazon EKS Cluster`,
outline_detail_4:`• Configuring Amazon Elastic Container Registry (Amazon ECR) 
• Demo- Configuring Amazon ECR
 • Deploying applications using Helm
 • Demo- Deploying applications with Helm
 • Continuous deployment in Amazon EKS 
• GitOps & Amazon EKS
 • Preparing for Lab 2- Deploying Applications`,
outline_detail_5:` • Configuring observability in an Amazon EKS cluster
 • Collecting metrics 
• Using metrics for automatic scaling
 • Managing logs 
• Application tracing in Amazon EKS
 • Gaining & applying insight from observability
 • Preparing for Lab 3- Monitoring Amazon EKS`,
outline_detail_6:`• The high-level overviews 
• Designing for resilience
 • Designing for cost optimization
 • Designing for efficiency `,
outline_detail_7:`• Review- Networking in AWS
 • Communicating in Amazon EKS
 • Managing your IP space
• Deploying a service mesh
 • Preparing for Lab 4- Exploring Amazon EKS Communication`,
outline_detail_8:` • Understanding the AWS shared responsibility model
 • Authentication & authorization
 • Managing IAM & RBAC
 • Demo- Customizing RBAC roles
 • Managing pod permissions using RBAC service accounts`,
outline_detail_9:` • Securing cluster endpoint access
 • Improving the security of your workflows
 • Improving host & network security 
• Managing secrets
 • Preparing for Lab 5- Securing Amazon EKS`,
outline_detail_10:`• Planning for an upgrade
 • Upgrading your Kubernetes version
 • Amazon EKS platform versions`,
skill_1:`Container Orchestration`,
skill_2:`AWS Management`,
skill_3:`Kubernetes Deployment`,
skill_4:`Kubernetes Deployment`,
skill_5:`Scalability Management`,
skill_6:`Troubleshooting and Debugging`,
course_faq_name:`FAQs`,
faq_question_1:`Q- Can Kubernetes run on ECS?`,
faq_question_2:`Q- What is the difference between ECS & EKS?`,
faq_question_3:`Q- What is the AWS data lake?`,
faq_question_4:` Q- What are the various techniques of data storage in AWS?`,
faq_question_5:` Q- What is the use of the ec2 instance?`,
faq_answer_1:`Ans- The ECS agent running on an EC2 host simulates what a kubelet performs in a Kubernetes node. Since ECS is an AWS-managed service, it's strongly connected with numerous AWS services such as CloudWatch, ALB, VPC, & others. ECS works well with Fargate, the serverless container platform from AWS.`,
faq_answer_2:`Ans- ECS is a scalable container orchestration technology that allows users to run, stop, & manage containers in a cluster. EKS, on the other hand, allows companies establish Kubernetes clusters on AWS without manually installing Kubernetes on EC2 compute instances.`,
faq_answer_3:` Ans- A data lake is a centralized & secured repository that contains all your data, both in its original form & prepared for analysis.`,
faq_answer_4:` Ans- There are three methods of data storage, namely- ▪ Object storage ▪ File storage ▪ Block storage`,
faq_answer_5:` Ans- EC2 is a service that lets corporate clients to run application applications in the computing environment.`,
},




{
    id:154,
categories: `AWS`,
course_detail_image:course_images.aws_1,
course_name:`AWS cloud for finance professionals`,
course_overview:`In this course, professionals will grasp how to fund persons who can utilize Amazon Web Services (AWS) to use the cloud in a fiscally appropriate manner. Professionals will receive foundational information to help professionals manage, optimize, & plan cloud spend. Professionals will learn how to push their organization’s builders to be more accountable & cost conscious. Finally, professionals will discuss how professionals might leverage AWS to innovate in their finance organization.
`,
certification:`Certification in AWS Cloud for finance professionals equips you with essential skills to optimize financial operations, enhance security, and drive innovation. Learn to leverage AWS services tailored for finance, including data analytics, machine learning, and compliance tools. Gain expertise in cost management, scalability, and resilient architectures. Achieve this certification to elevate your career in finance with cutting-edge cloud technology.`,
prerequisite:`We recommend that registrants of this course have
● Cloud Computing & AWS from the digital version of AWS Cloud for Finance Professionals
● AWS Cloud Practitioner Essentials 
● AWS Cloud Essentials for Business Leaders`,
outline_heading_1:`Module 1- Introduction `,
outline_heading_2:`Module 2- Planning & Forecasting `,
outline_heading_3:`Module 3- Measurement & Accountability `,
outline_heading_4:`Module 4- Cost Optimization`,
outline_heading_5:`Module 5- Cloud Financial Operations`,
outline_heading_6:`Module 6- Financial Transformation & Innovation`,
outline_heading_7:`Module 7- Resources & Next Steps `,
outline_detail_1:`● Cloud spending decisions 
● AWS pricing
 ● Cost drivers 
● AWS Well-Architected Framework 
● AWS Cloud Value Framework
Activity 1.1- Cloud value metrics 
● Cloud Financial Management
Activity 1.2- Cloud Financial Management results`,
outline_detail_2:`● Estimate cloud workload costs
Activity 2.1- Build & revise a cost estimate 
● Budget & anticipate cloud costs 
● Improve cloud financial predictability`,
outline_detail_3:`● KPIs & unit metrics 
● Cost visibility & monitoring 
● Demonstration 3.1- Tools for cost visibility, tools for cost monitoring 
● Cost allocation & accountability 
● Cost allocation building blocks`,
outline_detail_4:` ● Usage optimizations 
● Commitment-based purchasing options 
● Activity 4.1- Cost optimization`,
outline_detail_5:` ● Organizational change for CFM 
● Organization models for CFM 
● Activity 5.1- Organizational models 
● Establish a cost-aware organizational culture 
● Governance, control, & agility 
● AWS governance & control building blocks
 ● Automated-based governance using AWS services`,
outline_detail_6:`● Keys to financial innovation
 ● Financial transformation Activity 6.1- Solutions for financial innovation`,
outline_detail_7:`● Module resources
 ● Next steps`,

skill_1:`AWS Cloud Cost Optimization`,
skill_2:`AWS Cloud Security Management`,
skill_3:`AWS Cloud Compliance Governance`,
skill_4:`AWS Cloud Data Analytics`,
skill_5:`AWS Cloud Disaster Recovery Planning`,
skill_6:`AWS Cloud DevOps Integration`,
course_faq_name:`FAQs`,
faq_question_1:`
 Q- What are the various techniques of data storage in AWS?`,
faq_question_2:` Q- What is the use of the ec2 instance?`,
faq_question_3:` Q- What is the role of AWS security?`,
faq_question_4:`Q: Is AWS Cloud secure for sensitive financial data?`,
faq_question_5:`Q: Can AWS Cloud help streamline financial reporting processes?`,
faq_answer_1:`Ans- There are three ways of data storage, namely-  
● Object storage 
● File storage 
● Block storage`,
faq_answer_2:` Ans- EC2 is a service that lets corporate clients to run application applications in the computing environment.`,
faq_answer_3:` Ans- AWS security provides chances to protect the data, check out security-related behavior & receive automatic responses.`,
faq_answer_4:`Answer: Yes, AWS provides robust security measures including encryption, access controls, and compliance certifications like PCI DSS and SOC to ensure the protection of financial data.`,
faq_answer_5:`Answer: Absolutely, AWS Cloud offers services like Amazon Redshift for data warehousing, Amazon QuickSight for visualization, and AWS Glue for ETL processes, enabling streamlined financial reporting and analysis.`,
},





{
id:155,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`AWS cloud financial management for builders`,
course_overview:`This course is for professionals who look for a knowledge of how to manage, optimize, & estimate expenses while professionals execute workloads on AWS. Professionals learn how to use architectural best practices, investigate cost optimization methodologies, & design patterns to assist professionals create cost-efficient solutions on AWS.`,
certification:`Earn your certification in AWS Cloud Financial Management for Builders and enhance your expertise in managing finances within AWS cloud environments. Learn how to optimize costs, monitor spending, and drive financial efficiency while leveraging AWS services. Gain the skills needed to make informed financial decisions and maximize the value of your cloud investments. Start your journey today!`,
prerequisite:`
We recommend that registrants of this course have-

● Architecting on AWS classroom training`,
outline_heading_1:`Module 1- Introduction to Cloud Financial Management`,
outline_heading_2:`Module 2- Resource Tagging`,
outline_heading_3:` Module 3- Pricing & Cost`,
outline_heading_4:`Module 4- AWS Billing, Reporting, & Monitoring`,
outline_heading_5:`Module 5- Architecting for Cost- Compute`,
outline_heading_6:`Module 6- Architecting for Cost- Networking`,
outline_heading_7:`Module 7- Architecting for Cost- Storage`,
outline_heading_8:`
Module 8- Architecting for Cost- Databases`,
outline_heading_9:`Module 9- Cost Governance`,
outline_heading_10:`
Module 10- Course Summary`,
outline_detail_1:`● Introduction to Cloud Financial Management

● Four pillars of Cloud Financial Management`,
outline_detail_2:`● Tagging resources

● Hands-On Lab- Cost optimization- Control Resource Consumption Using Tagging & AWS Configuration`,
outline_detail_3:`● Fundamentals of pricing

● AWS Free Tier

● Volume discounts

● Savings plans & Reserved Instances

● Demonstration- AWS Pricing Calculator`,
outline_detail_4:`● Understanding AWS invoices

● Reporting & planning

● AWS Cost Explorer

● AWS Budgets

● Demonstration- AWS Billing Console

● Demonstration- AWS Cost Explorer

● Demonstration- Trusted Advisor

● Hands-On Lab- Cost optimization- Deploy Ephemeral Environments Using Amazon EC2 Auto Scaling`,
outline_detail_5:`● Evolution of compute efficiency

● Amazon EC2 right-sizing

● Purchasing choices

● Architect for Amazon EC2 Spot Instance

● Impact of software licensing

● Demonstration- Compute Optimizer

● Demonstration- Spot Instance Advisor

● Hands-On Lab- Cost optimization- Right Size Amazon EC2 Instances Using Amazon CloudWatch Metrics`,
outline_detail_6:`● Data transfer expenses

● Understand data prices for different services

● How to triage network costs

● Hands-On Lab- Cost optimization- Reduce Data Transfer Costs Using Amazon CloudFront & Endpoints`,
outline_detail_7:`● Amazon EBS cost, pricing, & best practices

● Amazon S3 cost, pricing, & best practices

● Amazon EFS cost, pricing, & best practices

● Hands-On Lab- Cost optimization- Reduce Storage Costs Using Amazon S3 Lifecycle Management`,
outline_detail_8:`
● Amazon RDS cost, pricing, & best practices

● Amazon Aurora cost, pricing, & best practices

● Amazon DynamoDB cost, pricing, & recommended practices

● Amazon Elasticache cost, pricing, & best practices

● Amazon Redshift cost, pricing, & best practices`,
outline_detail_9:`● Setting up AWS Organizations

● AWS Systems Manager

● Hands-On Lab- Cost optimization- Reduce Compute Costs Using AWS Instance Scheduler`,
outline_detail_10:`● Course review`,
skill_1:`Cost Optimization`,
skill_2:`Resource Allocation`,
skill_3:`Budgeting`,
skill_4:`Forecasting`,
skill_5:`Financial Reporting`,
skill_6:`Governance`,
course_faq_name:`AWS-cloud-financial-management-for-builders`,
faq_question_1:`Q- What is the AWS data lake?`,
faq_question_2:` Q- What are the various techniques of data storage in AWS?`,
faq_question_3:` Q- What is the use of the ec2 instance?`,
faq_question_4:` Q- What is the role of AWS security?`,
faq_answer_1:`
 Ans- A data lake is a centralized & secured repository that contains all your data, both in its original form & prepared for analys`,
faq_answer_2:` Ans- There are three techniques of data storage, namely- –

Object storage

File storage

Block storage`,
faq_answer_3:` Ans- EC2 is a service that lets corporate clients to run application applications in the computing environment.`,
faq_answer_4:`Ans- AWS security provides chances to protect the data, check out security-related behavior & receive automatic response`,
},






{
    id:156,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Developing serverless solutions on AWS`,
course_overview:`
This course gives developers exposure to and best practices for building serverless apps utilizing AWS Lambda & other services in the AWS serverless platform. Professionals will employ AWS frameworks to create a serverless application in hands-on-labs that advance from uncomplicated to more complex issues. Professionals will use AWS documentation throughout the course to establish authentic ways for learning & problem-solving beyond the classroom.`,
certification:`Earn your certification in Developing Serverless Solutions on AWS. Master the design, deployment, and optimization of serverless applications using AWS Lambda, API Gateway, DynamoDB, and more. Validate your expertise in building scalable, cost-effective solutions without managing servers. Gain recognition as a skilled AWS developer, equipped to meet the demands of modern cloud computing.`,
prerequisite:`We recommend that registrants of this course have-

Familiarity with the basics of AWS Cloud architecture

An expertise of developing applications on AWS similar to finishing the Developing on AWS classroom training

Knowledge equal to completing the following serverless digital training- AWS Lambda Foundations & Amazon API Gateway for Serverless Applications`,
outline_heading_1:`Module 1- Thinking Serverless`,
outline_heading_2:`Module 2- API-Driven Development & Synchronous Event Sources`,
outline_heading_3:`Module 3- Introduction to Authentication, Authorization, & Access Control`,
outline_heading_4:`
Module 4- Serverless Deployment Frameworks`,
outline_heading_5:`Module 5- Using Amazon EventBridge & Amazon SNS to Decouple Components`,
outline_heading_6:`Module 6- Event-Driven Development Using Queues & Streams`,
outline_heading_7:`Module 7- Writing Good Lambda Functions`,
outline_heading_8:`Module 8- Step Functions for Orchestration`,
outline_heading_9:`Module 9- Observability & Monitoring`,
Outline_heading_10:`Module 10- Serverless Application Security`,
Outline_heading_11:`Module 11- Handling Scale in Serverless Applications`,
outline_heading_12:`Module 12- Automating the Deployment Pipeline`,
outline_detail_1:`Best practices for creating modern serverless applications

Event-driven design

AWS services that facilitate event-driven serverless applications`,
outline_detail_2:`Authentication vs. Authorization

Options for authenticating to APIs using API Gateway

Amazon Cognito in serverless applications

Amazon Cognito user pools vs. federated identities`,
outline_detail_3:`Authentication vs. Authorization

Options for authenticating to APIs using API Gateway

Amazon Cognito in serverless applications

Amazon Cognito user pools vs. federated identities`,
outline_detail_4:`Overview of imperative vs. declarative programming for infrastructure as code

Comparison of CloudFormation, AWS CDK, Amplify, & AWS SAM frameworks

Features of AWS SAM & the AWS SAM CLI for local emulation & testing
`,
outline_detail_5:`Development considerations while employing asynchronous event sources

Features & use examples of Amazon EventBridge

Try-it-out exercise- Build a custom EventBridge bus & rule

Comparison of use cases for Amazon Simple Notification Service (Amazon SNS) vs. EventBridge

Try-it-out exercise- Configure an Amazon SNS topic with filtering
`,
outline_detail_6:`
Development considerations when employing polling event sources to trigger Lambda functions

Distinctions between queues & streams as event sources for Lambda

Selecting proper setups when using Amazon Simple Queue Service (Amazon SQS) or Amazon Kinesis Data Streams as an event source for Lambda

Try-it-out exercise- Configure an Amazon SQS queue with a dead-letter queue as a Lambda event source

Hands-On Labs

Hands-On Lab 1- Deploying a Simple Serverless Application

Hands-On Lab 2- Message Fan-Out with Amazon EventBridge`,
outline_detail_7:`How the Lambda lifetime effects your function code

Best practices for your Lambda functions

Configuring a function

Function code, versions & aliases

Try-it-out exercise- Configure & test a Lambda function

Lambda error handling

Handling partial failures with queues & streams`,
outline_detail_8:`AWS Step Functions in serverless architectures

Try-it-out exercise- Step Functions states

The callback patterns

Standard vs. Express Workflows

Step Functions direct integrations

Try-it-out exercise- Troubleshooting a Standard Step Functions process`,
outline_detail_9:`The three pillars of observability

Amazon CloudWatch Logs & Logs Insights

Writing effective log files

Try-it-out exercise- Interpreting logs

Using AWS X-Ray for observability

Try-it-out exercise- Enable X-Ray & evaluate X-Ray traces

CloudWatch metrics & embedded metrics format

Try-it-out exercise- Metrics & alerts

Try-it-out exercise- ServiceLens

Hands-On Labs

Hands-On Lab 3- Workflow Orchestration Using AWS Step Functions

Hands-On Lab 4- Observability & Monitoring`,
outline_detail_10:`Security best practices for serverless applications

Applying security at all layers

API Gateway & application security

Lambda & application security

Protecting data in your serverless data repositories

Auditing & traceability`,
outline_detail_11:`
Scaling considerations for serverless apps

Using API Gateway to control scale

Lambda concurrency scaling

How different event sources scale with Lambda`,
outline_detail_12:`The importance of CI/CD in serverless apps

Tools in a serverless pipeline

AWS SAM features for serverless deployments

Best practices for automation

Course wrap-up

Hands-On Labs

Hands-On Lab 5- Securing Serverless Applications

Hands-On Lab 6- Serverless CI/CD on AWS`,

skill_1:`Cloud Function Orchestration`,
skill_2:`Infrastructure as Code (IaC) Mastery`,
skill_3:`Scalable Microservices Architecture`,
skill_4:`Advanced Lambda Optimization`,
skill_5:`Real-time Data Streaming Integration`,
skill_6:`Serverless Security Frameworks`,
course_faq_name:`Developing serverless solutions on AW10`,
faq_question_1:`Q- What are the primary benefits of serverless?`,
faq_question_2:`Q- Which AWS service would you recommend developing a serverless solution?`,
faq_question_3:`Q- What are the various techniques of data storage in AWS? `,
faq_question_4:`Can serverless solutions on AWS handle high traffic and scalability?`,

faq_answer_1:`A- Serverless computing offers a number of advantages over typical cloud-based or server-centric systems. For many developers, serverless architectures offer higher scalability, more flexibility, & shorter time to delivery, all at a reduced cost.`,
faq_answer_2:`
A- Serverless applications start with AWS Lambda, an event-driven compute service natively linked with over 200 AWS services & software as a service (SaaS) application.`,
faq_answer_3:` A- There are three techniques of data storage, namely –

How can I monitor and troubleshoot serverless applications on AWS?

Answer: AWS provides various monitoring and troubleshooting tools for serverless applications, including AWS CloudWatch for logging and metrics, AWS X-Ray for tracing requests, and AWS Lambda Insights for detailed performance metrics. These tools help developers identify and resolve issues efficiently.
`,
faq_answer_4:`Answer: Yes, serverless solutions on AWS are highly scalable. AWS automatically scales resources based on demand, allowing applications to handle sudden spikes in traffic without manual intervention. This scalability is inherent to the serverless architecture.`,
},





{
    id:157,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`AWS security fundamentals`,
course_overview:`
AWS Security Essentials covers essential AWS cloud security themes, including AWS access control, data encryption methods, & how network admittance to your AWS infrastructure can be safeguarded. Grounded on the AWS Shared Security Model, you learn where you are accountable for applying security in the AWS Cloud & what security-oriented services are accessible to you & why & how the security services can aid meet the security needs of your firm 
This course lets you delve deep, ask questions, work through solutions, & get feedback from AWS- accredited teachers with strong technical understanding. This is a foundational level course & is a part of the AWS Security learning route.`,
certification:`
Earn your AWS Security Fundamentals certification to validate your understanding of AWS security concepts, services, and best practices. Demonstrate expertise in identifying AWS security services, implementing data protection measures, and securing AWS environments. Enhance your credentials and contribute to ensuring robust security within AWS cloud infrastructures.
`,
prerequisite:`We recommend that registrants of this course have-

Working understanding of IT security practices & infrastructure ideas, experience with cloud computing concepts`,
outline_heading_1:`Module 1- Security on AWS`,
outline_heading_2:`Module 2- Security OF the Cloud`,
outline_heading_3:`Module 3- Security IN the Cloud – Part 1`,
outline_heading_4:`Module 4- Security IN the Cloud – Part 2`,
outline_heading_5:`Module 5- Security IN the Cloud – Part 3`,
outline_heading_6:`Module 6- Course Wrap Up`,

outline_detail_1:`Security design principles in the AWS Cloud

AWS Shared Responsibility Model`,
outline_detail_2:`AWS Global Infrastructure

Data Center Security

Compliance & Governance`,
outline_detail_3:`Identity & Access Management

Data Protection

Lab 1 – Introduction to Security Policies`,
outline_detail_4:`
Securing your infrastructure

Monitoring & detective controls

Lab 2 – Securing VPC Resources with Security Groups`,
outline_detail_5:`DDoS mitigation

Incident response fundamentals

Lab 3 – Automating Incident Response with AWS Config & AWS Lambda`,
outline_detail_6:`AWS Well-Architected tool overview`,
skill_1:`Secure Access Management`,
skill_2:`Incident Response Planning`,
skill_3:`Network Security Configuration`,
skill_4:`Data Encryption Protocols`,
skill_5:`Compliance Monitoring`,
skill_6:`Identity and Access Control`,
course_faq_name:`AWS security fundamentals`,
faq_question_1:`Q- Which security requirements are managed by AWS?`,
faq_question_2:`Q- What are the important advantages of AWS security?`,
faq_question_3:`What is AWS data lake? `,
faq_question_4:`Q- What are the various techniques of data storage in AWS?`,
faq_question_5:`Q- What is the use of the ec2 instance?  `,
faq_answer_1:`A- AWS delivers security-specific tools & features throughout network security, configuration management, access control, & data encryption.`,
faq_answer_2:`A- Scale Securely with Superior Visibility & Control. With the help of AWS, you control where your data is collected, who can access it, & what resources your firm is spending at any given moment.

Automate & Reduce Risk with Deeply Integrated Services.

Build with the Highest Standards for Privacy & Data Security.`,
faq_answer_3:`A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
faq_answer_4:` A- There are three methods of data storage namely- 

Object storage

File storage`,
Faq_answer_5:`A- EC2 is a service that lets corporate clients to run application programs in the computing environment.      `,
},




{
    id:158,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`AWS security governance at scale`,
course_overview:`ecurity is foundational to AWS. Governance at scale is a brand-new concept for automating cloud governance that can assist firms in retiring human operations in budget enforcement, account administration, & and compliance. By automating common difficulties, a business can scale without restricting speed, agility, or creativity. In addition, they can give decision-makers the control, visibility, & governance necessary to protect sensitive data & systems.

In this training, professionals will learn how to facilitate developer speed & agility, & implement preventive & detective controls. By the completion of this training, professionals will be able to execute governance best practices.`,
certification:`
Certification in AWS Security Governance at Scale validates expertise in designing, implementing, and managing security controls at scale on the AWS platform. Covering advanced topics like automation, governance frameworks, and risk management, it equips professionals to ensure robust security architectures for large-scale AWS deployments, safeguarding against evolving threats.
`,
prerequisite:`Before taking this course, participants should have completed the following- 

Required-

AWS Security Fundamentals course

AWS Security Essentials course

Optional-

AWS Cloud Management Assessment

Introduction to AWS Control Tower course

Automated Landing Zone course

Introduction to AWS Service Catalog cours`,
Outline_heading_1:`
Module 1- Governance at Scale`,
outline_detail_1:`Governance at scale focal points

Business & Technical Challenges`,
Outline_heading_2:`Module 2- Governance Automation`,
outline_detail_2:`Multi-account methods, advice, & architecture

Environments for agility & governance at scale

Governance using AWS Control Tower

Use cases for governance at scale`,
Outline_heading_3:`Module 3- Preventive Controls`,
outline_detail_3:`Enterprise environment issues for developers

AWS Service Catalog

Resource creation

Workflows for provisioning accounts

Preventive cost & security governance

Self-service with existing IT service management (ITSM) technologies

Lab 1- Deploy Resources for AWS Catalog

Create a new AWS Service Catalog portfolio & product.

Add an IAM role to a launch constraint to limit the actions the product may perform.

Grant access to an IAM role to view the catalog items.

Deploy an S3 bucket from an AWS Service Catalog product.`,
Outline_heading_4:`Module 4- Detective Controls`,
outline_detail_4:`Operations component of governance at scale

Resource monitoring

Configuration rules for auditing

Operational insights

Remediation

Clean up accounts

Lab 2- Compliance & Security Automation using AWS Config

Apply Managed Rules with AWS Config to chosen resources

Automate remediation based on AWS Config rules

Investigate the Amazon Config dashboard & verify resources & rule compliance Lab 3- Taking Action using AWS Systems Manager.

Setup up Groups for various resources based on similar requirements

Perform automatic operations upon specific Resource Groups Module 5- Resources

Explore other resources for security governance at scale`,
skill_1:`Compliance Management`,
skill_2:`Risk Assessment`,
skill_3:`Incident Response Planning`,
skill_4:`Identity and Access Management (IAM)`,
skill_5:`Security Automation`,
skill_6:`Threat Detection`,
course_faq_name:`AWS security governance at scale`,
Faq_question_1:`Q- Which security requirements are managed by AWS?`,
faq_answer_1:`A- AWS delivers security-specific tools & features throughout network security, configuration management, access control, & data encryption.`,
Faq_question_2:`Q- What are the important advantages of AWS security?`,
faq_answer_2:`A- Scale Securely with Superior Visibility & Control. With AWS, you manage where your data is collected, who can access it, & what resources your company is spending at any given moment.

Automate & reduce Risk with deeply Integrated Services. ...

Build with the Highest Standards for Privacy & Data Security.`,
Faq_question_3:`Q- What is AWS data lake?`,
faq_answer_3:`  A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis. `,
Faq_question_4:` Q- What are the various techniques of data storage in AWS? `,
faq_answer_4:` A- There are three methods of data storage`,
},





{
    id:159,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Security engineering on AWS`,
course_overview:`This course demonstrates how to properly employ AWS security services to keep protected in the AWS Cloud. The course focuses the security procedures that AWS advises for strengthening the security of your data & systems in the cloud. It illustrates the security components of AWS major services, including computing, storage, networking, & database services. Professionals will also learn how to leverage AWS services & tools for automation, continuous monitoring & logging, & responding to security events.`,
certification:` in Security Engineering on AWS validates expertise in designing and implementing secure and scalable AWS solutions. Skills include identifying and mitigating security threats, implementing access controls, and ensuring compliance with industry regulations. Earn this credential to demonstrate proficiency in safeguarding AWS environments against cyber threats.`,
prerequisite:`We recommend that registrants of this course have-

Working grasp of IT security techniques & infrastructure concepts

Familiarity with cloud computing principles

Completed AWS Security Essentials & Architecting on AWS instructor-led courses`,
Outline_heading_1:`Module 1- Security on AWS`,
outline_detail_1:`Module 1- Security on AWS

Security in the AWS cloud

AWS Shared Responsibility Model

Incident response overview

DevOps and Security Engineering`,
Outline_heading_2:`Module 2- Identifying Entry Points on AWS`,
outline_detail_2:`Recognize the numerous ways to reach the AWS platform

Understanding IAM policies

IAM Permissions Boundary

IAM Access Analyzer

Multi-factor authentication

AWS CloudTrail

Lab 1- Cross-account access`,
Outline_heading_3:`Module 3- Security Considerations- Web Application Environments`,
outline_detail_3:`Threats in a three-tier architecture

Common threats- user access

Common threats- data access

AWS Trusted Advisor`,
Outline_heading_4:`Module 4- Application Security`,
outline_detail_4:`Amazon Machine Images

Amazon Inspector

AWS Systems Manager

Lab 2- Using AWS Systems Manager & Amazon Inspector`,
Outline_heading_5:`Module 5- Data Security`,
outline_detail_5:`Data protection strategies

Encryption on AWS

Protecting data at rest using Amazon S3, Amazon RDS, Amazon DynamoDB

Protecting preserved data with Amazon S3 Glacier

Amazon S3 Access Analyzer

Amazon S3 Access Point`,
Outline_heading_6:`Module 6- Securing Network Communications`,
outline_detail_6:`Amazon VPC security considerations

Amazon VPC Traffic Mirroring

Responding to compromised occurrences

Elastic Load Balancing

AWS Certificate Manage`,
Outline_heading_7:`Module 7- Monitoring & Collecting Logs on AWS`,
outline_detail_7:`Amazon CloudWatch & CloudWatch Logs

AWS Config

Amazon Macie

Amazon VPC Flow Logs

Amazon S3 Server Access Logs

ELB Access Logs

Lab 3- Monitor & Respond with AWS Config`,
Outline_heading_8:`Module 8- Processing Logs on AWS`,
outline_detail_8:`Amazon Kinesis

Amazon Athena

Lab 4- Web Server Log Analysis`,
Outline_heading_9:`Module 9- Security Considerations- Hybrid Environments`,
outline_detail_9:`AWS Site-to-Site & Client VPN connections

AWS Direct Connect

AWS Transit Gateway`,
Outline_heading_10:`Module 10- Out-Of-Region Protection`,
outline_detail_10:`Amazon Route 53

AWS WAF

Amazon CloudFront

AWS Shield

AWS Firewall Manager

DDoS mitigation on AWS`,
Outline_heading_11:`Module 11- Security Considerations- Serverless Environments`,
outline_detail_11:`Amazon Cognito

Amazon API Gateway

AWS Lambda`,
Outline_heading_12:`Module 12- Threat Detection & Investigation`,
outline_detail_12:`Amazon GuardDuty

AWS Security Hub

Amazon Detective`,
Outline_heading_13:`Module 13- Secrets Management on AWS`,
outline_detail_13:`AWS KMS

AWS CloudHSM

AWS Secrets Manager

Lab 5- Using AWS KMS`,
Outline_heading_14:`Module 14- Automation & Security by Design`,
outline_detail_14:`AWS CloudFormation

AWS Service Catalog

Lab 6- Security automation on AWS with AWS Service Catalog`,
Outline_heading_15:`Module 15- Account Management & Provisioning on AWS`,
outline_detail_15:`AWS Organizations

AWS Control Tower

AWS SSO

AWS Directory Service

Lab 7- Federated Access using ADFS`,

skill_1:`Cloud Security Architecture`,
skill_2:`Secure DevOps on AWS`,
skill_3:`AWS Identity and Access Management (IAM)`,
skill_4:`Security Automation and Orchestration on AWS`,
skill_5:`AWS Encryption and Key Management`,
skill_6:`AWS Network Security`,
course_faq_name:`Security engineering on AWS`,
Faq_question_1:`Q- Which security requirements are managed by AWS?`,
faq_answer_1:`A- AWS delivers security-specific tools & features throughout network security, configuration management, access control, & data encryption.`,
Faq_question_2:`Q- What are the important advantages of AWS security?`,
faq_answer_2:`A- Scale Securely with Superior Visibility & Control. With AWS, you manage where your data is collected, who can access it, & what resources your company is spending at any given moment.

Automate & Reduce Risk with Deeply Integrated Services. ...

Build with the Highest Standards for Privacy & Data Security.`,
Faq_question_3:`What is AWS data lake? `,
faq_answer_3:` A- A data lake is a centralised & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_4:` Q- What are the various techniques of data storage in AWS?  `,
faq_answer_4:`A- There are three techniques of data storage, namely`,
},



{
    id:160,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Systems operations on AWS`,
course_overview:`This course educates systems operators & anyone performing system operations functions how to automate, install, setup, monitor, protect, maintain & troubleshoot the services, networks, & systems on AWS necessary to aid business applications. The course also covers unique AWS capabilities, tools, & best practices relating to these functions.

Course level- Intermediate`,
certification:`Earn your Systems Operations on AWS certification to validate your expertise in deploying, managing, and operating scalable, highly available, and fault-tolerant systems on the AWS platform. Gain recognition for your proficiency in optimizing AWS infrastructure and enhancing operational efficiency. Stand out in the field of cloud operations with this esteemed credential.`,
prerequisite:`We recommend that participants of this course have the following prerequisites-

Successfully finished the AWS Technical Essentials course

Background in software development or systems administration

Excellence in maintaining operating systems at the command line, as shell scripting in Linux environments or cmd/PowerShell in Windows

Fundamental knowledge of networking protocols (TCP/IP, HTTP)`,
Outline_heading_1:`Module 1- Introduction to System Operations on AWS`,
outline_detail_1:`Systems operations

AWS Well-Architected Framework

AWS Well-Architected Tool`,
Outline_heading_2:`Module 2a- Access Management`,
outline_detail_2:`Access management

Resources, accounts, & AWS Organizations`,
Outline_heading_3:`Module 2b- System Discovery`,
outline_detail_3:`Methods to interface with AWS services

Introduction to monitoring services

Tools for automating resource discovery

Inventory with AWS Systems Manager & AWS Config

Troubleshooting scenario

Hands-On Lab- Auditing AWS Resources using AWS Systems Manager & AWS Config`,
Outline_heading_4:`Module 3- Deploy & Update Resources`,
outline_detail_4:`Systems operations in deployments

Tagging strategies

Deployment using Amazon Machine Images (AMIs)

Deployment using AWS Control Tower

Troubleshooting scenario`,
Outline_heading_5:`Module 4- Automate Resource Deployment`,
outline_detail_5:`Deployment with AWS CloudFormation

Deployment using AWS Service Catalog

Troubleshooting scenario

Hands-On Lab- Infrastructure as Code`,
Outline_heading_6:`Module 5- Manage Resources`,
outline_detail_6:`AWS Systems Manager

Troubleshooting scenario

Hands-On Lab- Operations as Code`,
Outline_heading_7:`Module 6a- Configure Highly Available Systems`,
outline_detail_7:`Distributing traffic with Elastic Load Balancing

Amazon Route 53`,
Outline_heading_8:`Module 6b- Automate Scaling`,
outline_detail_8:`Scaling with AWS Auto Scaling

Scaling with Spot Instances

Managing licenses with AWS License Manager

Troubleshooting scenario`,
Outline_heading_9:`Module 7- Monitor & Maintain System Health`,
outline_detail_9:`Monitoring & sustaining healthy workloads

Monitoring distributed apps

Monitoring AWS infrastructure

Monitoring your AWS account

Troubleshooting scenario

Hands-On Lab- Monitoring Applications & Infrastructure`,
Outline_heading_10:`Module 8- Data Security & System Auditing`,
Outline_detail_10:`Maintaining a solid identity & access foundation

Implementing detecting mechanisms

Automating incident remediation

Troubleshooting scenario

Hands-On Lab- Implementing IAM permissions boundaries`,
Outline_heading_11:`Module 9- Operate Secure & Resilient Networks`,
outline_detail_11:`Developing a safe Amazon Virtual Private Cloud (Amazon VPC)

Networking beyond the VPC

Troubleshooting scenario`,
Outline_heading_12:`Module 10a- Mountable Storage`,
outline_detail_12:`Configuring Amazon Elastic Block Storage (Amazon EBS)

Sizing Amazon EBS volumes for performance

Utilizing Amazon EBS snapshots

Utilizing Amazon Data Lifecycle Manager to manage your AWS resources

Creating backup & data recovery plans

Configuring shared file system storage`,
Outline_heading_13:`Module 10b- Object Storage`,
outline_detail_13:`
Organizing Amazon Simple Storage Service (Amazon S3) with Cross-Region Replication, Access Logs, & S3 Intelligent-Tiering

Hands-On Lab- Automating with AWS Backup for Archiving & Recovery`,
Outline_heading_14:`Module 11- Cost Reporting, Alerts, & Optimization`,
outline_detail_14:`Gaining AWS cost awareness

Using control mechanisms for cost management

Optimizing your AWS spend & use

Hands-On Lab- Capstone lab for SysOps`,

skill_1:`AWS Resource Provisioning`,
skill_2:`Infrastructure Monitoring and Management`,
skill_3:`Scalability Planning and Implementation`,
skill_4:`Automation with AWS CloudFormation`,
skill_5:`Security Configuration and Compliance`,
skill_6:`Troubleshooting and Incident Response`,
course_faq_name:`Systems operations on AWS`,
Faq_question_1:`Q- What is AWS database management? `,
faq_answer_1:` A- AWS fully managed database services include continuous monitoring, self-healing storage, & automatic Scaling to allow you focus on application development. Achieve performance at scale.`,
Faq_question_2:`Q- What is AWS data lake? `,
faq_answer_2:` A- A data lake is a centralised & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_3:`
  Q- What are the various techniques of data storage in AWS? `,
faq_answer_3:` A- There are three techniques of data storage, namely –

Object storage

File storage

Block storage`,
Faq_question_4:`  Q- What is the use of the ec2 instance? `,
faq_answer_4:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment. `,
},


{
    id:161,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Migrating to AWS`,
course_overview:`This course is for professionals who desire an understanding of how to plan & move existing workloads to the AWS Cloud. Professionals will learn about various cloud migration options & how to apply each phase of the migration process, with portfolio identification, application migration planning & design, conducting a migration, & post-migration validation & application optimization. Hands-on labs promote learning, & each lab is designed to offer professionals with the information & foundation essential to execute migration duties in your firm.`,
certification:`This certification validates proficiency in migrating existing workloads to Amazon Web Services (AWS). Demonstrates expertise in planning, executing, and optimizing migration strategies, ensuring seamless transition of applications and data to the AWS cloud environment.`,
prerequisite:`We recommend that registrants of this course have-

Familiarity with enterprise IT infrastructure (hardware & software)

Finished the AWS Technical Essentials or Architecting on AWS classroom training

Accomplished their AWS Certified Solutions Architect – Associate certification (optional)`,
Outline_heading_1:`Module 1- What Is a Cloud Migration?`,
outline_detail_1:`Summarize the reasons & expected objectives of a cloud migration

Recognize the ingredients of a successful migration journey

Describe the three-phase AWS migration process`,
Outline_heading_2:`Module 2- Assessing Migration Readiness`,
outline_detail_2:`Summarise the activities & goals of the assessment phase

Evaluate tools for analyzing your company's cloud readiness

Explain Migration Readiness Assessments (MRAs) & their importance

Group Exercise- Assess cloud migration readiness with AWS Cloud Adoption Readiness Tool (CART)`,
Outline_heading_3:`Module 3- Preparing for a Migration & Understanding Related Workstreams`,
outline_detail_3:`Summarize the mobilization step of the procedure

Discuss the eight unique migration-related workstreams`,
Outline_heading_4:`Module 4- Discovering Landing Zones & Their Merits`,
outline_detail_4:`Describe the function of a landing zone

Discuss recommended techniques for constructing a bespoke landing zone

Explain how you would use AWS Control Tower to construct a landing zone`,
Outline_heading_5:`Module 5- Building a Landing Zone`,
outline_detail_5:`Summarize the process of building the landing zone

Determine the best multi-account structure, governance policies, & connectivity plan for a landing zone

Demonstration- AWS Control Tower

Hands-On Lab: Connecting Your On-Premises Network & Directory Services to AWS`,
Outline_heading_6:`Module 6- Discovering the Portfolio & Understanding Migration Strategies`,
outline_detail_6:`Describe the actions of the detailed portfolio discovery workstream

Explain how to analyze an application’s migration readiness

Summarize the seven migration strategies

Group Exercise- Choose the best strategy (scenario-based)`,
Outline_heading_7:`Module 7- Understanding & Choosing Portfolio Discovery Tools`,
outline_detail_7:`Summarize the different discovery tools accessible

Differentiate which tools are preferable to utilize based on the scenario

Hands-On Lab- Gathering Application Data Necessary for Migration`,
Outline_heading_8:`Module 8- Planning & Designing a Migration`,
outline_detail_8:`Describe the planning & design process

Summarize how to set up the migration factory

Choose & finalize an application migration strategy`,
Outline_heading_9:`Module 9- Performing the Migration to AWS`,
outline_detail_9:`Explain the server migration process

Discuss the many migration automation & governance tools present

Estimate server migration tools for compatibility with your environment

Hands-On Lab- Migrating an Application to AWS`,
Outline_heading_10:`Module 10- Understanding Database & Data Migration Services`,
outline_detail_10:`Summarize the significance of database & data migration

Discuss the various data migration tools available

Hands-On Lab- Migrating an Existing Database to Amazon Aurora`,
Outline_heading_11:`
Module 11- Understanding Additional Migration Support Options`,
outline_detail_11:`Discuss additional migration support options

Summarize AWS Managed Services & AWS Marketplace

Describe SAP on AWS & services offered for Windows`,
Outline_heading_12:`Module 12- Integrating, Validating, & Cutting Over Applications`,
outline_detail_12:`Discuss the process & benefits of integrating, verifying, & cutting over applications`,
Outline_heading_13:`Module 13- Modernizing & Optimizing an Application Migration`,
outline_detail_13:`Identify post-migration options for modernization & optimization

Understand cost & security optimization processes

Explore technologies available to support these processes

Hands-On Lab- Optimizing an Application with Amazon S3 & Amazon ECS`,
Outline_heading_14:`Module 14- Understanding Operations Tools, Integration Testing, & Automation`,
outline_detail_14:`Summarize operations in the cloud

Discuss four roles of operations & their areas

Review operations automation & necessary support tools`,
Outline_heading_15:`Module 15- Migration Best Practices`,
outline_detail_15:`Course review & critical takeaways

Summarize & reinforce AWS key practices for moving

Hands-On Lab- Automating Application Deployments `,

skill_1:`AWS Infrastructure Management`,
skill_2:`Cloud Networking`,
skill_3:`Security and Compliance in AWS`,
skill_4:`AWS Cost Optimization`,
skill_5:`Deployment Automation with AWS Services`,
skill_6:`Monitoring and Troubleshooting in AWS`,
course_faq_name:`Migrating to AWS`,
Faq_question_1:`
Q- What are the four types of database platforms in AWS?`,
faq_answer_1:`
  A- Amazon Relational Database Service. Amazon RDS is accessible on many database instance types - optimized for memory, presentation, or I/O - & gives you with six familiar database engines to pick from, including Oracle Database, Amazon Aurora, PostgreSQL, MySQL, MariaDB, & SQL Server.`,
Faq_question_2:`Q- What is AWS database management?`,
faq_answer_2:`  A- AWS fully managed database services enable continuous monitoring, self-healing storage, & automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_3:`Q- What is AWS data lake? `,
faq_answer_3:`A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_4:` Q- What are the various techniques of data storage in AWS? `,
faq_answer_4:`A- There are three techniques of data storage, namely–

Object storage

File storage

Block storage `,
Faq_question_5:` Q- What is the use of the ec2 instance? `,

faq_answer_5:`A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
},





{
    id:162,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Video streaming fundamentals for AWS media services`,
course_overview:`This course will teach you the best practices for creating and using cloud-based video workflows. It covers fundamental video processing and distribution ideas, the variables that can effect migration decisions, & real-world instances of hybrid & cloud utilization cases for AWS Media Services. It also covers security, machine learning, & analytics approaches to assist you in considering how AWS Media Services fit into your entire cloud tactic.`,
certification:`Certification in Video Streaming Fundamentals for AWS Media Services equips professionals with essential knowledge in deploying, managing, and optimizing video streaming solutions on AWS. Participants gain expertise in using AWS Elemental Media Services, encoding, packaging, and delivering high-quality video content efficiently, ensuring seamless viewer experiences.`,
prerequisite:`We strongly urge learners finish the required requirements before class and suggest completion of the optional prerequisites for an optimal experience.

Required-

Video Streaming Concepts- AWS Media Services

Overview of AWS Media Services by Use Case

Optional-

AWS Technical Essentials (digital or classroom)`,
Outline_heading_1:`Module 1- Essential Video Concepts`,
outline_detail_1:`The resolution, frame rate, bitrate, delay, and compression

Codecs and containers

Group of pictures (GOP) encoding

ABR, packing, and distribution

Internet protocols used in video streaming`,
Outline_heading_2:`Module 2- Anatomy of Streaming Workflows`,
outline_detail_2:`Four stages of video streaming

Variables that effect design decisions`,
Outline_heading_3:`Module 3- Utilizing AWS Services in Video-on-Demand (VOD) Workflows`,
outline_detail_3:`Transforming a film or cassette library for internet streaming

Enhancing reach and accessibility utilizing different languages & captions

Streaming modified highlights from a live event

Analyzing & tagging VOD files for media content analysis. Utilizing machine learning & data analytics`,
Outline_heading_4:`Module 4- Optimizing Workflows`,
outline_detail_4:`Security

Migrating to the cloud

Cloud financial management`,
Outline_heading_5:`Module 5- Utilizing AWS Services in Live Workflows`,
outline_detail_5:`Obstacles of live broadcasting

Live streaming a basic interview show

Live streaming a key athletic event to a global audience

Live to switch between various inputs

Saving parts from a live show to make VOD segments`,
Outline_heading_6:`Module 6- Recap and Review
`,
skill_1:`Video Encoding Optimization`,
skill_2:`Live Streaming Configuration`,
skill_3:`
Content Delivery Network (CDN) Management`,
skill_4:`Transcoding Techniques`,
skill_5:`Stream Monitoring and Analytics`,
skill_6:`AWS Media Services Integration`,
course_faq_name:`Video streaming fundamentals for AWS media services`,
Faq_question_1:`Q- What are the four types of database platforms in AWS?  `,
faq_answer_1:`A- Amazon Relational Database Service. Amazon RDS is obtainable on many database depiction types - geared for performance, memory, or I/O - and equips you with 6 familiar database engines to pick from, along with Amazon Aurora, Oracle Database, PostgreSQL, MariaDB, MySQL, and SQL Server.`,
Faq_question_2:`Q- What is AWS database management?`,
faq_answer_2:` A- AWS fully managed database services provide continuous monitoring, self-healing storage, and automated scalability to help you focus on application development. Achieve performance at scale`,
Faq_question_3:`Q- What is AWS data lake? `,
faq_answer_3:` A- A data lake is a centralized and secured repository that contains all your data in its original form and is prepared for analysis.`,
Faq_question_4:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_4:`  A- There are three techniques of data storage, namely- –

Object storage

File storage

Block storage`,
Faq_question_5:`Q- What is the use of the ec2 instance? `,

faq_answer_5:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment`,
},




{
    id:163,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`DevOps engineering on AWS`,
course_overview:`DevOps Engineering on AWS educates professionals how to use the blend of DevOps cultural ideas, practices, & technologies to boost your organization’s capacity to build, deliver, & support apps & services at high speed on AWS. This course covers Continuous Integration (CI), infrastructure as code, microservices, Continuous Delivery (CD), monitoring & logging, & communication and collaboration.

Hands-on labs give professionals experience creating & deploying AWS CloudFormation templates & CI/CD pipelines that create & deploy apps on Amazon Elastic Compute Cloud (Amazon EC2), serverless applications, & container-based applications. Labs for multi-pipeline workflows & pipelines that deploy to numerous environments are also offered.`,
certification:`DevOps Engineering on AWS validates proficiency in implementing continuous integration and delivery practices using AWS technologies. Candidates demonstrate expertise in automation, monitoring, and infrastructure as code principles to enhance collaboration between development and operations teams, ensuring efficient deployment and management of applications on the AWS platform.`,
prerequisite:`We recommend that registrants of this course have-

● Preceding participation at the Systems Operations on AWS or Developing on AWS courses

● Working experience in one or more high-level programming languages, such as Java, C#, PHP, Python, and Ruby.

● Intermediate understanding of administering Linux or Windows systems at the command-line level

● Two or more years of experience operating, deploying, & managing AWS environments.`,
Outline_heading_1:`Module 1- Introduction to DevOps`,
outline_detail_1:`● What is DevOps?

● The Amazon road to DevOps

● Foundations for DevOps`,
Outline_heading_2:`Module 2- Infrastructure Automation`,
outline_detail_2:`● Introduction to Infrastructure Automation

● Diving inside the AWS CloudFormation template

● Modifying an AWS CloudFormation template

● Demonstration- AWS CloudFormation template structure, parameters, stacks, updates, importing resources, & drift detection`,
Outline_heading_3:`Module 3- AWS Toolkits`,
outline_detail_3:`● Configuring the AWS CLI

● AWS Software Development Kits (AWS SDKs)

● AWS SAM CLI

● AWS Cloud Development Kit (AWS CDK)
● Configuring the AWS CLI

● AWS Software Development Kits (AWS SDKs)

● AWS SAM CLI

● AWS Cloud Development Kit (AWS CDK)

● AWS Cloud9

● Demonstration- AWS CLI & AWS CDK

● Hands-on lab- Using AWS CloudFormation to provision & manage a simple infrastructure


● AWS Cloud9

● Demonstration- AWS CLI & AWS CDK

● Hands-on lab- Using AWS CloudFormation to provision & manage a simple infrastructure`,
Outline_heading_4:`Module 4- Continuous integration & continuous delivery (CI/CD) with development tools`,
outline_detail_4:`● CI/CD Pipeline & Dev Tools

● Demonstration- CI/CD pipeline demonstrating several activities from AWS CodeCommit, AWS CodeBuild, AWS CodeDeploy & AWS CodePipeline

● Hands-on lab- Deploying an application to an EC2 fleet using AWS CodeDeploy`,
Outline_heading_5:`Module 5- Introduction to Microservices`,
outline_detail_5:`● Introduction to Microservices`,
Outline_heading_6:`Module 6- DevOps & Containers`,
outline_detail_6:`● Deploying applications with Docker

● Amazon Elastic Container Service & AWS Fargate

● Amazon Elastic Container Registry & Amazon Elastic Kubernetes service

● Demonstration- CI/CD pipeline deployment in a containerized application`,
Outline_heading_7:`Module 7- DevOps & serverless computing`,
outline_detail_7:`● AWS Lambda & AWS Fargate

● AWS Serverless Application Repository & AWS SAM

● AWS Step Functions

● Demonstration- AWS Lambda & Characteristics

● Demonstration- AWS SAM fast start in AWS Cloud9

● Hands-on lab- Deploying a serverless application using AWS Serverless Application Model (AWS SAM) & a CI/CD Pipeline`,
Outline_heading_8:`Module 8- Deployment tactics`,
outline_detail_8:`● Continuous Deployment

● Deployments using AWS Services`,
Outline_heading_9:`Module 9- Automated testing`,
outline_detail_9:`● Introduction to testing

● Tests- Unit, integration, fault tolerance, load, & synthetic

● Product & service integrations.`,
Outline_heading_10:`Module 10- Security Automation`,
Outline_detail_10:`● Introduction to DevSecOps

● Security of the Pipeline

● Security in the Pipeline

● Threat Detection Tools

● Demonstration- AWS Security Hub, Amazon GuardDuty, AWS Config, & Amazon Inspector`,
Outline_heading_11:`Module 11- Configuration management`,
outline_detail_11:`● Introduction to the configuration management methodology

● AWS services & tooling for configuration management

● Hands-on lab- Performing blue/green deployments with CI/CD pipelines & Amazon Elastic Container Service (Amazon ECS)`,
Outline_heading_12:`Module 12- Observability`,
outline_detail_12:`● Introduction to observability

● AWS tools to assist with observability

● Hands-on lab- Using AWS DevOps tools for CI/CD pipeline automation`,
Outline_heading_13:`Module 13- Reference architecture (Optional module)`,
outline_detail_13:`● Reference architectures`,
Outline_heading_14:` Module 14- Course Summary`,
outline_detail_14:`● Components of DevOps practice

● CI/CD pipeline review

● AWS Certification`,

skill_1:`AWS Infrastructure as Code (IaC)`,
skill_2:`Continuous Integration/Continuous Deployment (CI/CD) pipelines`,
skill_3:`Containerization with Docker and Kubernetes`,
skill_4:`Monitoring and Logging in AWS environment`,
skill_5:`Automated Configuration Management`,
skill_6:`Cloud Security and Compliance in AWS`,
course_faq_name:`DevOps engineering on AWS `,
Faq_question_1:`Q- What is AWS database management?`,
faq_answer_1:`AWS completely managed database services enable continuous monitoring, self-healing storage, & automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_2:`Q- What is AWS data lake?`,
faq_answer_2:` A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_3:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_3:` A- There are three techniques of data storage, namely–

▪ Object storage

▪ File storage

▪ Block storage`,
Faq_question_4:`Q- What is the use of the ec2 instance?`,
faq_answer_4:`A- EC2 is a service that lets corporate clients to run application programs in the computing environment`,
Faq_question_5:` Q- What is the role of AWS security?`,
faq_answer_5:` A- AWS security provides ways to protect the data, check out security-related activity & receive automatic responses.`,
},




{
    id:164,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Planning-and-designing-databases-on-AWS`,
course_overview:`Professionals will learn about the process of planning & designing both relational & non relational databases. Professionals will understand the architectural concerns for hosting databases on Amazon Elastic Compute Cloud (Amazon EC2). Professionals will learn about our relational database services, including Amazon Relational Database Service (Amazon RDS), Amazon Aurora, & Amazon Redshift. Professionals will also learn about our nonrelational database services, including Amazon DocumentDB, Amazon DynamoDB, Amazon ElastiCache, Amazon Neptune, & Amazon QLDB. By the end of this course, professionals will be aware with the planning & design needs of all 8 of these AWS database services, their advantages & cons, and how to decide which AWS database service is right for your workloads.
Course level- Intermediate`,
certification:`Planning and Designing Databases on AWS
Master the principles of database planning and design on Amazon Web Services (AWS). Validate your expertise in optimizing database architectures for AWS environments, including best practices for scalability, reliability, and security. Gain recognition for your proficiency in leveraging AWS services for efficient database management.`,
prerequisite:`We recommend that attendees of this course have-
 ● Familiarity with AWS Database Services, equivalent to AWS Database Offerings digital training 
● Understanding of database design concepts, &/or data modeling for relational or non relational databases 
● Familiarity with cloud computing concepts 
● Familiarity with general networking & encryption concepts
 ● Understanding of the three V’s of data (volume, velocity, & variety) 
● Familiarity with basic data analytics concepts, equivalent to Data Analytics Fundamentals digital training 
● Understanding of general architecting best practices & the AWS Well-Architected Framework, equivalent to Architecting on AWS classroom training`,
Outline_heading_1:`Module 1- Database Concepts & General Guidelines `,
outline_detail_1:`● Databases on the cloud
 ● Database design principles 
● Transactional compliance`,
Outline_heading_2:`Module 2- Database Planning & Design `,
outline_detail_2:`● Workload requirements 
● Design considerations `,
Outline_heading_3:` Module 3- Databases on Amazon EC2`,
outline_detail_3:` ● Amazon EC2 for hosting databases`,
Outline_heading_4:`Module 4- Purpose-Built Databases`,
outline_detail_4:`● The route to AWS
 ● Data modeling basics `,
Outline_heading_5:` Module 5- Databases on Amazon RDS `,
outline_detail_5:`● Amazon RDS databases 
● Amazon RDS differentiating features 
● Amazon RDS Design considerations 
● Lab 1- Working with Amazon RDS databases`,
Outline_heading_6:`Module 6- Databases in Amazon Aurora`,
outline_detail_6:` ● Amazon Aurora databases
 ● Aurora distinguishing features 
● Aurora design considerations`,
Outline_heading_7:`Module 6- Databases in Amazon Aurora (continued) `,
outline_detail_7:` Lab 2- Working with Amazon Aurora databases `,
Outline_heading_8:`Module 7- Databases in Amazon DocumentDB (with MongoDB compatibility)`,
outline_detail_8:`● Amazon DocumentDB 
● Amazon DocumentDB design considerations 
● Lab 3- Working with Amazon DocumentDB databases`,
Outline_heading_9:`Module 8- Amazon DynamoDB Tables`,
outline_detail_9:`● Amazon DynamoDB 
● DynamoDB data modeling 
● DynamoDB distinguishing features 
● DynamoDB design considerations 
Lab 4- Working with Amazon DynamoDB Tables`,
Outline_heading_10:`Module 9- Databases in Amazon Neptune`,
outline_detail_10:`● Amazon Neptune
 ● Neptune design considerations `,
Outline_heading_11:`Module 10- Databases in Amazon Quantum Ledger Database (Amazon QLDB) `,
outline_detail_11:`● Amazon Quantum Ledger Database (Amazon QLDB) 
● Amazon QLDB Design Considerations`,
Outline_heading_12:`Module 11- Databases in Amazon ElastiCach `,
outline_detail_12:`● Amazon ElastiCache
 ● ElastiCache for Memcached 
● ElastiCache for Redis`,
Outline_heading_13:`Module 12- Data Warehousing in Amazon Redshift `,
outline_detail_13:`● Amazon Redshift 
● Amazon Redshift differentiating features
 ● Amazon Redshift data modeling 
● Amazon Redshift design considerations 
● Lab 5- Working with Amazon Redshift Clusters`,
skill_1:`Data Modeling`,
skill_2:`Database Optimization`,
skill_3:`AWS RDS Configuration`,
skill_4:`Scalability Planning`,
skill_5:`Security Implementation`,
skill_6:`Disaster Recovery Planning`,
course_faq_name:`Planning-and-designing-databases-on-AWS`,
Faq_question_1:`Q- What are the four types of database platforms in AWS?`,
faq_answer_1:` A- Amazon Relational Database Service. Amazon RDS is available on several database instance types: optimized for memory, performance, or I/O, & provides you with 6 familiar database engines to pick from, including MariaDB, MySQL, PostgreSQL, Oracle Database, Amazon Aurora, & SQL Server.`,
Faq_question_2:`Q- What is AWS database management?`,
faq_answer_2:` A- AWS fully managed database services enable continuous monitoring, self-healing storage, & automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_3:`Q- What is the AWS data lake?`,
faq_answer_3:` A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_4:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_4:` A- There are three methods of data storage, namely – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_5:` Q- What is the use of the ec2 instance?`,

faq_answer_5:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
},



{
    id:165,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Deep learning on AWS`,
course_overview:`In this course, you’ll learn about AWS’s deep learning solutions, including instances where deep learning makes sense and how deep learning works. You’ll learn how to run deep learning models on the cloud using Amazon SageMaker and the MXNet framework. You’ll also learn to deploy your deep learning models using services like AWS Lambda while developing intelligent systems on AWS.`,
certification:`Deep Learning on AWS validates proficiency in deploying, managing, and scaling deep learning models using Amazon Web Services. Candidates demonstrate expertise in building neural networks, utilizing AWS services like SageMaker, and optimizing performance for machine learning applications in various domains, enhancing their career prospects in AI development`,
prerequisite:`We recommend that registrants of this course have-

A rudimentary knowledge of ML processes

Knowledge with AWS key services like Amazon EC2 and AWS SDK

Knowledge of a scripting language like Python`,
Outline_heading_1:`Module 1- Machine learning overview`,
outline_detail_1:`A brief history of AI, ML, and DL
The business importance of ML

Common problems in ML

Different sorts of ML challenges and activities

AI on AWS`,
Outline_heading_2:`Module 2- Introduction to deep learning`,
outline_detail_2:`Introduction to DL

The DL ideas

A description of how to train DL models on AWS

Introduction to Amazon SageMaker

Hands-on lab- Spinning up an Amazon SageMaker notebook instance and running a multi-layer perceptron neural network model`,
Outline_heading_3:`Module 3- Introduction to Apache MXNet`,
outline_detail_3:`The motivation for and benefits of using MXNet and Gluon

Important terminology and APIs used in MXNet

Convolutional neural networks (CNN) architecture

Hands-on lab- Training a CNN on a CIFAR-10 dataset`,
Outline_heading_4:`Module 4- ML and DL architectures on AWS`,
outline_detail_4:`AWS services for deploying DL models (AWS Lambda, AWS IoT Greengrass, Amazon ECS, AWS Elastic Beanstalk)

Introduction to AWS AI services that are built on DL (Amazon Polly, Amazon Lex, Amazon Rekognition)

Hands-on lab- Deploying a trained model for prediction on AWS Lambda `,
skill_1:`  Neural Network Architecture Design`,
skill_2:`TensorFlow Implementation`,
skill_3:`PyTorch Implementation`,
skill_4:`AWS SageMaker Deployment`,
skill_5:`Convolutional Neural Networks (CNNs)`,
skill_6:`Recurrent Neural Networks (RNNs)`,
course_faq_name:`Deep learning on AWS`,
Faq_question_1:`Q- What are the four types of database platforms in AWS?  `,
faq_answer_1:`A- Amazon Relational Database Service. Amazon RDS is accessible on several database instance types- tailored for memory, performance, or I/O - and gives you with 6 familiar database engines to pick from, including PostgreSQL Amazon Aurora, MySQL, MariaDB, Oracle Database, and SQL Server.`,
Faq_question_2:`Q- What is AWS database management?`,
faq_answer_2:` A- AWS fully managed database services provide continuous monitoring, self-healing storage, and automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_3:`Q- What is AWS data lake? `,
faq_answer_3:`A- A data lake is a centralized and secured repository that accumulates all your data, both in its original form and prepared for analysis.`,
Faq_question_4:` Q- What are the various techniques of data storage in AWS?`,
faq_answer_4:` A- There are three techniques of data storage, namely- –

Object storage

File storage

Block storage  `,
Faq_question_5:`Q- What is the use of the ec2 instance?`,

faq_answer_5:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
},


{
    id:166,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`MLOps engineering on AWS`,
course_overview:`This course relies upon and extends the DevOps technique common in software development to train, construct, and deploy machine learning (ML) models. The course underscores the relevance of data, models, and code to effective ML deployments. It will exhibit the utilization of automation, processes, tools, & teamwork in tackling the difficulties connected to handoffs between data scientists, software developers, data engineers, and operations. The training will also discuss about the use of tools and processes to monitor

& take action when the model estimation in production begins to wander from agreed-upon key functioning indicators.

The instructor will encourage the professionals in this course to establish an MLOps action plan for their business through daily reflection on lesson and lab content and through conversations with instructors & peers.

Course level- Intermediate`,
certification:`Become proficient in MLOps engineering on AWS with our certification program. Gain expertise in deploying, monitoring, and managing machine learning models at scale. Learn best practices for automation, version control, and continuous integration/continuous deployment (CI/CD) pipelines. Elevate your skills to excel in the rapidly evolving field of machine learning operations.`,
prerequisite:`Required

AWS Technical Essentials course (digital or classroom)

DevOps Engineering on AWS course or comparable experience

Machine Learning Terminology and Process (digital course)

Practical Data Science with Amazon SageMaker course or comparable expertise Suggested

The Elements of Data Science (digital course) or comparable experience`,
Outline_heading_1:`Module 1- Introduction to MLOps`,
outline_detail_1:`Machine learning operations

Goals of MLOps

Communication

From DevOps to MLOps

ML workflow

Scope

maps view of ML workflow

MLOps cases`,
Outline_heading_2:`Module 2- MLOps Development`,
outline_detail_2:`Intro to train, develop, & evaluate machine learning models

MLOps security

Automating

Apache Airflow

Kubernetes integration for MLOps

Amazon SageMaker for MLOps

Lab- Bring your own algorithm to an MLOps pipeline

Demonstration- Amazon SageMaker

Intro to train, create and evaluate machine learning models

Lab- Code and serve your ML model using AWS CodeBuild

Activity- MLOps Action Plan Workbook`,
Outline_heading_3:`Module 3- MLOps Deployment`,
outline_detail_3:`Introduction to deployment operations

Model packaging

Inference

Lab- Deploy your model to production

SageMaker manufacturing versions

Deployment strategies

Deploying to the edge

Lab- Conduct A/B testing

Activity- MLOps Action Plan Workbook`,
Outline_heading_4:`Module 4- Model Monitoring and Operations`,
outline_detail_4:`
Lab- Troubleshoot your pipeline

The relevance of monitoring

Monitoring by design

Lab- Monitor your ML model

Human-in-the-loop

Amazon SageMaker Model Monitor

Demonstration- Amazon SageMaker Pipelines, Model Monitor, model registry, and Feature Store

Solving the Problem(s)

Activity- MLOps Action Plan Workbook`,
Outline_heading_5:`Module 5- Wrap-up`,
outline_detail_5:`Course review

Activity- MLOps Action Plan Workbook`,

skill_1:`Continuous Integration/Continuous Deployment (CI/CD)`,
skill_2:`Infrastructure as Code (IaC)`,
skill_3:`Model Versioning and Deployment`,
skill_4:`Automated Testing for Machine Learning Models`,
skill_5:`Monitoring and Logging for ML Systems`,
skill_6:`Scalable and Cost-effective ML Infrastructure on AWS`,
course_faq_name:`MLOps engineering on AWS`,
Faq_question_1:`Q- What do MLOps engineers do?`,
faq_answer_1:` A- MLOps Engineers (or ML Engineers) enable model deployment automation to production systems. The extent of automation varies with the company. MLOps engineers take a data scientist's model and make it accessible to the software that employs it.`,
Faq_question_2:`Q- What is the use of the ec2 instance? `,
faq_answer_2:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
Faq_question_3:`
Q- What is the role of AWS security?  `,
faq_answer_3:`A- AWS security provides chances to protect the data, check out security-related behavior and receive automated responses.  `,
Faq_question_4:`How does MLOps engineering benefit organizations using AWS?`,
faq_answer_4:`A: MLOps engineering on AWS enables organizations to accelerate the deployment of ML models, improve model performance, reduce operational overhead, and ensure scalability and reliability of ML systems.`,
Faq_question_5:`Q: What are the key components of MLOps engineering on AWS?`,

faq_answer_5:`A: Key components include Continuous Integration/Continuous Deployment (CI/CD) pipelines, automated model training and deployment, infrastructure as code (IaC), model versioning, monitoring, and automated testing`,
},


{
    id:167,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`The Machine Learning Pipeline On AWS`,
course_overview:`This course covers how the use of the iterative machine learning (ML) process pipeline to tackle a real business issue in a project-based learning setting. Professionals will learn about each part of the processing pipeline from instructor demos & presentations and then apply that knowledge to finish a project tackling one of three business problems- recommendation engines, fraud detection, or airline delays. By the end of the course, professionals will have successfully trained, developed, tuned, assessed, and deployed an ML model with Amazon SageMaker that resolves their specified business issue. Participants with little to no machine-learning experience or knowledge will profit from this course. Basic knowledge of Statistics will be beneficial.`,
certification:`This certification validates proficiency in deploying end-to-end machine learning workflows on Amazon Web Services (AWS). Candidates demonstrate expertise in data preprocessing, model training, evaluation, and deployment using AWS services such as SageMaker, S3, Lambda, and EC2. Successful completion attests to mastery of AWS-based ML pipelines.`,
prerequisite:`We recommend that registrants of this course have-

Fundamental knowledge of Python programming language

Fundamental comprehension of AWS Cloud infrastructure (Amazon S3 & Amazon CloudWatch)

Fundamental experience working in a Jupyter notebook environment`,
Outline_heading_1:`Module 1- Introduction to Machine Learning and the ML Pipeline`,
outline_detail_1:`Introduction to machine learning, covering use cases, kinds of machine learning, and essential ideas

Overview of the ML pipeline

Introduction to course projects and methodology`,
Outline_heading_2:`Module 2- Introduction to Amazon SageMaker`,
outline_detail_2:`Introduction to Amazon SageMaker

Demo- Amazon SageMaker and Jupyter notebooks

Hands-on- Amazon SageMaker and Jupyter notebooks`,
Outline_heading_3:`Module 3- Problem Formulation`,
outline_detail_3:`Introduction of problem formulation & choosing if ML is the correct answer

Transforming a commercial problem into an ML problem

Demo- Amazon SageMaker Ground Truth

Hands-on- Amazon SageMaker Ground Truth

Practice problem formulation

Formulate difficulties for projects`,
Outline_heading_4:`Checkpoint 1 and Answer Review Module 4- Pre-processing`,
outline_detail_4:`Overview of data collection and integration, and strategies for data pre-processing and visualization

Practice pre-processing

Pre-process project data

Class discussion regarding projects`,
Outline_heading_5:`Checkpoint 2 and Answer Review 

Module 5- Model Training`,
outline_detail_5:`Choosing the right algorithm

Formatting and dividing your data for training

Loss functions and gradient descent for improving your model

Demo- Create a training job in Amazon SageMaker`,
Outline_heading_6:`Module 6- Model Evaluation`,
outline_detail_6:`How to evaluate classification models

How to evaluate regression models

Practice model training and evaluation

Train and assess project models

Initial project presentations`,
Outline_heading_7:`Checkpoint 3 and Answer Review

Module 7- Feature Engineering and Model Tuning`,
outline_detail_7:`
Feature extraction, selection, construction, and transformation

Hyperparameter tweaking

Demo- SageMaker hyperparameter optimization

Practice feature engineering and model tuning

Apply feature engineering and model tuning to projects

Final project presentations`,
Outline_heading_8:`
Module 8- Deployment`,
outline_detail_8:`How to setup, infer, and monitor your model on Amazon SageMaker

Deploying ML at the edge

Demo- Creating an Amazon SageMaker endpoint

Post-assessment

Course wrap-up`,
skill_1:`AWS SageMaker expertise`,
skill_2:`Data preprocessing using AWS services`,
skill_3:`Model training on AWS infrastructure`,
skill_4:`Evaluation of machine learning models on AWS`,
skill_5:`Deployment of ML models using AWS Lambda`,
skill_6:`Management of ML pipeline workflows on AWS`,
course_faq_name:`The Machine Learning Pipeline On AWS`,
Faq_question_1:`Q- What is AWS database management?`,
faq_answer_1:` A- AWS fully managed database services provide continuous monitoring, self-healing storage, and automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_2:`Q- What is AWS data lake? `,
faq_answer_2:`A- A data lake is a centralized and secured repository that accumulates all your data, both in its original form and prepared for analysis. `,
Faq_question_3:` Q- What are the various techniques of data storage in AWS?`,
faq_answer_3:`A- There are three techniques of data storage, namely- –

Object storage

File storage

Block storage  `,
Faq_question_4:`Q- What is the use of the ec2 instance?  `,
faq_answer_4:`A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
Faq_question_5:`Q- What is the role of AWS security? `,

faq_answer_5:` A- AWS security provides chances to protect the data, check out security-related behavior and receive automated responses.`,
},




{
    id:168,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Advanced development on AWS`,
course_overview:`The Advanced Developing on AWS course takes use of the real-world settings of taking a legacy, on-premises monolithic program & converting it into a serverless microservices architecture. This 3-day advanced course covers advanced development subjects such as architecting for a cloud-native environment; deconstructing on-premises, legacy apps & repackaging them into cloud-based, cloud-native architectures; & utilizing the tenets of the Twelve-Factor Application approach.
Course level- Advanced`,
certification:`Certification in Advanced Development on AWS validates expertise in designing and building scalable, secure, and fault-tolerant applications on the Amazon Web Services platform. Covering advanced topics such as serverless architectures, microservices, and containerization, this credential demonstrates mastery in leveraging AWS services for complex software development projects`,
prerequisite:``,
Outline_heading_1:` Module 1- The cloud voyage`,
Outline_detail_1:`
● Common off-cloud architecture
 ● Introduction to Cloud Air
 ● Monolithic architecture
 ● Migration to the cloud
 ● Guardrails
 ● The six R’s of migration
 ● The Twelve-Factor Application Methodology
 ● Architectural styles & patterns 
● Overview of AWS Services 
● Interfacing with AWS Services 
● Authentication 
● Infrastructure as code & Elastic Beanstalk
● Demonstration- Walk through creating base infrastructure with AWS CloudFormation in the AWS console 
● Hands-on lab 1- Deploy your monolith application using AWS Elastic Beanstalk`,
Outline_heading_2:`Module 2- Gaining Agility`,
outline_detail_2:` ● DevOps
 ● CI/CD
 ● Application configuration 
● Secrets management 
● CI/CD Services on AWS 
● Demonstration- Demo AWS Secrets Manager`,
Outline_heading_3:`Module 3- Monolith to MicroServices `,
outline_detail_3:`● Microservices
 ● Serverless 
● A peek at Cloud Air
 ● Microservices with Lambda & API Gateway
 ● SAM 
● Strangling the Monolith
 ● Hands-on lab- with AWS Lambda to construct microservices`,
Outline_heading_4:`Module 4- Polyglot Persistence & Distributed Complexity `,
outline_detail_4:`● Polyglot persistence 
● DynamoDB recommended practices 
● Distributed complexity 
● Step functions`,
Outline_heading_5:`Module 5- Resilience & Scale`,
outline_detail_5:`● Decentralized data stores
 ● Amazon SQS 
● Amazon SNS
 ● Amazon Kinesis Streams 
● AWS IoT Message Broker
 ● Serverless event bus 
● Event sourcing & CQRS 
● Designing for resilience in the cloud 
● Hands-on lab- Exploring the AWS messaging choices`,
Outline_heading_6:`Module 6- Security & Observability `,
outline_detail_6:`● Serverless Compute with AWS Lambda 
● Authentication with Amazon Cognito 
● Debugging & traceability 
● Hands-on lab- Developing microservices on AWS
 ● Hands-on lab 8- Automating deployments with Cloud Formation`,
skill_1:`Advanced AWS Cloud Architecture`,
skill_2:`DevOps Automation with AWS Tools`,
skill_3:`Serverless Application Development on AWS`,
skill_4:`Advanced Networking and Security on AWS`,
skill_5:`Big Data Analytics with AWS Services`,
skill_6:`Machine Learning Deployment on AWS`,
course_faq_name:`Advanced development on AWS`,
Faq_question_1:`Q- What are the limits of Monolithic architecture?`,
faq_answer_1:`A- Monolithic design has a constraint in size & complexity. Applications are broad & tough to grasp & make the essential adjustments swiftly & accurately totally. This enormous size of the application can also slow down the start-up time.`,
Faq_question_2:`Q- What is the role of Microservices?`,
faq_answer_2:`A- Microservice Architecture is an architectural approach that arranges an application as a series of highly maintained & tested, loosely linked, and independently deployable services.`,
Faq_question_3:`Q- What is meant by monolithic architecture?`,
faq_answer_3:`A- Monolith has the connotation of composed fully in one piece. The Monolithic architecture depicts a single-tiered software application in which diverse components are compiled into a single program from a single platform.`,
Faq_question_4:` Q- What is AWS data lake?`,
faq_answer_4:` A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_5:` Q- What are the various techniques of data storage in AWS?`,

Faq_answer_5:` A- There are three methods of data storage, namely – ▪ Object storage ▪ File storage ▪ Block storage`,
},





{
    id:169,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Developing on AWS`,
course_overview:`This course teaches professional developers how to programmatically connect with AWS services to construct web applications. It walks professionals through a high-level architectural debate on resource selection & digs deep into leveraging the Command Line Interface (AWS CLI) and AWS Software Development Kits (AWS SDKs) to build & deploy your cloud apps. Professionals will create an example application during this course, learning how to establish permissions to the development environment, introduce business logic to process data via AWS core services, configure user authentications, deploy to the AWS cloud, & debug to fix application difficulties. The course contains code examples to help professionals execute the design patterns & solutions taught in the course. The labs reinforce essential course topics & assist professionals create solutions using the AWS SDK for Java, .Net, & Python, the AWS CLI, and the AWS Management Console.`,
certification:`Earn your Developing on AWS certification to demonstrate proficiency in designing, building, and deploying applications on Amazon Web Services. Validate your expertise in using AWS SDKs, APIs, and other tools to develop secure and scalable cloud-based solutions. Stand out as a skilled AWS developer with this recognized credential.`,
prerequisite:`We recommend that registrants of this course have- 
● AWS Technical Essentials
 ● Programming experience in one of the following languages-
 ● Python 
● .NET
 ● Java 
● Working understanding of AWS core services`,
Outline_heading_1:`Module 1- Course Overview`,
outline_detail_1:`● Logistics
 ● Student Resources 
● Agenda
● Introductions`,
Outline_heading_2:`Module 2- Building a Web Application on AWS`,
outline_detail_2:`● Discuss the architecture of the application professionals are going to create during this course ● Explore the AWS services needed to form your web application
 ● Discover how to store, administer, & host your web application `,
Outline_heading_3:`Module 3- Getting Started with Development on AWS`,
outline_detail_3:`● Describe how to access AWS services programmatically 
● List various programmatic patterns & how they enable efficiencies within AWS SDKs & AWS CLI 
● Explain the value of AWS Cloud9`,
Outline_heading_4:`Module 4- Getting Started with Permissions`,
outline_detail_4:`● Review AWS Identity & Access Management (IAM) features & components permissions to support a development environment.
● Demonstrate how to verify AWS IAM permissions
 ● Configure your IDEs & SDKs to support a development environment 
● Demonstrate accessing AWS services using SDKs & AWS Cloud9 
● Lab 1- Configure the Developer Environment 
● Connect to a developer environment
 ● Verify that the IDE & the AWS CLI are installed & configured to use the application profile 
● Authenticate that the necessary permissions have been permitted to run AWS CLI commands ● Allocate an AWS IAM policy to a role to discard an Amazon S3 bucket`,
Outline_heading_5:`Module 5- Getting Started with Storage`,
outline_detail_5:`● Explain the essential concepts of Amazon S3 
● List the alternatives for securing data using Amazon S3 
● Define SDK dependencies for your code 
● Describe how to connect to the Amazon S3 service 
● Describe request & response objects`,
Outline_heading_6:`Module 6- Processing Your Storage Operations`,
outline_detail_6:` ● Perform key bucket & object operations
 ● Explain how to handle multiple & large objects 
● Create & configure an Amazon S3 bucket to host a static website
 ● Grant temporary access to your objects 
● Demonstrate performing Amazon S3 operations using SDKs 
● Lab 2- Develop Solutions via Amazon S3 
● Interact with Amazon S3 programmatically via AWS SDKs & the AWS CLI 
● Create a bucket using waiters & verify service exceptions codes 
● Form the needed requests to upload an Amazon S3 object with metadata attached 
● Form requests to download an object from the bucket, process data, & upload the object back to the bucket 
● Configure a bucket to host the website & sync the source files with the help of AWS CLI ● Add IAM bucket policies to approach the S3 website.`,
Outline_heading_7:`Module 7- Getting Started with Databases`,
outline_detail_7:`● Describe the basic components of DynamoDB 
● Explain how to connect to DynamoDB
 ● Describe how to generate a request object
 ● Explain how to read a response object 
● List the most typical troubleshooting exceptions`,
Outline_heading_8:`Module 8- Processing Your Database Operations `,
outline_detail_8:`● Develop programs to interact with DynamoDB via AWS SDKs 
● Perform CRUD operations to access tables, indexes, & data 
● Explain ideal developer practices when accessing DynamoDB 
● Review caching options for DynamoDB to enhance performance 
● Perform DynamoDB operations using SDK 
● Lab 3- Develop Solutions with the help of Amazon DynamoDB
● Interact with Amazon DynamoDB programmatically via low-level, document, & complex APIs in your programs 
● Retrieve items from a table with the help of key attributes, expressions, filters, & paginations
 ● Insert a table by reading JSON objects from a file 
● Look for items from a table based on filters, expressions, key attributes, & paginations 
● Update items by adding new attributes & changing data conditionally 
● Access DynamoDB data using PartiQL & object-persistence models where applicable`,
Outline_heading_9:`t-persistence models where applicable`,
outline_detail_9:` ● Develop a Lambda function using SDKs
 ● Configure triggers & permissions for Lambda functions
● Test, deploy, & monitor Lambda functions 
● Lab 4- Develop Solutions Using AWS Lambda Functions
 ● Create AWS Lambda functions & interact programmatically using AWS SDKs & AWS CLI
 ● Configure AWS Lambda functions to use environment variables & combine with other services 
● Create Amazon S3 pre-signed URLs via AWS SDKs & authenticate the access to bucket objects 
● Deploy AWS Lambda functions with .zip file archives by means of your IDE & test as needed ● Invoke AWS Lambda functions utilising the AWS Console & AWS CLI`,
Outline_heading_10:`Module 10- Managing the APIs`,
outline_detail_10:` ● Explain the key components of API Gateway 
● Develop API Gateway resources to combine with AWS services 
● Configure API request & response calls for your application endpoints 
● Test API resources & deploy your application API endpoint
 ● Demonstrate generating API Gateway resources to interact with your application APIs 
● Lab 5- Develop Solutions Using Amazon API Gateway 
● Create RESTful API Gateway resources & configure CORS for your application 
● Combine API methods with AWS Lambda functions to process application data 
● Configure mapping templates to convert the pass-through data during method integration 
● Generate a request model for API methods to make sure that the pass-through data format complies with application rules 
● Deploy the API Gateway to a stage & validate the results using the API endpoint`,
Outline_heading_11:`Module 11- Building a Modern Application `,
outline_detail_11:`● Describe the challenges with traditional architectures 
● Describe the microservice architecture & benefits 
● Explain various approaches for designing microservice applications
 ● Explain the steps involved in decoupling monolithic applications
 ● Demonstrate the orchestration of Lambda Functions via AWS Step Functions`,
Outline_heading_12:`Module 12- Granting Access to Your Application Users `,
outline_detail_12:`● Analyze the evolution of security protocols 
● Explore the authentication process using Amazon Cognito 
● Manage user access & authorize serverless APIs
 ● Observe best practices for implementing Amazon Cognito 
● Demonstrate the integration of Amazon Cognito & review JWT tokens 
● Lab 6- Capstone – Complete the Application Build 
● Create a User pool & an Application Client for your web application using 
● Add new users & confirm their ability to sign in with the help of Amazon Cognito CLI
 ● Configure API Gateway methods to utilize Amazon Cognito as the authorizer
 ● Authenticate JWT authentication tokens are created during API Gateway calls
 ● Develop API Gateway resources quickly using a Swagger importing tactic 
● Set up your web application frontend to utilize Amazon Cognito & API Gateway configurations & verify the entire application functionality`,
Outline_heading_13:`Module 13- Deploying Your Application`,
outline_detail_13:`● Recognize risks associated with conventional software development practices 
● Comprehend DevOps methodology 
● Configure the AWS SAM template to deploy a serverless application
 ● Explain various application deployment tactics 
● Demonstrate deploying a serverless application with the help of AWS SAM`,
Outline_heading_14:`Module 14- Observing Your Application `,
outline_detail_14:`● Differentiate between monitoring & observability 
● Evaluate why observability is necessary for modern development & key components 
● Comprehend CloudWatch’s part in configuring the observability
 ● Illustrate using CloudWatch Application Insights to monitor applications 
● Demonstrate using X-Ray to debug your applications 
● Lab 7- Observe the Application with the help of AWS X-Ray
 ● Instrument your application code to utilize AWS X-Ray abilities 
● Allow your application deployment package to produce logs
 ● Comprehend the key components of an AWS SAM template & deploy your application 
● Construct AWS X-Ray service maps to observe the end-to-end processing behavior of your application 

● Analyze & debug application issues using AWS X-Ray traces & annotations

`,
Outline_heading_15:`Module 15- Course Wrap-up`,
outline_detail_15:``,
skill_1:`Cloud Architecture`,
skill_2:`DevOps Automation`,
skill_3:`Serverless Computing`,
skill_4:`Data Analytics on AWS`,
skill_5:`Security and Compliance on AWS`,
skill_6:`Machine Learning on AWS`,
course_faq_name:`Developing on AWS `,
Faq_question_1:`Q- What type of audience is expected for the training?`,
faq_answer_1:`A- This training is beneficial for IT company decision-makers along with-It professionals who have working experience in AWS.`,
Faq_question_2:` Q- What prerequisite knowledge do I need to have to attend the training?`,
faq_answer_2:`A- One should have followed required knowledge-- ▪ Working understanding of IT infrastructure ideas ▪ Familiarity with fundamental finance concepts ▪ Familiarity with basic IT security principles`,
Faq_question_3:` Q- What is the AWS data lake?`,
faq_answer_3:` A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_4:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_4:` A- There are three methods of data storage, namely- – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_5:` Q- What is the use of the ec2 instance?`,

faq_answer_5:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
},




{
    id:170,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Authoring Visual Analytics using Amazon QuickSight`,
course_overview:`In this training, experts will form a data visualization solution with Amazon QuickSight. QuickSight enables everyone in your business to interpret your data by exploring via interactive dashboards, asking queries in natural language, or automatically to seek for patterns & outliers driven by machine learning. This course covers connecting to data sources, constructing visualizations, designing interactivity, & creating calculations. Professionals will learn how to apply security best practices to their analyses. Professionals will also study the machine learning capabilities embedded within QuickSight.`,
certification:`Demonstrate proficiency in creating insightful visualizations, dashboards, and data stories with Amazon QuickSight. Learn to leverage data visualization techniques, explore datasets, and effectively communicate findings. Gain recognition for mastering QuickSight's features to drive data-driven decisions.`,
prerequisite:`Students having a minimum one-year of experience producing visual analytics will benefit from this course. We propose that participants of this course have- ● Completed Data Analytics Fundamentals`,
Outline_heading_1:`Module 1- Introduction & Overview of Amazon QuickSight`,
outline_detail_1:`● Introducing Amazon QuickSight 
● Why use Amazon QuickSight for data visualization`,
Outline_heading_2:`Module 2- Getting Started with Amazon QuickSight `,
outline_detail_2:`● Interacting with Amazon QuickSight 
● Loading data into Amazon QuickSight 
● Visualizing data in Amazon QuickSight 
● Demonstration- Walkthrough of Amazon QuickSight interface 
● Practice Lab- Create your first dashboard`,
Outline_heading_3:`Module 3- Enhancing & Adding Interactivity to Your Dashboard`,
outline_detail_3:`● Enhancing your dashboard 
● Demonstration- Optimize the size, shape, & aesthetics of a dashboard
 ● Enhancing visualizations with interaction 
● Demonstration- Walkthrough of dashboard interactivity features 
● Practice Lab- Enhancing your dashboard`,
Outline_heading_4:`Module 4- Preparing Datasets for Analysis `,
outline_detail_4:`● Working with datasets 
● Demonstration- Transform your datasets for analysis
 ● Practice Lab- Preparing data for analysis`,
Outline_heading_5:`Module 5- Performing Advanced Data Calculations `,
outline_detail_5:`● Transform data by use of advanced calculations 
● Practice Lab- Performing advanced data calculations 
● Activity- Designing a Visual Analytics Solution
Day 2`,
Outline_heading_6:`Module 6- Overview of Amazon QuickSight Security & Access Control`,
outline_detail_6:`● Overview of Amazon QuickSight security & access control 
● Dataset access control in Amazon QuickSight 
● Lab- Implementing access control in Amazon QuickSight visualizations`,
Outline_heading_7:`Module 7- Exploring machine learning capabilities `,
outline_detail_7:`● Introducing Machine Learning (ML) insights 
● Natural Language Query with QuickSight Q
 ● Demonstration- Using QuickSight Q
● Lab- Using machine learning for anomaly detection & forecasting
 ● End of day challenge labs
● Join data sources together 
● Create a dashboard
 ● Enhance the dashboard & add interactivity 
● Perform advanced data calculations 
● Integrate machine learning technologies into the dashboard`,

skill_1:`Data Visualization Mastery`,
skill_2:`Amazon QuickSight Proficiency`,
skill_3:`Storytelling with Data`,
skill_4:`Dashboard Design Expertise`,
skill_5:`Analytical Thinking`,
skill_6:`User Experience (UX) Optimization`,
course_faq_name:`Authoring Visual Analytics using Amazon QuickSight `,
Faq_question_1:`Q- What is Amazon QuickSight used for?`,
faq_answer_1:`A- Amazon QuickSight is a simple-to-use, very fast, cloud-powered business analytics solution that makes it easy for the entire workforce inside a company to execute ad-hoc analysis, develop visualizations, & rapidly acquire business understandings from their data anytime, on any device.`,
Faq_question_2:`Q- How do you make visuals in QuickSight?`,
faq_answer_2:`A- To generate a new visual
 On the analysis page, choose the dataset that you wish to use from the dataset list at the top of the Fields list pane. For further details, see Adding a dataset to an analysis. Choose Add in the application bar, & then choose to Add visual. A new, blank visual is formed & receives emphasis.`,
Faq_question_3:`Q- What is the use of ec2 instance?`,
faq_answer_3:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
Faq_question_4:`
 Q- What is the role of AWS security?`,
faq_answer_4:` A- AWS security provides ways to protect the data, check out security-related activity & receive automatic responses.`,
},




{
    id:171,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Building Big Data Analytics Solutions on AWS`,
course_overview:`In this course, professionals will learn to construct batch data analytics solutions utilizing Amazon EMR, an enterprise-grade Apache Spark & Apache Hadoop managed service. Professionals will discover how Amazon EMR combines with open-source projects such as Apache Hive, Hue, & HBase, & with AWS services such as AWS Glue & AWS Lake Formation. The course discusses data collecting, cataloging, ingestion, storage, & processing components in the context of Spark & Hadoop. Professionals will learn to leverage EMR Notebooks to support both analytics & machine learning workloads. Professionals will also learn to apply security, performance, & cost management ideal practices to the operation of Amazon EMR.
Course level- Intermediate`,
certification:`Building Big Data Analytics Solutions on AWS. Master AWS services like Amazon EMR, Redshift, Athena, and Glue to design scalable data analytics solutions. Gain expertise in data processing, storage, and analysis to propel your career in big data. Validate your skills with industry-recognized certification. Start today!`,
prerequisite:`Students with a minimum of one year of experience handling open-source data frameworks such as Apache Spark or Apache Hadoop will benefit from this course.
We advise the AWS Hadoop Fundamentals course for people that need a refresher on Apache Hadoop.
 We suggest that participants of this course have: 
● Completed either AWS Technical Essentials or Architecting on AWS 
● Completed either Building Data Lakes on AWS or Getting Started with AWS Glue`,
Outline_heading_1:`Module A: Overview of Data Analytics & the Data Pipeline `,
outline_detail_1:` ● Using Amazon EMR in analytics solutions 
● Amazon EMR cluster architecture 
● Interactive Demo 1: Launching an Amazon EMR cluster 
● Cost management strategies`,
Outline_heading_2:`Module 2: Data Analytics Pipeline Using Amazon EMR: Ingestion & Storage `,
outline_detail_2:`● Storage optimization with Amazon EMR
 ● Data ingestion techniques`,
Outline_heading_3:`Module 3: High-Performance Batch Data Analytics Using Apache Spark on Amazon EMR `,
outline_detail_3:`● Apache Spark on Amazon EMR use cases
 ● Why Apache Spark on Amazon EMR 
● Spark concepts 
● Interactive Demo 2: Connect to an EMR cluster & conduct Scala commands using the Spark shell
 ● Transformation, processing, & analytics 
● Using notebooks with Amazon EMR 
● Practice Lab 1: Low-latency data analytics using Apache Spark on Amazon EMR`,
Outline_heading_4:`Module 4: Processing & Analyzing Batch Data with Amazon EMR & Apache Hive `,
outline_detail_4:`Module 4: Processing & Analyzing Batch Data with Amazon EMR & Apache Hive `,
Outline_heading_5:`Module 5: Serverless Data Processing `,
outline_detail_5:`● Serverless data processing, transformation, & analytics 
● with AWS Glue with Amazon EMR workloads 
● Practice Lab 3: Orchestrate data processing in Spark with AWS Step Functions`,
Outline_heading_6:`Module 6: Security & Monitoring of Amazon EMR Clusters `,
outline_detail_6:`● Securing EMR clusters 
● Interactive Demo 3: Client-side encryption with EMRFS 
● Monitoring & troubleshooting Amazon EMR clusters
 ● Demo: Reviewing Apache Spark cluster history`,
Outline_heading_7:`Module 7: Designing Batch Data Analytics Solutions `,
outline_detail_7:`● Batch data analytics use cases 
● Activity: Designing a batch data analytics workflow`,
Outline_heading_8:`Module 8: Developing Modern Data Architectures on AWS`,
outline_detail_8:` ● Modern data architectures`,
skill_1:`Data Modeling`,
skill_2:`ETL (Extract, Transform, Load) Processes`,
skill_3:`AWS Services Integration`,
skill_4:`Data Visualization`,
skill_5:`Data Governance`,
skill_6:`Performance Optimization`,
course_faq_name:`Building Big Data Analytics Solutions on AWS `,
Faq_question_1:`Q- What is AWS data lake?`,
faq_answer_1:` A: A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_2:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_2:` A: There are three ways of data storage, namely: – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_3:` Q- What is the use of the ec2 instance?`,
faq_answer_3:` A: EC2 is a service that enables corporate clients to run application applications in the computing environment.`,
Faq_question_4:` Q- What is the role of AWS security`,
faq_answer_4:` A: AWS security provides chances to protect the data, check out security-related activity & obtain automated response`,
},




{
    id:172,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Building Data Analytics Solutions Using Amazon Redshift`,
course_overview:`In this course, experts will design a data analytics solution leveraging Amazon Redshift, a cloud data warehouse service. The course focusses on the data collection, ingestion, categorization, storage, & processing components of the analytics pipeline. Professionals will learn to combine Amazon Redshift with a data lake to handle both analytics & machine learning workloads. Professionals will also learn to apply security, performance, & cost management ideal practices to the operation of Amazon Redshift.`,
certification:`Building Data Analytics Solutions Using Amazon Redshift validates expertise in leveraging Redshift for efficient data analysis. Gain skills in data warehousing, querying, and optimizing performance. Become proficient in designing scalable solutions to extract valuable insights, enhancing decision-making capabilities. Elevate your career with this sought-after certification.`,
prerequisite:`Students having a minimum of one year of experience operating data warehouses will benefit from this course.
We suggest that registrants of this course have- 
● Completed either AWS Technical Essentials or Architecting on AWS 
● Completed Building Data Lakes on AWS`,
Outline_heading_1:`Module A- Overview of Data Analytics & the Data Pipeline `,
outline_detail_1:`● Data analytics use cases 
● Using the data pipeline for analytics`,
Outline_heading_2:`Module 1- Using Amazon Redshift in the Data Analytics Pipeline `,
outline_detail_2:`● Why Amazon Redshift for data warehousing?
 ● Overview of Amazon Redshift`,
Outline_heading_3:`Module 2- Introduction to Amazon Redshift`,
outline_detail_3:` ● Amazon Redshift architecture 
● Interactive Demo 1- Touring the Amazon Redshift console 
● Amazon Redshift features 
● Practice Lab 1- Load & query data in an Amazon Redshift cluster`,
Outline_heading_4:`Module 3- Ingestion & Storage`,
outline_detail_4:`● Ingestion
 ● Interactive Demo 2- Connecting your Amazon Redshift cluster using a Jupyter notebook with 
● Data API 
● Data distribution & storage 
● Interactive Demo 3- Analyzing semi-structured data using the SUPER data type
 ● Querying data in Amazon Redshift 
● Practice Lab 2- Data analytics using Amazon Redshift Spectrum`,
Outline_heading_5:`Module 4- Processing & Optimizing Data `,
outline_detail_5:`● Data transformation
 ● Advanced querying
 ● Practice Lab 3- Data transformation & querying in Amazon Redshif
t ● Resource management
 ● Interactive Demo 4- Applying mixed workload management on Amazon Redshift
 ● Automation & Optimization 
● Interactive demo 5- Amazon Redshift cluster scaling from the dc2.large to ra3.xlplus cluster`,
Outline_heading_6:`Module 5- Security & Monitoring of Amazon Redshift Clusters `,
outline_detail_6:`● Securing the Amazon Redshift cluster
 ● Monitoring & troubleshooting Amazon Redshift clusters`,
Outline_heading_7:`Module 6- Designing Data Warehouse Analytics Solutions`,
outline_detail_7:`● Data warehouse use case review 
● Activity- Designing a data warehouse analytics process
Module B- Developing Modern Data Architectures on AWS
 ● Modern data architecture`,
skill_1:`SQL Proficiency`,
skill_2:`Data Warehousing Design`,
skill_3:`Amazon Redshift Administration`,
skill_4:`ETL Development`,
skill_5:`Data Visualization`,
skill_6:`Performance Optimization`,
course_faq_name:`Building Data Analytics Solutions Using Amazon Redshift `,
Faq_question_1:`Q- What is Amazon Redshift utilized for?`,
faq_answer_1:`A- Tens of thousands of clients use Amazon Redshift daily to run SQL analytics in the cloud, analyzing exabytes of data for business understanding. Whether your expanding data is collected in operational data stores, streaming data services, data lakes, or third-party datasets, Amazon Redshift supports with securely accessing, merging, & sharing data with the tiniest movement or copying. Amazon Redshift is extensively linked with AWS database, analytics, & machine learning services to utilize Zero-ETL techniques or aid you in accessing data in place for near real-time analytics, construct machine learning models in SQL, & allow Apache Spark analytics utilizing data in Redshift. Amazon Redshift Serverless helps your data scientists, engineers, developers, & analysts to get started immediately & grow analytics quickly in a zero-administration environment. With its Massively Parallel Processing (MPP) engine & architecture that separates compute & storage for efficient scaling, & machine learning-driven performance innovations (for example- Auto Materialized Views), Amazon Redshift is built for scale & delivers up to 5x more reasonable price performance than other cloud data warehouses.`,
Faq_question_2:`Q- What are the deployment options for Amazon Redshift?
`,
faq_answer_2:`Amazon Redshift is a fully managed service & offers both provisioned & serverless alternatives, making it more effective for you to conduct & grow analytics without having to handle your data warehouse. One can turn up a fresh Amazon Redshift Serverless endpoint to instantly provision the data warehouse in seconds or use the supplied choice for predictable workloads.`,
Faq_question_3:`Q- What is the use of the ec2 instance?`,
faq_answer_3:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
Faq_question_4:` Q- How does the performance of Amazon Redshift compare to that of competing data warehouses?`,
faq_answer_4:`TPC-DS benchmark results indicate that Amazon Redshift gives the perfect pricing performance, even for a modest 3 TB dataset. Amazon Redshift delivers up to 5x better price implementation than other cloud data warehouses. You can gain from Amazon Redshift’s excellent price performance from the start without manual configuration. Based on our performance fleet telemetry, we know that most workloads are short query workloads (that run in less than 1 second). For these workloads, the current benchmarks indicate that Amazon Redshift gives up to 7x more reasonable pricing performance on heightened concurrency and low dormancy workloads than any other cloud data warehouses.`,
},




{
    id:173,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Building Data Lakes on AWS`,
course_overview:`In this course, professionals will learn how to construct an operational data lake that supports the analysis of both structured & unstructured data. You will understand the components & functionality of the services involved in establishing a data lake. Professionals will utilize AWS Lake Formation to construct a data lake, AWS Glue to form a data catalog, & Amazon Athena to analyze data. The course lectures & labs deepen you’re understanding by exploring numerous common data lake architectures.
Course level- Intermediate`,
certification:`Building Data Lakes on AWS and master the art of efficiently storing, managing, and analyzing vast volumes of data. Learn industry best practices for designing, implementing, and optimizing data lakes using AWS services. Enhance your skills and career prospects in the realm of big data analytics.`,
prerequisite:`We propose that participants of this course have the following:
● done the AWS Technical Essentials classroom course 
● One-year of experience creating data analytics pipelines or have done the Data Analytics Fundamentals digital course`,
Outline_heading_1:`Module 1- Introduction to data lakes `,
outline_detail_1:`● Describe the usefulness of data lakes
 ● Describe the components of a data lake 
● Recognize common designs built on data lakes`,
Outline_heading_2:`Module 2: Data ingestion, cataloging, & Preparation `,
outline_detail_2:`● Describe the relationship between data lake storage & data ingestion
 ● Describe AWS Glue crawlers & how they are used to construct a data catalog
 ● Identify data formatting, partitioning, & compression for effective storage & query 
● Lab 1- Set up a basic data lake`,
Outline_heading_3:`Module 3- Data processing & analytics`,
outline_detail_3:` ● Identify how data processing pertains to the data lake
● Use AWS Glue to handle data within the data lake
 ● Explain how to use Amazon Athena to analyze data in a data lake`,
Outline_heading_4:`Module 4- Building a data lake with AWS Lake Formation`,
outline_detail_4:` ● Describe the characteristics & benefits of AWS Lake Formation
 ● Use AWS Lake Formation to construct a data lake
 ● Understand the AWS Lake Formation security model 
● Lab 2- Build a data lake with AWS Lake Formation`,
Outline_heading_5:`Module 5- Additional Lake Formation configurations`,
outline_detail_5:` ● Automate AWS Lake Formation using blueprints & workflows 
● Apply security & access restrictions to AWS Lake Formation
 ● Match records using AWS Lake Formation FindMatches
 ● Visualize data with Amazon QuickSight
 ● Lab 3- Automate data lake building using AWS Lake Formation blueprints
● Lab 4- Data visualization with Amazon QuickSight `,
Outline_heading_6:`Module 6: Architecture & course review`,
outline_detail_6:` ● Post-course knowledge check
 ● Architecture review 
● Course review`,

skill_1:`Data Ingestion`,
skill_2:`Data Storage Management`,
skill_3:`Data Transformation`,
skill_4:`Data Security and Compliance`,
skill_5:`Data Analytics and Querying`,
skill_6:`Data Visualization and Reporting`,
course_faq_name:`Building Data Lakes on AWS `,
Faq_question_1:`Q- What is AWS data lake?`,
faq_answer_1:` A- A data lake is a centralized & secured repository that piles all your data, both in its original form & prepared for analy`,
Faq_question_2:` Q- What are the various techniques of data storage in AWS?`,
faq_answer_2:` A- There are three methods of data storage, namely – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_3:` Q- What is the use of the ec2 instance?`,
faq_answer_3:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
Faq_question_4:`Q- What is the role of AWS security`,
faq_answer_4:` A- AWS security provides ways to protect the data, check out security-related activity & receive automatic responses.`,
},





{
    id:174,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Building Streaming Data Analytics Solutions on AWS`,
course_overview:`In this course, professionals will learn to develop streaming data analytics solutions utilizing AWS services, including Amazon Kinesis & Amazon Managed Streaming for Apache Kafka (Amazon MSK). Amazon Kinesis is a hugely scalable & durable real-time data streaming service. Amazon MSK delivers a secure, fully managed, & highly available Apache Kafka service. Professionals will study how Amazon Kinesis & Amazon MSK integrates with AWS services such as AWS Glue & AWS Lambda. The course tackles the stream storage, streaming data ingestion, & stream processing components of the data analytics pipeline. Professionals will also learn to apply security, performance, & cost management ideal practices to the operation of Kinesis & Amazon MSK.`,
certification:`
Earn your certification in Building Streaming Data Analytics Solutions on AWS and master the skills needed to design, build, and manage real-time data processing solutions. Gain expertise in AWS services like Kinesis, Glue, Lambda, and more. Validate your proficiency in architecting high-performance streaming data analytics systems with this prestigious certification.`,
prerequisite:`We propose that participants of this course have the following-
 ● At least one-year of data analytics experience or direct expertise building real-time applications or streaming analytics solutions. We offer the Streaming Data Solutions on AWS whitepaper for the ones who need a refresher on streaming fundamentals.
● Finished either Architecting on AWS or Data Analytics Fundamentals 
● Completed Building Data Lakes on AWS `,
Outline_heading_1:`Module A: Overview of Data Analytics & the Data Pipeline`,
outline_detail_1:` ● Data analytics use cases 
● Using the data pipeline for analytics`,
Outline_heading_2:`Module 1- Using Streaming Services in the Data Analytics Pipeline`,
outline_detail_2:`● The importance of streaming data analytics 
● The streaming data analytics pipeline 
● Streaming concepts`,
Outline_heading_3:`Module 2- Introduction to AWS Streaming Services `,
outline_detail_3:`● Streaming data services in AWS 
● Amazon Kinesis in analytics solutions 
● Demonstration- Explore Amazon Kinesis Data Streams 
● Practice Lab- Setting up a streaming delivery pipeline with Amazon Kinesis 
● Using Amazon Kinesis Data Analytics 
● Introduction to Amazon MSK
● Overview of Spark Streaming`,
Outline_heading_4:`
 Module 3- Using Amazon Kinesis for Real-time Data Analytics`,
outline_detail_4:` ● Exploring Amazon Kinesis using a clickstream workload
 ● Creating Kinesis data & delivery streams 
● Demonstration- Understanding producers & consumers 
● Building stream producers 
● Building stream consumers
 ● Building & deploying Flink applications in Kinesis Data Analytics
 ● Demonstration- Explore Zeppelin notebooks for Kinesis Data Analytics
 ● Practice Lab- Streaming analytics with Amazon Kinesis Data Analytics & Apache Flink`,
Outline_heading_5:`Module 4- Securing, Monitoring, & Optimizing Amazon Kinesis `,
outline_detail_5:`● Optimize Amazon Kinesis to gain meaningful business insights 
● Security & monitoring recommended practices`,
Outline_heading_6:`Module 5- Using Amazon MSK in Streaming Data Analytics Solutions
`,
outline_detail_6:` ● Use cases for Amazon MSK 
● Creating MSK clusters 
● Demonstration- Provisioning an MSK Cluster
 ● Ingesting data into Amazon MSK
 ● Practice Lab- Introduction to access control with Amazon MSK 
● Transforming & processing in Amazon MSK`,
Outline_heading_7:`Module 6: Securing, Monitoring, & Optimizing Amazon MSK`,
outline_detail_7:` ● Optimizing Amazon MSK 
● Demonstration: Scaling up Amazon MSK storage 
● Practice Lab: Amazon MSK streaming pipeline & application deployment
 ● Security & Monitoring 
● Demonstration: Monitoring an MSK cluster`,
Outline_heading_8:`Module 7- Designing Streaming Data Analytics Solutions `,
outline_detail_8:`● Use case review 
● Class Exercise- Designing a streaming data analytics process`,
Outline_heading_9:`Module 8- Developing Modern Data Architectures on AWS`,
outline_detail_9:` ● Modern data architectures`,
skill_1:`AWS CloudFormation`,
skill_2:`Apache Kafka`,
skill_3:`Apache Flink`,
skill_4:`Amazon Kinesis`,
skill_5:`Real-time Data Processing`,
skill_6:`Data Streaming Architecture`,
course_faq_name:`Building Streaming Data Analytics Solutions on AWS`,
Faq_question_1:`Q- What is AWS data lake?`,
faq_answer_1:` A: A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_2:` Q- What are the various techniques of data storage in AWS?`,
faq_answer_2:` A: There are three ways of data storage, namely: – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_3:` Q- What is the use of the ec2 instance?`,
faq_answer_3:` A: EC2 is a service that enables corporate clients to run application applications in the computing environment.`,
Faq_question_4:`Q- What is the role of AWS security?`,
faq_answer_4:` A: AWS security provides chances to protect the data, check out security-related activity & obtain automated responses.`,
},




{
    id:175,
categories: `AWS`,
course_detail_image:course_images.programing_1,
course_name:`Planning-and-designing-databases-on-AWS`,
course_overview:`Professionals will learn about the process of planning & designing both relational & non relational databases. Professionals will understand the architectural concerns for hosting databases on Amazon Elastic Compute Cloud (Amazon EC2). Professionals will learn about our relational database services, including Amazon Relational Database Service (Amazon RDS), Amazon Aurora, & Amazon Redshift. Professionals will also learn about our nonrelational database services, including Amazon DocumentDB, Amazon DynamoDB, Amazon ElastiCache, Amazon Neptune, & Amazon QLDB. By the end of this course, professionals will be aware with the planning & design needs of all 8 of these AWS database services, their advantages & cons, and how to decide which AWS database service is right for your workloads.
Course level- Intermediate`,
certification:`Earn certification in Planning and Designing Databases on AWS to master database architecture, scalability, and performance optimization. Gain expertise in AWS database services like Amazon RDS, Amazon Aurora, and Amazon DynamoDB. Demonstrate proficiency in designing resilient and cost-effective database solutions tailored to diverse business needs.`,
prerequisite:`We recommend that attendees of this course have- 
● Familiarity with AWS Database Services, equivalent to AWS Database Offerings digital training
 ● Understanding of database design concepts, &/or data modeling for relational or non relational databases 
● Familiarity with cloud computing concepts
 ● Familiarity with general networking & encryption concepts 
● Understanding of the three V’s of data (volume, velocity, & variety) 
● Familiarity with basic data analytics concepts, equivalent to Data Analytics Fundamentals digital training
 ● Understanding of general architecting best practices & the AWS Well-Architected Framework, equivalent to Architecting on AWS classroom training`,
Outline_heading_1:`Module 1- Database Concepts & General Guidelines `,
outline_detail_1:`● Databases on the cloud
 ● Database design principles
 ● Transactional compliance`,
Outline_heading_2:`Module 2- Database Planning & Design`,
outline_detail_2:` ● Workload requirements
 ● Design considerations `,
Outline_heading_3:` Module 3- Databases on Amazon EC2 `,
outline_detail_3:`● Amazon EC2 for hosting databases`,
Outline_heading_4:`Module 4- Purpose-Built Databases`,
outline_detail_4:` ● The route to AWS
 ● Data modeling basics `,
Outline_heading_5:`Module 5- Databases on Amazon RDS `,
outline_detail_5:`● Amazon RDS databases 
● Amazon RDS differentiating features 
● Amazon RDS Design considerations 
● Lab 1- Working with Amazon RDS databases`,
Outline_heading_6:`Module 6- Databases in Amazon Aurora `,
outline_detail_6:`● Amazon Aurora databases
 ● Aurora distinguishing features
 ● Aurora design considerations`,
Outline_heading_7:`Module 7- Databases in Amazon Aurora (continued) `,
outline_detail_7:`● Lab 2- Working with Amazon Aurora databases  `,
Outline_heading_8:`Module 8- Databases in Amazon DocumentDB (with MongoDB compatibility)`,
outline_detail_8:`● Amazon DocumentDB design considerations 
● Lab 3- Working with Amazon DocumentDB databases`,
Outline_heading_9:`Module 9- Amazon DynamoDB Tables`,
outline_detail_9:` ● Amazon DynamoDB
 ● DynamoDB data modeling
 ● DynamoDB distinguishing features
 ● DynamoDB design considerations 
● Lab 4- Working with Amazon DynamoDB Tables
Day Three`,
Outline_heading_10:`Module 10- Databases in Amazon Neptune `,
outline_detail_10:`● Amazon Neptune 
● Neptune design considerations `,
Outline_heading_11:`Module 11- Databases in Amazon Quantum Ledger Database (Amazon QLDB) `,
outline_detail_11:`● Amazon Quantum Ledger Database (Amazon QLDB) 
● Amazon QLDB Design Considerations`,
Outline_heading_12:`Module 12- Databases in Amazon ElastiCache`,
outline_detail_12:`● Amazon ElastiCache 
● ElastiCache for Memcached 
● ElastiCache for Redis`,
Outline_heading_13:`Module 13- Data Warehousing in Amazon Redshift `,
outline_detail_13:`● Amazon Redshift 
● Amazon Redshift differentiating features 
● Amazon Redshift data modeling 
● Amazon Redshift design considerations
● Lab 5- Working with Amazon Redshift Clusters`,

skill_1:`Data Modeling`,
skill_2:`ETL (Extract, Transform, Load)`,
skill_3:`Data Integration`,
skill_4:`Data Security and Compliance`,
skill_5:`Performance Tuning`,
skill_6:`Data Analysis and Reporting`,
course_faq_name:`Planning-and-designing-databases-on-AWS`,
Faq_question_1:`Q- What are the four types of database platforms in AWS?`,
faq_answer_1:` A- Amazon Relational Database Service. Amazon RDS is available on several database instance types: optimized for memory, performance, or I/O, & provides you with 6 familiar database engines to pick from, including MariaDB, MySQL, PostgreSQL, Oracle Database, Amazon Aurora, & SQL Server.`,
Faq_question_2:`Q- What is AWS database management?`,
faq_answer_2:` A- AWS fully managed database services enable continuous monitoring, self-healing storage, & automated scalability to help you focus on application development. Achieve performance at scale.`,
Faq_question_3:`Q- What is the AWS data lake?`,
faq_answer_3:` A- A data lake is a centralized & secured store that accumulates all your data, both in its original form & prepared for analysis.`,
Faq_question_4:`Q- What are the various techniques of data storage in AWS?`,
faq_answer_4:` A- There are three methods of data storage, namely – ▪ Object storage ▪ File storage ▪ Block storage`,
Faq_question_5:` Q- What is the use of the ec2 instance?`,

faq_answer_5:` A- EC2 is a service that lets corporate clients to run application programs in the computing environment.`,
},

//snowflake //snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake//snowflake

//snow flake
{
    id:200,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Snowpark DataFrame Programming Training`,
course_overview:`This one-day session, targeted for professionals designing DataFrame data solutions in Snowflake, covers fundamental Snowpark ideas, features, and programming structures. There will be lectures, demonstrations, laboratories, and discussions in this course.`,
certification:`Snowflake Snowpark DataFrame Programming Training offers certification in advanced data processing and analytics within the Snowflake environment. Participants gain expertise in leveraging Snowpark's capabilities for executing complex transformations and machine learning tasks. The certification validates proficiency in Snowflake Snowpark, enhancing career prospects in data engineering and analytics roles.`,
prerequisite:`Requires completion of the Snowflake Essentials Data Warehousing Workshop or related knowledge.

Prior data warehouse experience is presumpted.

The ability to develop code in Java, Scala, or Python with a basic degree of expertise.

Familiarity with Snowflake objects and necessary SQL

Audience Profile 

Data Engineers

Data Scientists

Data Application Developers

Database Architects

Database Administrators

Data Analysts with programming experience`,
outline_heading_1:`Module 1: Snowpark Overview`,
outline_detail_1:`A technical overview of Snowpark.

Getting the Snowpark API up and working.

Establishing Snowflake connections and evaluating various ways of authentication.

Understanding Snowpark's DataFrames and how they function Snowflake's elastic compute engine`,
outline_heading_2:`Module 2: Creating Snowpark DataFrames`,
outline_detail_2:`Examining several ways for constructing a DataFrame object.

Key programming notions for Snowpark DataFrames, such as data types, schemas, and lazy evaluation.

Creating fundamental produce claims.`,
outline_heading_3:`Module 3: Transforming DataFrames: Basic and Advanced Operations`,
outline_detail_3:`Applying column operations for filtering and converting data

Using scalar functions and operators

Sorting and restricting results

Performing aggregate and set-based operations on DataFrames

Transforming semi-structured data in DataFrames`,
outline_heading_4:`Module 4: Actions on DataFrames: Evaluating and Persisting`,
outline_detail_4:`Identifying the distinctions between and how to utilize DataFrame actions and transformations

Best practices for leveraging slow evaluation in data transformations

Evaluating DataFrame modifications using actions that return data to the client-side

Publishing logical DataFrame operations as views

Creating and adding Snowflake tables using DataFrame results`,
outline_heading_5:`Module 5: Creating and Registering User-Defined Functions (UDFs)`,
outline_detail_5:`Writing a simple UDF in Snowpark

Registering and allowing access to UDFs to share code with others`,


skill_1:`Snowflake Snowpark DataFrame Programming`,
skill_2:`Data Analysis`,
skill_3:`Data Manipulation`,
skill_4:`Snowflake SQL`,
skill_5:`Snowflake Architecture`,
skill_6:`Snowflake Snowpark Integration`,
course_faq_name:`Snowflake Snowpark DataFrame Programming Training`,
faq_question_1:`What is Snowflake Snowpark DataFrame Programming?`,
faq_answer_1:`Snowflake Snowpark DataFrame Programming is a feature within Snowflake that allows users to execute complex data transformations, analytics, and machine learning directly within the Snowflake environment using familiar programming constructs.`,
faq_question_2:`What are the advantages of using Snowflake Snowpark DataFrame Programming?`,
faq_answer_2:`Snowflake Snowpark DataFrame Programming offers several advantages, including enhanced data processing capabilities, simplified data workflows, improved performance, and the ability to leverage existing programming skills and libraries.`,
faq_question_3:`What programming languages are supported in Snowflake Snowpark DataFrame Programming?`,
faq_answer_3:`Snowflake Snowpark DataFrame Programming supports popular programming languages such as Java, Scala, and Python, allowing users to leverage their existing skills and libraries for data processing and analytics tasks.`,
faq_question_4:`How does Snowflake Snowpark integrate with Snowflake SQL?`,
faq_answer_4:`Snowflake Snowpark integrates seamlessly with Snowflake SQL, allowing users to combine the power of Snowflake's SQL-based querying with the flexibility and scalability of Snowpark DataFrame Programming for advanced data processing and analysis.`,
faq_question_5:`Is Snowflake Snowpark DataFrame Programming suitable for real-time data processing?`,
faq_answer_5:`Yes, Snowflake Snowpark DataFrame Programming can be used for real-time data processing tasks, thanks to its ability to handle large volumes of data efficiently and its support for streaming data sources. However, the suitability depends on the specific requirements and use case of the organization.`,
},




{
    id:201,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Performance Automation and Tuning 3-Day Training – Private`,
course_overview:`This three-day accelerated training provides important performance features, Snowflake best practices, and tuning strategies aimed to enable participants leverage Snowflake's Cloud Data Platform to construct diversified, high-performance workloads. The course highlights performance design best practices and hazards, so learners are equipped to apply the approach and features to their different workloads. The system is offered on the current version of the Snowflake Cloud Data Platform with new performance enhancements and some upcoming features under public preview. This course includes of lectures with many practical examples in instructor-led demos, followed by hands-on activities.`,
certification:`Enhance your expertise with Snowflake Performance Automation and Tuning in this intensive 3-Day Private Training. Learn advanced techniques to optimize Snowflake's performance, automate processes, and fine-tune configurations. Master the art of maximizing efficiency and scalability in your Snowflake environment. Boost your skills with hands-on exercises and expert guidance.`,
prerequisite:`This course is meant for individuals with substantial hands-on experience with Snowflake or who have completed previous relevant Snowflake courses, such as Snowflake Fundamentals (FUND) or Snowflake Advanced (ADV).`,
outline_heading_1:`Module 1: Overview and Architecture`,
outline_detail_1:`Overview

Snowflake Architecture Review

Cloud Services Layer

Data Storage Layer

Compute Layer

Caching Review`,
outline_heading_2:`Module 2: Performance Diagnostic and Tuning Toolset`,
outline_detail_2:`Query Profile

Explain Plan

Query Pruning`,
outline_heading_3:`Module 3: Data Zones`,
outline_detail_3:`Data Zones`,
outline_heading_4:`Module 4: Tuning for the Raw Data Zone`,
outline_detail_4:`Bulk Loading Data

Transforming Data on Load

Continuous Data Loading

Loading Semi-structured Data

Unloading Data

Unloading Options`,
Outline_heading_5:`Module 5: External Tables and Data Lakes`,
outline_detail_5:`External Tables

Data Lake

Snowflake Recommendations for External Tables and Data Lakes`,
outline_heading_6:`Module 6: Query Processing and Execution`,
outline_detail_6:`Optimization Principles and Techniques

Query Processing and Optimizations

Join Filters and Pruning`,
outline_heading_7:`Module 7: Snowflake Query Best Practices and Tuning`,
outline_detail_7:`Order By Tuning Scenarios

Snowflake SQL Query Best Practices: Searching and Filtering

Filter Tuning Scenarios

Snowflake SQL Best Practices: Joining Data

Tuning Joins

Snowflake SQL Best Practices: Aggregate and Grouping

Tuning Group By`,
outline_heading_8:`Module 8: Snowflake SQL Best Practices: Multi-step Advanced SQL`,
outline_detail_8:`Subquery

Subquery Tuning

Common Table Expressions`,
outline_heading_9:`Module 9: Tuning Access Path`,
outline_detail_9:`Performance Services Overview

Clustering

Search Optimization Service

Materialized Views`,
outline_heading_10:`Module 10: Transforming Your Data and Managing Changes`,
outline_detail_10:`Streamline with Cloning and Time Travel

Tap into the Power of SQL in ELT Processes

System-defined Functions and Custom Functions`,
outline_heading_11:`Module 11: Using Table Streams`,
outline_detail_11:`Streams`,
outline_heading_12:`Module 12: Best Practices for Modeled Data Zone`,
outline_detail_12:`Analytic Features and Performance

Analytic Window Functions

Ranking Queries and Comparison

Top (N) Queries

Performance and Tuning Considerations

Sampling Queries`,
outline_heading_13:`Module 13: Using Approximation for Big Data Analytics`,
outline_detail_13:`
Challenges with Statistical and Aggregation Functions

Approximate Aggregates

Approximate Statistics

Additional Approximation`,
outline_heading_14:`Module 14: Monitoring Performance, Cost Optimization, and Compute Right-sizing`,
outline_detail_14:`Performance Bottlenecks

Monitoring Query Queuing

Compute Layer Resource Optimization

Query Acceleration Service (QAS)

Additional Cost Optimization`,



skill_1:`Snowflake Performance Optimization`,
skill_2:`Automation Techniques`,
skill_3:`Query Tuning`,
skill_4:`Data Warehousing Best Practices`,
skill_5:`Snowflake Architecture Understanding`,
skill_6:`Performance Monitoring Tools`,
course_faq_name:`Snowflake Performance Automation and Tuning 3-Day Training – Private`,
faq_question_1:`What are the primary challenges in Snowflake performance optimization?`,
faq_answer_1:`Answer: The primary challenges often revolve around inefficient queries, improper data distribution, inadequate warehouse sizing, and suboptimal schema design. This training addresses these challenges comprehensively.`,
faq_question_2:`How does Snowflake handle query optimization and execution?`,
faq_answer_2:`Answer: Snowflake utilizes various optimization techniques such as query rewrites, automatic clustering, and multi-cluster warehouses to enhance query performance. This training covers strategies to leverage these features effectively.`,
faq_question_3:`What role does automation play in Snowflake performance tuning?`,
faq_answer_3:`Answer: Automation streamlines repetitive tasks like query optimization, warehouse scaling, and data maintenance, freeing up resources for more strategic performance enhancements. Participants will learn automation techniques tailored for Snowflake environments.`,
faq_question_4:`What are some key metrics for monitoring Snowflake performance?`,
faq_answer_4:`Answer: Important metrics include query execution time, warehouse utilization, data transfer rates, and storage consumption. Understanding these metrics is crucial for identifying performance bottlenecks and optimizing system performance.`,
faq_question_5:`How does this training address real-world scenarios in Snowflake performance optimization?`,
faq_answer_5:`Answer: This training incorporates practical case studies and hands-on exercises that simulate real-world challenges encountered in Snowflake environments. Participants will gain practical experience in diagnosing and resolving performance issues effectively.`,
},





{
    id:202,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake New Features Training – Private`,
course_overview:`The one-day, high-intensity workshop will strengthen your working understanding of the platform and widen your Snowflake palette.. Here’s your chance to immerse yourself in features added to the Snowflake Cloud Data Platform in the previous 12 months. This course includes of lectures, demos, hands-on laboratories, and discussions.`,
certification:`Snowflake New Features Training – Private certification covers advanced features such as support for semi-structured data, Snowpark for Java and Scala developers, enhanced security measures, scalability improvements, and real-time data processing capabilities. Participants gain expertise in leveraging Snowflake's latest functionalities for optimized data warehousing and analytics solutions.`,
prerequisite:`Familiarity with Snowflake architecture and concepts such as Warehouses, Stages, Users, and Roles

Foundational knowledge and experience working with Snowflake

Basic understanding of SQL is assumed`,
outline_heading_1:`Module 1: Data Warehousing / Platform`,
outline_detail_1:`Query Acceleration Service

Object Tagging

Data Classification

Conditional Masking

Row Access Policies

Object Dependencies

Access History`,
outline_heading_2:`Module 2: Data Engineering / Unstructured Data`,
outline_detail_2:`Directory tables

Streams on Directory tables

Scoped URLs

Ingesting and querying geoJSON data

GEOGRAPHY data type

GEO functions

Utilizing INFER_SCHEMA to automate table construction from a group of semi-structured source files

Joining structured and unstructured data sets

Leveraging JAVA UDFs to parse and extract PDF data

Loading open-source JAR files to JAVA UDFs

Serverless tasks

Manual task execution

Reviewing task graph execution and task faults`,
outline_heading_3:`Module 3: Building Modern Applications`,
outline_detail_3:`Snowflake Scripting

User-defined functions

Loading open-source JAR files to JAVA UDFs

Leveraging open-source Python libraries directly available in Snowflake via Anaconda in Python UDFs

User-defined table functions

Loading open-source JAR files to JAVA UDTFs

Stored Procedures

Snowpark libraries

SQL API

Streamlight`,
outline_heading_4:`Module 4: Data Science / M`,
outline_detail_4:`Snowpark

Data Science / ML using Snowflake Overview 

Snowpark Python walkthrough`,


skill_1:`Snowflake`,
skill_2:`Data Warehousing`,
skill_3:`Cloud Computing`,
skill_4:`SQL`,
skill_5:`Data Analysis`,
skill_6:`Data Modeling`,
course_faq_name:`Snowflake New Features Training – Private`,
faq_question_1:`What are some new features introduced in Snowflake?`,
faq_answer_1:`Answer: Some of the new features in Snowflake include support for semi-structured data types like VARIANT and OBJECT, Snowpark for Java and Scala developers, enhanced support for time travel, and improved performance optimizations.`,
faq_question_2:`How does Snowflake handle security in its latest features?`,
faq_answer_2:`Answer: Snowflake enhances security through features such as multi-factor authentication (MFA), role-based access control (RBAC), granular access controls at the object level, and integration with key management services for encryption.`,
faq_question_3:`What improvements have been made in Snowflake's scalability and performance?`,
faq_answer_3:`Answer: Snowflake continually improves scalability and performance through enhancements in its architecture, such as automatic scaling to handle varying workloads, optimizations in query processing, and leveraging the elasticity of cloud infrastructure.`,
faq_question_4:`What are the benefits of Snowpark for developers?`,
faq_answer_4:`Answer: Snowpark enables Java and Scala developers to build native applications within the Snowflake ecosystem, allowing for seamless integration of Snowflake's capabilities into existing workflows and applications, and providing greater flexibility and efficiency in data processing.`,
faq_question_5:`How does Snowflake support real-time data processing and analytics?`,
faq_answer_5:`Answer: Snowflake supports real-time data processing and analytics through features like continuous data ingestion from various sources, near-zero maintenance for managing infrastructure, and integration with streaming platforms like Kafka for real-time data processing pipelines.`,
},




{
    id:203,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Fundamentals 4-Day – Private`,
course_overview:`This 4-day training covers the key principles, design considerations, and Snowflake best practices intended for important stakeholders working on the Snowflake Cloud Data Platform. The system comprises of lectures, presentations, and hands-on labs covering fundamental subjects`,
certification:`Snowflake Fundamentals 4-Day Private Certification covers foundational concepts of Snowflake, including architecture, data loading, querying, and security. Participants gain hands-on experience in managing data warehouses, optimizing queries, and implementing best practices for data governance. The course equips professionals with essential skills for leveraging Snowflake efficiently in data analytics and warehousing projects.`,
prerequisite:`Previous Data Warehouse knowledge is expected.`,
outline_heading_1:`Module 1: Snowflake Overview and Architecture`,
outline_detail_1:`Overview of Snowflake

Snowflake structure

Using Snowsight

Snowflake architecture

Storage layer

Compute layer

Cloud Services layer`,
outline_heading_2:`Module 2: Connecting to Snowflake`,
outline_detail_2:`Connection options

SnowSQL`,
outline_heading_3:`Module 3: Data Protection FeaturesCloning`,
outline_detail_3:`Time Travel

Fail-safe

Introduction to replication`,
outline_heading_4:`Module 4: SQL Support in Snowflake`,
outline_detail_4:`Tables, Views, and data kinds

Standard SQL and Snowflake

Transactions

Multi-table inserts

Collations

Query Tags

Working with parameters`,
outline_heading_5:`Module 5: Caching in Snowflake`,
outline_detail_5:`Metadata cache

Query result cache

Data cache`,


skill_1:`Snowflake Fundamentals`,
skill_2:`Data Warehousing`,
skill_3:`Cloud Data Management`,
skill_4:`SQL Queries`,
skill_5:`Snowflake Architecture`,
skill_6:`Data Loading and Unloading`,
course_faq_name:`Snowflake Fundamentals 4-Day – Private`,
faq_question_1:`What are the prerequisites for the Snowflake Fundamentals course?`,
faq_answer_1:`Answer: Basic understanding of databases and SQL is recommended, but not mandatory. Familiarity with cloud computing concepts would also be beneficial.`,
faq_question_2:`Is this course suitable for beginners in data warehousing?`,
faq_answer_2:`Answer: Yes, this course is designed for individuals who are new to Snowflake and data warehousing concepts. It covers fundamental topics to provide a solid foundation.`,
faq_question_3:`Can I attend this course remotely?`,
faq_answer_3:`Answer: Yes, this course can be conducted remotely as a private session for your organization. Virtual delivery options are available to accommodate remote participants`,
faq_question_4:`What is the duration of the Snowflake Fundamentals 4-Day course`,
faq_answer_4:`Answer: The course spans four days, typically covering foundational aspects of Snowflake, data warehousing principles, SQL querying, and practical exercises to reinforce learning.`,
faq_question_5:`Will I receive any certification upon completion of the course?`,
faq_answer_5:`Answer: This course does not typically offer certification upon completion. However, it provides valuable knowledge and skills in Snowflake fundamentals that can be applied in real-world scenarios and serve as a strong basis for further certification pursuits, such as SnowPro certifications.`,
},




{
    id:204,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Data Governance Training – Private`,
course_overview:`This 1-day Snowflake Data Governance add-on training equips attendees with practical concepts and skills to properly apply Snowflake data governance capabilities into your organization’s data governance program. You will apply approaches to control a single source of trustworthy data to drive actionable choices for multiple users while preserving sensitive information and facilitating regulatory compliance. This course includes of lectures, live demos, hands-on experiments, and discussions.`,
certification:`Snowflake Data Governance Training - Private offers comprehensive instruction on managing data within Snowflake's platform, emphasizing privacy compliance, security protocols, and governance strategies. Participants gain expertise in data classification, access controls, and regulatory compliance measures, empowering them to ensure data integrity and security while maximizing the value of organizational data assets.`,
prerequisite:`SQL skills and a background in database administration or database management are essential. 

Suggested completion of one of the following courses (or similar experience): Administering Snowflake, Snowflake Data Engineer, or Snowflake Fundamentals.`,
outline_heading_1:`Module 1: Data Governance Overview`,
outline_detail_1:`Data governance in Snowflake’s Cloud Data Platform

Key data governance ideas, terminology, and meanings

Native Snowflake features for data governance`,
outline_heading_2:`Module 2: Identify Data Sensitivity`,
outline_detail_2:`Know your data: categorize data for regulatory and privacy compliance

Recommended approaches for data categorization in Snowflake

Label sensitive columns, views, tables, and other objects with tags

Create custom tags using your organization’s terminology

Use tags to track Snowflake resource costs by cost center any time`,
outline_heading_3:`Module 3: Protect Your Data`,
outline_detail_3:`Secure your data with data governance regulations

Dynamically restrict query results to just rows authorized for a given user role

Maintain compliance with data masking to secure the viewing of sensitive data

Leverage broad partner ecosystem for increasing data protection, including tools for tokenizing data`,
outline_heading_4:`Module 4: Control Data in Your Data Lake`,
outline_detail_4:`Easily extend tagging and data governance principles to data in your data lake

Keep data safe and decrease complexity as your data lake scales

Share, Query, and Monitor Use of Your Data

Enable cooperation among internal and external stakeholders with real, controlled, secure data sharing

Leverage context functions to share filtered data based on user role

Explore access history to determine which users accessed what things

Increase data trustworthiness utilizing object dependencies to analyze the downstream impact of planned changes

Easily derive the provenance of sensitive data columns and objects for regulatory audits`,


skill_1:`Snowflake Data Governance`,
skill_2:`Data Management`,
skill_3:`Privacy Compliance`,
skill_4:`Data Security`,
skill_5:`Cloud Computing`,
skill_6:`Training Delivery`,
course_faq_name:`Snowflake Data Governance Training – Private`,
faq_question_1:`What is the importance of Snowflake Data Governance?`,
faq_answer_1:`Answer: Snowflake Data Governance ensures that data within the Snowflake platform is managed, controlled, and compliant with privacy regulations, enhancing data quality, security, and regulatory compliance.`,
faq_question_2:`How does Snowflake ensure data security in its governance framework?`,
faq_answer_2:`Answer: Snowflake implements robust security measures such as encryption, access controls, and authentication mechanisms to safeguard data against unauthorized access, ensuring compliance with industry standards and regulations`,
faq_question_3:`What are the key components of a Snowflake Data Governance strategy?`,
faq_answer_3:`Answer: A comprehensive Snowflake Data Governance strategy includes data classification, access control policies, data lineage tracking, auditing capabilities, and compliance monitoring tools to ensure data integrity and regulatory compliance.`,
faq_question_4:`How does Snowflake facilitate privacy compliance within its platform?`,
faq_answer_4:`Answer: Snowflake provides features such as data masking, anonymization, and data access controls to help organizations enforce privacy regulations such as GDPR, CCPA, and HIPAA, ensuring that sensitive data is protected and handled appropriately.`,
faq_question_5:`What role does training play in Snowflake Data Governance implementation?`,
faq_answer_5:`Answer: Training equips users and administrators with the knowledge and skills necessary to effectively manage data within the Snowflake platform, ensuring compliance with governance policies, enhancing data security, and maximizing the value of data assets.`,
},




{
    id:205,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Data Engineer Training - Private`,
course_overview:`This three-day, role-specific workshop covers foundational ideas, features, concerns, and Snowflake best practices through the perspective of the data engineering lifecycle. It is aimed for participants who will be accessing, generating, and querying datasets for analytical purposes and constructing data pipelines in Snowflake. This course comprises of key data engineering principles given through lectures, demos, experiments, and conversations.`,
certification:``,
prerequisite:`Basic familiarity of SQL is helpful.`,
outline_heading_1:`Module 1: Snowflake Overview and Architecture Recap`,
outline_detail_1:`Overview

Snowflake Architecture

Cloud Services Layer

Data Storage Layer

Compute Layer

Caching (Metadata, Query Result, Data)

Sample Data

Web User Interface

SnowSQL`,
outline_heading_2:`Module 2: Introduction to the Data Engineering Lifecycle`,
outline_detail_2:`Data Engineering Lifecycle Introduction`,
outline_heading_3:`Module 3: Supporting Platform Features`,
outline_detail_3:`Authentication Recap

Role-based Access Control (RBAC) Recap

Connectors Overview

Cost Governance Framework

Introduction to Data Governance`,
outline_heading_4:`Module 4: Data Storage`,
outline_detail_4:`Snowflake Database Objects

Time Travel and Cloning

Data Lake (External Tables, Materialized Views)`,
outline_heading_5:`Module 5: Ingestion`,
outline_detail_5:`Data Loading Concepts

Bulk Data Loading

Bulk vs. Continuous Data Loading Approaches

Snowpipe

Snowflake Data Loading Best Practices

Data Loading, Transformations, and Validations`,
outline_heading_6:`Module 6: Loading Semi-structured Data`,
outline_detail_6:`Schema-on-Read with Semi-structured Data (Querying VARIANT Data)

Schema-on-Write with Semi-structured Data (INFER_SCHEMA)

Working With Unstructured Data (Directory Tables, Scoped URLs)

Creating and Managing Streams

Snowflake Connector for Kafka`,
outline_heading_7:`Module 7: Orchestration`,
outline_detail_7:`Creating and Managing Tasks

Using Streams and Tasks Together`,
outline_heading_8:`Module 8: Transformation
`,
outline_detail_8:`DML and System Functions for Transformation (Sampling, Estimation, Grouping Sets, CTEs)

Stored Procedures (JavaScript, Snowflake Scripting)

UDFs and UDTFs (SQL, JavaScript)

External Functions

Introducing UDFs and UDTFs (Java, Python)

Introducing Stored Procedures (Java, Python)

Introduction to Snowpark
`,
outline_heading_9:`Module 9: Performance Optimization`,
outline_detail_9:`Query Profile

Explain Plan

Natural Clustering

Automatic Clustering Service (Explicit Clustering)

Search Optimization Introduction

SQL Performance Tips

Performance Bottleneck Scenarios
`,
outline_heading_10:`Module 10: Delivery
`,
outline_detail_10:`Snowflake Connector Ecosystem

Materialized Views

Unloading Data

Snowflake Connector for Python

Data Sharing (Secure Views and UDFs)

Data Exchange

Snowflake Marketplace
`,
outline_heading_11:`Module 11: Management and Observability`,
outline_detail_11:`Information Schema

Snowflake Database

Query Tags

Cost Controls

Resource Monitors

Parameters`,



skill_1:`Evaluate performance metrics`,
skill_2:`
Design end-to-end near real-time streams`,
skill_3:`Design scalable computing solutions for DE workloads`,
skill_4:`Transform, duplicate, and distribute data across cloud platforms`,
skill_5:`Source data from Data Lakes, APIs, and on-premises`,
skill_6:`Data Warehousing`,
course_faq_name:`Snowflake Data Engineer Training - Private`,
faq_question_1:`What is Snowflake Data Engineering?`,
faq_answer_1:`Answer: Snowflake Data Engineering involves designing, implementing, and managing data solutions using the Snowflake cloud data platform. It includes tasks such as data modeling, ETL processes, and performance optimization within Snowflake.`,
faq_question_2:`What are the key components of Snowflake Data Engineering?`,
faq_answer_2:`Answer: Key components include data warehouses, databases, compute resources, storage, and integration tools within the Snowflake platform.`,
faq_question_3:`How does Snowflake handle data warehousing tasks?`,
faq_answer_3:`Answer: Snowflake utilizes a unique architecture that separates storage and compute, allowing for scalability, elasticity, and efficient data warehousing operations. It offers features like automatic scaling, data sharing, and concurrency controls.`,
faq_question_4:`What role does SQL play in Snowflake Data Engineering?`,
faq_answer_4:`Answer: SQL (Structured Query Language) is essential for querying, manipulating, and managing data within Snowflake. Data engineers need to be proficient in SQL to perform tasks such as data extraction, transformation, and loading.`,
faq_question_5:`How can one optimize performance in Snowflake Data Engineering?`,
faq_answer_5:`Answer: Performance optimization in Snowflake involves various strategies such as optimizing data warehouse design, query optimization, using appropriate clustering keys, and leveraging caching mechanisms. Additionally, tuning resource allocation and concurrency settings can enhance overall performance.`,
},




{
    id:206,
categories: `Snowflake`,
course_detail_image:course_images.snowflake_first,
course_name:`Snowflake Data Engineer II Training - Private`,
course_overview:`This two-day, role-specific training covers additional topics and a deep dive into key issues for the Data Engineer via the lens of the data engineering lifecycle. The course covers Snowflake ideas, features, considerations, and best practices designed for stakeholders accessing, generating, and querying datasets for analytical activities and constructing data pipelines in Snowflake. This course contains data engineering topics taught through lectures, demos, experiments, and conversations.`,
certification:`Snowflake Data Engineer II Training - Private provides advanced expertise in Snowflake data engineering, covering topics like data warehousing, SQL programming, ETL processes, cloud computing, and data modeling. Participants gain hands-on experience in optimizing data pipelines and leveraging Snowflake's architecture for scalable and efficient data management in private cloud environments.`,
prerequisite:`Successful completion of either the Snowflake Fundamentals or Snowflake Data Engineer course is recommended.`,
outline_heading_1:`Module 1: Snowflake Overview and Architecture`,
outline_detail_1:`Overview and Architecture Recap`,
outline_heading_2:`Module 2: Introduction to the Data Engineering Lifecycle`,
outline_detail_2:`DE Lifecycle Recap`,
outline_heading_3:`Module 3: Supporting Platform Features`,
outline_detail_3:`Data Governance (Tagging, Policies, Classification, External Tokenization)`,
outline_heading_4:`Module 4: Data Platform Architecture`,
outline_detail_4:`Data Modeling

Data Vault

Data Mesh`,
outline_heading_5:`Module 5: Data Storage`,
outline_detail_5:`What are Iceberg Tables?`,
outline_heading_6:`Module 6: Ingestion`,
outline_detail_6:`Schema Detection`,
outline_heading_7:`Module 7: Transformation`,
outline_detail_7:`UDFs and UDTFs (Java, Python)

Stored Procedures (Java, Scala, Python)

Working With Snowpark`,
outline_heading_8:`Module 8: Performance Optimization`,
outline_detail_8:`Search Optimization Service

Query Acceleration Service`,


skill_1:`Evaluate performance metrics`,
skill_2:`Design scalable computing solutions for DE workloads`,
skill_3:`Design end-to-end near real-time streams`,
skill_4:`Transform, duplicate, and distribute data across cloud platforms`,
skill_5:`Source data from Data Lakes, APIs, and on-premises`,
skill_6:`Snowflake Data Engineering`,
course_faq_name:`Snowflake Data Engineer II Training - Private`,
faq_question_1:`What is Snowflake Data Engineering?`,
faq_answer_1:`Snowflake Data Engineering involves managing and optimizing data pipelines within the Snowflake cloud data platform, ensuring efficient storage, retrieval, and analysis of large datasets.`,
faq_question_2:`What are the key components of Snowflake architecture?`,
faq_answer_2:`Snowflake architecture comprises three main layers: storage, compute, and services. The storage layer stores data in a scalable and efficient manner, the compute layer processes queries and transformations, and the services layer manages metadata and access control.`,
faq_question_3:`How does Snowflake handle concurrency and scalability?`,
faq_answer_3:`Snowflake employs a multi-cluster, shared-data architecture that enables automatic scaling of compute resources based on workload demands. This architecture ensures high concurrency and performance without manual intervention.`,
faq_question_4:`What is the role of SQL programming in Snowflake Data Engineering?`,
faq_answer_4:`SQL programming is fundamental to Snowflake Data Engineering as it's used to query, transform, and manipulate data within the platform. Advanced SQL skills are crucial for designing efficient data models, implementing complex transformations, and optimizing query performance.`,
faq_question_5:`How does Snowflake support ETL processes?`,
faq_answer_5:`Snowflake provides native support for ETL (Extract, Transform, Load) processes through its built-in features such as Snowpipe for continuous data ingestion, tasks for scheduling data workflows, and external functions for integrating with external systems. These capabilities streamline data integration and processing tasks for data engineers.`,
},




{
    id:207,
categories: `Snowflake`,
course_detail_image:course_images.programing_1,
course_name:`Snowflake Data Analyst Training – Private`,
course_overview:`This 2-day, role-specific training begins with how to connect to the Snowflake Cloud Data Platform. It then covers how to analyze, ingest, enhance, report, and diagnose data insights using Snowflake. The course includes of lectures, laboratories, demonstrations, and discussions.`,
certification:`The Snowflake Data Analyst Training - Private certification equips individuals with essential skills in data analysis within the Snowflake cloud data platform. Participants learn to extract insights, perform advanced analytics, and visualize data effectively. This certification validates proficiency in leveraging Snowflake's features for data analysis tasks in private training settings.`,
prerequisite:`Basic familiarity of SQL is essential.`,
outline_heading_1:`Module 1: Snowflake Overview`,
outline_detail_1:`Why Snowflake
Snowflake Workload Segmentation`,
outline_heading_2:`Module 2: Snowflake Architecture`,
outline_detail_2:`Snowflake Architecture Layers

Using Snowsight

Loading Scripts`,
outline_heading_3:`Module 3: Snowflake Structure`,
outline_detail_3:`Explaining structure of snowflake`,
outline_heading_4:`Module 4: Account Usage and More`,
outline_detail_4:`Information_Schema and Account Usage

Using Parameters to Control Snowflake

Snowflake Sample Data`,
outline_heading_5:`Module 5: Data Analysis using Snowflake`,
outline_detail_5:`Methods of data analysis`,
outline_heading_6:`Module 6: Client Interfaces`,
outline_detail_6:`Client Interface Overview

Snowsight

SnowSQL
`,
outline_heading_7:`Module 7: Visualizing Data With BI Tools`,
outline_detail_7:`Connecting Tools to Snowflake

Querying Data

Using Snowflake-specific Features`,
outline_heading_8:`Module 8: SQL Support in Snowflake`,
outline_detail_8:`Data Definition Language (DDL)

Data Manipulation Language (DML)

Constructing Efficient Queries`,
outline_heading_9:`Module 9: SQL Functions, User-defined Functions, and Stored Procedures`,
outline_detail_9:`Function Overview

User-defined Functions

Stored Procedures

Estimation Functions

Sampling`,
outline_heading_10:`Module 10: Other SQL Topics`,
outline_detail_10:`Collations

Query Tags`,
outline_heading_11:`Module 11: Working with Complex Queries`,
outline_detail_11:`Window Functions, Syntax, and Usage

Group By and Grouping Sets

Recursive With and Connect By`,
outline_heading_12:`Module 12: Semi-structured Data`,
outline_detail_12:`Semi-structured Data Overview

Query Semi-structured Data`,
outline_heading_13:`Module 13: Time Travel and Zero-copy Cloning`,
outline_detail_13:`How Does Time Travel Work?

How Does Zero-copy Cloning Work?`,
outline_heading_14:`Module 14: Data Sharing`,
outline_detail_14:`Data sharing approaches`,
outline_heading_15:`Module 15: Data Loading`,
outline_detail_15:`Data Loading Concepts

Examples of Data Loading`,
outline_heading_16:`Module 16: Snowflake Data Lake Support`,
outline_detail_16:`Data lake support`,
outline_heading_17:`Module 17: Reporting`,
outline_detail_17:`Reporting Objectives

Explore Data

Prepare Data

Enhance Data

Analyze Data

Visualize Data`,
outline_heading_18:`Module 18: Visualizing Query Execution with Query Profile
`,
outline_detail_18:`Using the Query Profile

Using the Explain Plan
`,
outline_heading_19:`Module 19: Caching and Query Pruning`,
outline_detail_19:`Caching Overview

Metadata Cache

Query Result Cache

Data Cache

Query Pruning`,
outline_heading_20:`Module 20: Performance Tips and Troubleshooting`,
outline_detail_20:`SQL Performance Tips

Performance Bottlenecks
`,
skill_1:`Prepare and display data to suit business requirements`,
skill_2:`Perform data forecasting`,
skill_3:`Perform descriptive and diagnostic data analyses`,
skill_4:`Perform descriptive and diagnostic data analyses`,
skill_5:`Build and debug sophisticated SQL queries in Snowflake`,
skill_6:`Perform simple data modifications for data analysis`,
course_faq_name:`Snowflake Data Analyst Training – Private`,
faq_question_1:`What is Snowflake Data Analyst Training - Private?`,
faq_answer_1:`Answer: Snowflake Data Analyst Training - Private is a specialized training program designed to equip individuals with the skills and knowledge required to effectively analyze data using Snowflake, a cloud-based data warehousing platform. This training is conducted privately for organizations or groups seeking tailored instruction.`,
faq_question_2:`What prerequisites are required for Snowflake Data Analyst Training - Private?`,
faq_answer_2:`Answer: Prior experience with SQL querying and data analysis concepts is recommended for participants to maximize their learning outcomes. Familiarity with data warehousing principles may also be beneficial but is not mandatory.`,
faq_question_3:`How long is the Snowflake Data Analyst Training - Private program?`,
faq_answer_3:`Answer: The duration of the training program can vary depending on the specific requirements and objectives of the organization or group. Typically, the program ranges from several days to a few weeks, with customized content and scheduling to accommodate the participants' needs.`,
faq_question_4:`What topics are covered in Snowflake Data Analyst Training - Private?`,
faq_answer_4:`Answer: The training curriculum covers a wide range of topics, including but not limited to Snowflake architecture, SQL querying in Snowflake, data modeling, data loading and integration, data visualization techniques, administration tasks in Snowflake, and advanced analytics using Snowflake's features.`,
faq_question_5:`How is Snowflake Data Analyst Training - Private conducted?`,
faq_answer_5:`Answer: Snowflake Data Analyst Training - Private can be conducted in various formats, such as instructor-led sessions delivered onsite at the organization's premises, virtual instructor-led training sessions, or a combination of both. The training is highly interactive, with hands-on exercises and real-world scenarios to reinforce learning. Additionally, participants may have access to training materials and resources for self-paced learning.`,
},





{
    id:208,
categories: `Snowflake`,
course_detail_image:course_images.programing_1,
course_name:`Snowflake Advanced Training – Private`,
course_overview:`This three-day course covers advanced Snowflake capabilities relating to data transfer, performance, authentication, design considerations, and best practices in the Snowflake Platform. This advanced course includes of lectures, demonstrations, and labs.`,
certification:`Snowflake Advanced Training - Private certification covers advanced topics such as optimization, scalability, security, and performance tuning in Snowflake data warehousing. Participants deepen their expertise in SQL queries, data modeling, and administration, gaining practical skills through hands-on exercises. Prerequisites include proficiency in basic Snowflake concepts and SQL.`,
prerequisite:`Snowflake Fundamentals course`,
outline_heading_1:`Module 1: Architecture Highlights`,
outline_detail_1:`Three Architectural Layers

Caching`,
outline_heading_2:`Module 2: Connecting to Snowflake`,
outline_detail_2:`What is Integration?

Security Integration

Key Pair Authentication`,
outline_heading_3:`Module 3: Data Loading and Troubleshooting`,
outline_detail_3:`Data Ingestion Options

Storage Integrations

Copy Validation and Error Handling

Fixing Load Failures

Continuous Loading with Snowpipe

Notification Integration`,
outline_heading_4:`
Module 4: Streams (Change Data Capture)`,
outline_detail_4:`Traditional Micro-batch Pipeline

Alternative: Continuous Data Pipeline

Stream Details

How Streams Work

Common Use Cases`,
outline_heading_5:`Module 5: Working with Semi-Structured Data`,
outline_detail_5:`Structured vs. Semi-structured

Loading and Transforming Semi-structured Data`,
outline_heading_6:`Module 6: Unloading Data`,
outline_detail_6:`Workflow

Unload Options

Unloading Semi-structured Data

Strategies and Capabilities for Unloading Semi-structured Data`,
outline_heading_7:`Module 7: Geospatial`,
outline_detail_7:`Geospatial Overview

Geography

Geometry`,
outline_heading_8:`Module 8: Working with Unstructured Data`,
outline_detail_8:`Overview

Concepts

Workflow`,
outline_heading_9:`Module 9: Working with Data Lakes`,
outline_detail_9:`Data Lake Deployments

Querying External Data Lakes

Creating and Querying External Tables

Partitioning External Tables`,
outline_heading_10:`Module 10: Data Governance in Snowflake`,
outline_detail_10:`
Data Governance Overview

Classification and Object Tagging

Dynamic Data Masking

External Tokenization

Row Access Policies

Audit Access History`,
outline_heading_11:`Module 11: Data Sharing`,
outline_detail_11:`Data Access Options

Direct Data Sharing

Direct Data Sharing Workflow`,
outline_heading_12:`Module 12: Extensibility and Programmability`,
outline_detail_12:`External Functions

API Integrations

SQL API

Developer Framework`,
outline_heading_13:`Module 13: Data Clustering
`,
outline_detail_13:`What is Data Clustering?

Micro-partition Pruning (Elimination)

Evaluating Clustering

Implement and Test Cluster Keys`,
outline_heading_14:`Module 14: Performance Optimization`,
outline_detail_14:`Search Optimization
Materialized Views
Materialized View Use Cases
When to Use Materialized Views
Query Acceleration Services (QAS)`,
skill_1:`Snowflake Data Warehousing`,
skill_2:`Advanced SQL Queries`,
skill_3:`Data Modeling in Snowflake`,
skill_4:`Snowflake Performance Tuning`,
skill_5:`Snowflake Security Management`,
skill_6:`Advanced Snowflake Administration`,
course_faq_name:`Snowflake Advanced Training – Private`,
faq_question_1:`Q: What are the prerequisites for Snowflake Advanced Training?
`,
faq_answer_1:`A: Participants should have a solid understanding of basic Snowflake concepts, SQL, and database management principles. Completion of introductory Snowflake training or equivalent experience is recommended.`,
faq_question_2:`Q: Will the training cover advanced topics like optimization and scalability in Snowflake?`,
faq_answer_2:`A: Yes, the training will delve into advanced optimization techniques, scalability considerations, and best practices for maximizing performance in Snowflake.
`,
faq_question_3:`Q: How hands-on is the training?`,
faq_answer_3:`A: The training is highly interactive and includes hands-on exercises and labs designed to reinforce learning. Participants will have the opportunity to apply advanced concepts in real-world scenarios.`,
faq_question_4:`Q: Is this training suitable for beginners in Snowflake?`,
faq_answer_4:`A: No, this training is specifically designed for individuals who already have a solid understanding of Snowflake fundamentals and wish to deepen their knowledge and skills in advanced topics.`,
faq_question_5:`Q: Will the training cover Snowflake security features and best practices?`,
faq_answer_5:`A: Yes, the training includes comprehensive coverage of Snowflake security features, including data encryption, access controls, and compliance considerations. Participants will learn best practices for securing data and maintaining regulatory compliance in Snowflake environments.`,
},




{
    id:209,
categories: `Snowflake`,
course_detail_image:course_images.programing_1,
course_name:`Data Science Training – Private`,
course_overview:`This 3-day, role-specific workshop is aimed for participants interested in acquiring skills and expertise with Snowflake Cloud Data Platform for data science workloads. The participant will acquire exposure to the rich features of Snowflake, varied machine learning datasets, relevant and popular open-source ML frameworks and libraries, and model deployment procedures that will give practical skills applicable to data science careers. This course includes of lectures, demos, hands-on laboratories, and discussions.`,
certification:`Data Science Training - Private offers comprehensive modules covering statistics, machine learning algorithms, data visualization, and practical application. Participants gain expertise in data analysis, modeling, and interpretation. The certification validates proficiency in advanced analytics, predictive modeling, and data-driven decision-making, essential for successful careers in data science.
`,
prerequisite:`Basic understanding of SQL is essential

Foundational understanding of databases

Python or some other object-oriented programming language`,
outline_heading_1:`Module 1: Overview of Data Science using Snowflake`,
outline_detail_1:`Data science applications

Common machine-learning vocabulary

Machine learning process and pipeline
`,
outline_heading_2:`Module 2: Snowflake Architecture`,
outline_detail_2:`Snowflake Cloud Data Platform Overview

Three-tiered architecture

Snowflake UI Its key capabilities, including elasticity, task separation, data security, and simplicity of performance

Built-in routines for traversing, flattening, and layering of semi-structured data

Seamless communication with Snowflake connectors for languages such as Python, Spark, and C++

Notebook-based data science development environments
`,
outline_heading_3:`Module 3: Acquire Data`,
outline_detail_3:`Raw and external data sets in object stores

External tables and direct searches in data lakes

Native data formats such as CSV, JSON, and Parquet

Ingesting into native semi-structured data types without pre-processing

Snowflake data ingestion recommended practices

Serverless continuous ingestion service Snowpipe

Diverse data, including consumer demographic data, time-series data, geographical data

Private and Public Data Exchange

Data Marketplace provides ready-to-use and third-party datasets for data augmentation`,
outline_heading_4:`Module 4: Prepare Data`,
outline_detail_4:`Sampling data

Cloning data and employing Time Travel

Data cleansing approaches to resolve duplicates, missing data, and outliers

Bulk ingestion and scheduling data loading using tasks

Table stream for recording change data`,
outline_heading_5:`Module 5: Exploratory Data Analysis`,
outline_detail_5:`Exploration and visualization with Snowsight

Descriptive exploratory data analysis employing statistical, analytic, and approximation functions

Visual exploratory data analysis utilizing popular and relevant libraries`,
outline_heading_6:`Module 6: Feature Engineering`,
outline_detail_6:`Employ conventional feature selection and feature engineering methodologies

Advanced SQL functions for data manipulation at scale

Transform data and do feature engineering using Snowpark`,
outline_heading_7:`Module 7: Training Models (Machine Learning)`,
outline_detail_7:`Supervised learning: linear regression using common ML libraries

Supervised learning: categorization utilizing techniques such as logistic regression, random forests, gradient boosts, and more

Identifying, applying, and interpreting metrics to assess models and performance

Unsupervised learning

Developing models utilizing a range of popular machine learning packages, including Scikit-Learn and more

Communicating machine learning findings

Integrating with partner systems for data science automation and democratization around AutoML
`,
outline_heading_8:`Module 8: Deploying Models`,
outline_detail_8:`Storing machine learning outcomes in Snowflake

Deploying machine learning models with scalable frameworks

Creating external functions to help prediction and data augmentation using APIs

Utilizing partner platforms for deployment and practices with ML Ops`,
outline_heading_9:`Module 9: Beyond Deployment: ML Ops`,
outline_detail_9:`Operationalizing models using Snowflake’s broad partner ecosystem leveraging automation (AutoML) and ML Ops techniques

Using Snowflake features, including Snowpipe, table stream, and tasks for continuous data pipelines to update machine learning models
`,


skill_1:`Data Analysis`,
skill_2:`Machine Learning`,
skill_3:`Python Programming`,
skill_4:`Statistical Modeling`,
skill_5:`Data Visualization`,
skill_6:`Big Data Analytics`,
course_faq_name:`Data Science Training – Private`,
faq_question_1:`What is the duration of the Data Science training?`,
faq_answer_1:`
The duration of the training typically ranges from a few weeks to several months, depending on the depth and breadth of the curriculum. It can vary based on the specific program and the pace of learning.`,
faq_question_2:`What are the prerequisites for enrolling in the Data Science training?`,
faq_answer_2:`Prerequisites may vary, but generally, a strong foundation in mathematics and statistics is recommended. Additionally, familiarity with programming languages like Python and knowledge of databases can be beneficial. Some courses may have specific prerequisites listed in their course descriptions.`,
faq_question_3:`Do I need a background in computer science to pursue Data Science training?`,
faq_answer_3:`While a background in computer science can be advantageous, it is not always necessary. Many Data Science training programs cater to individuals from diverse educational backgrounds, including mathematics, engineering, business, and social sciences. However, a willingness to learn programming and analytical skills is essential.
`,
faq_question_4:`What types of projects will I work on during the Data Science training?`,
faq_answer_4:`Projects in Data Science training often cover a wide range of topics, including data cleaning and preprocessing, exploratory data analysis, predictive modeling, and data visualization. These projects are designed to provide hands-on experience and practical skills that are applicable in real-world scenarios.`,
faq_question_5:`Is there any certification provided upon completion of the Data Science training?`,
faq_answer_5:`Many Data Science training programs offer certificates of completion to participants who successfully finish the course requirements. These certificates can be valuable for showcasing your skills and knowledge to potential employers. However, the specific certification process may vary depending on the training provider.`,
},






{
    id:210,
categories: `Snowflake`,
course_detail_image:course_images.programing_1,
course_name:`Supervising Snowflake Training - Individual`,
course_overview:`The Snowflake Cloud Data Platform database administrator position is covered extensively in this three-day role-specific training. Topics covered in this course include designing role hierarchies, advanced performance optimization, agile development, and data loading performance. Lessons, practical exercises, class debates, and laboratories make up the system.`,
certification:`Upon successful completion of our Supervising Snowflake Training - Individual program, participants will earn a certification credential. This certification validates expertise in Snowflake administration, covering data warehousing concepts, architecture, SQL querying, data loading, security, and performance optimization. Join us to elevate your skills in managing Snowflake data warehouses.`,
prerequisite:`A background in database administration or management is essential.`,
outline_heading_1:`Module 1: Overview and Architecture`,
outline_detail_1:`Snowflake Cloud Data Platform

Functional Architecture and Workloads

Global Structure: Cloud Platforms and Regions`,
outline_heading_2:`Module 2: Snowflake Environment`,
outline_detail_2:`Cloud Services Layer

Compute Layer

Data Storage Layer

Scaling Across, Up, and Out

Account Structure

Set Account Parameters`,
outline_heading_3:`Module 3: Data Storage and Metadat`,
outline_detail_3:`Data Storage Layer

What is Micro-partitioning?

Data Clustering`,
outline_heading_4:`Module 4: Data Objects and Commands`,
outline_detail_4:`
Objects

Tables

Constraints

Views

Data Types

Semi-structured Data

Querying Semi-structured data`,
outline_heading_5:`Module 5: Clients, Connectors, and Ecosystems`,
outline_detail_5:`Clients and Interfaces

SnowSQL`,
outline_heading_6:`Module 6: Query Profile`,
outline_detail_6:`Query Profile Overview

Explain Plan`,
outline_heading_7:`Module 7: Caching`,
outline_detail_7:`Metadata Cache

Query Result Cache

Data Cache`,
outline_heading_8:`Module 8: Managing Security`,
outline_detail_8:`Access

Authentication

What is an Integration?

Security Integrations

Authorization and Access Control

Privileges and Roles

Role Management

User Management`,
outline_heading_9:`Module 9: Administering Data Governance`,
outline_detail_9:`Data Governance and Snowflake

Identify and Classify

Protect

Audit and Analyze`,
outline_heading_10:`
Module 10: Managing Data Ingestion and Scaling`,
outline_detail_10:`Streaming and Batch Loading Overview

Bulk Loading

Storage Integration

Continuous Loading with Snowpipe

Notification Integrations`,
outline_heading_11:`Module 11: Stored Procedures and Tasks`,
outline_detail_11:`Stored Procedures

Task`,
outline_heading_12:`Module 12: Snowflake Scripting`,
outline_detail_12:`Snowflake Scripting Overview

Snowflake Scripting Constructs`,
outline_heading_13:`Module 13: Data Sharing`,
outline_detail_13:`Data Access Options

Direct Data Sharing

Direct Data Sharing Workflow`,
outline_heading_14:`Module 14: DML and Transaction Concurrency`,
outline_detail_14:`Transaction Concurrency Goals

System’s Point of View

Monitoring and Management Functions`,
outline_heading_15:`Module 15: Backup, Restore, and Snapshot`,
outline_detail_15:`Time Travel and Point-in-time Restore

How Does Time Travel Work?

Fail-safe

Zero-copy Cloning`,
outline_heading_16:`Module 16: Data Replication`,
outline_detail_16:`Replication Workflow

Failover Workflow`,
outline_heading_17:`Module 17: Managing Query Performance`,
outline_detail_17:`Writing Efficient Queries`,
outline_heading_18:`Module 18: Advanced Tuning Strategies`,
outline_detail_18:`Micro-partitions and Clustering

Automatic Clustering Service`,
outline_heading_19:`Module 19: Designing Clustering Keys`,
outline_detail_19:`Materialized Views

Search Optimization Service`,
outline_heading_20:`Module 20: Organizations`,
outline_detail_20:`Snowflake Organizations

Administrative Operations with Organizations

ORGADMIN Role

Creating Accounts

Querying Organization Account Usage

Account Management Commands
`,
outline_heading_21:`Module 21: Resource and Cost Governance`,
outline_detail_21:`Monitoring Compute

Monitoring Serverless Compute

Monitoring Storage

Monitoring Data Transfer

Resource Monitors`,

skill_1:`Manage and administer Snowflake accounts`,
skill_2:`Manage and maintain Snowflake data security and governance`,
skill_3:`Manage and manage database objects`,
skill_4:`Manage and manage virtual warehouses`,
skill_5:`Perform database monitoring and tweaking`,
skill_6:`Perform data sharing and leverage the Snowflake Data Exchange and Data Marketplace`,
course_faq_name:`Supervising Snowflake Training - Individual`,
faq_question_1:`What topics are covered in the Snowflake training?`,
faq_answer_1:`The Snowflake training covers a wide range of topics including data warehousing concepts, Snowflake architecture, SQL querying in Snowflake, data loading and unloading, security best practices, and performance optimization techniques.`,
faq_question_2:`How do I access the training materials?`,
faq_answer_2:`Upon enrollment, you will be provided with access to our online learning platform where you can find all the training materials including videos, slides, exercises, and supplementary resources.`,
faq_question_3:`What kind of support can I expect during the training?`,
faq_answer_3:`Throughout the training period, you will have access to a dedicated supervisor who will provide individual coaching, answer your questions, offer guidance, and monitor your progress to ensure a successful learning experience.`,
faq_question_4:`Is there a certification exam at the end of the training?`,
faq_answer_4:`Yes, upon completion of the training, you will have the opportunity to take a certification exam to validate your understanding and skills in Snowflake administration. Successful completion of the exam will earn you a certification credential.`,
faq_question_5:`What happens if I struggle with certain concepts during the training?`,
faq_answer_5:`If you encounter difficulties with certain concepts, our training supervisor will offer personalized assistance and additional explanations to help you grasp the material more effectively. We are committed to supporting your learning journey and ensuring your success.`,
},

// Linux // // Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //// Linux //

{
    id:40,
},



//devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // //devops // 
{
    id:250,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`Value Stream Management Foundation`,
    course_overview:`Introduction to the Course
    
    An introductory course that walks students through the value stream management implementation process is the Value Stream Management Foundation course from the Value Stream Management Consortium, which is given in partnership with the DevOps Institute. It analyzes how this approach of working takes into consideration people, processes, and technology and explains how optimizing value streams for flow and realization promotes organizational success.  Time required: two days of
    
    Goals for Learning:
    
    After taking this course, you would be able to:
    
    Explain how value stream management came to be, as well as crucial words like flow, value, and delivery.
    
    Explain value stream management, why it is required, and the benefits it offers for firms.
    
    Explain the value stream management advantages of lean, agile, DevOps, and ITSM methodologies.
    
    Value streams should be identified and explained, along with where they begin and terminate and how they interact.`,
    certification:`Successfully passing (65%) the 60-minute examination, consisting of 40 multiple-choice questions, leads to the candidate receiving their Value Stream Management Foundation certification and digital badge. The certificate is governed and maintained in partnership with DevOps Institute.`,
    prerequisite:`Although having a working knowledge of lean, agile, DevOps, and value stream principles will be helpful.
    
    Audience Profile 
    
    Leadership and CXO, especially CIO, CTO, CPO, and CVO
    Transformation and evolution lead and change agents
    Value stream architects, managers, engineers
    Scrum Masters, agile and DevOps coaches, and facilitators
    Portfolio, product and project managers, and owners
    Business analysts
    Architects, developers, and engineers
    Release and environment managers
    IT Ops, service, and support desk workers
    Customer experience and success professionals`,
    outline_heading_1:`The history and evolution of VSM`,
    outline_detail_1:`The history and evolution of VSM
    Identifying value streams
    Mapping value streams
    Connecting DevOps toolchains
    Value stream metrics
    Inspecting the value stream
    Organizing as value streams
    Evolving value streams`,
    skill_1:`Value Stream Mapping`,
    skill_2:`Process Optimization`,
    skill_3:`Lean Principles`,
    skill_4:`Continuous Improvement`,
    skill_5:`Data Analysis`,
    skill_6:`Collaboration`,
    course_faq_name:`Value Stream Management Foundation`,
    faq_question_1:`Q: What is the description of this course?`,
    faq_answer_1:`The Value Stream Management Foundation course from the Value Stream Management Consortium, offered in partnership with the DevOps Institute, is an introductory course taking learners through a value stream management implementation journey.`,
    faq_question_2:`Q-What is value stream management?`,
    faq_answer_2:`Q-What is value stream management?`,
    faq_question_3:`Q-What is the role of the value stream manager?`,
    faq_answer_3:`A value-stream manager is tasked with maximising the value-to-non-value ratio, reducing waste throughout the whole supply chain for a certain product family, and guaranteeing that the value stream satisfies or exceeds customer expectations`,
    faq_question_4:`Q-What are the benefits of value stream management?`,
    faq_answer_4:`Providing a view of the entire SDLC, value stream management enables software teams to identify and examine critical elements through the lens of the customer. This perspective also helps teams quickly catch issues or delays, eliminate wasteful activity, and create room for innovation.`,
    faq_question_5:`Q-How can Value Stream Management contribute to Agile and DevOps practices?`,
    faq_answer_5:`Value Stream Management complements Agile and DevOps practices by providing visibility into the flow of work from ideation to delivery. It helps teams identify bottlenecks, streamline processes, and prioritize improvements, fostering a culture of continuous delivery and innovation.`,
},
    
    
    

    
    {
        id:251,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`Site Reliability Engineering (SRE) Foundation℠ Course`,
    course_overview:`The course shows the development of SRE and its future direction. It empowers participants with the strategies, methods, and resources to engage individuals throughout the business engaged in dependability and stability shown via real-life situations and case studies. Upon course completion, participants will have real takeaways to use while back in the workplace, such as understanding, defining, and monitoring Service Level Objectives (SLOs). The course was produced by using important SRE sources, connecting with thought leaders in the SRE industry, and working with businesses adopting SRE to extract real-life best practices and has been intended to educate the core ideas & practices required for commencing SRE adoption. This course equips learners to complete the SRE Foundation certification test successfully.  Time required: two days of
    
    Goals for Learning:
    
    The learning goals for the SRE Foundation course include a practical grasp of:
    
    The history of SRE and its development at Google`,
    certification:`Successfully passing (65%) the 60-minute test, consisting of 40 multiple-choice questions, leads to the SRE (Site Reliability Engineering) Foundation credential. The certification is controlled and maintained by the DevOps Institute.
    
    
    Skills after learning Site Reliability Engineering (SRE) Foundation℠ Course:
    `,
    prerequisite:`An awareness and knowledge of popular DevOps terms and ideas and associated work experience are needed.
    
    Audience Profile 
    
    The target audience for the SRE Foundation course is professionals, including:
    Anyone initiating or leading a drive toward higher dependability
    Anyone interested in current IT leadership and organizational transformation
    approaches
    Business Managers
    Business Stakeholders
    Change Agents
    Consultants
    DevOps Practitioners
    IT Directors
    IT Managers
    IT Team Leaders
    Product Owners
    Scrum Masters
    Software Engineers
    Site Reliability Engineers
    System Integrators
    Tool Providers
    Course Outline 
    Course Introduction
    Course Goals`,
    outline_heading_1:`Course Agenda  Module 1: SRE Principles & Practices`,
    outline_detail_1:`What is Site Reliability Engineering?
    SRE & DevOps: What is the Difference?
    SRE Principles & Practices`,
    outline_heading_2:`Module 2: Service Level Objectives & Error Budgets`,
    outline_detail_2:`Service Level Objectives (SLOs)
    Error Budgets
    Error Budget Policies`,
    outline_heading_3:`Doing Something About Toil  Module 4: Monitoring & Service Level Indicators`,
    outline_detail_3:`Service Level Indicators (slis)
    Monitoring`,
    outline_heading_4:`Observability  Module 5: SRE Tools & Automation`,
    outline_detail_4:`Automation Defined
    Automation Focus
    Hierarchy of Automation Types
    Secure Automation`,
    outline_heading_5:`Module 3: Reducing Toil`,
    outline_detail_5:`What is Toil?
    Why is Toil Bad?`,
    outline_heading_6:`Automation Tools  Module 6: Anti-Fragility & Learning from Failure`,
    outline_detail_6:`Why Learn from Failure
    Benefits of Anti-Fragility`,
    outline_heading_7:`Shifting the Organizational Balance  Module 7: Organizational Impact of SRE`,
    outline_detail_7:`Site Reliability Engineering (SRE) Foundation℠
    Course Description
    © DevOps Institute unless otherwise specified SREF v1.1 4
    Why Organizations Embrace SRE
    Patterns for SRE Adoption
    On-Call Necessities
    Blameless Post-Mortems`,
    outline_heading_8:`SRE & Scale  Module 8: SRE, Other Frameworks, The Future`,
    outline_detail_8:`SRE & Other Frameworks
    The Future
    Additional Sources of Information
    Exam Preparations
    Exam Requirements, Question Weighting, and Terminology List
    Sample Exam Review`,
    skill_1:`Incident Management`,
    skill_2:`Automation`,
    skill_3:`Monitoring and Alerting`,
    skill_4:`Capacity Planning`,
    skill_5:`Service Level Objectives (SLOs) Definition`,
    skill_6:`Collaboration and Communication`,
    course_faq_name:`Site Reliability Engineering (SRE) Foundation℠ Course`,
    faq_question_1:`Q: What is the description of this course?`,
    faq_answer_1:`The course shows the development of SRE and its future direction. It empowers participants with the strategies, methods, and resources to engage individuals throughout the business engaged in dependability and stability shown via real-life situations and case studies.`,
    faq_question_2:`Q-What is the basis of SRE?`,
    faq_answer_2:`In this scenario, the key pillars of SRE include SLOs, monitoring, alerting, toil reduction, and simplicity. Getting these things correctly will prepare you well to succeed on your SRE journey. The next chapters address strategies for converting these key concepts into tangible practices for your company.`,
    faq_question_3:`Q-What is the key function of SRE?`,
    faq_answer_3:`A site reliability engineer (SRE) bridges development and IT operations by taking on the activities traditionally done by processes. Instead, such studies are awarded to engineers who utilize automation technologies to address challenges by constructing scalable and trustworthy software systems.`,
    faq_question_4:`Q-What is Site Reliability Engineering (SRE)?`,
    faq_answer_4:`Site Reliability Engineering (SRE) is a discipline that incorporates aspects of software engineering and applies them to infrastructure and operations problems. It aims to create scalable and reliable software systems.`,
    faq_question_5:`Q-How does SRE differ from traditional operations or system administration?`,
    faq_answer_5:`Traditional operations or system administration often focuses on manual tasks and firefighting. SRE, on the other hand, emphasizes automation, scalability, and reliability through the use of software engineering principles.`,
    },
    

    
    {
        id:252,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`Site Reliability Engineering (SRE) PractitionerSM Course`,
    course_overview:`Using case studies and real-world situations, the course strives to provide learners the methodologies, methods, and skills they need to incorporate dependability in all sectors of the organization. Participants will have practical takeaways to use in the workplace after completing the course, including how to implement SRE models that fit their organization's context, build advanced observability in distributed systems, create resiliency by design, and effectively respond to incidents using SRE practises.. The course is built by using important SRE sources, connecting with thought-leaders in the SRE industry, and working with businesses adopting SRE to extract real-life best practices and has been intended to educate the core ideas & practices required for commencing SRE adoption. This course prepares learners to complete the SRE Practitioner certification test.  Duration:  3 days
    
    Goals for Learning:
    
    At the completion of the course, the following learning goals are anticipated to be achieved`,
    certification:``,
    prerequisite:`Learners are strongly urged to take the SRE Foundation course with an approved DevOps Institute Education Partner and acquire the SRE Foundation certification before taking the SRE Practitioner course and test. An grasp and knowledge of common SRE vocabulary, ideas, principles, and associated work experience are recommended.
    
    Audience Profile 
    
    The specialists who are interested in large-scale service scalability and dependability are the course's target audience.
    Anyone with an interest in modern ways to organizational transformation and leadership in IT.
    Business Stakeholders
    Business Managers
    Change Agents
    Consultants
    DevOps Practitioners
    IT Directors
    IT Managers
    IT Team Leaders
    Product Owners
    Scrum Masters
    Software Engineers
    Site Reliability Engineers
    System Integrators
    Tool Providers
    Course Outline 
    Course Introduction`,
    outline_heading_1:`Module 1: SRE Anti-patterns`,
    outline_detail_1:``,
    outline_heading_2:`Module 2: SLO is a Proxy for Customer Happiness`,
    outline_detail_2:``,
    outline_heading_3:`Module 3: Building Secure and Reliable Systems`,
    outline_detail_3:``,
    outline_heading_4:`Module 4: Full-Stack Observability`,
    outline_detail_4:``,
    outline_heading_5:`Module 5: Platform Engineering and AIOPs`,
    outline_detail_5:``,
    outline_heading_6:`Module 6: SRE & Incident Response Management`,
    outline_detail_6:``,
    outline_heading_7:`Module 7: Chaos Engineering`,
    outline_detail_7:``,
    outline_heading_8:`Module 8: SRE is one of the Purest kind of DevOps`,
    outline_detail_8:``,
    skill_1:`Incident Response Management`,
    skill_2:`Automation and Scripting`,
    skill_3:`Monitoring and Alerting`,
    skill_4:`Performance Optimization`,
    skill_5:`Infrastructure as Code`,
    skill_6:`Risk Assessment and Mitigation`,
    course_faq_name:`Site Reliability Engineering (SRE) PractitionerSM Course`,
    faq_question_1:`Q-What is an SRE practitioner?`,
    faq_answer_1:`The SRE (Site Reliability Engineering) Practitioner course presents strategies to efficiently and successfully grow administrative services.`,
    faq_question_2:`Q-What is SRE vs. DevOps?`,
    faq_answer_2:`DevOps focuses on the development side of product management and providing tools for developers and monitoring systems. SRE focuses on the operations aspect of product management. SREs concentrate on enabling developers' code deployments and server deployments.`,
    faq_question_3:`Q-What does an SRE engineer do?`,
    faq_answer_3:`A site reliability engineer is an IT specialist who employs automated techniques to monitor and observe software dependability in the production environment. They are also experienced at spotting faults in software and developing programs to repair them.`,
    faq_question_4:`What are some common tools used in SRE?`,
    faq_answer_4:`Common tools used in SRE include monitoring and alerting systems such as Prometheus and Grafana, automation tools like Ansible and Terraform, incident management platforms such as PagerDuty, and version control systems like Git.`,
    faq_question_5:`What skills are required to become an SRE practitioner?`,
    faq_answer_5:`Skills required for an SRE practitioner include proficiency in programming and scripting languages, knowledge of cloud computing platforms, experience with containerization and orchestration tools, strong problem-solving abilities, and a deep understanding of system architecture and networking concepts.`,
    },
    

    
    
    {
        id:253,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`DevSecOps Practitioner℠`,
    course_overview:`A continuation of the DevSecOps Foundation course is the DevSecOps Practitioner course. The course focuses on existing information before getting into technical implementation. Each component highlights practical metrics and adds innovative ideas into diverse ways. To enhance DevSecOps results, these modules advocate addressing problems with people, procedures, and technology. The course examines exploiting existing metrics or inventing novel expressions applicable to each DevOps experience after taking a closer look at the underlying ideas. The middle portion tackles architectural modifications, infrastructure development, and optimizing the CI/CD pipeline. The training also explores where DevSecOps may go in the future and how to optimize your experimental techniques.More complex techniques to investigate DevSecOps within your business are addressed in the DevsecOps Practitioner course. Each component describes how to integrate people, processes, and technology to enhance results and gives practical maturity recommendations.`,
    certification:`The DevSecOps Practitioner credential is acquired by passing (65%) the 90-minute exam, which consists of 40 multiple-choice questions. DevOps Institute monitors and maintains the certification.`,
    prerequisite:`Before enrolling in the DevSecOps Practitioner course and test, students are highly urged to attend the DevSecOps Foundation course via a certified DevOps Institute Education Partner and gain the DevSecOps Foundation certification. It is suggested that you have knowledge with common DevSecOps terminology, ideas, and working methods.
    
    Audience Profile 
    The professionals who take the DevSecOps Practitioner course include:
    Someone attempting to build or strengthen DevSecOps policies within their firm
    Anybody interested in modern ways to organizational transformation and leadership in IT
    Business Stakeholders
    Business Managers
    Change Agents
    Consultants
    DevOps Practitioners
    IT Directors
    IT Managers
    IT Team Leaders
    Product Owners
    Scrum Masters
    Software Engineers
    Site Reliability Engineers
    System Integrators
    Tool Providers
    Course Outline 
    Course Introduction`,
    outline_heading_1:`Module 1: DevSecOps Advanced Basics`,
    outline_detail_1:``,
    outline_heading_2:`Module 2: Understanding Applied Metrics`,
    outline_detail_2:``,
    outline_heading_3:`Module 3: Architecting and Planning for DevSecOps`,
    outline_detail_3:``,
    outline_heading_4:`Module 4: Creating a DevSecOps Infrastructure`,
    outline_detail_4:``,
    outline_heading_5:`Module 5: Establishing a Pipeline`,
    outline_detail_5:``,
    outline_heading_6:`Module 6: Observing DevSecOps Outcomes`,
    outline_detail_6:``,
    outline_heading_7:`Module 7: Practical 3rd Way Applications`,
    outline_detail_7:``,
    outline_heading_8:`Module 8: The Future of DevOps`,
    outline_detail_8:``,
    skill_1:`Continuous Integration/Continuous Deployment (CI/CD)`,
    skill_2:`Security Automation`,
    skill_3:`Threat Modeling`,
    skill_4:`Secure Software Development Lifecycle (SSDLC)`,
    skill_5:`Vulnerability Management`,
    skill_6:`Container Security`,
    course_faq_name:`DevSecOps Practitioner℠`,
    faq_question_1:`Q-What does a DevSecOps engineer do?`,
    faq_answer_1:`DevSecOps engineers pick and install the proper automated application security testing technologies. They are responsible for making users aware of how to make the most of program security features. Software projects have become a complicated blend of diverse moving pieces -- both human and mechanical.`,
    faq_question_2:`Q-What is DevSecOps certification`,
    faq_answer_2:`Practical DevSecOps issued it. The CDP certificate confirms the functional knowledge to comprehend , execute and manage the DevSecOps program in an enterprise. A CDP may also examine the present level of DevSecOps, incorporate security as part of DevOps, correct vulnerabilities, and increase the overall Maturity Level.`,
    faq_question_3:`Q-What is the difference between DevSecOps and DevOps?`,
    faq_answer_3:`Differences Between DevOps and DevSecOps. DevSecOps emerged from DevOps, although the two disciplines have distinct purposes. DevOps emphasizes on efficiency, whereas DevSecOps focuses on security. DevSecOps builds upon DevOps to solve a vulnerability in the cloud.`,
    faq_question_4:`Why is DevSecOps important?`,
    faq_answer_4:`DevSecOps is important because it helps organizations build secure and resilient software applications by integrating security into the development process. By addressing security concerns early and continuously, organizations can reduce the risk of security breaches and ensure the integrity and confidentiality of their systems and data.`,
    faq_question_5:`What are the key benefits of implementing DevSecOps?`,
    faq_answer_5:`Key benefits of DevSecOps include faster time to market, improved collaboration between teams, reduced security vulnerabilities, better compliance with regulations and standards, and increased overall security posture.`,
    },
    
    
    
    
    
    {
        id:254,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`DevSecOps Foundation (DSOF)℠ Course`,
    course_overview:`DevSecOps' contribution to business value, your business potential, and corporate value are some of the subjects discussed in the course. The essential DevSecOps ideas offered may help in organisational transformation, enhance production, minimize risk, and make optimum use of existing resources. This course examines how DevOps security processes vary from prior tactics before offering the expertise essential to create changes within your firm. Participants receive understanding of DevSecOps' aims, benefits, concepts, terminology, and applications. What matters most is that students understand how DevSecOps positions fit within a DevOps culture and organization. Participants will grasp "security as code" at the completion of the course, which will allow security and compliance value to be consumed as a service. This course outlines the techniques for integrating security programs from the developers and operators. No system would be complete without actual usage.`,
    certification:`Successfully passing (65%) the 60-minute test, comprising of 40 multiple-choice questions, leads to the candidate’s certification as DevSecOps Foundation (DSOF) certified. The certification is controlled and maintained by DevOps Institute.`,
    prerequisite:`Participants should have baseline knowledge and awareness of common DevOps concepts and principles.
    
    Audience Profile 
    
    Anyone active or interested in learning about DevSecOps techniques and automation
    Anyone engaged with Continuous Delivery toolchain architectures
    Compliance Team
    Business managers
    Delivery Staff
    DevOps Engineers
    IT Managers
    IT Security Professionals, Practitioners, and Managers
    Maintenance and support crew
    Managed Service Providers
    Project & Product Managers
    Quality Assurance Teams
    Release Managers
    Scrum Masters
    Site Reliability Engineers
    Software Engineers
    Testers`,
    outline_heading_1:`Why DevSecOps?`,
    outline_detail_1:``,
    outline_heading_2:`Culture and Management`,
    outline_detail_2:``,
    outline_heading_3:`Strategic Considerations`,
    outline_detail_3:``,
    outline_heading_4:`General Security Considerations`,
    outline_detail_4:``,
    outline_heading_5:`IAM: Identity and Access Management`,
    outline_detail_5:``,
    outline_heading_6:`Application Security`,
    outline_detail_6:``,
    outline_heading_7:`Operational Security`,
    outline_detail_7:``,
    outline_heading_8:`Governance, Risk, Compliance (GRC), and Audit`,
    outline_detail_8:``,
    outline_heading_9:`Logging, Monitoring, and Response`,
    outline_detail_9:``,
    skill_1:`Secure Software Development`,
    skill_2:`Automated Testing`,
    skill_3:`Infrastructure as Code (IaC)`,
    skill_4:`Security Monitoring and Incident Response`,
    skill_5:`Secure Configuration Management`,
    skill_6:`Threat Modeling`,
    course_faq_name:`DevSecOps Foundation (DSOF)℠ Course`,
    faq_question_1:`Q-What is DevSecOps in DevOps?`,
    faq_answer_1:`Development, security, and operations, sometimes known as DevSecOps, automate security integration at each level of the software development lifecycle, from basic design through integration, deployment,testing, and product delivery.`,
    faq_question_2:`Q-What is the notion of DevSecOps?`,
    faq_answer_2:`DevSecOps (short for development, security, and operations) is a development methodology that incorporates security activities at every level of the software development lifecycle to provide strong and secure products.`,
    faq_question_3:`Q: What are the requirements of this course?`,
    faq_answer_3:`Participants should have baseline knowledge and awareness of common DevOps concepts and principles.`,
    faq_question_4:`What will I learn in DSOF?`,
    faq_answer_4:`DSOF covers various topics including secure software development, automated testing, infrastructure as code, security monitoring, secure configuration management, and threat modeling.`,
    faq_question_5:`Is DSOF certification recognized in the industry?`,
    faq_answer_5:`Yes, DSOF certification is recognized in the industry as a validation of skills and knowledge in integrating security practices into DevOps processes.`,
    },
    
    
    
    {
        id:255,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`DevOps Leader (DOL)® Course`,
    course_overview:`For participants who aim to embrace a transformative leadership position and make an influence on their organization by embracing DevOps, the DevOps Leader course provides a unique and practical experience. It needs new talents, cutting-edge technologies, innovative thinking, and revolutionary leadership to steer people through a DevOps progression. To remove silos and progress the firm, executives at all levels and from all sectors of the organization must unify and work together. The Course stresses how individuals engage with cultural change. It presents learners with the ideas, strategies, and tools they need to engage folks from all parts of the DevOps community via case studies and real-world situations. After completing the course, learners will have practical takeaways to utilize in the business, such as understanding of value stream mapping.  Time required: two days of`,
    certification:`Successfully passing (65%) the 60-minute test, comprising of 40 multiple-choice questions, leads to the candidate's recognition as a certified DevOps Leader (DOL). The certification is controlled and maintained by DevOps Institute.`,
    prerequisite:`An awareness and knowledge of popular DevOps terms and ideas and associated work experience are needed.
    
    Audience Profile 
    
    The target audience for the course DevOps Leader is professionals, including:
    Anyone initiating or leading a DevOps culture change campaign
    Anyone interested in current IT leadership and organizational transformation methods
    Business Managers
    Business Stakeholders
    Change Agents
    Consultants
    DevOps Consultants
    DevOps Engineers
    IT Directors
    IT Managers
    IT Team Leaders
    Lean Coaches
    Practitioners
    Product Owners
    Scrum Masters
    System Integrators
    Tool Providers`,
    outline_heading_1:`Module 1: DevOps and Transformational Leadership`,
    outline_detail_1:``,
    outline_heading_2:`Module 2: Unlearning Behaviors`,
    outline_detail_2:``,
    outline_heading_3:`Module 3: Becoming a DevOps Organization`,
    outline_detail_3:``,
    outline_heading_4:`Module 4: Measure to Learn`,
    outline_detail_4:``,
    outline_heading_5:`Module 5: Measure to Improve`,
    outline_detail_5:``,
    outline_heading_6:`Module 6: Target Operating Models and Organizational Design`,
    outline_detail_6:``,
    outline_heading_7:`Module 7: Articulating and Socializing Vision`,
    outline_detail_7:``,
    outline_heading_8:`Module 8: Maintaining Energy and Momentum`,
    outline_detail_8:``,
    skill_1:`Strategic Planning`,
    skill_2:`Team Leadership`,
    skill_3:`Change Management`,
    skill_4:`Performance Monitoring and Optimization`,
    skill_5:`Culture Transformation`,
    skill_6:`Stakeholder Management`,
    course_faq_name:`DevOps Leader (DOL)® Course`,
    faq_question_1:`Q-Who is a DevOps leader?`,
    faq_answer_1:`A DevOps Leader is a tactical or strategic professional who helps create, inspire, execute or encourage the culture shift demonstrated to be a major success factor in DevOps adoption.`,
    faq_question_2:`Q-What is the job of the DevOps lead?`,
    faq_answer_2:`The head of the DevOps team will design and execute the company's production and development environments. Responsibilities will involve producing scalable, resilient, cost-effective design and establishing a team of first-class experts by mentoring, directing, planning career routes, and optimizing the recruiting.`,
    faq_question_3:`Q3: What is the target audience of this Course?`,
    faq_answer_3:`A4:Anyone initiating or leading a DevOps culture change campaign
    Anyone interested in current IT leadership and organizational transformation methods
    Business Managers
    Business Stakeholders
    Change Agents`,
    faq_question_4:`Q4: How will the course help me improve my organization's DevOps practices?`,
    faq_answer_4:`A4: The course provides insights into strategic planning, team leadership, and culture transformation, enabling participants to implement and drive DevOps initiatives that improve organizational performance and efficiency.`,
    faq_question_5:`Q5: What are some key topics covered in the DevOps Leader course?`,
    faq_answer_5:`A5: Key topics covered include change management, performance monitoring, stakeholder management, and fostering a DevOps culture conducive to innovation and collaboration.`,
    },




    {
        id:256,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`DevOps Foundation® Course Description`,
    course_overview:`The DevOps Foundation course stresses the benefits of DevOps to increase organizational performance and delivers a fundamental grasp of crucial DevOps concepts to assure that everyone is speaking the same language. The system incorporates the most recent theories, guidelines, and practises from the DevOps community, as well as actual case studies from high-performing businesses like ING Bank, Ticketmaster, Capital One, Alaska Air, Target, Fannie Mae, Societe Generale, and Disney that inspire and engage students. These exercises make use of multimedia and interactive activities, such as the Three Ways as outlined in the Phoenix Project by Gene Kim. DevOps, the cultural and professional movement that promotes communication, collaboration, integration, and automation to better the workflow between software developers and IT operations professionals, will be introduced to learners. The course's open enrollment enables participants on the business side to understand containers and microservices.  Time required: two days of`,
    certification:`The DevOps Foundation Certificate is acquired by passing (65%) the 60-minute exam, which comprises of 40 multiple-choice questions. The DevOps Institute monitors and maintains the certification.`,
    prerequisite:`Familiarity with IT terminology and IT-related job experience is recommended.
    
    Audience Profile 
    
    The DevOps Foundation course is designed for professionals in management, operations, development, quality assurance, and testing, including those working in IT development, IT operations, or IT service management.
    
    Those who need to know the DevOps concepts.
    
    IT professionals who are presently functioning in or about to join an Agile Service Design Environment
    
    Business analysts, business managers, business stakeholders, change agents, consultants, devops consultants, devops engineers, infrastructure architects, integration, and application developers are some examples of IT jobs.`,
    outline_heading_1:`Module 1: Exploring DevOps`,
    outline_detail_1:``,
    outline_heading_2:`Module 2: Core DevOps Principles`,
    outline_detail_2:``,
    outline_heading_3:`Module 3: Key DevOps Practices`,
    outline_detail_3:``,
    outline_heading_4:`Module 4: Business and Technology Frameworks`,
    outline_detail_4:``,
    outline_heading_5:`Module 5: Culture, Behaviors & Operating Models`,
    outline_detail_5:``,
    outline_heading_6:`Module 6: Automation & Architecting DevOps Toolchains`,
    outline_detail_6:``,
    outline_heading_7:`Module 7: Measurement, Metrics, and Reporting`,
    outline_detail_7:``,
    outline_heading_8:`Module 8: Sharing, Shadowing, and Evolving`,
    outline_detail_8:``,
    skill_1:`Continuous Integration (CI)`,
    skill_2:`Continuous Delivery (CD)`,
    skill_3:`Infrastructure as Code (IaC)`,
    skill_4:`Automation`,
    skill_5:`Collaboration and Communication`,
    skill_6:`Monitoring and Logging`,
    course_faq_name:`DevOps Foundation® Course Description`,
    faq_question_1:`Q-What is DevOps Foundations?`,
    faq_answer_1:`The DevOps Foundation course gives a basic grasp of important DevOps vocabulary to ensure everyone is talking the same language and illustrates the advantages of DevOps to promote corporate success.`,
    faq_question_2:`Q-What are the requirements of this course?`,
    faq_answer_2:`Familiarity with IT terminology and IT-related job experience is recommended.`,
    faq_question_3:`Q-What is DevOps?`,
    faq_answer_3:`DevOps is a cultural and professional movement that focuses on improving collaboration and communication between software development and IT operations teams, with the goal of delivering high-quality software products and services at a faster pace.`,
    faq_question_4:`Q-What are the key benefits of implementing DevOps?`,
    faq_answer_4:`Some key benefits of DevOps include faster time-to-market, improved quality and reliability of software releases, increased collaboration between teams, and more efficient use of resources.`,
    faq_question_5:`Q-What is Continuous Integration (CI)?`,
    faq_answer_5:`Continuous Integration is a practice where developers frequently integrate their code changes into a shared repository, followed by automated builds and tests to detect integration errors early in the development process.`,
    },
    
    
    
    
    {
        id:257,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`Continuous Testing Foundation (CTF) SM Course`,
    course_overview:`This thorough course discusses Testing in a DevOps setting. It addresses themes such as implementing test automation, testing early in  development cycle, and establishing testing skills in developers, quality assurance, security, and operational teams. The training is useful for any contemporary IT professional in establishing or adopting a DevOps testing strategy for their firm. Test engineering is the backbone of DevOps and the major component to a successful DevOps pipeline to enable digital transformation.  Time required: two days 
    
    Goals for Learning:
    
    The learning goals for CTF include a practical grasp of the following:
    The aim, advantages, principles, and terminology of DevOps testing
    How DevOps testing differs from other methods of Testing
    DevOps testing techniques, test management, and outcomes analysis
    Methods for choosing test tools and implementing test automation
    Integration of DevOps testing into Continuous Integration and Continuous Delivery processes
    How DevOps testers fit with a DevOps culture, organization, and responsibilities`,
    certification:``,
    prerequisite:`An awareness and knowledge of the popular DevOps terminology and ideas and other similar job experience are needed.
    
    Audience Profile 
    
    The target audience for the Continuous Testing Foundation course is anybody engaged in establishing a DevOps Testing strategy, such as
    Delivery Staff
    DevOps Engineers
    IT Managers
    Project Managers
    Lab Staff
    Maintenance and Support Staff
    Quality Assurance Managers
    Quality Assurance Teams
    Release Managers
    Testers
    Software Engineers`,
    outline_heading_1:`DevOps Continuous Testing Concepts, Terminology, and Benefits`,
    outline_detail_1:``,
    outline_heading_2:`DevOps Continuous Testing and Culture`,
    outline_detail_2:``,
    outline_heading_3:`DevOps Continuous Testing Strategies`,
    outline_detail_3:``,
    outline_heading_4:`DevOps Continuous Testing Frameworks and Tools`,
    outline_detail_4:``,
    outline_heading_5:`DevOps Test Planning Best Practices`,
    outline_detail_5:``,
    outline_heading_6:`DevOps Test Automation Best Practices`,
    outline_detail_6:``,
    outline_heading_7:`DevOps Test Management and Analysis Best Practices`,
    outline_detail_7:``,
    outline_heading_8:`Defining a DevOps Test Strategy`,
    outline_detail_8:``,
    skill_1:`Test Automation`,
    skill_2:`Agile Testing`,
    skill_3:`Test-Driven Development (TDD)`,
    skill_4:`Behavior-Driven Development (BDD)`,
    skill_5:`Behavior-Driven Development (BDD)`,
    skill_6:`Performance Testing`,
    course_faq_name:`Continuous Testing Foundation (CTF) SM Course`,
    faq_question_1:`Q-Why is continuous Testing important in DevOps?`,
    faq_answer_1:`Continuous Testing advocates automating tests wherever feasible throughout the development cycle. This helps teams analyze the code validity and overall program quality at each level. These insights may assist businesses assess if the product is ready to move through the delivery pipeline.`,
    faq_question_2:`Q-Who is the target audience for the Continuous Testing Foundation course?`,
    faq_answer_2:`The Continuous Testing Foundation course is suitable for testers, developers, test managers, quality assurance professionals, and anyone involved in software development and testing processes.`,
    faq_question_3:`Q-Is any prior experience required to enroll in the Continuous Testing Foundation course?`,
    faq_answer_3:`No prior experience is required to enroll in the Continuous Testing Foundation course. However, familiarity with software development and testing concepts can be beneficial.`,
    faq_question_4:`Q-How does Continuous Testing differ from traditional testing approaches?`,
    faq_answer_4:`Continuous Testing emphasizes testing throughout the software development lifecycle, integrating testing activities into the continuous integration and continuous delivery pipelines. This ensures early detection of defects and faster feedback loops.`,
    faq_question_5:`Q-What are some key topics covered in the Continuous Testing Foundation course?`,
    faq_answer_5:`Some key topics covered in the Continuous Testing Foundation course include test automation, agile testing principles, test-driven development (TDD), behavior-driven development (BDD), test planning and strategy, and performance testing.`,
    },
   
    {
        id:258,
    categories: ``,
    course_detail_image:course_images.programing_1,
    course_name:`Professional in Agile Service Management (CASM)`,
    course_overview:`Agile Service Management, the practice of applying and integrating agile thinking into service management procedures, is introduced in this course. With an agile mindset, IT becomes more efficient and productive, and it can keep delivering value even when needs change. By studying and improving the IT service's end-to-end value streams, IT service management (ITSM) makes sure the service is valuable. By combining Agile and IT Service Management principles, this course paves the way for end-to-end Agile Service Management, which improves work flow and speed to value via scaling to a "just enough" method. By adopting an iterative approach to process engineering, which will increase the velocity of process improvement teams and help IT achieve customer needs quicker, Agile Service Management improves communication between Dev and Ops and manages process workflow restrictions. In order to pass the CASM test, students need to take this course.Time required: two days of`,
    certification:`Successfully completing (65%) the 60-minute test, consisting of 40 multiple-choice questions, leads to the candidate’s accreditation as a Certified Agile Service Manager. The certification is controlled and maintained by the DevOps Institute.`,
    prerequisite:`Some Familiarity with ITSM procedures and Scrum is recommended.
    
    Audience Profile 
    The target audience for the CASM course is:
    Practice owners and process designers
    Developers that are interested in helping make processes more agile
    Managers that are aiming to bridge diverse approaches into a DevOps environment
    Employees and administrators responsible for designing or enhancing process
    Consultants assisting their clients through process improvement and DevOps projects
    Anyone responsible for:
    Managing process-related requirements
    Ensuring the efficiency and effectiveness of processes
    Maximizing the value of processes`,
    outline_heading_1:`Module 1: Why Agile Service Management?`,
    outline_detail_1:``,
    outline_heading_2:`Module 2: Agile Service Management`,
    outline_detail_2:``,
    outline_heading_3:`Module 3: Leveraging Related Guidance`,
    outline_detail_3:``,
    outline_heading_4:`Module 4: Agile Service Management Roles`,
    outline_detail_4:``,
    outline_heading_5:`Module 5: Agile Process Engineering`,
    outline_detail_5:``,
    outline_heading_6:`Module 6: Agile Service Management Artifacts`,
    outline_detail_6:``,
    outline_heading_7:`Module 7: Agile Service Management Events`,
    outline_detail_7:``,
    outline_heading_8:`Module 8: Agile Process Improvement`,
    outline_detail_8:``,
    skill_1:`Agile Service Management`,
    skill_2:`Scrum Framework`,
    skill_3:`Kanban Method`,
    skill_4:`Lean Principles`,
    skill_5:`DevOps Practices`,
    skill_6:`IT Service Management (ITSM) Integration`,
    course_faq_name:`Professional in Agile Service Management (CASM)`,
    faq_question_1:`Q-What is the Scrum framework, and how does it relate to Agile Service Management?`,
    faq_answer_1:`Scrum is an Agile framework for managing work, commonly used in software development. Agile Service Management incorporates principles from Scrum to enhance service delivery processes and increase efficiency.`,
    faq_question_2:`Q-How does Agile Service Management differ from traditional ITSM?`,
    faq_answer_2:`Traditional ITSM often follows a waterfall approach, while Agile Service Management embraces iterative and incremental practices, allowing for quicker adaptation to changing requirements and customer needs.`,
    faq_question_3:`Q-What is Agile Service Management?`,
    faq_answer_3:`Agile Service Management combines Agile methodologies and IT Service Management (ITSM) principles to deliver value in a flexible and responsive manner.`,
    faq_question_4:`Q-What is a Certified agile Service Manager CASM?`,
    faq_answer_4:`A Certified Agile Service Manager (CASM) is the operational equivalent of a Certified Scrum Master (CSM). Working together, Scrum Masters and Agile Service Managers may inculcate agile thinking throughout the whole IT (Information Technology) company as the backbone of a DevOps culture.`,
    faq_question_5:`Q-What is the agile approach to service management?`,
    faq_answer_5:`Involve your consumers in creating your procedures and offerings. When delivering a new product, deploy a basic solution to a limited test audience and iterate depending on their input. Aim for simplicity in your operations, and reduce stages without value for your consumer.`,
    },
    
    

// HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR // HR



{
    id:40,
    categories: `HR training`,
    course_detail_image:course_images.hr_first,
    course_name:`HR FUNDAMENTALS`,
    course_overview:`The HR Fundamentals course offers a comprehensive introduction to the core principles and practices of human resource management. Topics include recruitment, employee relations, performance management, and legal compliance. Through case studies and practical exercises, participants gain the knowledge and skills essential for effective HR leadership in diverse organisational settings.`,
    certification:`The certification covers essential HR topics such as recruitment and selection, employee development, compensation and benefits, HR laws and regulations, and strategic HR management.`,
    prerequisite:`No prior HR experience required. Basic understanding of organizational behavior and business operations recommended.`,
    outline_heading_1:` Introduction to HR Management`,
    outline_heading_2:`Recruitment and Selection`,
    outline_heading_3:`Training and Development`,
    outline_heading_4:`Performance Management`,
    outline_heading_5:`Employee Relations and Engagement`,
    outline_heading_6:`HR Metrics and Strategic HR`,
    outline_heading_7:`HR Information System`,
    outline_detail_1:`Day 1 Orientation and Program Overview
                 Day 2 Introduction to Human Resources Management
                 Day 3 HR Roles and Responsibilities
                 Day 4 HR Ethics and Professionalism
                 Day 5 HR Compliance and Legal Considerations`,
    outline_detail_2:`Day 1: Workforce Planning and Job Analysis
                 Day 2: Sourcing and Attracting Talent
                 Day 3: Screening and Shortlisting Candidates
                 Day 4: Interviewing Techniques and Selection Methods
                 Day 5 Background Checks and Reference Checks`,
    outline_detail_3:`Day 1: Training Needs Assessment
                 Day 2: Designing Effective Training Programs
                 Day 3: Delivery Methods and Techniques
                 Day 4: Evaluating Training Effectiveness
                 Day 5: Career Development and Succession Planning`,
    outline_detail_4:`            Day 1: Performance Appraisal Systems
                Day 2: Setting SMART Goals and Objectives
                Day 3: Providing Feedback and Coaching
                Day 4: Performance Improvement Plans
                Day 5: Managing Employee Performance Issues`,
    outline_detail_5:` Day 1: Performance Appraisal Systems
                Day 2: Setting SMART Goals and Objectives
                Day 3: Providing Feedback and Coaching
                Day 4: Performance Improvement Plans
                Day 5: Managing Employee Performance Issues`,
    outline_detail_6:`            Day 1: HR Metrics and Analytics
                Day 2: HR Data Management and Reporting
                Day 3: HR Technology and Automation
                Day 4: Strategic HR Planning
                Day 5: Future Trends in HR Management`,
    outline_detail_7:`           Day 1: Introduction and overview
               Day 2: Leave Management
               Day 3: Data handling
               Day 4: Hands on experience`,
    skill_1:`Recruitment`,
    skill_2:`Employee Relations`,
    skill_3:`Performance Management`,
    skill_4:`Legal Compliance`,
    skill_5:`Organisational Behaviour`,
    skill_6:`Strategic HR Management`,
    course_faq_name:`HR fundamentals`,
    faq_question_1:`Can I take this course without any HR background?`,
    faq_question_2:`What type of certification will I receive upon completion?`,
    faq_question_3:`Are there any prerequisites for this course?`,
    faq_question_4:` long does it take to How complete the course?`,
    faq_question_5:`Is this course suitable for experienced HR professionals?`,
    faq_answer_1:`Yes, the course is designed for beginners with no prior HR experience.`,
    faq_answer_2:`You'll receive a certification in HR Fundamentals, validating your understanding of core HR principles.`,
    faq_answer_3:`Basic knowledge of organisational behaviour and business operations is recommended but not mandatory.`,
    faq_answer_4:`The course duration varies but typically takes between 6 to 8 weeks to complete.`,
    faq_answer_5:`While it's designed for beginners, experienced professionals can also benefit from its comprehensive overview and refresh their knowledge.`,
    
},
{
    id:41,
    categories: `HR training `,
    course_detail_image:course_images.hr_second,
    course_name:`HR GENERALIST`,
    course_overview:`The HR Generalist course equips participants with the diverse skill set required to excel in HR roles. Covering recruitment, training, employee relations, compensation, and compliance, this comprehensive program integrates theoretical knowledge with practical applications. Through interactive sessions and real-world case studies, participants develop proficiency in managing various HR functions, preparing them for dynamic workplace challenges and opportunities.`,
    certification:`The certification encompasses recruitment and staffing, employee development, performance management, compensation and benefits, HR laws and regulations, and strategic HR planning, ensuring comprehensive proficiency in HR generalist functions.`,
    prerequisite:`Prior experience in HR or related field recommended but not mandatory. Basic understanding of HR principles, organizational behavior, and employment laws beneficial.`,
    outline_heading_1:`Introduction to Human Resources Management`,
    outline_heading_2:`Employment Law and Regulations`,
    outline_heading_3:`Employee Onboarding and Orientation`,
    outline_heading_4:`Performance Management`,
    outline_heading_5:`Training and Development`,
    outline_heading_6:`Compensation and Benefits`,
    outline_heading_7:`Employee Relations`,
    outline_heading_8:`HR Information Systems (HRIS)`,
    outline_heading_9:`Legal Compliance and Ethics`,
    outline_heading_10:`Emerging Trends in HR`,
    outline_detail_1:`Overview of HR roles and responsibilities
    ·       Historical evolution of HRM
    ·       Importance of HRM in organizational success
    ·       HRM functions and objectives
    `,
    outline_detail_2:`
    ·       Overview of employment laws (e.g., Title VII, ADA, FMLA)
    ·       Understanding of labor regulations and compliance requirements
    ·       Equal employment opportunity principles
    ·       Workplace safety regulations (OSHA)
    `,
    outline_detail_3:`
    ·       Importance of effective onboarding
    ·       Onboarding process design
    ·       Orientation programs and materials
    ·       Integration of new hires into organizational culture
    `,
    outline_detail_4:`
    ·       Performance appraisal methods and systems
    ·       Goal setting and performance planning
    ·       Continuous feedback and coaching
    ·       Performance improvement plans (PIPs)
    ·       Handling performance-related issues and disciplinary actions
    `,
    outline_detail_5:`
    ·       Training needs analysis
    ·       Learning and development strategies
    ·       Training program design and delivery
    ·       Evaluating training effectiveness
    ·       Career development and succession planning
    `,
    outline_detail_6:`
    ·       Compensation structures and strategies
    ·       Job evaluation and salary surveys
    ·       Benefits administration
    ·       Payroll processing and compliance
    ·       Total rewards management
    `,
    outline_detail_7:`
    · Employee relations fundamentals
    · Conflict resolution techniques
    · Grievance handling procedures
    · Employee engagement strategies
    · Workplace diversity and inclusion initiatives
    `,
    outline_detail_8:`
    •	Introduction to HRIS
    •	HR data management and analytics
    •	HR technology trends
    •	Implementation and utilization of HRIS platforms
    `,
    outline_detail_9:`
    ·   Legal and ethical considerations in HRM
    ·   Confidentiality and data protection
    ·   Ethical decision-making frameworks
    ·   Code of conduct enforcement
    `,
    Outline_detail_10:`
    •	Remote work and virtual teams
    •	Gig economy and flexible work arrangements
    •	Artificial intelligence in HR
    •	Future challenges and opportunities in HRM
    `,
    skill_1:`Recruitment and Staffing`,
    skill_2:`Training and Development`,
    skill_3:`Employee Relations`,
    skill_4:`Compensation and Benefits Administration`,
    skill_5:`Legal Compliance`,
    skill_6:`Strategic HR Planning`,
    course_faq_name:`HR generalist`,
    faq_question_1:`Do I need prior HR experience to enroll in this course?`,
    faq_question_2:`What does the certification cover?`,
    faq_question_3:`How long does it take to complete the course?`,
    faq_question_4:`Can I expect practical applications in the course?`,
    faq_question_5:`Is this course suitable for those seeking career advancement in HR?`,
    faq_answer_1:`While prior experience is beneficial, it's not mandatory. The course caters to both beginners and experienced professionals.`,
    faq_answer_2:`The certification encompasses various HR functions, including recruitment, training, employee relations, compensation, and compliance.`,
    faq_answer_3:`The course duration varies but typically ranges from 8 to 12 weeks, depending on the learning pace and schedule.`,
    faq_answer_4:`Yes, the course integrates real-world case studies and interactive sessions to provide hands-on experience in HR functions.`,
    faq_answer_5:`Absolutely, the course is designed to equip participants with the skills and knowledge needed to excel in HR generalist roles and advance their careers in the field.`,
    
},
{
    id:42,
    categories: `Hr training`,
    course_detail_image:course_images.hr_third,
    course_name:`HR ANALYTICS`,
    course_overview:`The HR Analytics course delves into the data-driven approach to human resource management, exploring how analytics can optimize HR processes and decision-making. Participants learn statistical analysis, data visualization, and predictive modeling techniques to extract insights from HR data, leading to better recruitment strategies, talent retention, and workforce planning. Through hands-on projects and case studies, learners develop proficiency in using analytics tools and interpreting HR metrics for strategic organizational impact.`,
    certification:`The certification covers topics such as HR metrics and KPIs, predictive modeling, data visualization, talent analytics, workforce planning, and the ethical considerations of HR data usage.`,
    prerequisite:`Basic understanding of HR principles and familiarity with statistical concepts recommended. Proficiency in spreadsheet software like Excel is beneficial but not mandatory.`,
    outline_heading_1:`Introduction to HR Analytics`,
    outline_heading_2:`Data Management and Governance`,
    outline_heading_3:`Descriptive Analytics in HR`,
    outline_heading_4:`Predictive Analytics in HR`,
    outline_heading_5:`Prescriptive Analytics in HR`,
    outline_heading_6:`Employee Lifecycle Analytics`,
    outline_heading_7:`Talent Acquisition Analytics`,
    outline_heading_8:`Employee Engagement and Retention Analytics`,
    outline_heading_9:`Performance Management Analytics`,
    outline_heading_10:`HR Analytics Strategy and Implementation`,
    outline_detail_1:`
    ·       Overview of HR analytics and its significance
    ·       Evolution of HR analytics in organizational decision-making
    ·       Role of HR analytics in strategic HR management
    ·       Key concepts and terminology in HR analytics`,
    outline_detail_2:`
    ·       Principles of data management in HR
    ·       Data collection methods and sources (e.g., HRIS, surveys)
    ·       Data governance frameworks and best practices
    ·       Data privacy and compliance considerations (e.g., GDPR, CCPA)`,
    outline_detail_3:`
    ·       Understanding descriptive analytics
    ·       Data visualization techniques (e.g., dashboards, reports)
    ·       HR metrics and KPIs for tracking workforce trends
    ·       Analyzing employee demographics, turnover rates, and engagement levels
    `,
    outline_detail_4:`
    ·       Introduction to predictive analytics
    ·       Predictive modeling techniques (e.g., regression analysis, decision trees)
    ·       Forecasting workforce trends (e.g., attrition, talent demand)
    ·       Predictive workforce planning and scenario analysis`,
    outline_detail_5:`
    ·       Overview of prescriptive analytics
    ·       Optimization techniques (e.g., linear programming, simulation)
    ·       Recommender systems for talent management and career development
    ·       Utilizing prescriptive analytics to improve HR processes and decision-making
    `,
    outline_detail_6:`
    ·       Analysing the employee lifecycle stages (e.g., recruitment, onboarding, performance)
    ·       Identifying critical touch points for HR intervention
    ·       Improving employee experience through data-driven insights
    ·       Measuring the effectiveness of HR programs and initiatives
    `,
    outline_detail_7:`
    ·       Recruitment analytics fundamentals
    ·       Assessing recruitment effectiveness and efficiency
    ·       Predictive modeling for candidate sourcing and selection
    ·       Optimizing recruitment strategies based on data analysis
    `,
    outline_detail_8:`
    ·       Measuring employee engagement and satisfaction
    ·       Analyzing factors influencing employee retention
    ·       Predicting turnover risk and implementing retention strategies
    ·       Designing interventions to improve employee engagement based on data insights
    `,
    outline_detail_9:`
    •	Analyzing performance appraisal data
    •	Identifying high-performing employees and performance patterns
    •	Predicting performance outcomes and potential areas for improvement
    •	Aligning performance metrics with organizational goals
    
    `,
    outline_detail_10:`
    ·       Developing an HR analytics strategy aligned with organizational objectives
    ·       Building a data-driven HR culture
    ·       Overcoming challenges in HR analytics implementation
    Case studies and real-world applications of HR analytics`,
    skill_1:`Statistical Analysis`,
    skill_2:`Data Visualization`,
    skill_3:`Predictive Modelling`,
    skill_4:`Talent Analytics`,
    skill_5:` Workforce Planning`,
    skill_6:`Ethical Data Usage`,
    course_faq_name:``,
    faq_question_1:`Do I need prior experience in HR to take this course?`,
    faq_question_2:`What software/tools will I be using for analytics?`,
    faq_question_3:`Can I expect to learn predictive modeling techniques?`,
    faq_question_4:`How will this course benefit HR professionals?`,
    faq_question_5:`Is ethical data usage addressed in this course?`,
    faq_answer_1:`While it's beneficial, it's not mandatory. Basic understanding of HR principles is recommended.`,
    faq_answer_2:`You'll be using analytics tools like R, Python, and Excel for data analysis and visualization.`,
    faq_answer_3:`Yes, the course covers predictive modeling to forecast HR trends and outcomes.`,
    faq_answer_4:`By mastering HR analytics, professionals can make data-driven decisions, enhance recruitment strategies, and optimize workforce planning.`,
    faq_answer_5:`Absolutely. The course emphasizes ethical considerations in collecting, analysing, and using HR data to ensure responsible and fair practices.`,
    
},
{
    id:43,
    categories: `HR Training`,
    course_detail_image:course_images.hr_fourth,
    course_name:`HR social compiance`,
    course_overview:`HR Social Compliance delves into the critical aspect of ensuring ethical and socially responsible practices within organizations. Participants will explore topics such as diversity and inclusion, workplace ethics, labor laws, and corporate social responsibility. Through case studies and interactive sessions, learners will develop the skills needed to promote a positive organizational culture while adhering to legal and ethical standards, fostering employee well-being, and enhancing the company's reputation.`,
    certification:`The certification covers key areas including diversity and inclusion, workplace ethics, labor laws, corporate social responsibility, compliance auditing, and fostering a positive organisational culture.`,
    prerequisite:`No prior HR experience required. Basic understanding of organizational behavior and familiarity with HR fundamentals recommended.`,
    outline_heading_1:`Introduction to HR Compliance`,
    outline_heading_2:`Equal Employment Opportunity (EEO) Compliance`,
    outline_heading_3:`Wage and Hour Compliance`,
    outline_heading_4:`Workplace Health and Safety Compliance`,
    outline_heading_5:`Family and Medical Leave Act (FMLA) Compliance`,
    outline_heading_6:`Employee Privacy and Data Protection Compliance`,
    outline_heading_7:`Immigration Compliance`,
    outline_heading_8:`Workplace Diversity and Inclusion Compliance`,
    outline_detail_1:`
    •	Overview of HR compliance and its importance
    •	Legal and regulatory framework impacting HR functions
    •	Role of HR in ensuring organizational compliance
    •	Ethical considerations in HR compliance`,
    outline_detail_2:`
    •	Overview of EEO laws (e.g., Title VII, ADA, ADEA)
    •	Prohibitions against discrimination and harassment
    •	Affirmative action requirements and compliance obligations
    •	Handling EEO complaints and investigations`,
    outline_detail_3:`
    •	Fair Labor Standards Act (FLSA) regulations
    •	Minimum wage and overtime pay requirements
    •	Classification of employees as exempt or non-exempt
    •	Recordkeeping and documentation requirements`,
    outline_detail_4:`
    •	Occupational Safety and Health Administration (OSHA) regulations
    •	Workplace safety standards and hazard communication
    •	Injury and illness reporting requirements
    •	Developing and implementing safety programs`,
    outline_detail_5:`
    •	FMLA eligibility and entitlements
    •	Notice and certification requirements for FMLA leave
    •	Managing intermittent leave and leave abuse
    •	Coordination of FMLA with other leave laws`,
    outline_detail_6:`
    •	Overview of employee privacy laws (e.g., HIPAA, GDPR)
    •	Confidentiality of employee records and information
    •	Data protection principles and compliance measures
    •	Managing electronic communications and social media use`,
    outline_detail_7:`
    •	Immigration laws and regulations affecting employment
    •	Verification of employment eligibility (Form I-9)
    •	Compliance with visa sponsorship requirements
    •	Responding to government audits and investigations`,
    outline_detail_8:`
    •	Promoting diversity and inclusion in the workplace
    •	Legal considerations related to diversity initiatives
    •	Preventing discrimination based on protected characteristics
    •	Creating inclusive policies and practices`,
    skill_1:`Diversity and Inclusion`,
    skill_2:`Workplace Ethics`,
    skill_3:`Compliance Auditing`,
    skill_4:`Labor Laws`,
    skill_5:`Corporate Social Responsibility`,
    skill_6:`Organisational Culture Enhancement`,
    Course_faq_name:`hr compliance`,
    faq_question_1:`Do I need HR experience to enroll in this course?`,
    faq_question_2:`What does the certification cover?`,
    faq_question_3:`How long does it take to complete the course?`,
    faq_question_4:`Is this course applicable to all industries?`,
    faq_question_5:`Will I learn how to conduct compliance audits?`,
    faq_answer_1:`No, the course is suitable for beginners, although familiarity with organizational behavior is recommended.`,
    faq_answer_2:`The certification encompasses topics such as diversity, ethics, labor laws, corporate social responsibility, compliance auditing, and culture enhancement.`,
    faq_answer_3:`The duration varies but typically takes between 4 to 6 weeks to complete.`,
    faq_answer_4:`Yes, the principles taught are applicable across industries, ensuring ethical and socially responsible practices.`,
    faq_answer_5:`Yes, the course covers compliance auditing techniques to ensure organizations adhere to legal and ethical standards.`,
    
},
{
    id:44,
    categories: `HR Training `,
    course_detail_image:course_images.hr_fifth,
    course_name:`HR Payroll Specialist`,
    course_overview:`The HR Payroll Specialist course delves into the intricacies of payroll processing, tax regulations, and compliance. Participants will learn payroll software operation, wage and hour laws, benefits administration, and reporting. Through hands-on exercises and real-world scenarios, students will develop the expertise necessary to manage payroll functions accurately and efficiently.`,
    certification:`The certification covers payroll processing, tax compliance, benefits administration, payroll software operation, wage and hour laws, and reporting requirements, ensuring proficiency in all aspects of payroll management.`,
    prerequisite:`Basic understanding of accounting principles and HR fundamentals recommended. No prior payroll experience is necessary, but familiarity with payroll software and spreadsheet applications is beneficial.`,
    outline_heading_1:`Introduction to Payroll Management`,
    outline_heading_2:`Payroll Legislation and Compliance`,
    outline_heading_3:`Payroll Processing Systems and Software`,
    outline_heading_4:`Employee Compensation and Benefits`,
    outline_heading_5:`Payroll Calculations and Adjustments`,
    outline_heading_6:`Payroll Auditing and Compliance Checks`,
    outline_heading_7:` Payroll Reporting and Recordkeeping`,
    outline_heading_8:`Payroll Administration and Documentation`,
    outline_heading_9:`Payroll Management Best Practices`,
    outline_detail_1:`
    •	Overview of payroll functions and responsibilities
    •	Importance of accurate payroll processing
    •	Role of the payroll specialist in an organization
    •	Key payroll terms and concepts
    `,
    outline_detail_2:`
    ·       Understanding labour laws and regulations related to payroll (e.g., FLSA, IRS regulations)
    ·       Compliance with wage and hour laws
    ·       Taxation rules and regulations (federal, state, and local)
    ·       Reporting requirements (e.g., W-2, 941, 940 forms)`,
    outline_detail_3:`
    ·       Introduction to payroll processing systems
    ·       Payroll software applications and their features
    ·       Setting up and configuring payroll software
    ·       Data entry and processing payroll transactions`,
    outline_detail_4:`
    ·       Types of employee compensation (e.g., salaries, wages, bonuses)
    ·       Understanding employee benefits (e.g., health insurance, retirement plans)
    ·       Deductions and withholdings from employee paychecks
    ·       Calculating gross pay, net pay, and deductions`,
    outline_detail_5:`
    ·       Calculating regular and overtime pay
    ·       Handling pay adjustments (e.g., retroactive pay, bonuses, commissions)
    ·       Processing payroll deductions (e.g., garnishments, child support)
    ·       Resolving payroll discrepancies and errors`,
    outline_detail_6:`
    ·       Conducting payroll audits to ensure accuracy and compliance
    ·       Internal controls for payroll processing
    ·       Identifying and correcting payroll errors
    ·       Responding to payroll-related inquiries and disputes
    `,
    outline_detail_7:`
    ·       Generating payroll reports for management and regulatory purposes
    ·       Recordkeeping requirements for payroll documents
    ·       Retention periods for payroll records
    ·       Electronic filing and submission of payroll data
    `,
    outline_detail_8:`·       Developing payroll policies and procedures
    ·       Communicating payroll-related information to employees
    ·       Handling payroll inquiries and providing assistance
    ·       Maintaining confidentiality and data security in payroll operations
    `,
    outline_detail_9:`
    ·       Continuous improvement in payroll processes
    ·       Staying updated on payroll laws and regulations
    ·       Collaboration with other HR functions (e.g., benefits, HRIS)
    ·       Professional development opportunities for payroll specialists`,
    skill_1:`Payroll Processing`,
    skill_2:`Tax Compliance`,
    skill_3:` Benefits Administration`,
    skill_4:`Payroll Software Operation`,
    skill_5:`Wage and Hour Laws`,
    skill_6:`Reporting Requirements`,
    Course_faq_name:`hr Payroll specialist`,
    faq_question_1:`Do I need prior payroll experience to enroll in this course?`,
    faq_question_2:`What software will I learn to operate?`,
    faq_question_3:`Is there a specific duration for completing the course?`,
    faq_question_4:`Will I receive a certification upon completion?`,
    faq_question_5:`Are there any ongoing requirements to maintain the certification?`,
    faq_answer_1:`No, this course is designed for beginners. However, familiarity with accounting principles and HR fundamentals is recommended.`,
    faq_answer_2:`You'll learn to operate popular payroll software, ensuring you're proficient in industry-standard tools.`,
    faq_answer_3:`The course duration varies, but typically ranges from 8 to 12 weeks.`,
    faq_answer_4:`Yes, you'll receive a certification as an HR Payroll Specialist, validating your skills and expertise in payroll management.`,
    faq_answer_5:`Currently, there are no ongoing requirements, but staying updated with industry trends and regulations is recommended for professional growth.`,
    
},
{
    id:45,

},
{
    id:46,

},

// web Development // web Development // web Development // web Development // web Development // web Development // web Development // web Development 
{
    id:20,
    categories: `web development`,
    course_detail_image:course_images.web_dev_1,
    course_name:`Become a certified HTML, CSS, javascript, web developer`,
    course_overview:`Become an authorised JavaScript, HTML, CSS, or Web Developer in this complete course aimed at providing you with the skills needed for achievement in the digital world. Master the fundamentals of HTML and CSS to organise and decorate web pages with accuracy. Dive in JavaScript to add dynamic and interactive components to your webpages, boosting the user's experience. Master adaptive design concepts to guarantee your works seem outstanding across all devices. With hands-on projects and hands-on training, you'll build skill in creating electricity, professional-grade webpages. Taught by professionals, this course covers everything from basic syntax to advanced methods, preparing you for accreditation and job options in the field of website development. Join us and start on a road towards becoming a professional and authorised web developer in only a few short weeks.`,
    certification:`Upon successful completion of this course, you'll receive a certification verifying your proficiency in HTML, CSS, JavaScript, and web development. Showcase your skills and enhance your career prospects as a certified web developer.
    `,
    prerequisite:`Basic computer literacy, familiarity with internet usage, and a passion for learning. No prior coding experience necessary. Suitable for beginners aspiring to enter the web development field and pursue certification in HTML, CSS, JavaScript, and web development.
    `,
    outline_heading_1:`HTML-First Week Curriculum`,
    outline_heading_2:`CSS-2nd Week Curriculum`,
    outline_heading_3:`JavaScript-3rd week Curriculum`,
    outline_heading_4:`Bootstrap-4th Week Curriculum `,
    outline_heading_5:`work on the project`,
    outline_detail_1:` 
    HTML Introduction 
    HTML Basic 
    HTML Elements 
    HTML Attributes
    HTML Headings 
    HTML Paragraphs 
    HTML Formatting Elements 
    HTML Comments HTML 
    Tables HTML List 
    HTML Responsive
    HTML Form HTML Media `,
    outline_detail_2:`
    CSS Introduction 
    CSS Basic
    CSS Color 
    CSS Border
    CSS Background 
    CSS Spacing
    CSS Buttons 
    CSS FlexBox 
    CSS Opacity
    CSS hover
    CSS Bx-shadow
    CSS Position
    CSS media Query `,
    outline_detail_3:`
    Introduction to JavaScript
    Variables and Data Types
    Operators
    Control Flow
    Functions
    Objects
    ES6 Features
    Asynchronous JavaScript`,
    outline_detail_4:`Introduction to Bootstrap
    Bootstrap Grid System
    Typography in Bootstrap
    Bootstrap CSS Components
    Bootstrap JavaScript Components
    Customising Bootstrap
    Responsive Design with Bootstrap
    Building Web Layouts with Bootstrap
    Bootstrap Forms and Inputs
    Bootstrap Navigation Components
    Bootstrap Modals and Popovers
    Bootstrap Carousel and Slideshow Components
    Integrating Bootstrap with Other Frameworks
    `,
    outline_detail_5:`Introduction
    Objectives
    Scope
    Timeline
    Resources Needed
    Team Roles and Responsibilities
    Research Phase
    Analysis
    Design
    Development
    Testing
    Implementation
    Training
    Documentation
    Evaluation and Feedback
    Iterative Improvements
    Project Closure
    `,
    skill_1:`Proficiency in front-end web development.`,
    skill_2:`Ability to create responsive and visually appealing web pages.
    `,
    skill_3:`Skill in implementing dynamic and interactive features using JavaScript.`,
    skill_4:`Understanding of web design principles and best practices.
    `,
    skill_5:`Problem-solving and debugging skills for web development projects.
    `,
    skill_6:`Capability to collaborate effectively with designers and back-end developers in web development teams.
    `,
    course_faq_name:``,
    faq_question_1:`What are the prerequisites for becoming a certified JavaScript, HTML, CSS Web Developer`,
    faq_question_2:`How long does it take to become certified in JavaScript, HTML, and CSS Web Development?`,
    faq_question_3:`Are there any specific certification exams for JavaScript, HTML, and CSS Web Development?`,
    faq_question_4:`How can I prepare for JavaScript, HTML, and CSS certification exams?`,
    faq_question_5:`What career opportunities are available after becoming certified in JavaScript, HTML, and CSS Web Development?`,
    
    faq_answer_1:` Prior knowledge of basic programming concepts, familiarity with HTML, CSS, and JavaScript fundamentals, and a passion for web development are recommended prerequisites. Online courses or tutorials can help bridge any knowledge gaps.`,
    
    faq_answer_2:`The duration varies depending on individual learning pace, prior experience, and the depth of the certification program. Generally, dedicated study for several months, coupled with practical projects, can prepare you for certification.`,
    
    faq_answer_3:` Yes, various organisations offer certification exams tailored to web development skills. Examples include exams from organisations like W3Schools, FreeCodeCamp, and Coursera. Each exam assesses proficiency in JavaScript, HTML, CSS, and related concepts.`,
    
    faq_answer_4:` Utilise online resources such as tutorials, practice exercises, and mock exams to reinforce your understanding of key concepts. Hands-on projects and real-world application of skills are crucial for effective preparation. Additionally, consider enrolling in structured online courses or bootcamps specifically designed for certification exam prep.`,
    
    faq_answer_5:`With certification in JavaScript, HTML, CSS, and web development, you can pursue various career paths, including front-end developer, web designer, UI/UX developer, or full-stack developer. Industries ranging from tech companies to marketing agencies seek professionals with these skills, offering diverse opportunities for career growth and specialization.`,    
},
{
    id:21,
    categories: `Web Development`,
    course_detail_image:course_images.web_dev_2,
    course_name:`The full stack web developer`,
    course_overview:`This intensive Full Stack Web Developer course equips participants with the skills to develop dynamic web applications from front-end to back-end. Covering essential technologies such as HTML, CSS, JavaScript, and popular frameworks like React and Node.js, participants will learn to build responsive and scalable web solutions. Through hands-on projects and real-world scenarios, participants gain proficiency in database management, server-side programming, version control, and deployment strategies. By the end of the course, participants will have a robust understanding of the full web development stack, enabling them to pursue careers as versatile web developers.`,
    certification:`Upon successful completion of the course and passing the assessment, participants will receive a certification as a Full Stack Web Developer, demonstrating their proficiency in building dynamic web applications from front-end to back-end.`,
    prerequisite:` 
    Basic understanding of HTML, CSS, and JavaScript.
    Familiarity with programming concepts and principles.
    Proficiency in using text editors and version control systems.
    Strong problem-solving and analytical skills.
    Eagerness to learn and adapt to new technologies.
    Access to a computer with internet connectivity.`,
    outline_heading_1:`HTML`,
    outline_heading_2:`CSS`,
    outline_heading_3:`Bootstrap`,
    outline_heading_4:`C++`,
    outline_heading_5:`JavaScript`,
    outline_detail_1:` Learn structure & content of web pages. Tags, elements, semantics, and basic layout concepts. Essential for web development.`,
    outline_detail_2:`Style web pages. Selectors, properties, values for layout, typography, colours, responsiveness. Enhance HTML presentation.`,
    outline_detail_3:` Framework for responsive, mobile-first web development. Pre-designed CSS and JS components for faster development and consistent design.`,
    outline_detail_4:`Powerful, versatile programming language. Used for system/software development, game development, and high-performance applications. Strongly typed, with OOP features.`,
    outline_detail_5:`: Dynamic, versatile scripting language. Essential for web development. Manipulate HTML/CSS, handle events, create interactive web experiences. Compatible with all browsers.`,
    skill_1:`HTML5 & CSS3`,
    skill_2:`JavaScript`,
    skill_3:`React.js`,
    skill_4:`Node.js `,
    skill_5:`Database Management`,
    skill_6:`Deployment Strategies`,
    course_faq_name:`The full stack developer`,
    faq_question_1:`What is a Full Stack Web Developer, and what skills do they need?`,
    faq_question_2:`What career opportunities are available for Full Stack Web Developers?`,
    faq_question_3:`How long does it take to become a Full Stack Web Developer?`,
    faq_question_4:`What are the main differences between front-end and back-end development?`,
    faq_question_5:`How can I prepare for a career as a Full Stack Web Developer?`,
    faq_answer_1:`A Full Stack Web Developer is proficient in both front-end and back-end web development. They require skills in HTML, CSS, JavaScript, React.js, Node.js, database management, and deployment strategies.`,
    faq_answer_2:`Full Stack Web developers can pursue careers as web developers, software engineers, application developers, or freelance developers. They can work in various industries, including technology, e-commerce, finance, and healthcare.`,
    faq_answer_3:`The time to become a Full Stack developer varies based on individual learning pace and prior experience. With dedication and consistent practice, individuals can typically acquire the necessary skills within 6-12 months of focused study.`,
    faq_answer_4:`Front-end development focuses on building user interfaces and client-side functionality using HTML, CSS, and JavaScript, while back-end development involves server-side programming, database management, and server configuration using frameworks like Node.js and database systems like MongoDB.`,
    faq_answer_5:`To prepare for a career as a Full Stack Web Developer, start by learning the fundamentals of HTML, CSS, and JavaScript. Then, dive into advanced topics such as React.js, Node.js, and database management. Practice building projects, collaborate with peers, and continuously learn and adapt to new technologies.`
    
},
{
    id:22,
    categories: `Web Development`,
    course_detail_image:course_images.web_dev_3,
    course_name:`Introduction to Web Development`,
    course_overview:`This introductory course offers a comprehensive overview of web development fundamentals, covering essential concepts and technologies for building static and interactive websites. Participants will learn HTML for content structuring, CSS for styling, and JavaScript for adding interactivity. Through hands-on projects and practical exercises, participants will gain foundational skills in creating responsive and visually appealing web pages. By the end of the course, participants will have a solid understanding of web development principles and be ready to pursue further studies or entry-level positions in the field.`,
    certification:`Upon successful completion of the course and passing the assessment, participants will receive a certification in Introduction to Web Development, validating their understanding of fundamental web development concepts and skills.`,
    prerequisite:`Basic computer literacy.
    Familiarity with internet browsing.
    Eagerness to learn and explore web development concepts.
    Access to a computer with internet connectivity.
    No prior coding experience required.`,
    outline_heading_1:`React JS`,
    outline_heading_2:`ReactJS Introduction to JSX`,
    outline_heading_3:`ReactJS Rendering Elements `,
    outline_heading_4:`ReactJS Components `,
    outline_heading_5:`ReactJS Components – Set 2 `,
    Outline_heading_6:`ReactJS Props – Set 1 `,
    Outline_heading_7:`ReactJS Props – Set 2`,
    Outline_heading_8:`ReactJS State in React `,
    Outline_heading_9:`ReactJS Lifecycle of Components`,
    Outline_heading_10:`ReactJS Conditional Rendering`,
    Outline_heading_11:`ReactJS Lists `,
    outline_heading_12:`ReactJS Keys`,
    outline_heading_13:`ReactJS forms `,
    outline_heading_14:`Work on Project`,
    outline_detail_1:``,
    outline_detail_2:``,
    outline_detail_3:``,
    outline_detail_4:``,
    outline_detail_5:``,
    outline_detail_6:``,
    outline_detail_7:``,
    outline_detail_8:``,
    outline_detail_9:``,
    Outline_detail_10:``,
    outline_detail_11:``,
    outline_detail_12:``,
    outline_detail_13:``,
    outline_detail_14:``,
    skill_1:`HTML`,
    skill_2:`CSS`,
    skill_3:`JavaScript`,
    skill_4:`Web Design Principles`,
    skill_5:`Responsive Web Development`,
    skill_6:`Version Control Basics`,
    course_faq_name:`Introduction to Web Development`,
    faq_question_1:`What is web development, and why is it important?
    `,
    faq_question_2:`What are HTML, CSS, and JavaScript, and how are they used in web development?`,
    faq_question_3:`What is responsive web development, and why is it important?`,
    faq_question_4:`How can I start learning web development with no prior experience?`,
    faq_question_5:`What career opportunities are available in web development?`,
    faq_answer_1:`Web development involves building websites and web applications for the internet. It's essential for creating online presence, enabling communication, sharing information, and conducting business in today's digital world.`,
    faq_answer_2:`HTML (Hypertext Markup Language) is used for structuring web content, CSS (Cascading Style Sheets) is used for styling and layout, and JavaScript is used for adding interactivity and dynamic behavior to web pages.`,
    faq_answer_3:`Responsive web development ensures that websites adapt to various screen sizes and devices, providing a consistent user experience across desktops, tablets, and smartphones. It's important for accessibility and user satisfaction.`,
    faq_answer_4:`You can start by taking introductory courses like this one, which cover the basics of HTML, CSS, and JavaScript. Practice building simple projects, explore online tutorials and resources, and join coding communities for support and guidance.`,
    faq_answer_5:`Web development offers various career paths, including front-end development, back-end development, full-stack development, web design, and freelance opportunities. With continued learning and experience, individuals can pursue roles such as web developer, software engineer, UX/UI designer, or web project manager.`,
    
},
{
    id:23,
    categories: `Web Development`,
    course_detail_image:course_images.web_dev_4,
    course_name:`Software quality testing- manual testing`,
    course_overview:`Examine the human and computerised approaches for software quality testing. Discuss the preparation, execution, reporting, and defect handling of tests. Acquire knowledge of industry-standard tools and methods to guarantee that software fulfills quality criteria, increasing user contentment and product reliability.`,
    certification:`Upon completion, earn a recognized certification in software quality testing. Validate your expertise in testing methodologies, tools, and practices, demonstrating your ability to ensure software meets quality standards.`,
    prerequisite:`Basic understanding of software development lifecycle. Familiarity with programming concepts and operating systems. Proficiency in communication and analytical skills to effectively identify and report issues.`,
    outline_heading_1:`Introduction to Software Application and Fundamental of Testing`,
    outline_heading_2:`Models of Software Engineering`,
    outline_heading_3:`Quality Management System`,
    outline_heading_4:`Software Testing methodology`,
    outline_heading_5:`Levels of Software Testing`,
    outline_heading_6:`Testing Types/Approaches`,
    outline_heading_7:`Introduction of different Test Estimation techniques and Reporting techniques`,
    outline_detail_1:`Overview of Software Application Testing Why is testing necessary Software testing Principles Psychology of software testing Causes of Software Defects Overview of Project Execution Lifecycle`,
    outline_detail_2:`Waterfall Model Prototype model Spiral Model Incremental Model Iterative Model RAD (Rapid Application Development) V Model`,
    outline_detail_3:`What is Quality Management System and It's need What is Quality Assurance What is Quality Control Review process and Types of Review Process Different Roles and Responsibilities of reviewers Static & Dynamic Testing techniques`,
    outline_detail_4:`White Box Black Box Grey Box`,
    outline_detail_5:`Unit Testing Integration Testing System testing UAT`,
    outline_detail_6:`Smoke Testing Sanity Testing Re-Testing Regression Testing Ad-hoc and Exploratory testing Monkey & Gorilla Testing`,
    outline_detail_7:`Test plans estimates and strategies Different test approaches Test progress,monitoring and control Configuration management Risk and testing Identifying test condition and design test cases Categories test design techniques`,
    skill_1:`Proficiency in software testing methodologies.`,
    skill_2:`Ability to create effective test plans and strategies.`,
    skill_3:`Competent in test design and execution`,
    skill_4:`Ability to analyze and manage defects.`,
    skill_5:`Understanding of quality assurance processes.`,
    skill_6:`Capability to use metrics for software quality assessment.`,
    course_faq_name:`Software quality testing- manual testing`,
    faq_question_1:`What's the difference between manual and automated testing?`,
    faq_question_2:`Why is software testing important?`,
    faq_question_3:`How do you prioritise testing efforts?`,
    faq_question_4:`What is regression testing?`,
    faq_question_5:`What tools are commonly used for software testing?`,
    faq_answer_1:`Manual testing involves human intervention, while automated testing utilizes scripts and tools to execute test cases automatically.`,
    faq_answer_2:`Testing ensures that software meets requirements, functions correctly, and provides a satisfactory user experience, reducing defects and enhancing reliability.`,
    faq_answer_3:`Regression testing validates that recent code changes haven't adversely affected existing functionality, ensuring software stability and preventing regressions.`,
    faq_answer_4:`Regression testing validates that recent code changes haven't adversely affected existing functionality, ensuring software stability and preventing regressions.`,
    faq_answer_5:`Popular testing tools include Selenium for web automation, JUnit for unit testing (Java), and Postman for API testing, among others.`,
    
},

{
    id:24,
    categories: `Web Development`,
    course_detail_image:course_images.web_dev_5,
    course_name:`software quality testing MANUAL + SELENIUM`,
    course_overview:`Learn manual and automated software quality testing techniques. Covers test planning, execution, defect tracking, Selenium automation, frameworks, and integration with CI/CD pipelines. Practical exercises and real-world projects ensure proficiency in testing methodologies and tools.`,
    certification:`Upon completion, receive a recognized certification demonstrating proficiency in manual and Selenium testing, enhancing career opportunities in software quality assurance roles.`,
    prerequisite:`Basic understanding of software development lifecycle and programming concepts. Familiarity with web technologies and willingness to learn automation frameworks.`,
    Outline_heading_1:`Introduction to Software Application and Fundamental of Testing`,
    outline_heading_2:`Models of Software Engineering`,
    outline_heading_3:`Quality Management System`,
    outline_heading_4:`Software Testing methodology`,
    outline_heading_5:`Levels of Software Testing`,
    outline_detail_1:`Overview of Software Application Testing Why is testing necessary Software testing Principles Psychology of software testing Causes of Software Defects Terms used in Software Testing [Defect, Error, Bug, Failure etc..] Overview of SDLC [Software Development Life Cycle]`,
    outline_detail_2:`Waterfall Model Prototype model Spiral Model Incremental Model Iterative Model RAD (Rapid Application Development) V Model Agile Model`,
    outline_detail_3:`What is Quality Management System and It's need What is Quality Assurance What is Quality Control Review process and Types of Review Process Different Roles and Responsibilities of reviewers Walkthrough Inspection Code Review`,
    outline_detail_4:`White Box Black Box Grey Box`,
    outline_detail_5:`Unit Testing Integration Testing System testing UAT`,
    skill_1:`Proficiency in manual testing methodologies.`,
    skill_2:`Mastery in Selenium automation testing.`,
    skill_3:`Ability to create robust test plans and strategies.`,
    skill_4:`Expertise in defect tracking and reporting.`,
    skill_5:`Competence in integrating testing into CI/CD pipelines.`,
    skill_6:``,
    course_faq_name:`Strong problem-solving and troubleshooting skills.`,
    faq_question_1:`What is the difference between manual and automated testing?`,
    faq_question_2:`Is Selenium the only automation tool covered in this course?`,
    faq_question_3:`Can I pursue this course without any prior testing experience?`,
    faq_question_4:`How long does it take to complete the course?`,
    faq_question_5:`Is there any practical hands-on experience included?`,
    faq_answer_1:`Manual testing involves human testers executing test cases, while automated testing involves using tools to execute pre-scripted tests automatically.`,
    faq_answer_2:`No, while Selenium is a major focus, other automation tools and frameworks may also be explored depending on the course curriculum.`,
    faq_answer_3:`Yes, the course is designed for both beginners and experienced professionals looking to enhance their testing skills.`,
    faq_answer_4:`The duration varies depending on the program format (e.g., online, in-person) and individual learning pace`,
    faq_answer_5:`Yes, the course includes practical exercises, projects, and case studies to ensure practical application of learned concepts.`,
    
},



// linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux // linux
{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Developing Applications For Linux (LFD401)`,
course_overview:`Understand how to design programs for the Linux environment. In this instructor-led session, you’ll receive hands-on experience with the required tools & procedures for Linux application development & learn about the features & approaches that are unique to Linux.

Duration: 4 days

Learning Objectives: 

In this class, you will learn about the tools & methods for writing C programs & conducting systems programming under Linux, debugging techniques, process management, Linux-specific functions & system calls, & more.`,
certification:` LFD401 covers Linux system administration, shell scripting, programming, DevOps tools, package management, and troubleshooting. Participants gain expertise in developing applications for Linux environments, equipping them with essential skills for managing and deploying Linux-based software solutions.`,
prerequisite:`This training is for experienced developers. Professionals should be knowledgeable in C programming, & be acquainted with fundamental Linux tools & text editors.

Audience Profile


This training is for experienced developers. Professionals should be knowledgeable in C programming, & be acquainted with fundamental Linux tools & text editors.`,
outline_heading_1:`Introduction`,
outline_detail_1:`- Objectives
- Who You Are
- The Linux Foundation
- Copyright & No Confidential Information
- Linux Foundation Training
- Certification Programs & Digital Badging
- Linux Distributions
- Platforms
- Preparing Your System
- Using & Downloading a Virtual Machine
- Things Change in Linux & Open Source Projects`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`- Procedures
- Standards & the LSB`,
outline_heading_3:`How to Work in OSS Projects **`,
outline_detail_3:`- Overview of How to Contribute Properly
- Know Where the Code is Coming From DCO & CLA
- Stay Close to Mainline for Security & Quality
- Study & Understand the Project DNA
- Figure Out What Itch You Want to Scratch
- Identify Maintainers & Their Work Flows & Methods
- Get Early Input & Work in the Open
- Contribute Incremental Bits, Not Large Code Dumps
- Leave Your Ego at the Door: Don’t Be Thin-Skinned
- Be Patient, Develop Long Term Relationships, and Be Helpful`,
outline_heading_4:`Compilers`,
outline_detail_4:`- GCC
- Other Compilers
- Major gcc Options
- Preprocessor
- Integrated Development Environments (IDE)
- Labs`,
outline_heading_5:`Libraries`,
outline_detail_5:`- Static Libraries
- Shared Libraries
- Linking To Libraries
- Dynamic Linking Loader
- Labs`,
outline_heading_6:`Make`,
outline_detail_6:`- Using make & Makefiles
- Building huge projects
- More intricate rules
- Built-in rules
- Labs`,
outline_heading_7:`Source Control`,
outline_detail_7:`- Source Control
- RCS & CVS
- Subversion
- git
- Labs`,
outline_heading_8:`Debugging & Core Dumps`,
outline_detail_8:`- gdb
- What is Core Dump Files?
- Producing Core Dumps
- Examining Core Dumps
- Labs`,
outline_heading_9:`Debugging Tools`,
outline_detail_9:`- Getting the Time
- Profiling & Performance
- valgrind
- Address Sanitizer
- Labs`,
outline_heading_10:`System Calls`,
outline_detail_10:`- System Calls vs. Library Functions
- How System Calls are Made
- Return Values & Error Numbers
- Labs`,
outline_heading_11:`Memory Management & Allocation`,
outline_detail_11:`- Memory Management
- Dynamical Allocation
- Tuning malloc()
- Locking Pages
- Labs`,
outline_heading_12:`Files & Filesystems in Linux **`,
outline_detail_12:`- Files, Directories & Devices
- The Virtual File System
- The ext2/ext3 Filesystem
- Journaling Filesystems
- The ext4/ Filesystem
- Labs`,
outline_heading_13:`File I/O`,
outline_detail_13:`- UNIX File I/O
- Opening & Closing
- Reading, Writing & Seeking
- Positional & Vector I/O
- Standard I/O Library
- Large File Support (LFS)
- Labs`,
outline_heading_14:`Advanced File Operations`,
outline_detail_14:`- Stat Functions
- Directory Functions
- inotify
- Memory Mapping
- flock() & fcntl()
- Making Temporary Files
- Other System Calls
- Labs`,
outline_heading_15:`Processes - I`,
outline_detail_15:`- What is a Process?
- Process Limits
- Process Groups
- The proc Filesystem
- Inter-Process Communication Methods
- Labs`,
outline_heading_16:`Processes - II`,
outline_detail_16:`- Using system() to Create a Process
- Using fork() to Create a Process
- Using exec() to Create a Process
- Using clone()
- Exiting
- Constructors & Destructors
- Waiting
- Daemon Processes
- Labs`,
outline_heading_17:`Pipes & Fifos`,
outline_detail_17:`- Pipes & Inter-Process Communication
- popen() & pclose()
- pipe()
- Named Pipes (FIFOs)
- splice(), vmsplice() & tee()
- Labs`,
outline_heading_18:`Asynchronous I/O**`,
outline_detail_18:`- What is Asynchronous I/O?
- The POSIX Asynchronous I/O API
- Linux Implementation
- Labs`,
outline_heading_19:`Signals - I`,
outline_detail_19:`- What are Signals?
- Signals Available
- Dispatching Signals
- Alarms, Pausing & Sleeping
- Setting up a Signal Handler
- Signal Sets
- sigaction()
- Labs`,
outline_heading_20:`Signals - II`,
outline_detail_20:`- Reentrancy & Signal Handlers
- Jumping & Non-Local Returns
- siginfo & sigqueue()
- Real-Time Signals
- Labs`,
outline_heading_21:`POSIX Threads - I`,
outline_detail_21:`- Multi-threading under Linux
- Basic Program Structure
- Creating & Destroying Threads
- Signals & Threads
- Forking vs. Threading
- Labs`,
outline_heading_22:`POSIX Threads - II`,
outline_detail_22:`- Deadlocks & Race Conditions
- Mutex Operations
- Semaphores
- Futexes
- Conditional Operations
- Labs`,
outline_heading_23:`Networking & Sockets`,
outline_detail_23:`- Networking Layers
- What are Sockets?
- Stream Sockets
- Datagram Sockets
- Raw Sockets
- Byte Ordering
- Labs`,
outline_heading_24:`Sockets - Addresses & Hosts`,
outline_detail_24:`- Socket Address Structures
- Converting IP Addresses
- Host Information
- Labs`,
outline_heading_25:`Sockets - Ports & Protocols`,
outline_detail_25:`- Service Port Information
- Protocol Information
- Labs`,
outline_heading_26:`Sockets - Clients`,
outline_detail_26:`- Basic Client Sequence
- socket()
- connect()
- close() & shutdown()
- UNIX Client
- Internet Client
- Labs`,
outline_heading_27:`Sockets - Servers`,
outline_detail_27:`- Basic Server Sequence
- bind()
- listen()
- accept()
- UNIX Server
- Internet Server
- Labs`,
outline_heading_28:`Sockets - Input/Output Operations`,
outline_detail_28:`- write(), read()
- send(), recv()
- sendto(), recvfrom()
- sendmsg(), recvmsg()
- sendfile()
- socketpair()
- Labs`,
outline_heading_29:`Sockets - Options`,
outline_detail_29:`- Getting & Setting Socket Options
- fcntl()
- ioctl()
- getsockopt() & setsockopt()
- Labs`,
outline_heading_30:`Netlink Sockets**`,
outline_detail_30:`- What are Netlink Sockets?
- Opening a Netlink Socket
- netlink Messages
- Labs`,
outline_heading_31:`Sockets - Multiplexing & Concurrent Servers`,
outline_detail_31:`- Multiplexed & Asynchronous Socket I/O
- select()
- poll()
- pselect() & ppoll()
- epoll
- Signal Driven & Asynchronous I/O
- Concurrent Servers
- Labs`,
outline_heading_32:`Inter-Process Communication`,
outline_detail_32:`- Methods of IPC
- POSIX IPC
- System V IPC**
- Labs`,
outline_heading_33:`Shared Memory`,
outline_detail_33:`- What is Shared Memory?
- POSIX Shared Memory
- System V Shared Memory**
- Labs`,
outline_heading_34:`Semaphores`,
outline_detail_34:`- What is a Semaphore?
- POSIX Semaphores
- System V Semaphores**
- Labs`,
outline_heading_35:`Message Queues`,
outline_detail_35:`- What are Message Queues?
- POSIX Message Queues
- System V Message Queues**
- Labs`,
outline_heading_36:`Closing & Evaluation Survey`,
outline_detail_36:`- Evaluation Survey `,
skill_1:`Linux System Administration`,
skill_2:`Shell Scripting`,
skill_3:`Linux Programming`,
skill_4:`DevOps Tools (e.g., Docker, Kubernetes)`,
skill_5:`Package Management`,
skill_6:`Troubleshooting and Debugging`,
course_faq_name:`Developing Applications For Linux (LFD401)`,
faq_question_1:`What are prerequisites for LFD401?`,
faq_answer_1:`Basic Linux command-line skills.`,
faq_question_2:`Is prior programming experience necessary?`,
faq_answer_2:`Basic programming knowledge is beneficial but not mandatory.`,
faq_question_3:`How long does it take to complete the course?`,
faq_answer_3:`Typically 4-6 weeks depending on study pace.`,
faq_question_4:`Can I access course materials offline?`,
faq_answer_4:`Yes, through the provided downloadable resources.`,
faq_question_5:`Are there any hands-on labs?`,
faq_answer_5:`Yes, the course includes practical exercises for skill reinforcement.`,
},


{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Developing Embedded Linux Device Drivers (LFD435)`,
course_overview:`This instructor-led class is aimed to demonstrate experienced programmers how to write device drivers for the embedded Linux systems, & provide them a basic knowledge & familiarity with the Linux kernel. Hands-on laboratories using a RISC-V-based simulated development target enable professionals to practice what is taught in class.

Duration: 4 days

Learning Objectives: 

Upon learning this subject, you will be acquainted with the numerous sorts of device drivers used under Linux, & have an introduction to several proper APIs to be utilized when building a device driver. Labs for showcasing these topics will be run on ARM hardware to get developers comfortable with the cross-compiling & creating drivers for an embedded target.`,
certification:`LFD435 equips you with skills in Linux kernel and driver development, covering topics like kernel architecture, device driver basics, debugging techniques, and more. Upon completion, you'll earn certification validating your ability to develop embedded Linux device drivers, a valuable asset in the embedded systems industry.`,
prerequisite:`To get the most of this training, you must have the following:

Knowledge of essential kernel interfaces & techniques, such as how to build, compile, load & unload modules, utilize synchronization primitives, & the basics of memory allocation & management, like given by LFD420 (Kernel Internals & Development. Pre class preparation material will be supplied before class'.


Audience Profile

This program is for an experienced developers who need to design device drivers for the embedded Linux systems.`,
outline_heading_1:`Introduction`,
outline_detail_1:`- Objectives
- Who You Are
- The Linux Foundation
- Copyright & No Confidential Information
- Linux Foundation Training
- Certification Programs & Digital Badging
- Linux Distributions
- Preparing Your System
- Things change in Linux
- Documentation & Links`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`- Procedures
- Kernel Versions
- Kernel Sources & Use of git
- Hardware
- Staging Tree
- Labs`,
outline_heading_3:`How to Work in OSS Projects **`,
outline_detail_3:`- Overview of How to Contribute properly
- Knowing Where the Code is Coming From DCO & CLA
- Stay Close to Mainline for Security & Quality
- Study & Understand the Project DNA
- Figuring Out What Itch You Want to Scratch
- Identify Maintainers & Their Work Flows & Method
- Get Early Input & Work in the Open
- Contributing Incremental Bits, Not Large Code Dumps
- Leaving Your Ego at the Door: Dont Be Thin-Skinned
- Be Patient, Develop Long Term Relationships, and Be Helpful`,
outline_heading_4:`Cross Development Toolchain`,
outline_detail_4:`- The Compiler Triplet
- Built in Linux Distribution Cross Compiler
- CodeSourcery
- Buildroot
- crosstool-ng
- OpenEmbedded
- Linaro
- Labs
- Yocto Project`,
outline_heading_5:`QEMU`,
outline_detail_5:`- Why use QEMU?
- What is QEMU?
- Image Formats
- Emulated Architectures
- Labs`,
outline_heading_6:`Booting a Target Development Board from uSD`,
outline_detail_6:`- Getting SW onto an uSD card
- Why do we utilize uSD cards?
- Booting from flash
- Labs
- Why using uSD cards a terrible idea?`,
outline_heading_7:`Booting a Target Development Board over Ethernet`,
outline_detail_7:`- Using virtual Hardware
- An simpler approach to build
- The Boot Sequence with TFTP & NFSroot
- Objectives of Lab
- Labs`,
outline_heading_8:`Kernel Configuration, Compilation, Booting`,
outline_detail_8:`- Labs
- Configuring the Kernel for the Development Board`,
outline_heading_9:`Device Drivers`,
outline_detail_9:`- Mechanism vs. Policy
- Types of Devices
- Power Management
- Avoiding Binary Blobs
- How Applications Use Device Drivers
- Error Numbers
- printk()
- Walking Through a System Call Accessing a Device
- devres: Managed Device Resources
- Labs`,
outline_heading_10:`Modules & Device Drivers`,
outline_detail_10:`- The module driver() Macros
- Modules & Hot Plug
- Labs`,
outline_heading_11:`Memory Management & Allocation`,
outline_detail_11:`- Virtual & Physical Memory
- Memory Zones
- Page Tables
- kmalloc()
- receive free pages()
- vmalloc()
- Slabs & Cache Allocations
- Labs`,
outline_heading_12:`Character Devices`,
outline_detail_12:`- Device Nodes
- Major & Minor Numbers
- Reserving Major/Minor Numbers
- Accessing the Device Node
- Registering the Device
- udev
- dev printk() & Associates
- file operations Structure
- Driver Entry Points
- The file & inode Structures
- Miscellaneous Character Drivers
- Labs`,
outline_heading_13:`Kernel Features`,
outline_detail_13:`- Components of the Kernel
- User-Space vs. Kernel-Space
- What are System Calls?
- Available System Calls
- Scheduling Algorithms & Task Structures
- Process Context
- Labs`,
outline_heading_14:`Transferring Between User & Kernel Space`,
outline_detail_14:`- Transferring Between Spaces
- put(get) user() & copy to(from) user()
- Direct Transfer: Kernel I/O & Memory Mapping
- Kernel I/O
- Mapping User Pages
- Memory Mapping
- User-Space Functions for mmap()
- Driver Entry Point for mmap()
- Accessing Files from the Kernel
- Labs`,
outline_heading_15:`Platform Drivers`,
outline_detail_15:`- What are Platform Drivers?
- Main Data Structures
- Registering Platform Devices
- An Example
- Hardcoded Platform Data
- The New Way: Device Trees
- Labs`,
outline_heading_16:`Device Trees`,
outline_detail_16:`- What are Device Trees?
- What Device Trees Do & What They Do Not Do
- Device Tree Syntax
- Device Tree Walk Through
- Device Tree Bindings
- Device Tree support in Boot Loaders
- Using Device Tree Data in Drivers
- Coexistence & Conversion of Old Drivers
- Labs`,
outline_heading_17:`Interrupts & Exceptions`,
outline_detail_17:`- What are Interrupts & Exceptions?
- Exceptions
- Asynchronous Interrupts
- MSI
- Enabling/Disabling Interrupts
- What You Cannot Do During Interrupt Time
- IRQ Data Structures
- Installing an Interrupt Handler
- Labs`,
outline_heading_18:`Timing Measurements`,
outline_detail_18:`- Kinds of Timing Measurements
- Jiffies
- Getting the Current Time
- Clock Sources
- Real-Time Clock
- Programmable Interval Timer
- Time Stamp Counter
- HPET
- Going Tickless`,
outline_heading_19:`Kernel Timers`,
outline_detail_19:`- Inserting Delays
- What are Kernel Timers?
- Low-Resolution Timer Functions
- Low-Resolution Timer Implementation
- High-Resolution Timers
- Using High-Resolution Timers
- Labs`,
outline_heading_20:`Firmware`,
outline_detail_20:`- What is Firmware?
- Loading Firmware
- Labs`,
outline_heading_21:`Sleeping & Wait Queues`,
outline_detail_21:`- What are Wait Queues?
- Going to Sleep & Waking Up
- Going to Sleep Details
- Exclusive Sleeping
- Waking Up Details
- Polling
- Labs`,
outline_heading_22:`Interrupt Handling: Deferrable Functions & User Drivers`,
outline_detail_22:`- Top & Bottom Halves
- Softirqs
- Tasklets
- Work Queues
- New Work Queue API
- Creating Kernel Threads
- Threaded Interrupt Handlers
- Interrupt Handling in User-Space
- Labs`,
outline_heading_23:`Hardware I/O`,
outline_detail_23:`- Memory Barriers
- Allocating & Mapping I/O Memory
- Accessing I/O Memory`,
outline_heading_24:`Direct Memory Access (DMA)**`,
outline_detail_24:`- What is DMA?
- DMA Directly to the User
- DMA & Interrupts
- DMA Memory Constraints
- DMA Masks
- DMA API
- DMA Pools
- Scatter/Gather Mappings
- Labs`,
outline_heading_25:`Memory Technology Devices (Flash Memory Filesystems)`,
outline_detail_25:`- What are MTD Devices?
- NAND vs. NOR vs. eMMC
- Driver & User Modules
- Flash Filesystems`,
outline_heading_26:`USB Drivers`,
outline_detail_26:`- What is USB?
- USB Topology
- Terminology
- Endpoints
- Descriptors
- USB Device Classes
- USB Support in Linux
- Registering USB Device Drivers
- Moving Data
- Example of a USB Driver
- Labs`,
outline_heading_27:`Closing & Evaluation Survey`,
outline_detail_27:`- Evaluation Survey`,
outline_heading_28:`Kernel Architecture I`,
outline_detail_28:`- UNIX & Linux **
- Monolithic & Micro Kernels
- Object-Oriented Methods
- Main Kernel Components
- User-Space & Kernel-Space`,
outline_heading_29:`Kernel Programming Preview`,
outline_detail_29:`- Task Structure
- Memory Allocation
- Transferring Data between User & Kernel Spaces
- Object-Oriented Inheritance - Sort Of
- Linked Lists
- Jiffies
- Labs`,
outline_heading_30:`Modules`,
outline_detail_30:`- What are Modules?
- A Trivial Example
- Compiling Modules
- Modules vs. Built-in
- Module Utilities
- Automatic Module Loading
- Module Usage Count
- Module Licensing
- Exporting Symbols
- Resolving Symbols **
- Labs`,
outline_heading_31:`Kernel Architecture II`,
outline_detail_31:`- Processes, Threads, & Tasks
- Kernel Preemption
- Real-Time Preemption Patch
- Labs`,
outline_heading_32:`Kernel Configuration & Compilation`,
outline_detail_32:`- Installation & Layout of the Kernel Source
- Kernel Browsers
- Kernel Configuration Files
- Kernel Building & Makefiles
- initrd & initramfs
- Labs`,
outline_heading_33:`Kernel Style & General Considerations`,
outline_detail_33:`- Coding Style
- Using Generic Kernel Routines & Methods
- Making a Kernel Patch
- sparse
- Using likely() & unlikely()
- Writing Portable Code, CPU, 32/64-bit, Endianness
- Writing for SMP
- Writing for High Memory Systems
- Power Management
- Keeping Security in Mind
- Labs`,
outline_heading_34:`Race Conditions & Synchronization Methods`,
outline_detail_34:`- Concurrency & Synchronization Methods
- Atomic Operations
- Bit Operations
- Spinlocks
- Seqlocks
- Disabling Preemption
- Mutexes
- Semaphores
- Completion Functions
- Read-Copy-Update (RCU)
- Reference Counts
- Labs`,
outline_heading_35:`Memory Addressing`,
outline_detail_35:`- Virtual Memory Management
- Systems With & Without MMU & the TLB
- Memory Addresses
- High & Low Memory
- Memory Zones
- Special Device Nodes
- NUMA
- Paging
- Page Tables
- page structure
- Labs`,
outline_heading_36:`Memory Allocation`,
outline_detail_36:`- Requesting & Releasing Pages
- Buddy System
- Slabs & Cache Allocations
- Memory Pools
- kmalloc()
- vmalloc()
- Early Allocations & bootmem()
- Memory Defragmentation
- Labs`,
skill_1:`C Programming`,
skill_2:`Linux Kernel Development`,
skill_3:`Device Driver Development`,
skill_4:`Embedded Systems`,
skill_5:`Debugging Techniques`,
skill_6:`Shell Scripting`,
course_faq_name:`Developing Embedded Linux Device Drivers (LFD435)`,
faq_question_1:`What prerequisites are needed for LFD435?`,
faq_answer_1:`Basic knowledge of C programming and Linux.`,
faq_question_2:`Is prior experience with embedded systems necessary?`,
faq_answer_2:`It's helpful but not mandatory.`,
faq_question_3:`Can I take LFD435 remotely?`,
faq_answer_3:`Yes, it's available online.`,
faq_question_4:`Are there any hands-on labs?`,
faq_answer_4:`Yes, plenty to reinforce learning.`,
faq_question_5:`Will I receive a certification?`,
faq_answer_5:`Yes, upon successful completion, you'll earn the "Developing Embedded Linux Device Drivers" certification.`,
},


{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Embedded Linux Development (LFD450)`,
course_overview:`This instructor-led workshop will offer you the step-by-step foundation for designing an embedded Linux solution. Starting with the cross-compiler, you’ll learn about setting up a development system, boot loaders, the kernel, drivers, device tree, & all the various software & decisions that is to be made while building a user space root filesystem, such as those in use in consumer electronics, military, medical, industrial, & auto industries. Hands-on labs using a RISC-V based simulated development target enable experts to practice both coding & developing the different aspects of the system discussed in class.



Duration: 4 days



Learning Objectives: 

In this tutorial you will learn about the Linux kernel architecture, stressing the main elements related to adapting the kernel to a specific embedded platform. The training also covers techniques for right-sizing systems to meet project constraints, the multitude of resources available for constructing a cross development environment for embedded projects, the options available for populating libraries & application user-spaces to meet the goals & constraints of embedded systems, & more.`,
certification:`LFD450 covers kernel configuration, device drivers development, board bring-up, shell scripting, cross-compilation, and debugging techniques in embedded Linux systems, ensuring proficiency in designing and deploying embedded Linux solutions.`,
prerequisite:`The seminar is mainly meant for experienced developers, programmers, & engineers who are interested in learning how to adapt Linux to an embedded system. You should be acquainted with fundamental Linux tools, know the C programming language, & be comfortable creating for Linux or UNIX. Pre-class preparatory material will be supplied before class.

Audience Profile

This program is for experienced developers who need to design an embedded Linux solution from the ground up.`,
outline_heading_1:`Introduction`,
outline_detail_1:`- Objectives
- Who You Are
- The Linux Foundation
- Copyright & No Confidential Information
- Linux Foundation Training
- Certification Programs & Digital Badging
- Linux Distributions
- Platforms
- Preparing Your System
- Things change in Linux
- Documentation & Links`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`- Linux Distributions
- Virtual Machine Installation
- Procedures
- Labs`,
outline_heading_3:`How to Work in OSS Projects **`,
outline_detail_3:`- Overview on How to Contribute Properly
- Know Where the Code is Coming From: DCO & CLA
- Stay Close to Mainline for Security & Quality
- Study & Understand the Project DNA
- Figure Out What Itch You Want to Scratch
- Identify Maintainers & Their Work Flows & Methods
- Get Early Input & Work in the Open
- Contribute Incremental Bits, Not Large Code Dumps
- Leave Your Ego at the Door: Dont Be Thin-Skinned
- Be Patient, Develop Long Term Relationships, Be Helpful`,
outline_heading_4:`Embedded & Real-Time Systems Concepts`,
outline_detail_4:`- Basic Concepts
- Protection Motivations
- Off the Shelf (OTS)
- Embedded Caveats
- Real Time Operating Systems
- Real Time Linux
- Custom Hardware Assistance
- Resources`,
outline_heading_5:`Cross-Development Environments: Goals & Needs`,
outline_detail_5:`- Introduction
- Why is it Hard?
- Project Goal Considerations
- Links to Additional Discussions
- Labs`,
outline_heading_6:`Kbuild System`,
outline_detail_6:`- Introduction
- Kbuild Makefiles
- Kconfig Basics
- Searching Kconfig`,
outline_heading_7:`Cross-Development Toolchain`,
outline_detail_7:`- The Compiler Triplet
- Built-in Linux Distribution Cross Compiler
- Linaro
- CodeSourcery
- crosstool-ng
- Buildroot
- OpenEmbedded
- Yocto Project
- Clang
- Labs`,
outline_heading_8:`QEMU`,
outline_detail_8:`- Why do we utilize uSD cards?
- Getting SW onto an uSD card
- Booting from flash
- Why is utilizing uSD cards a bad idea?
- Labs`,
outline_heading_9:`Booting a Target Development Board over Ethernet`,
outline_detail_9:`- Using virtual Hardware
- An simpler approach to build
- The Boot Sequence with TFTP & NFSroot
- Objectives of the Lab
- Labs`,
outline_heading_10:`Boot loaders & U-Boot`,
outline_detail_10:`- Boot Code Stages
- Some GPL BIOSes
- Some GPL Boot Loaders
- Das U-Boot
- U-Boot Command Line
- U-Boot Environment
- Labs`,
outline_heading_11:`Kernel Configuration, Compilation, Booting`,
outline_detail_11:`- Configuring the Kernel for the Development Board
- Labs`,
outline_heading_12:`Device Drivers`,
outline_detail_12:`- Types of Devices
- Device Nodes
- Character Drivers
- An Example
- Labs`,
outline_heading_13:`Device Trees`,
outline_detail_13:`- What are Device Trees?
- What Device Trees Do & What They Do Not Do
- Device Tree Syntax
- Device Tree Walk Through
- Device Tree Bindings
- Device Tree support in Boot Loaders
- Using Device Tree Data in Drivers
- Coexistence & Conversion of Old Drivers
- Labs`,
outline_heading_14:`Target Filesystem Packaging`,
outline_detail_14:`- Embedded Filesystem Goals
- Directories: a Survey
- Embedded Filesystem Types`,
outline_heading_15:`Build Target Root Filesystem`,
outline_detail_15:`- Objectives of the Lab
- Labs`,
outline_heading_16:`Root Filesystem Choices`,
outline_detail_16:`- SysV init vs. BusyBox init
- udev vs. BusyBox mdev
- Systemd
- C Library Choices
- Labs`,
outline_heading_17:`Configuring uClibc`,
outline_detail_17:`- Configuring uClibc for NFS
- Labs`,
outline_heading_18:`Another Alternate C-library: musl`,
outline_detail_18:`- What is musl?
- Configuring BuildRoot for musl
- Labs`,
outline_heading_19:`Build BusyBox Utility Suite`,
outline_detail_19:`- Basic Workings
- Integrated with Buildroot
- Labs`,
outline_heading_20:`Kernel Monitoring & Debugging`,
outline_detail_20:`- Tracing & Profiling
- Ftrace, Trace-Cmd, Kernelshark
- Perf
- Using perf
- sysctl
- SysRq Key
- oops Messages
- Kernel Debuggers
- debugfs`,
outline_heading_21:`Right-Sizing`,
outline_detail_21:`- Oft-Needed Embedded Components
- Taking Inventory of Kernel Sizes`,
outline_heading_22:`Memory Technology Devices (Flash Memory Filesystems)`,
outline_detail_22:`- What are MTD Devices?
- NAND vs. NOR vs. eMMC
- Driver & User Modules
- Flash Filesystems`,
outline_heading_23:`Compressed Filesystems`,
outline_detail_23:`- SquashFS
- Deploying in an MTD Partition
- Labs`,
outline_heading_24:`System Upgrades`,
outline_detail_24:`- When do we need to update?
- Update tactics
- Prebuilt upgrade systems
- Labs`,
outline_heading_25:`Real-Time Extensions`,
outline_detail_25:`- Predictability & Preemption & Locks
- PREEMPT RT Project
- Real-Time Checklist`,
outline_heading_26:`Closing & Evaluation Survey`,
outline_detail_26:`- Evaluation Survey`,
outline_heading_27:`Kernel Architecture Preview`,
outline_detail_27:`- Linux & UNIX
- Monolithic & Micro Kernels
- Main Kernel Tasks
- User-Space & Kernel-Space`,
outline_heading_28:`Kernel Source Tree Overview`,
outline_detail_28:`- Installation & Layout of the Kernel Source
- Kernel Browsers
- Kernel Configuration Files
- Why is it Hard? Part 2`,
outline_heading_29:`Kernel Programming Preview`,
outline_detail_29:`- Coding Style
- kernel-doc
- Using Generic Kernel Routines & Methods
- Error Numbers, Printing Kernel Output, syslogd
- Task Structure
- Memory Allocation
- Transferring Data between User & Kernel Space`,
outline_heading_30:`Modules`,
outline_detail_30:`- What are Modules?
- A Trivial Example
- Compiling Modules
- Modules versus Built-in
- Module Utilities
- Automatic Module Loading
- Module Usage Count
- Module Licensing
- Exporting Symbols
- Resolving Symbols **
- Labs`,
outline_heading_31:`Basic Target Development Board Setup`,
outline_detail_31:`- Objectives of the Lab
- Labs`,
outline_heading_32:`Booting the Target Development Board from uSD`,
outline_detail_32:`- Objectives of the Lab
- Labs`,
outline_heading_33:`Booting a Target Development Board over Ethernet`,
outline_detail_33:`- An simpler approach to build
- The Boot Sequence with TFTP & NFSroot
- Objectives of the Lab
- Labs`,
skill_1:`Kernel Configuration`,
skill_2:`Device Drivers Development`,
skill_3:`Board Bring-up`,
skill_4:`Shell Scripting`,
skill_5:`Cross-Compilation`,
skill_6:`Debugging Techniques`,
course_faq_name:`Embedded Linux Development (LFD450)`,
faq_question_1:`Q: What is Embedded Linux Development?`,
faq_answer_1:`Answer: Embedded Linux Development involves creating software for embedded systems using the Linux operating system.`,
faq_question_2:`Q: What are the prerequisites for LFD450?`,
faq_answer_2:`Answer: Basic knowledge of Linux and C programming is recommended.`,
faq_question_3:`Q: Can I take LFD450 remotely?`,
faq_answer_3:`Answer: Yes, LFD450 is available online.`,
faq_question_4:`Q: Is LFD450 certification recognized in the industry?`,
faq_answer_4:`Answer: Yes, LFD450 certification is recognized as a valuable credential for embedded Linux developers.`,
faq_question_5:`Q:How long does it take to complete LFD450?`,
faq_answer_5:`Answer: LFD450 typically takes around 40 hours to complete.`,
},



{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Embedded Linux Platform Development with the Yocto Project (LFD460)`,
course_overview:`In this instructor-led program, you’ll get knowledge of how to develop a repeatable embedded Linux target using the Yocto Project. Moreover to understanding the build system, you'll learn about more sophisticated tools like toaster, devtool, wic, eSDK, & eclipse IDE integration. This training expands on the Embedded Linux subjects acquired in the LFD450 class.


Duration: 3 days



Learning Objectives: 

In this Yocto training, you will learn how to use the Poky & Bitbake build process, with its recipes & layers to customize Linux kernel & packages for a custom embedded application, how to build Linux on an embedded board, how to use emulators for verification, how to create board support packages (BSP) for target hardware, & more.`,
certification:`The LFD460 course covers Embedded Linux Platform Development using the Yocto Project. Topics include Yocto Project basics, creating custom Linux distributions, kernel customization, board bring-up, application packaging, and device driver development. Successful completion certifies proficiency in building and customizing embedded Linux systems with the Yocto Project.`,
prerequisite:`This program is mainly aimed for experienced embedded Linux engineers who are interested in learning how to repeatably construct an embedded Linux system. This program assumes you have basic understanding of Embedded Linux systems, such as developing & install boot loaders, kernels & user space applications (the types of things you study in LFD450: Embedded Linux Development). You should also be comfortable with creating code from the command line, fundamental Linux tools & text editors. Some expertise with Python is beneficial but not essential.


Audience Profile

This program is mainly aimed for experienced embedded Linux engineers who are interested in learning how to repeatably construct an embedded Linux system. This program assumes you have basic experience of Embedded Linux systems, such as creating & install boot loaders, kernels & user space applications. You should also be comfortable with creating code from the command line, fundamental Linux tools & text editors. Some expertise with Python is beneficial but not essential.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Objectives
Goals
Audience
Who You Are
The Linux Foundation
Copyright & No Confidential Information
Linux Foundation Training
Certification Programs & Digital Badging
Linux Distributions
Platforms
Preparing Your System
Things Change in Linux & Open Source Projects
Documentation & Links`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`Linux Distributions
Procedures
Labs`,
outline_heading_3:`How to Work in OSS Projects **`,
outline_detail_3:`Overview of How to Contribute Properly
Know Where the Code is Coming From DCO & CLA
Stay Close to Mainline for Security & Quality
Study & Understand the Project DNA
Figure Out What Itch You Want to Scratch
Identify Maintainers & Their Work Flows & Methods
Get Early Input & Work in the Open
Contribute Incremental Bits, Not Large Code Dumps
Leave Your Ego at the Door: Don’t Be Thin-Skinned
Be Patient, Develop Long Term Relationships, and Be Helpful`,
outline_heading_4:`The Yocto Project & OpenEmbedded`,
outline_detail_4:`What is the Yocto Project?
What is OpenEmbedded?
The Yocto Project Family
Yocto Project Terms
Yocto Project Releases
Resources`,
outline_heading_5:`Poky`,
outline_detail_5:`Pronouncing Poky
Poky & BitBake Concepts
Yocto/OE Architecture`,
outline_heading_6:`Bitbake`,
outline_detail_6:`Obtaining & Installing
Metadata
Metadata Layers
Metadata Syntax
Events
Variants
File Downloads
Fetchers
Built-in Default Metadata
Checksums
Event Implementation
Tasks
Project Layout
Provisioning & Dependency Handling
BitBake Command Line
Labs`,
outline_heading_7:`Setting up the Yocto Project Build System`,
outline_detail_7:`The Yocto Project Reference System Structure
A. Poky Core Components
B. OpenEmbedded-Core Metadata Layer
C. Poky Metadata Layer
D. Yocto Board Support Package (BSP) Layer`,
outline_heading_8:`Labs`,
outline_detail_8:`Building an x86 Emulation Image
Build Environment Structure`,
outline_heading_9:`Troubleshooting Build Failures`,
outline_detail_9:`When Things Break
Task Logs
Running Specific Tasks
Debug Statements
Dependency Graphs
Displaying Variables & Metadata
Bitbake Debug Output
Developer Shell`,
outline_heading_10:`Customizing Images`,
outline_detail_10:`Extending a Pre-defined Image
Inheriting from Core-Image
Package Groups
Using Image Features
Labs`,
outline_heading_11:`Toaster`,
outline_detail_11:`What is Toaster?
Dependencies & Set Up of Toaster
Running Toaster (local)
Remote or hosted Setup`,
outline_heading_12:`Adding Packages`,
outline_detail_12:`Recipe Conventions & Best Practices
Single C-File Package
Makefile-based Package
Autotooled Package
Labs`,
outline_heading_13:`Layers`,
outline_detail_13:`Why Layers?
Working with Layers
Labs`,
outline_heading_14:`Kernel Recipes`,
outline_detail_14:`Yocto Kernel Recipes
Configuring the Yocto Project Kernel
Building a Custom Kernel
Extending the Yocto Project Kernel
Labs`,
outline_heading_15:`Introduction to certain BBCLASSes`,
outline_detail_15:`rm work.bbclass
allarch.bbclass
buildhistory & buildstats
cve-check.bbclass
archiver.bbclass`,
outline_heading_16:`rm work.bbclass
allarch.bbclass
buildhistory & buildstats
cve-check.bbclass
archiver.bbclass`,
outline_detail_16:`devtool
wic`,
outline_heading_17:`Licensing & Compliance`,
outline_detail_17:`License Management with Yocto
License Tracking
Enabling Commercially Licensed Recipes
Labs`,
outline_heading_18:`Closing & Evaluation Survey`,
outline_detail_18:`Evaluation Survey`,
outline_heading_19:`Kbuild System`,
outline_detail_19:`Introduction
Kbuild Makefiles
Kconfig Basics
Searching Kconfig`,
outline_heading_20:`Cross-Development Toolchain`,
outline_detail_20:`The Compiler Triplet
Built-in Linux Distribution Cross Compiler
Linaro
CodeSourcery
crosstool-ng
Buildroot
OpenEmbedded
Yocto Project
Labs`,
outline_heading_21:`Booting a Target Development Board over Ethernet`,
outline_detail_21:`Using virtual Hardware
An simpler approach to build
The Boot Sequence utilizing TFTP & NFSroot
Objectives of the Lab
Labs`,
outline_heading_22:`Target Filesystem Packaging`,
outline_detail_22:`Embedded Filesystem Goals
Directories: a Survey
Embedded Filesystem Types`,
outline_heading_23:`Root Filesystem Choices`,
outline_detail_23:`SysV init vs. BusyBox init
udev vs. BusyBox mdev
Systemd
C Library Choices
Labs`,
outline_heading_24:`Right-Sizing`,
outline_detail_24:`Oft-Needed Embedded Components
Taking Inventory of Kernel Sizes  `,
skill_1:`Yocto Project`,
skill_2:`Embedded Linux Development`,
skill_3:`Linux Kernel Customization`,
skill_4:`Board Bring-up`,
skill_5:`Application Packaging`,
skill_6:`Application Packaging`,
course_faq_name:`Embedded Linux Platform Development using the Yocto Project (LFD460)`,
faq_question_1:`What is the Yocto Project?`,
faq_answer_1:`The Yocto Project is an open-source collaboration project that provides templates, tools, and methods to create custom Linux-based systems for embedded products.`,
faq_question_2:`How does Yocto facilitate embedded Linux development?`,
faq_answer_2:`Yocto streamlines embedded Linux development by offering a set of tools for building custom Linux distributions tailored to specific hardware platforms.`,
faq_question_3:`What is the role of Linux Kernel customization?`,
faq_answer_3:`Linux Kernel customization involves configuring and optimizing the Linux kernel to support specific hardware requirements and functionalities for embedded systems.`,
faq_question_4:`What does board bring-up entail?`,
faq_answer_4:`Board bring-up involves configuring and initializing the embedded hardware platform to ensure proper communication and functionality with the Linux operating system.`,
faq_question_5:`Why is application packaging important in embedded Linux development?`,
faq_answer_5:`Application packaging involves bundling software applications with their dependencies into distributable packages, ensuring easy deployment and management on embedded Linux systems.`,
},



{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Fundamentals of Linux`,
course_overview:`Linux runs virtually all of the world’s supercomputers, most of the servers powering Internet, the bulk of the financial transactions globally & billions of Android smartphones. In summary, Linux is everywhere. This instructor-led course will offer you a strong working understanding of Linux from both graphical & command-line perspectives, enabling you to quickly navigate around any of the main Linux variants.

Duration: 4 days

Learning Objectives: 

In this tutorial, you'll learn about the history of Linux, installing Linux, how to utilize the Graphical User Interface, how to operate Linux with the aid of command line, basic Linux security, & much more.`,
certification:`Covering basics such as command line usage, file management, user administration, scripting, networking, and package management. Includes practical exercises and assessments on these topics, aiming for proficiency in Linux fundamentals.`,
prerequisite:`This program is aimed to give professionals with the required skills & abilities to function as professional Linux system administrators. Professionals should have fundamental understanding of Linux & its most prevalent programs & text editors.

Audience Profile

This class is meant for students who have little/no previous familiarity with Linux or Unix. System administrators, developers, architects, decision-makers/new Linux users may all benefit from knowledge presented in the program, particularly if they're wanting to engage with more complex issues like Linux system administration, network management & enterprise system design.    `,
outline_heading_1:`Linux Foundation`,
outline_detail_1:`Linux Foundation Training
Linux Foundation Certifications
Linux Foundation Digital Badges
Laboratory Exercises, Solutions & Resources
Distribution Details
Lab`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`The Command Line
Linux Distributions & Desktops
Keeping Current
Filesystem Layout
Editors
sudo
Labs`,
outline_heading_3:`The Command Line
Linux Distributions & Desktops
Keeping Current
Filesystem Layout
Editors
sudo
Labs`,
outline_detail_3:`Linux History
Linux Philosophy
Linux Community
Linux Vocabulary
Linux Distributions
Labs`,
outline_heading_4:`Linux Installation`,
outline_detail_4:`Planning the Installation Process
Source Media
Doing the Install
Labs`,
outline_heading_5:`Planning the Installation Process
Source Media
Doing the Install
Labs`,
outline_detail_5:`Graphical Layers
Session Management
Exploring the Filesystem
Customizing the Graphical Desktop
Labs`,
outline_heading_6:`System Configuration from the Graphical Interface`,
outline_detail_6:`System Settings
Display Settings
Network Manager
NTP (Network Time Protocol)
Graphical Software Package Management
Labs`,
outline_heading_7:`Finding Linux Documentation`,
outline_detail_7:`Documentation Sources
The UNIX Manual
GNU Info
Command Help
Other Documentation Sources
Labs`,
outline_heading_8:`Common Applications`,
outline_detail_8:`Internet Applications
Office Applications
Multimedia Applications
Graphics Editors
Labs`,
outline_heading_9:`Text Editors`,
outline_detail_9:`Available Text Editors
Creating a File Without an Editor
nano
gedit
vi
emacs
Labs`,
outline_heading_10:`Boot Process`,
outline_detail_10:`Bootloader
Linux Kernel & initramfs
init & Services
Console
X Window System & Desktop Manager
Labs`,
outline_heading_11:`Command-line Operations`,
outline_detail_11:`Command Line Operations & Options
Basic Operations
Command Prompt
Wildcards
Searching for Files
Package Management
Labs`,
outline_heading_12:`User Environment`,
outline_detail_12:`Accounts
Environment Variables
Key Shortcuts
Command History
Command Aliases
File Ownership & Permissions
Labs`,
outline_heading_13:`Text Operations`,
outline_detail_13:`cat
echo
sed
awk
Miscellaneous Text Utilities
Sorting, Cutting, Pasting, Joining, Splitting
Regular Expressions & grep
Labs`,
outline_heading_14:`File Operations`,
outline_detail_14:`Filesystems
Partitions & Mount Points
Network File System (NFS)
Working with Files
Comparing Files
File Types
Compressing Data
Labs`,
outline_heading_15:`Bash Shell Scripting`,
outline_detail_15:`Scripts
Features
Functions
Command Substitutions & Arithmetic
If Conditions & Tests
Looping Structures
Case Structure
Debugging
Creating Temporary Files & Directories
Labs`,
outline_heading_16:`Processes`,
outline_detail_16:`Introduction to Processes
Process Attributes
ps
top
Load Averages
Process Control
Starting Processes in the Future
Labs`,
outline_heading_17:`Printing`,
outline_detail_17:`CUPS & Printer Configuration
Printing Operations
PostScript & PDF
Labs`,
outline_heading_18:`Networking`,
outline_detail_18:`Addressing
Networking Interfaces & Configuration
Networking Utilities & Tools
Labs`,
outline_heading_19:`Local Security Principles`,
outline_detail_19:`Local Security
When to Use Root
sudo
Passwords
Bypassing User Authentication
Labs`,
outline_heading_20:`Closing & Evaluation Survey`,
outline_detail_20:`Evaluation Survey `,
skill_1:`Command Line Navigation`,
skill_2:`File System Management`,
skill_3:`User and Group Administration`,
skill_4:`Shell Scripting`,
skill_5:`Network Configuration`,
skill_6:`Package Management`,
course_faq_name:`Fundamentals of Linux`,
faq_question_1:`What is Linux?`,
faq_answer_1:`Linux is an open-source operating system kernel used in various distributions.`,
faq_question_2:`Linux is an open-source operating system kernel used in various distributions.`,
faq_answer_2:`Use commands like cd to change directories and ls to list files.`,
faq_question_3:`What is shell scripting?`,
faq_answer_3:`Shell scripting involves writing scripts to automate tasks in the Linux environment.`,
faq_question_4:`How do I manage users and groups?`,
faq_answer_4:`Utilize commands like useradd and groupadd to add users and groups respectively.`,
faq_question_5:`What is package management?`,
faq_answer_5:`Package management involves installing, updating, and removing software packages using package managers like apt or yum.`,
},

{
categories: ``,
course_detail_image:course_images.programing_1,
course_name:`Introduction to Linux, Open Source Development, & GIT (LFD301)`,
course_overview:`Linux is booming, & the need for Linux engineers has never been higher. The course will introduce you to the world of Linux programming & offer you the background & knowledge you need to start working with Linux. If you’ve been thinking about getting into Linux programming, here is the ideal place to start.

Duration: 4 days
Learning Objectives: 

In this course, you'll learn about the history of Linux & what differentiates it from other UNIX-like operating systems, what the main ingredients of a Linux system are & how to use them, the essentials of system administration, such as user accounts & groups, essential commands, filesystems, security, & more, networking & threading models, aspects of compiling & using libraries, working with Java under Linux, & more.`,
certification:`LFD301 covers essential Linux system administration, open-source development principles, Git version control, Bash scripting, and collaborative development workflows. Participants gain proficiency in Linux command line, understanding open-source ecosystem, mastering Git workflows, and scripting automation, preparing them for real-world Linux environments and collaborative software development projects.`,
prerequisite:`This course is for professionals who have previously experienced computer users on another operating system but have little or no experience working in a Linux environment.

Audience Profile

This course is for professionals who have previously experienced computer users on another operating system but have little or no experience working in a Linux environment. The content in this course will work with any major Linux distribution.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Objectives & Audience
The Linux Foundation
Copyright & No Confidential Information
Linux Foundation Training
Certification Programs & Digital Badging
Where Does the Software Come From?
Is it Difficult to Migrate to Linux?
Migration Considerations
Migration Aids & Documentation
Scope & Coverage of System Administration
Preparing Your System
Procedures
Things change in Linux
Labs`,
outline_heading_2:`Open Source Software`,
outline_detail_2:`What is Open Source Software?
What is Proprietary (Closed Source) Software?
Pragmatism against Idealism
History of Open Source Software
Open Source Governance Models
Labs`,
outline_heading_3:`Why Use Open Source Software?`,
outline_detail_3:`Collaborative Development
Security & Quality of Source Code
Users: Flexibility
Business: Collaborative Development
Business: Marketing
Education: El-Hi, Public Systems
Education: University
Developers: Greater Visibility
Labs`,
outline_heading_4:`Examples of Successful OSS Projects`,
outline_detail_4:`Linux Kernel
git
Apache
Python, Perl & Other Computer Languages
TEX & LATEX
GNU: gcc, gdb, etc
X & Desktop Managers
OpenStack, Kubernetes, & Other Projects
Labs`,
outline_heading_5:`How to Work in OSS Projects`,
outline_detail_5:`- Overview of How to Contribute Properly
Know Where the Code is Coming From DCO & CLA
 Study & Understand the Project DNA
 Figure Out What Itch You Want to Scratch
 Identify Maintainers & Their Work Flows & Methods
 Get Early Input & Work in the Open
 Contribute Incremental Bits, Not Large Code Dumps
 Leave Your Ego at the Door: Don’t Be Thin-Skinned
 Be Patient, Develop Long Term Relationships, and Be Helpful
Labs`,
outline_heading_6:`Continuous Integration`,
outline_detail_6:`Why Continuous Integration?
Continuous Delivery & Continuous Deployment
Cost & Benefits
Tools
Example: Linux Kernel Continuous Integration Project
The Continuous Delivery Foundation`,
outline_heading_7:`OSS Licensing & Legal Issues`,
outline_detail_7:`Restrictive vs. Permissive Licensing
FUD
Software Patents
Patents & Licenses
Choosing a License
Combining Licenses
Labs`,
outline_heading_8:`Leadership vs. Control & Why Projects Fail`,
outline_detail_8:`Leadership vs. Control
Loosen the Chains
Mentoring
Building Trust
Why do many OSS initiatives fail
Labs`,
outline_heading_9:`Respecting & Encouraging Diversity in OSS`,
outline_detail_9:`Diversity & Open Source Software
Sex & Gender Identity
National Origin, Geography & Language
Religion & Politics
Differences in Opinion
Labs`,
outline_heading_10:`GitHub & Other Hosting Providers`,
outline_detail_10:`GitHub
Public vs. Private
GUI vs. command line
Labs`,
outline_heading_11:`Linux & the Operating System`,
outline_detail_11:`Kernel vs. Operating System & Tools
History of Linux
UNIX & Linux
Linux Distributions
Linux Standard Base (LSB)
Labs`,
outline_heading_12:`Graphical Environments & Interfaces`,
outline_detail_12:`Graphical Layers
X Window System
Window Managers
Desktop Managers
Terminal Window Options
Labs`,
outline_heading_13:`System Administration`,
outline_detail_13:`Installation
Software Management & Packaging
Upgrading & Patching
User Directories, Environments, etc
Logging Files
Labs`,
outline_heading_14:`Getting Help`,
outline_detail_14:`Sources of Documentation
man Pages
info
–help & assistance
Graphical Interfaces
Labs`,
outline_heading_15:`Text Editors`,
outline_detail_15:`Available Text Editors
vi
emacs
Labs`,
outline_heading_16:`Shells, bash, & the Command Line`,
outline_detail_16:`Shells
Shell Initialization
Aliases
Environment Variables
Customizing the Command Line Prompt
Special Charac
ters
Redirection
Pipes
Command Substitution & Expressions
Labs`,
outline_heading_17:`Filesystem Layout, Partitions, Paths & Links`,
outline_detail_17:`Filesystem Layout
Partitions
Partitioning Considerations
Paths
Hard & Soft (Symbolic) Links
Labs`,
outline_heading_18:`System Initialization`,
outline_detail_18:`System Boot
System Initialization
Labs`,
outline_heading_19:`Memory`,
outline_detail_19:`Memory
Swap
Threading Models
Labs`,
outline_heading_20:`Networking`,
outline_detail_20:`Networking & Network Interfaces
Labs`,
outline_heading_21:`Command Details`,
outline_detail_21:`Basic Commands & Utilities
File Transfer Tools
Monitoring & Performance Utilities
Graphical Monitoring Tools
Loading/Unloading Kernel Modules
Device Management
Managing System Services
Labs`,
outline_heading_22:`Users & Groups`,
outline_detail_22:`Basics of Users & Groups
Adding & Removing Users & Groups
Files, Users & Permissions
root (super) user, su & sudo
Labs`,
outline_heading_23:`Linux Filesystems`,
outline_detail_23:`Filesystems & the VFS
ext2, ext3, & ext4 Filesystems
Journaling Filesystems
btrfs
Mounting Filesystems
RAID
LVM`,
outline_heading_24:`Essential Command Line Tools`,
outline_detail_24:`Listing, Creating, Deleting & Renaming Files & Directories
Finding Files: find & locate
Finding Strings: grep
String Substitution: sed
Labs`,
outline_heading_25:`Bash Scripting`,
outline_detail_25:`Script Basics
Conditionals
Loops
Functions
Labs`,
outline_heading_26:`Files & Filesystems`,
outline_detail_26:`Types of Files
Permissions & Access Rights
Changing Permissions & Ownership
Labs`,
outline_heading_27:`Compiling, Linking & Libraries`,
outline_detail_27:`gcc
Other Compilers
Major gcc Options
Static Libraries
Shared Libraries
Linking To Libraries
Debugging using gdb
Labs`,
outline_heading_28:`Java Installation & Environment`,
outline_detail_28:`Write Once & Use Anywhere?
Installing Java on Linux
Handling Multiple JREs & JDKs: the alternatives System
Environment Variables & Class Paths
Integrated Development Environments`,
outline_heading_29:`Write Once & Use Anywhere?
Installing Java on Linux
Handling Multiple JREs & JDKs: the alternatives System
Environment Variables & Class Paths
Integrated Development Environments`,
outline_detail_29:`Write Once & Use Anywhere?
Installing Java on Linux
Handling Multiple JREs & JDKs: the alternatives System
Environment Variables & Class Paths
Integrated Development Environments`,
outline_heading_30:`Building RPM & Debian Packages`,
outline_detail_30:`Why Use Package Management
Packaging System Benefits
Linux Distributions & Package Management
RPM Creation
The RPM spec File
Details on RPM standard Sections
RPM Dependencies
Debian Package Creation Workflow
Labs`,
outline_heading_31:`Introduction to GIT`,
outline_detail_31:`Revision Control
Know Where the Code is Coming From DCO & CLA
Available Revision Control Systems
Graphical Interfaces
Documentation
Labs`,
outline_heading_32:`Git Installation`,
outline_detail_32:`Binary Installation
Installing from Source
Labs`,
outline_heading_33:`Git & Revision Control Systems`,
outline_detail_33:`Converting Between Different Systems
RCS & CVS
Subversion
git
git & Distributed Development
Labs`,
outline_heading_34:`Using Git: an Example`,
outline_detail_34:`Basic Commands
A Simple Example
Labs`,
outline_heading_35:`Git Concepts & Architecture`,
outline_detail_35:`Concepts
Design Features
Repositories
Objects & Index
Content vs. Pathnames
Committing vs. Publishing
Upstream & Downstream
Forking
Labs`,
outline_heading_36:`Managing Files & the Index`,
outline_detail_36:`File Categories
Basic File Commands
Labs`,
outline_heading_37:`Commits`,
outline_detail_37:`Making a Commitment
Identifiers & Tags
Viewing the Commit History
Reverting & Resetting Commits
Tidying Repositories
Who is to Blame?
Bisecting
Labs`,
outline_heading_38:`Branches`,
outline_detail_38:`What is a branch?
Branch Names vs. Tags
Branch Creation
Branch Checkout
Getting Earlier File Versions
Labs`,
outline_heading_39:`Diffs`,
outline_detail_39:`Differencing Files
Diffing in Git
Labs`,
outline_heading_40:`Merges`,
outline_detail_40:`What is Merging?
Merge Commands
Rebasing
Labs`,
skill_1:`Linux System Administration`,
skill_2:`Open Source Software Development`,
skill_3:`Git Version Control`,
skill_4:`Bash Scripting`,
skill_5:`Linux Command Line Proficiency`,
skill_6:`Collaborative Development Workflow`,
course_faq_name:`Introduction to Linux, Open Source Development, & GIT (LFD301)`,
faq_question_1:`What is Linux?`,
faq_answer_1:`Linux is an open-source operating system kernel initially developed by Linus Torvalds. It is widely used in servers, desktops, and embedded systems.`,
faq_question_2:`What is Open Source Development?`,
faq_answer_2:`Open Source Development involves creating software with source code that is freely available for anyone to use, modify, and distribute.`,
faq_question_3:`What is Git?`,
faq_answer_3:`Git is a distributed version control system designed to track changes in source code during software development.`,
faq_question_4:`What is Bash Scripting?`,
faq_answer_4:`Bash scripting involves writing scripts using the Bash shell to automate tasks and execute commands in Linux and Unix-like operating systems.`,
faq_question_5:`Why learn Linux Command Line Proficiency?`,
faq_answer_5:`Command line proficiency in Linux enables efficient system administration, file management, and troubleshooting tasks in various computing environments.`,
},


{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Kubernetes Administration (LFS458)`,
course_overview:`This tutorial covers the key ideas generally used to construct & operate a Kubernetes cluster in production using vendor-independent technologies. We establish a cluster, decide network setup, extend the cluster, install apps & configure the storage, security & other items essential for normal operation. This program gives exposure to the numerous skills essential to manage Kubernetes in the production environment & is ideal preparation for Certified Kubernetes Administrator (CKA) test.


Duration: 4 days

Learning Objectives: 

In this tutorial, you will learn about the installation of a multi-node Kubernetes cluster with the aid of kubeadm, & how to develop a cluster, choosing & implementing cluster networking, & different ways of application lifecycle management, including scaling, updates & roll-backs. The training also covers configuring security both for the cluster & containers, managing storage available to containers, monitoring, logging & troubleshooting of containers & cluster, configuring scheduling & affinity container deployments, using Helm & Charts to automate application deployment, & understanding Federation for fault-tolerance & higher availability.`,
certification:`LFS458 introduces Kubernetes administration essentials, covering cluster deployment, management, scaling, and troubleshooting. Topics include Kubernetes architecture, installation, pod management, service discovery, monitoring, logging, and security. Certification validates proficiency in administering Kubernetes clusters, ensuring competence in maintaining and optimizing containerized environments.`,
prerequisite:`Professionals should have an awareness of Linux administration abilities, comfortable utilizing the command line. Must be able to modify files using a command-line text editor.

Audience Profile

This program is suitable for individuals looking to manage a containerized application architecture. This covers incumbent IT administrators, as well as individuals wishing to start a cloud career.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Linux Foundation
Linux Foundation Training
Linux Foundation Certifications
Linux Foundation Digital Badges
Laboratory Exercises, Solutions & Resources
E-Learning Training: LFS258
Platform Details`,
outline_heading_2:`Basics of Kubernetes`,
outline_detail_2:`Define Kubernetes
Cluster Structure
Adoption
Project Governance & CNCF
Labs`,
outline_heading_3:`Installation & Configuration`,
outline_detail_3:`Getting Started With Kubernetes
Minikube
kubeadm
More Installation Tools
Labs`,
outline_heading_4:`Kubernetes Architecture`,
outline_detail_4:`Kubernetes Architecture
Networking
Other Cluster Systems
Labs`,
outline_heading_5:`APIs & Access`,
outline_detail_5:`API Access
Annotations
Working with A Simple Pod
kubectl & API
Swagger & OpenAPI`,
outline_heading_6:`API Objects`,
outline_detail_6:`API Objects
The v1 Group
API Resources
RBAC APIs
Labs`,
outline_heading_7:`Managing State With Deployments`,
outline_detail_7:`Deployment Overview
Managing Deployment States
Deployments & Replica Sets
DaemonSets
Labels
Labs`,
outline_heading_8:`Volumes & Data`,
outline_detail_8:`Volumes Overview
Volumes
Persistent Volumes
Rook
Passing Data To Pods
ConfigMaps
Labs`,
outline_heading_9:`Services`,
outline_detail_9:`Overview
Accessing Services
DNS
Labs`,
outline_heading_10:`Helm`,
outline_detail_10:`Overview
Helm
Using Helm
Labs`,
outline_heading_11:`Ingress`,
outline_detail_11:`Overview
Ingress Controller
Ingress Rules
Service Mesh
Labs`,
outline_heading_12:`Scheduling`,
outline_detail_12:`Overview
Scheduler Settings
Policies
Affinity Rules
Taints & Tolerations
Labs`,
outline_heading_13:`Logging & Troubleshooting`,
outline_detail_13:`Overview
Troubleshooting Flow
Basic Start Sequence
Monitoring
Plugins
Logging
Troubleshooting Resources`,
outline_heading_14:`Custom Resource Definition`,
outline_detail_14:`Overview
Custom Resource Definitions
Aggregated APIs
Labs`,
outline_heading_15:`Security`,
outline_detail_15:`Overview
Accessing the API
Authentication & Authorization
Admission Controller
Pod Policies
Network Policies
Labs`,
outline_heading_16:`High Availability`,
outline_detail_16:`Overview
Stacked Database
External Database
Labs`,
outline_heading_17:`Closing & Evaluation Survey`,
outline_detail_17:`Evaluation Survey`,
outline_heading_18:`Domain Review`,
outline_detail_18:`CKA Exam
Exam Domain Review`,
skill_1:`Container Orchestration`,
skill_2:`Kubernetes Installation and Configuration`,
skill_3:`Pod Management`,
skill_4:`Service Discovery and Load Balancing`,
skill_5:`Cluster Monitoring and Logging`,
skill_6:`Troubleshooting and Debugging`,
course_faq_name:`Kubernetes Administration (LFS458)`,
faq_question_1:`What is the Kubernetes Administration about?`,
faq_answer_1:`Kubernetes Administration focuses on managing containerized applications in production environments using Kubernetes orchestration.`,
faq_question_2:`Why is Kubernetes important?`,
faq_answer_2:`Kubernetes simplifies the deployment, scaling, and management of containerized applications, enabling efficient resource utilization and faster development cycles.`,
faq_question_3:`What are the prerequisites for LFS458?`,
faq_answer_3:`Familiarity with Linux command-line, container concepts, and basic networking is recommended for LFS458.`,
faq_question_4:`What can I expect from the certification exam?`,
faq_answer_4:`The exam evaluates skills in deploying, managing, and troubleshooting Kubernetes clusters, ensuring candidates are proficient in Kubernetes administration.`,
faq_question_5:`How does LFS458 prepare for real-world scenarios?`,
faq_answer_5:`LFS458 covers practical exercises, case studies, and hands-on labs to simulate real-world Kubernetes administration challenges, providing comprehensive preparation for managing production environments.`,
},


{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Kubernetes for App Developers (LFD459)`,
course_overview:`This training will teach you how to containerize, host, deploy, & configure an application in a multi-node cluster. It also serves as preparation for the Certified Kubernetes Application Developer (CKAD) exam.



Duration: 3 days



Learning Objectives: 

Starting with a simple Python script, this training will show you how to define application resources & use core primitives to build, monitor & troubleshoot scalable applications in Kubernetes. Working with network plugins, security & Cloud storage, you will be exposed to many of the features needed to deploy an application in a production environment.`,
certification:`LFD459, Kubernetes for App Developers certification covers containerization concepts, Kubernetes architecture, deployment, scaling, and management. It includes topics on microservices, CI/CD, monitoring, logging, troubleshooting, and practical skills for building resilient applications on Kubernetes.`,
prerequisite:`To get the most out of this training, you should have the following:

Basic Linux command line & file editing skills & be familiar with using a programming language (such as Python, Node.js, Go). Knowledge of Cloud Native application concepts & architectures (such as is taught in our free Introduction to Kubernetes edX MOOC) is helpful for this training.



Please note Kubernetes Administration (LFS458) is not a prerequisite for this training. There is overlap in the training materials as each one is designed to stand alone & aligns with the related exams.



Audience Profile

This training is for experienced application developers who need to containerize, host, deploy, & configure an application in a multi-node cluster.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Objectives
Who You Are
The Linux Foundation
Linux Foundation Training
Certification Programs & Digital Badging
Platform Details`,
outline_heading_2:`Kubernetes Architecture`,
outline_detail_2:`What Is Kubernetes?
Components of Kubernetes
Challenges
The Borg Heritage
Kubernetes Architecture
Terminology
Control Plane Node
Worker Nodes
Pods
Services
Operators
Single IP per Pod
Networking Setup
CNI Network Configuration File
Pod-to-Pod Communication
Cloud Native Computing Foundation
Resource Recommendations
Labs`,
outline_heading_3:`Build`,
outline_detail_3:`Container Options
Containerizing an Application
Creating the Dockerfile
Hosting a Local Repository
Creating a Deployment
Running Commands in a Container
Multi-Container Pod
readinessProbe
livenessProbe
startupProbe
Testing
Helm
Labs`,
outline_heading_4:`Design`,
outline_detail_4:`Traditional Applications: Considerations
Decoupled Resources
Transience
Flexible Framework
Managing Resource Usage
Using Label Selectors
Multi-Container Pods
Sidecar Container
Adapter Container
Ambassador
initContainer
Custom Resource Definitions
Points to Ponder
Jobs
Labs`,
outline_heading_5:`Deployment Configuration`,
outline_detail_5:`Volumes Overview
Introducing Volumes
Volume Spec
Volume Types
Shared Volume Example
Persistent Volumes & Claims
Persistent Volume
Persistent Volume Claim
Dynamic Provisioning
Secrets
Using Secrets via Environment Variables
Mounting Secrets as Volumes
Portable Data with ConfigMaps
Using ConfigMaps
Deployment Configuration Status
Scaling & Rolling Updates
Deployment Rollbacks
Labs`,
outline_heading_6:`Understanding Security`,
outline_detail_6:`Security Overview
Accessing the API
Authentication
Authorization
RBAC
RBAC Process Overview
Admission Controller
Security Contexts
Pod Security Policies
Pod Security Standards
Network Security Policies
Network Security Policy Example
Default Policy Example
Labs`,
outline_heading_7:`Exposing Applications`,
outline_detail_7:`Service Types
Services Diagram
Service Update Pattern
Accessing an Application with a Service
Service without a Selector
ClusterIP
NodePort
LoadBalancer
ExternalName
Ingress Resource
Ingress Controller
Service Mesh
Labs`,
outline_heading_8:`Application Troubleshooting`,
outline_detail_8:`Troubleshooting Overview
Basic Troubleshooting Steps
Ongoing (Constant) Change
Basic Troubleshooting Flow: Pods
Basic Troubleshooting Flow: Node & Security
Basic Troubleshooting Flow: Agents
Monitoring
Logging Tools
Monitoring Applications
System & Agent Logs
Conformance Testing
More Resource
Labs`,
outline_heading_9:`Closing & Evaluation Survey`,
outline_detail_9:`Evaluation Survey`,
skill_1:`Containerization`,
skill_2:`Troubleshooting and Debugging`,
skill_3:`Continuous Integration/Continuous Deployment (CI/CD)`,
skill_4:`Microservices Architecture`,
skill_5:`Monitoring and Logging`,
skill_6:`Kubernetes Orchestration`,
course_faq_name:`Kubernetes for App Developers (LFD459)`,
faq_question_1:`What is Kubernetes?`,
faq_answer_1:`Kubernetes is an open-source platform for automating deployment, scaling, and management of containerized applications`,
faq_question_2:`How does Kubernetes help developers?`,
faq_answer_2:`It simplifies container orchestration, allowing developers to focus on building and deploying applications without worrying about infrastructure management.`,
faq_question_3:`What are Pods in Kubernetes?`,
faq_answer_3:`Pods are the smallest deployable units in Kubernetes, consisting of one or more containers sharing network and storage resources.`,
faq_question_4:`How does Kubernetes ensure high availability?`,
faq_answer_4:`Kubernetes offers features like automatic scaling, self-healing, and load balancing, ensuring that applications are highly available and resilient.`,
faq_question_5:`What tools complement Kubernetes for development?`,
faq_answer_5:`Tools like Helm for package management, Prometheus for monitoring, and Fluentd for logging integrate seamlessly with Kubernetes, enhancing the development experience.`,

},


{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Linux for Cloud Technicians (LFS303)`,
course_overview:`Linux & Cloud are two of the most in-demand expertise in IT. This program includes fundamental information for establishing & administering Linux systems & containers, extending on the traditional administrative skills & including crucial tasks & operations typically utilized in cloud contexts. Whether you’re searching for training to assist start a new Linux IT job or you’re simply brushing up on your abilities, this instructor-led course will teach you all you need to know.



Duration: 4 days



Learning Objectives: 

This course trains you to connect to a system & execute important customization & configuration activities for Cloud & non-cloud instances. You will have the opportunity to manage networking, system services, user account creation & administration, & much more; you will also familiarize yourself with virtualization, containers, & DevOps concepts; & gain important skills like text file processing, file operations, bash scripting, use of git, troubleshooting, etc., which will come in handy when administering multiple systems in the Cloud efficiently.`,
certification:`Linux for Cloud Technicians (LFS303) covers Linux fundamentals, cloud computing concepts, system administration, virtualization, networking, security, and automation. Participants learn to deploy and manage cloud-based solutions efficiently, ensuring scalability, reliability, and security.`,
prerequisite:`While the program has no needed prerequisites, basic understanding of Linux & the command line will improve the learning experience.



Audience Profile

This program is meant for those who wish to increase their understanding of Linux system setup, administration, & command line environment. Whether you are working in a cloud environment today or in the future, these abilities are important for system usage & setup. Professionals will benefit from having a basic grasp of Linux before attending this class.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Linux Foundation
Linux Foundation Training
Linux Foundation Certifications
Linux Foundation Digital Badges
Laboratory Exercises, Solutions & Resources
Things Change in Linux & Open Source Projects
E-Learning Training: LFS203
Distribution Details
Labs`,
outline_heading_2:`System Configuration from the Graphical Interface`,
outline_detail_2:`GUI or Command Line?
System Settings
Display Settings
Network Manager
NTP (Network Time Protocol)
Graphical Software Package Management
Console
X Window System & Desktop Manager
Labs`,
outline_heading_3:`Boot Process & System Initialization`,
outline_detail_3:`Bootloader
Linux Kernel & initramfs
init & Services
systemd
systemctl
Labs`,
outline_heading_4:`Command-line Operations`,
outline_detail_4:`Command Line Operations & Options
Logging In & Out, Rebooting & Shutting Down
Setting Time & Date
Locating Applications
Directories & Paths
Wildcards
Searching for Files
Command Prompt
Package Management
Labs`,
outline_heading_5:`User Accounts & Environment`,
outline_detail_5:`User Accounts
Groups
Group Management
Shell Startup Files
Management of User Accounts
Passwords
File Ownership & Permissions
SSH
Environment Variables
Key Shortcuts
Command History
Command Aliases
Labs`,
outline_heading_6:`Text Operations`,
outline_detail_6:`cat
echo
sed
awk
Miscellaneous Text Utilities
Sorting, Cutting, Pasting, Joining, Splitting
Regular Expressions & grep
Labs`,
outline_heading_7:`File Operations`,
outline_detail_7:`Filesystems
Partitions & Mount Points
Network File System (NFS)
rsync
Working with Files
Comparing Files
File Types
Compressing Data
Labs`,
outline_heading_8:`Bash Shell Scripting`,
outline_detail_8:`Scripts
Features
Functions
Command Substitutions & Arithmetic
If Conditions & Tests
Looping Structures
Case Structure
Debugging
Creating Temporary Files & Directories
Labs`,
outline_heading_9:`Networking`,
outline_detail_9:`Addressing
Networking Interfaces & Configuration
Networking Utilities & Tools
Labs`,
outline_heading_10:`Working With Linux Filesystems`,
outline_detail_10:`Filesystem Basics
Virtual Filesystem (VFS)
Hard & Soft Links
Available Filesystems
Creating & formatting filesystems
Checking & Repairing Filesystems
Filesystem Usage
Disk Usage
Mounting filesystems
NFS
Mounting at Boot & /etc/fstab
Labs`,
outline_heading_11:`Virtualization Overview`,
outline_detail_11:`Virtualization Overview
Introduction to Virtualization
Hosts & Guests
Emulation
Hypervisors
libvirt
QEMU
KVM
Labs`,
outline_heading_12:`Containers Overview`,
outline_detail_12:`Containers
Application Virtualization
Containers vs. Virtual Machines
Docker
Docker Commands
Podman
Labs`,
outline_heading_13:`Basic Troubleshooting`,
outline_detail_13:`Troubleshooting Levels
Troubleshooting Techniques
Networking
File Integrity
Boot Process Failures
Filesystem Corruption & Recovery
Virtual Consoles
Labs`,
outline_heading_14:`Introduction to GIT`,
outline_detail_14:`Revision Control
Know Where the Code is Coming From DCO & CLA
Available Revision Control Systems
Graphical Interfaces
Documentation
Labs`,
outline_heading_15:`Using Git: an Example`,
outline_detail_15:`Basic Commands
A Simple Example
Signing Off on Commits
master vs. main
Labs`,
outline_heading_16:`DevOps & GitOps`,
outline_detail_16:`Introduction
Cultural Philosophies & Methodologies
Early Software Development Management Practice
Modern Software Development
DevOps Methodologies
DevOps tools`,
outline_heading_17:`Closing & Evaluation Survey`,
outline_detail_17:`Evaluation Survey`,
skill_1:`Bash Scripting`,
skill_2:`System Administration`,
skill_3:`Cloud Computing`,
skill_4:`Networking`,
skill_5:`Security Management`,
skill_6:`Virtualization`,
course_faq_name:`Linux for Cloud Technicians (LFS303)`,
faq_question_1:`What is Linux?`,
faq_answer_1:`Linux is an open-source operating system kernel used in various distributions for computing devices.`,
faq_question_2:`How does Linux integrate with cloud computing?`,
faq_answer_2:`Linux provides a stable and flexible foundation for cloud infrastructure, offering scalability, reliability, and cost-effectiveness.`,
faq_question_3:`What are common Linux distributions used in cloud environments?`,
faq_answer_3:`Common distributions include Ubuntu, CentOS, Debian, and Red Hat Enterprise Linux (RHEL), tailored for cloud deployments.`,
faq_question_4:`What security measures are important for Linux in cloud environments?`,
faq_answer_4:`Security measures include regular updates, access controls, encryption, and monitoring to protect data and resources.`,
faq_question_5:`How can Linux skills benefit cloud technicians?`,
faq_answer_5:`Linux skills are essential for managing cloud infrastructure, deploying applications, automating tasks, and ensuring system stability and security.
`,
},



{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Linux for System Engineers (LFS311)`,
course_overview:`The demand for sysadmins with sophisticated administration & networking expertise has never been stronger, & competition for those with experience is severe. Whether you’re looking for expert test prep for the Linux Foundation Certified Engineer (LFCE) certification, need the training to help transition to Linux from other platforms, or you’re just brushing up on these vital admin & networking skills, this instructor-led training will teach you what you need to know.



Duration: 4 days



Learning Objectives: 

In this training, you will learn advanced Linux administration skills, including how to design, deploy & maintain the network running under Linux, how to administer the network services, the skills to create & operate the network in major Linux distributions, how to configure the network interfaces & how to deploy & configure file, web, email & name servers.`,
certification:``,
prerequisite:`This program is meant for system administrators & IT professionals who need to get hands-on understanding of Linux network setup & services as well as associated subjects such as fundamental security & performance.



Audience Profile

This advanced Linux course is meant for system administrators & IT professionals who need to get hands-on understanding of Linux network setup & services as well as related subjects such as fundamental security & performance.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Linux Foundation
Linux Foundation Training
Linux Foundation Certifications
Linux Foundation Digital Badges
Laboratory Exercises, Solutions & Resources
E-Learning Training: LFS211
Distribution Details
Labs`,
outline_heading_2:`Linux Networking Concepts & Review`,
outline_detail_2:`OSI Model Introduction & Upper Layers
OSI Model Transport Layer
OSI Model Network Layer
OSI Model Lower Layers
Network Topology
Domain Name System
System Services
Managing System Services
Labs`,
outline_heading_3:`Network Configuration`,
outline_detail_3:`Runtime Network Configuration
Boot Time Network Configuration
DNS Client
Intro to OpenVPN
Labs`,
outline_heading_4:`Network Troubleshooting & Monitoring`,
outline_detail_4:`Network Troubleshooting
Client-Side Troubleshooting
Server-Side Troubleshooting
Network Monitoring
Labs`,
outline_heading_5:`Remote Access`,
outline_detail_5:`Remote Access History
Intro to Cryptography
Secure Remote Access
Remote Graphics
Labs`,
outline_heading_6:`Domain Name Service`,
outline_detail_6:`Overview Of DNS
BIND (named) Server
BIND Zone Configuration
DNS views
Labs`,
outline_heading_7:`HTTP Servers`,
outline_detail_7:`Apache
Apache Configuration
Apache Virtual Hosts
Apache Security
Labs`,
outline_heading_8:`Advanced HTTP Servers`,
outline_detail_8:`Mod Rewrite
Mod Alias
Mod Status
Mod Include
Mod Perl
Performance Considerations
HTTP Balancer
Labs`,
outline_heading_9:`Email Servers`,
outline_detail_9:`Email Overview
Postfix
Dovecot
Labs`,
outline_heading_10:`File Sharing`,
outline_detail_10:`FTP
vsftpd
rsync
SSH Based Protocols
Other Protocols
Labs`,
outline_heading_11:`Advanced Networking`,
outline_detail_11:`Routing
VLANs
DHCP
NTP
Labs`,
outline_heading_12:`HTTP Caching`,
outline_detail_12:`Overview
Squid Configuration
Labs`,
outline_heading_13:`Network File Systems`,
outline_detail_13:`NFS
SMB/CIFS
Other Network File Systems
Mounting Network File Systems
Labs`,
outline_heading_14:`Introduction to Network Security`,
outline_detail_14:`Security Concepts
Security Practices
Security Tools
Labs`,
outline_heading_15:`Firewalls`,
outline_detail_15:`TCP Wrappers
netfilter Concepts
Iptables Command
Managing IPtables
Advanced Firewalls
Network Address Translation
nft Concepts
Labs`,
outline_heading_16:`LXC Virtualization Overview`,
outline_detail_16:`Linux Containers (LXC)
Labs`,
outline_heading_17:`High Availability`,
outline_detail_17:`Overview
DRBD
Labs`,
outline_heading_18:`Database`,
outline_detail_18:`Introduction
Database Management Systems
Structured Query Language (SQL)
Labs`,
outline_heading_19:`System log`,
outline_detail_19:`Overview
Remote Logging: Client
Remote Logging: Server
Labs`,
outline_heading_20:`Package Management`,
outline_detail_20:`Installing from Source
Package Management
Packaging System Benefits
Main Package Management Systems
Role of Linux Distributions
Building RPM Packages
RPM Spec File Sections
RPM Spec File Example
Building Debian Packages
Labs`,
skill_1:`System Admini`,
skill_2:`Bash Scriptingstration`,
skill_3:`Networking`,
skill_4:`Security Management`,
skill_5:`Troubleshooting`,
skill_6:`Virtualization`,
course_faq_name:`Linux for System Engineers (LFS311)`,
faq_question_1:`What is Linux?`,
faq_answer_1:`Linux is an open-source operating system kernel used in various distributions.`,
faq_question_2:`How do I execute commands in Linux?`,
faq_answer_2:`Use the terminal or command line interface to execute commands.`,
faq_question_3:`What is SSH?`,
faq_answer_3:`SSH (Secure Shell) is a network protocol used for secure remote access to systems.`,
faq_question_4:`How can I secure my Linux system?`,
faq_answer_4:`Utilize firewalls, strong passwords, regular updates, and access control mechanisms.`,
faq_question_5:`How do I troubleshoot Linux system issues?`,
faq_answer_5:`Analyze logs, use diagnostic tools, and reference online resources for guidance.
Certification Content (LFS311):`,

},



{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Linux Kernel Internals & Development (LFD420)`,
course_overview:`Learn how to design the Linux kernel. In this instructor-led class, you’ll discover how Linux is architected, the fundamental methodologies for the building on the kernel, & how to effectively collaborate with the Linux developer community. If you're interested in learning about the Linux kernel, this is the canonical instruction on the topic.



Duration: 4 days



Learning Objectives: 

In this program, you will study how Linux is architected, how kernel algorithms function, hardware & memory management, modularization approaches & debugging, how the kernel development community runs & how to effectively interact with it, & much more.`,
certification:`LFD420, Linux Kernel Internals & Development, covers essential skills including kernel debugging, device drivers development, memory management, kernel configuration, system calls, and kernel module programming. Participants learn through hands-on labs and gain expertise in understanding, modifying, and developing features for the Linux kernel, crucial for career advancement in Linux systems development.`,
prerequisite:`To get the most of this course, you must:

Be competent in the C programming language and fundamental Linux (UNIX) tools like ls, grep & tar, & be conversant with any of accessible text editors (e.g., emacs, vi, etc.) Experience with a major Linux distribution is beneficial but not necessarily essential.



Audience Profile

This class is aimed to equip an experienced programmers with the strong grasp of the Linux kernel. Professionals should be skilled in the C programming language and fundamental Linux (UNIX) tools such as ls, grep & tar, & be conversant with any of the available text editors (e.g., emacs, vi, etc.). `,
outline_heading_1:`Introduction`,
outline_detail_1:`Objectives
Who You Are
The Linux Foundation
Copyright & No Confidential Information
Linux Foundation Training
Certification Programs & Digital Badging
Linux Distributions
Platforms
Preparing Your System
Using & Downloading a Virtual Machine
Things Change in Linux & Open Source Projects
Documentation & Links`,
outline_heading_2:`Preliminaries`,
outline_detail_2:`Procedures
Kernel Versions
Kernel Sources & Use of git
Labs`,
outline_heading_3:`How to Work in OSS Projects`,
outline_detail_3:`Overview of How to Contribute Properly
Know Where the Code is Coming From DCO & CLA
Stay Close to Mainline for Security & Quality
Study & Understand the Project DNA
Figure Out What Itch You Want to Scratch
Identify Maintainers & Their Work Flows & Methods
Get Early Input & Work in the Open
Contribute Incremental Bits, Not Large Code Dumps
Leave Your Ego at the Door: Don’t Be Thin-Skinned
Be Patient, Develop Long Term Relationships, and Be Helpful`,
outline_heading_4:`Kernel Architecture`,
outline_detail_4:`UNIX & Linux **
Monolithic & Micro Kernels
Object-Oriented Methods
Main Kernel Components
User-Space & Kernel-Space`,
outline_heading_5:`Kernel Programming Preview`,
outline_detail_5:`Error Numbers & Getting Kernel Output
Task Structure
Memory Allocation
Transferring Data between User & Kernel Spaces
Object-Oriented Inheritance - Sort Of
Linked Lists
String to Number Conversions
Jiffies
Labs`,
outline_heading_6:`Modules`,
outline_detail_6:`What are Modules?
A Trivial Example
Compiling Modules
Modules vs. Built-in
Module Utilities
Automatic Module Loading
Module Usage Count
The module struct
Module Licensing
Exporting Symbols
Resolving Symbols **
Labs`,
outline_heading_7:`Kernel Architecture II`,
outline_detail_7:`Processes, Threads, & Tasks
Process Context
Kernel Preemption
Real-Time Preemption Patch
Dynamic Kernel Patching
Run-time Alternatives **
Porting to a New Platform **
Labs`,
outline_heading_8:`Kernel Initialization`,
outline_detail_8:`Overview of System Initialization
System Boot
Das U-Boot for Embedded Systems**
Kernel Startup`,
outline_heading_9:`Kernel Configuration & Compilation`,
outline_detail_9:`Installation & Layout of the Kernel Source
Kernel Browsers
Kernel Configuration Files
Kernel Building & Makefiles
initrd & initramfs
Labs`,
outline_heading_10:`System Calls`,
outline_detail_10:`What are System Calls?
Available System Calls
How System Calls are Implemented
Adding a New System Call
Labs`,
outline_heading_11:`Kernel Style & General Considerations`,
outline_detail_11:`Coding Style
kernel-doc **
Using Generic Kernel Routines & Methods
Making a Kernel Patch
sparse
Using likely() & unlikely()
Writing Portable Code, CPU, 32/64-bit, Endianness
Writing for SMP
Writing for High Memory Systems
Power Management
Keeping Security in Mind
Mixing User- & Kernel-Space Headers **
Labs`,
outline_heading_12:`Race Conditions & Synchronization Methods`,
outline_detail_12:`Concurrency & Synchronization Methods
Atomic Operations
Bit Operations
Spinlocks
Seqlocks
Disabling Preemption
Mutexes
Semaphores
Completion Functions
Read-Copy-Update (RCU)
Reference Counts
Labs`,
outline_heading_13:`SMP & Threads`,
outline_detail_13:`SMP Kernels & Modules
Processor Affinity
CPUSETS
SMP Algorithms - Scheduling, Locking, etc
Per-CPU Variables **
Labs`,
outline_heading_14:`Processes`,
outline_detail_14:`What are Processes?
The task struct
Creating User Processes & Threads
Creating Kernel Threads
Destroying Processes & Threads
Executing User-Space Processes From Within the Kernel
Labs`,
outline_heading_15:`Process Limits & Capabilities`,
outline_detail_15:`Process Limits
Capabilities
Labs`,
outline_heading_16:`Monitoring & Debugging`,
outline_detail_16:`Debuginfo Packages
Tracing & Profiling
sysctl
SysRq Key
oops Messages
Kernel Debuggers
debugfs
Labs`,
outline_heading_17:`Scheduling`,
outline_detail_17:`Main Scheduling Tasks
SMP
Scheduling Priorities
Scheduling System Calls
The 2.4 schedule() Function **
O(1) Scheduler **
Time Slices & Priorities
Load Balancing
Priority Inversion & Priority Inheritance **
The CFS Scheduler
Calculating Priorities & Fair Times
Scheduling Classes
Scheduler Details
Labs`,
outline_heading_18:`Memory Addressing`,
outline_detail_18:`Virtual Memory Management
Systems With & Without MMU & the TLB
Memory Addresses
High & Low Memory
Memory Zones
Special Device Nodes
NUMA
Paging
Page Tables
page structure
Kernel Samepage Merging (KSM) **
Labs`,
outline_heading_19:`Signals`,
outline_detail_19:`What are Signals?
Available Signals
System Calls for Signals
Sigaction
Signals & Threads
How the Kernel Installs Signal Handlers
How the Kernel Sends Signals
How the Kernel Invokes Signal Handlers
Real-Time Signals
Labs`,
outline_heading_20:`Disk Caches & Swapping`,
outline_detail_20:`Caches
Page Cache Basics
What is Swapping?
Swap Areas
Swapping Pages In & Out
Controlling Swappiness
The Swap Cache
Reverse Mapping **
OOM Killer
Labs`,
skill_1:`Kernel Debugging`,
skill_2:`Device Drivers Development`,
skill_3:`Memory Management`,
skill_4:`Kernel Configuration`,
skill_5:`System Calls`,
skill_6:`Kernel Module Programming`,
course_faq_name:`Linux Kernel Internals & Development (LFD420)`,
faq_question_1:`What is the Linux kernel?`,
faq_answer_1:`The Linux kernel is the core component of the Linux operating system, responsible for managing system resources and providing essential functionalities.`,
faq_question_2:`How do I debug kernel issues?`,
faq_answer_2:`Kernel issues can be debugged using tools like GDB, printk statements, and kernel debugging frameworks like KGDB.`,
faq_question_3:`What are kernel modules?`,
faq_answer_3:`Kernel modules are pieces of code that can be dynamically loaded into the Linux kernel to extend its functionality without rebooting.`,
faq_question_4:`How can I develop device drivers?`,
faq_answer_4:`Device drivers can be developed using programming languages like C, with proper understanding of hardware interfaces and Linux kernel APIs.`,
faq_question_5:`How does memory management work in the Linux kernel?`,
faq_answer_5:`Memory management in the Linux kernel involves managing physical and virtual memory, including tasks like page allocation, paging, and memory mapping.`,
},

{
categories: `Linux`,
course_detail_image:course_images.programing_1,
course_name:`Open Source Virtualization (LFS462)`,
course_overview:`Open Source Virtualization takes deep dive into KVM (Kernel-based Virtual Machine) & Xen, the most prominent hypervisor virtualization technologies in the open source environment, & the deployment & usage of containers. Built by a specialist in the area of virtualization, this program gives the technical basis to comprehend the components necessary to develop & run a contemporary virtual IT infrastructure.



Duration: 4 days



Learning Objectives: 

This program includes KVM instruction from the ground up with an emphasis on QEMU & libvirt, as well as Xen. You will discover how these & other similar open-source components may be integrated to form a virtual IT architecture. There is an emphasis on practical deployment skills, protecting the virtual infrastructure, & administering solutions, as well as thorough instruction about utilizing containers. Lab activities concentrate on virtual images & snapshots, deployment, VM construction & maintenance, interaction with networking infrastructure, device pass-through & more. `,
certification:`The LFS462 course covers essential skills in open source virtualization, including managing virtual environments, deploying open source technologies, Linux system administration for virtualization, configuring networks, ensuring system security, and troubleshooting common issues. Completion of the course provides a comprehensive understanding and proficiency in open source virtualization technologies.`,
prerequisite:`This program is for technical IT professionals interested in developing a stable, effective & open virtualization infrastructure using KVM. Professionals are required to have mastered the skills taught in the lower-level Linux Foundation System Administration program. Professionals are required to be well­ acquainted in Linux command line use, shell scripting & text file editing.



Audience Profile

This program is for technical IT professionals interested in developing a stable, effective & open virtualization infrastructure using KVM. Professionals are required to have mastered the skills taught in the lower-level Linux Foundation System Administration program. Professionals are required to be well­ acquainted in Linux command line use, shell scripting & text file editing.`,
outline_heading_1:`Introduction`,
outline_detail_1:`Linux Foundation
Linux Foundation Training
Linux Foundation Certifications
Laboratory Exercises, Solutions & Resources
Distribution Details
Labs`,
outline_heading_2:`Virtualization Overview`,
outline_detail_2:`Virtualization Terminology
Host versus Guest
Virtual Machine Monitor
Emulation
Software versus Hardware Virtualization
Hypervisor Types
Instruction Translation & Caching
Paravirtualization
Virtual Machine Image File
Linux Containers
Xen Hypervisor
Comparison
Labs`,
outline_heading_3:`KVM Quick-Start`,
outline_detail_3:`Verifying your Linux Kernel has KVM
Validating your Hardware for KVM
Obtaining & Installing KVM & QEMU
Building Upstream QEMU
qemu-img & Virtual Disk Files
virt-install
Pausing & Restoring the Guest
Managing VMs using GUIs
The QEMU Monitor
The QEMU Command Line
Labs`,
outline_heading_4:`KVM Architecture & Relationship with Linux`,
outline_detail_4:`Brief History of KVM
Controlling KVM Using Linux Commands
Obtaining KVM
Labs`,
outline_heading_5:`Running KVM Using QEMU`,
outline_detail_5:`What is QEMU?
Running KVM Using QEMU
Machine Commands
Starting Guest Networking
Dumping Network Traffic with QEMU
Guest-to-Host Communication with Virtio
QEMU Tracing
Managing Storage With QEMU
Managing Virtual Storage with libguestfs
Using Host Physical Storage
Using Host Physical Networking
Graphics
BIOS
USB Pass-through
Labs`,
outline_heading_6:`libvirt`,
outline_detail_6:`libvirt
Architecture
libvirt Access Control
Virsh
virt-manager
libvirt XML
Open vSwitch
Labs`,
outline_heading_7:`Hardware Support`,
outline_detail_7:`Assigning Devices
I/O Provisioning
Labs`,
outline_heading_8:`Tuning for Performance`,
outline_detail_8:`Measuring Performance
netperf
fio
Memory Testing using Stream
SPECVirt Tuning
Labs`,
outline_heading_9:`KVM Security`,
outline_detail_9:`KVM Security
Review – libvirt Access Control
Host Security Concerns
libvirt-sandbox
sVirt
Security Updates
Labs`,
outline_heading_10:`Xen Hypervisor Architecture`,
outline_detail_10:`Domain Types
Toolstack Choices
Virtualization Modes
Labs`,
outline_heading_11:`Installation & Configuring Xen`,
outline_detail_11:`Preparing the Host
Getting the Software
Verifying dom0
Toolstack Selection
Installing domU
Persistent Xen Configuration
Connections to domU
Labs`,
outline_heading_12:`Administration of Xen domains`,
outline_detail_12:`Debugging Xen Issues
Backup & Restore
Live Migration
NUMA Features
PCI Pass-through
USB Device Pass-through
Troubleshooting
Labs`,
outline_heading_13:`Xen Performance & Tuning`,
outline_detail_13:`Domain Resource Usage
Xen Scheduler Options
I/O Benchmark Testing
Boot Options for dom0
Labs`,
outline_heading_14:`Xen Security`,
outline_detail_14:`Domain Type Considerations
Xen Security Modules
FLASK
Virtual Trusted Platform Module
pvgrub
Network Security
Labs`,
outline_heading_15:`Working with Linux Containers`,
outline_detail_15:`Container Basics
OCI
Kubernetes
CRI-O
Rocket
Docker
LXD Container Hypervisor
lxc command line tools
Labs`,
skill_1:`Virtualization Management`,
skill_2:`Open Source Technologies`,
skill_3:`Linux Administration`,
skill_4:`Network Configuration`,
skill_5:`System Security`,
skill_6:`Troubleshooting`,
course_faq_name:`Open Source Virtualization (LFS462)`,
faq_question_1:`What is open source virtualization?`,
faq_answer_1:`Open source virtualization utilizes freely available software to create virtual instances of operating systems or applications.`,
faq_question_2:`Which open source virtualization platforms are commonly used?`,
faq_answer_2:`Popular platforms include KVM (Kernel-based Virtual Machine) and Xen.`,
faq_question_3:`What are the benefits of open source virtualization?`,
faq_answer_3:`Cost-effectiveness, flexibility, and community support are key advantages`,
faq_question_4:`How does open source virtualization differ from proprietary solutions?`,
faq_answer_4:`Open source virtualization offers greater customization and control compared to proprietary alternatives.`,
faq_question_5:`Is open source virtualization secure?`,
faq_answer_5:`With proper configuration and maintenance, open source virtualization can be as secure as proprietary solutions.`,
},




]
export default Programmingdata ;