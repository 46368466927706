import React, { useEffect } from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "../Styling/course_detail.css";
import live1 from "..//../assets/images/live1.webp";
import live2 from "..//../assets/images/live2.webp";
import live3 from "..//../assets/images/live3.webp";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Programmingdata from '..//../utils/Programmingdata';


const Hr_50 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const hr_50_Courses = Programmingdata.filter(course => course.course_name === 'HR FUNDAMENTALS');
  return (
    <div>
        <Navbar />
        {hr_50_Courses.map(course => (
            <div key={course.id}>
                <div className="course-overview py-5 bg-light " id="inner-coursepage">
                    <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 p-2 cp-img">
                                <img src={course.course_detail_image} alt="Course-image" style={{ width: "100%" }}/>
                            </div>
                            <div className="col-lg-6 col-sm-12 px-5 pt-2 cp-des">
                                <h3>{course.course_name}</h3>
                                <p className="text-dark">{course.course_overview}</p>
                                <div className="d-flex justify-content-center">
                                    <a  href="https://forms.gle/ayLvjdWZb87cSJ636" target='_blank'>
                                        <button type="button" class="btn enroll-btn btn-primary me-3">Enroll Now</button>
                                    </a>
                                    <button type="button" class="btn btn-primary ms-3" style={{ width: "100%" }}>
                                        Download Syllabus
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="certi-prereq mt-5">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="d-flex justify-content-center mb-3">
                                    <h3>Prerequisite</h3>
                                </div>
                                <div className="accordion accordion-flush" id="accordionFlushExample1">
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingPreOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePreOne" aria-expanded="false" aria-controls="flush-collapsePreOne">
                                            Students Must Have
                                        </button>
                                    </h2>
                                    <div id="flush-collapsePreOne" className="accordion-collapse collapse" aria-labelledby="flush-headingPreOne" data-bs-parent="#accordionFlushExample1">
                                        <div className="accordion-body">
                                            <p>{course.prerequisite}</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="d-flex justify-content-center mb-3">
                                    <h3>Certification</h3>
                                </div>
                                <div className="accordion accordion-flush" id="accordionFlushExample2">
                                    <div className="accordion-item mb-2 border-0 shadow">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Certification
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample2">
                                        <div className="accordion-body">
                                            <p>{course.certification}</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whybi mt-5 bg-light pb-5">
                    <div className="container-fluid">
                        <div className="container">
                            <div className={`d-flex justify-content-center py-5`}>
                                <h2>Why Certification Course from digiiPath</h2>
                            </div>
                            <div className="row">
                                <div className="col mb-3 d-flex justify-content-center">
                                    <div className="card border-0 shadow" style={{ width: "25rem" }}>
                                    <div className="card-body p-0">
                                        <div className="d-flex align-items-center border-bottom p-2">
                                            <img src={live1} alt="" />
                                            <h5 className="card-title">Instructor-Led Live, Online Training</h5>
                                        </div>
                                        <div className={`card-detail pt-2`}>
                                            <ul>
                                                <li className="text-dark">World-className Instructors</li>
                                                <li className="text-dark">Expert-Led Mentoring Sessions</li>
                                                <li className="text-dark">Instant doubt clearing</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col mb-3 d-flex justify-content-center">
                                    <div className="card border-0 shadow" style={{ width: "25rem" }}>
                                        <div className="card-body p-0">
                                            <div className="d-flex align-items-center border-bottom p-2">
                                                <img src={live2} alt="" />
                                                <h5 className="card-title">On-Premise classNameroom Training</h5>
                                            </div>
                                            <div className={`card-detail pt-2`}>
                                                <ul>
                                                    <li className="text-dark">World-className Instructors</li>
                                                    <li className="text-dark">Expert-Led Mentoring Sessions</li>
                                                    <li className="text-dark">Instant doubt clearing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col mb-3 d-flex justify-content-center">
                                    <div className="card border-0 shadow" style={{ width: "25rem" }}>
                                        <div className="card-body p-0">
                                            <div className="d-flex align-items-center border-bottom p-2">
                                                <img src={live3} alt="" />
                                                <h5 className="card-title">Live Intracting className</h5>
                                            </div>
                                            <div className={`card-detail pt-2`}>
                                                <ul>
                                                    <li className="text-dark">World-className Instructors</li>
                                                    <li className="text-dark">Expert-Led Mentoring Sessions</li>
                                                    <li className="text-dark">Instant doubt clearing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-course mt-5">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="d-flex justify-content-center mt-3">
                                <h3>Skills Covered</h3>
                            </div>
                            <div className="row mt-4">
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />
                                    {course.skill_1}
                                </span>
                            </div>
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />
                                    {course.skill_2}
                                </span>
                            </div>
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />
                                    {course.skill_3}
                                </span>
                            </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} /> 
                                    {course.skill_4}
                                </span>
                            </div>
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />
                                    {course.skill_5}
                                </span>
                            </div>
                            <div className="col">
                                <span>
                                    <CheckCircleRoundedIcon style={{ color: "#f04c24" }} />
                                    {course.skill_6}
                                </span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Course-outline mt-5 bg-light py-5">
                    <div className="container-fluid">
                    <div className="container">
                        <div className="d-flex justify-content-center">
                        <h3>Cousre Outline Designed by Experts</h3>
                        </div>
                        <div className="row mt-3">
                        <div className="col">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            {course.outline_heading_1}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_1.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            {course.outline_heading_2}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_2.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            {course.outline_heading_3}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_3.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            {course.outline_heading_4}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_4.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {course.outline_heading_5}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_5.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-2 border-0 shadow">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                            {course.outline_heading_6}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {course.outline_detail_6.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="faq" className="mt-5">
                    <div className={`container-fluid py-5`}>
                        <div className={`container`}>
                            <div className={`mb-5`}>
                                <h2 className={`d-flex justify-content-center`}>{course.course_faq_name}</h2>
                            </div>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            {course.faq_question_1}
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div className="accordion-body">
                                            <p>{course.faq_answer_1}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            {course.faq_question_2}
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div className="accordion-body">
                                            <p>{course.faq_answer_2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        {course.faq_question_3}
                                    </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div className="accordion-body">
                                            <p>{course.faq_answer_3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                            {course.faq_question_4}
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                        <div className="accordion-body">
                                            <p>{course.faq_answer_4}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                            {course.faq_question_5}
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                        <div className="accordion-body">
                                            <p>{course.faq_answer_5}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}      
        <Footer />  
    </div>
  )
}

export default Hr_50
