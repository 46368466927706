import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./internship.css";
import InternshipData from "../../utils/Internship_data";

const Internship = () => {
    
  return (
    <div className="internship">
        <Navbar />
        <div className="container d-flex justify-content-center mt-4">
            <div className="row d-flex justify-content-center align-items-center text-center flex-column intern-head">
                <div className="col-12">
                    <h1 style={{color:"#f04c24"}}>Our Internships</h1>
                    <p>We are passionate about technology and believe in the power of software to transform the world. Our internship program is just one of the ways in which we are investing in the future of the industry.</p>
                </div>
            </div>
        </div>
        <div className="container mt-5">
            <div className="row mb-5">
                <h3 className='d-flex justify-content-center' style={{color:"#f04c24"}}>
                    <u>INTERNSHIP POSITION</u>
                </h3>
            </div>
            <div className="row">
            {InternshipData.map(({ id, internship_image, internship_category, internship_detail, link }) => (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5" key={id}>
                    <div className="card border-0 shadow h-100">
                        <img src={internship_image} className="card-img-top" alt={internship_category} />
                        <div className="card-body d-flex flex-column">
                            <h4 className="card-title text-center">{internship_category}</h4>
                            <p className="card-text">{internship_detail}</p>
                            <div className="mt-auto d-flex justify-content-center">
                                <a href={link}>
                                    <button type="button" className="btn intern-btn">Apply</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Internship;
