
import { course_images } from "./images";
const Internship_data =[
    {
        id:1,
        internship_image:course_images.web_dev_1 ,
        internship_category:`Web Development `,
        internship_detail:`This web development internship offers valuable hands-on experience and an 
        introduction to developing.`,
        link:``,
    },
    {
        id:2,
        internship_image:course_images.andriod_dev_1 ,
        internship_category:`Android Development`,
        internship_detail:`Android, renowned for its adaptability, has redefined mobile functionality and 
        internet access.`,
        link:``,
    },
    {
        id:3,
        internship_image:course_images.data_science_3 ,
        internship_category:`Data Science`,
        internship_detail:`Remote data science internships offer hands-on experience and exposure to top 
        industry organizations.`,
        link:``,
    },
    {
        id:4,
        internship_image:course_images.programing_2 ,
        internship_category:`Java Programming`,
        internship_detail:`Master Java programming from the comfort of your home and unlock amazing job 
        opportunities.`,
        link:``,
    },
    {
        id:5,
        internship_image:course_images.programing_1,
        internship_category:`C++ Programming`,
        internship_detail:`Master C++ programming from home and unlock excellent job prospects with our 
        certification program.`,
        link:``,
    },
    {
        id:6,
        internship_image:course_images.programing_4,
        internship_category:`Python Programming`,
        internship_detail:`Join our 4-week intensive internship to master Python programming from the 
        comfort of your home.`,
        link:``,
    },
    {
        id:7,
        internship_image:course_images.ui_ux,
        internship_category:`UI/UX Design`,
        internship_detail:`Master UI/UX Design from the comfort of your home and unlock fantastic job 
        opportunities.`,
        link:``,
    },
    {
        id:8,
        internship_image:course_images.ai,
        internship_category:`Artificial intelligence`,
        internship_detail:`Master artificial intelligence from the comfort of your home and unlock 
        incredible job opportunities.`,
        link:``,
    },
    {
        id:9,
        internship_image:course_images.ml,
        internship_category:`Machine learning`,
        internship_detail:`Master machine learning from the comfort of your home and unlock incredible 
        job opportunities.`,
        link:``,
    },
    
]
export default Internship_data ;