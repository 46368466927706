import React from 'react';
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import campus_amb from "../assets/images/cam-amb.jpg"
import "./Styling/jobs.css";

const Campus_Ambassador = () => {
  return (
    <div>
        
        <div className="container mt-5 jobs">
            <div className="row d-flex justify-content-center mb-5">
                {/* <h5 className='d-flex justify-content-center'>Our Internship Program</h5> */}
                {/* <h1 className='d-flex justify-content-center text-center'>Join Our Job Program  and Ignite Your Career!</h1> */}
            </div>
            <div className="row mb-5">
                <div className="col-md-6 d-flex align-items-center flex-column justify-content-center">
                    <h2 className='mb-3 d-flex justify-content-center'>Campus Ambassador</h2>
                    <p>Become a part of our Campus Ambassador Internship Program and acquire essential experience in talent acquisition, employee engagement, and campus outreach. Enhance your leadership abilities, expand your professional network, and contribute to the growth of our organization. Apply now and create a positive impact on your campus!</p>
                    <a href="https://forms.gle/Kzgiu8pNgAWQCt2LA">
                        <button type="button" class="btn job-btn">Apply</button>
                    </a>
                </div>
                <div className="col-md-6 mobile-col">
                    <img src={campus_amb} alt="" srcset="" />
                </div>
            </div>
            
        </div>


        {/* <Footer /> */}
      
    </div>
  )
}

export default Campus_Ambassador
