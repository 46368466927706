import React, { useState } from 'react';
import styles from './Demographic.module.css';
import AnnualIncome from "./AnnualIncome/AnnualIncome";

//import ScreensVisibility from "./ScreensVisibility/ScreensVisibility";
import Location from "./Location/Location";
import OnboardingScreen from "./OnboardingScreen/OnboardingScreen";
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';
import { MdAdd} from 'react-icons/md';

const Demographic = () => {
  const [searchOptionsVisible, setSearchOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [showIncomeForm, setShowIncomeForm] = useState(false);

  const searchOptions = [
    'Gender', 'Education', 'Occupation', 'Annual Income',
    'Location', 'Course Screens'
  ];

  const handleSearchBarClick = () => {
    setSearchOptionsVisible(!searchOptionsVisible);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSearchOptionsVisible(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleAddNewClick = () => {
    // Handle Add New button click action here
    setShowIncomeForm(true);
  };

  return (
    <div>
      {/* Search container */}
      <div className={styles.searchContainer}>
        <div className={styles.searchBox}>
          <AiOutlineSearch className={styles.searchSymbol} />
          <input
            type="text"
            placeholder={selectedOption  || "Search Demographic"}
            value={searchText}
            onClick={handleSearchBarClick}
            onChange={handleSearchInputChange}
          />
          {selectedOption && (
            <div className={styles.clearIcon} onClick={() => setSelectedOption(null)}>
              <AiOutlineClose />
            </div>
          )}
          <div className={styles.arrowContainer} onClick={handleSearchBarClick}>
            <div className={searchOptionsVisible ? styles.arrowRight : styles.arrowDown}></div>
          </div>
          <button className={styles.addButton} onClick={handleAddNewClick}>
            Add New
            <MdAdd className={styles.buttonIcon} />
          </button>
        </div>
        {searchOptionsVisible && (
          <div className={styles.dropdown}>
            {searchOptions.map((option) => (
              <div
                key={option}
                className={styles.option}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Annual Income*/}
      {selectedOption==='Annual Income' && <AnnualIncome showForm={showIncomeForm} setshowForm={setShowIncomeForm} />}

      {/* Location */}
      {selectedOption==='Location' && <Location showForm={showIncomeForm} setShowForm={setShowIncomeForm}/>}

      {/* Onboarding Screen */}
      {selectedOption==='Course Screens' &&  <OnboardingScreen showForm={showIncomeForm} setShowForm={setShowIncomeForm}/>}
    </div>
  );
};

export default Demographic;