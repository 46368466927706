// authReducer.js
const initialState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      
  
      default:
        return state;
    }
  };
  
  export default authReducer;