// // import React from 'react';
// // import './CarrerPage.css';
// // import work from "../../assets/images/workwith.png"; // Replace with the correct path to your image

// // const CarrerPage = () => {
// //   return (
// //     <div className="landing-page">
// //       <div className="join-team">
// //         <h1>Join our team!</h1>
        
// //         <p>
// //           We're growing fast as one of the <a href="#">hottest London startups</a> and are headed 
// //           by experienced founders from two of London's biggest tech success stories.  <a href="#">Recruiters click here</a>
          
// //         </p>
// //         <div className="hero-section">
// //         <img src={work} alt="Desk Setup" className="desk-image" /></div>
       
// //         <button className="cta-button">View Our Open Positions</button>
        
// //       </div>

      

// //       <div className="quote-section">
// //         <p className="quote">
// //           “We will succeed by providing the best service, driven by the most effective operation 
// //           and smartest technology”
// //         </p>
// //         <p className="author">Shivam Solanki - Founder and CEO</p>
// //         <p className="description">
// //           Our operation involves a number of complex challenges, which is why we invest heavily 
// //           in both our technology and our team.
// //         </p>
// //       </div>
// //     </div>
// //   );
// // };

// // export default CarrerPage;
// import React from 'react';
// import './CarrerPage.css';
// import work from "../../assets/images/workwith.png";; // Replace with the correct path to your image

// const CarrerPage = () => {
//   return (
//     <div className="styled-container">
//       <div className="text-content">
//         <h1>Join our team!</h1>
//         <div className="content" >
//         <p >
//           We're growing fast as one of the <a href="#">hottest London startups</a> and are headed 
//           by experienced founders from two of London's biggest tech success stories. 
//           <a href="#">Recruiters click here</a>
//         </p>
//         </div>
//         <button className="cta-button">View Our Open Positions</button>
//       </div>

//       <div className="image-container">
//       <img src={work} alt="Desk Setup" className="desk-image" />
//       </div>
//     </div>
//   );
// };

// export default CarrerPage;
import React from 'react';
import './CarrerPage.css';
import work from "../../assets/images/workwith.png"; 

const CarrerPage = () => {
  return (
    <div className="career-container ">
      <div className="styled-container">
        <div className="text-content">
          <h1>Join our team!</h1>
          <div className="carrercontent">
            <p>
              We're growing fast as one of the <a href="#">hottest London startups</a> and are headed 
              by experienced founders from two of London's biggest tech success stories. 
              <a href="#">Recruiters click here</a><br />
              <button className="carrer-button">View Our Open Positions</button>
            </p>
            
          </div>
     
        </div>

        <div className="image-container">
          <img src={work} alt="Desk Setup" className="desk-image" />
        </div>
      </div>
    </div>
  );
};

export default CarrerPage;
