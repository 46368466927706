import React from 'react'
import "./QandA.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Image1 from "../../assets/images/logo1.png";

const QandA = () => {
  return (
    <div>
      <Navbar />
      <div id="QandA">
          <div className='QandA-heading d-flex justify-content-center align-items-center'>
              <h2>Study Help By</h2>
              <img src={Image1} alt="" srcset="" />
          </div>
          <div className='QandA-form d-flex justify-content-center mb-5'>
            <form action="">
              <div class="input-group">
                <div>
                  <input type="search" className='form-control input-search' id="form1" placeholder='Enter your Question'/>
                </div>
                <button type="button" class="btn">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="container">
                  <div className="row mb-5">
                    <div className="col d-flex justify-content-center">
                      <div
                        className="card border-0 shadow"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body p-0">
                          <div className="d-flex align-items-center justify-content-center border-bottom p-2">
                            <h5 className="card-title">Study</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <div
                        className="card border-0 shadow"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body p-0">
                          <div className="d-flex align-items-center justify-content-center border-bottom p-2">
                            <h5 className="card-title">Study Support 24X7</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <div
                        className="card border-0 shadow"
                        style={{ width: "25rem" }}
                      >
                        <div className="card-body p-0">
                          <div className="d-flex align-items-center justify-content-center border-bottom p-2">
                            <h5 className="card-title">Step By Step Solution</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      <Footer />
    </div>
  )
}

export default QandA
