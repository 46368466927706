
import  "./google_review.css";
import reviews1 from '../../assets/images/reviews1.png';
import reviews2 from '../../assets/images/reviews2.jpeg';
import React, { useEffect, useState } from "react";
import { apiGet } from "../../utils/utils";
import { useLocation } from 'react-router-dom';
import { TESTIMONIAL_LISTING } from "../../config/urls";

const GoogleReview = () => {

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  // const [googleReviewData, setGoogleReviewData] = useState([]);

  // useEffect(() => {
  //   fetchGoogleReviewData();
  // }, []);

  // const fetchGoogleReviewData = () => {
  //   apiGet(TESTIMONIAL_LISTING)
  //     .then((response) => {
  //       setGoogleReviewData(response);
  //       console.log(response);
        
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch Google reviews:", error);
  //       console.log(error);
        
  //     });
  // };
  const [googleReviewData, setGoogleReviewData] = useState([
    {
      id: 1,
      image: reviews1, 
      description: "I am writing to share my positive experience with the Master Python Learning Program at DigiPaath. My journey through this course has been incredibly rewarding.",
      name: "Abhishek",
    },
    {
      id: 2,
      image: reviews2,
      description: "It is a good platform for beginners to learn and those who are willing to learn new technologies from instructors with their guidance...Choose it if you have good enough time . ",
      name: "Prateek",
    },
    {
      id: 3,
      image: reviews2,
      description: "I recently completed the Data Science and Machine course from DigiPaath it was a positive experience overall. The course content was comprehensive and well-structured.",
      name: "Shruti",
    },
    {
      id: 4,
      image: reviews1, 
      description: "Getting course of Power BI from DigiPaath was one of my best decisions. The teaching pattern was awesome that was not in speed everything was done slow and steady.",
      name: "Vikas",
    },
  
  ]);

  return (
    <div className="google-review container-fluid mt-3 pt-3 pb-3" id="google-reviews">
      <div className="google-review-head">
        <div className="container mb-3">
          
          <div className="main-section d-flex justify-content-center align-items-center mb-2">
            <div className="d-flex align-items-end flex-column">
              <div className="blue-line2"></div>
              <div className="blue-line1 mt-1"></div>
            </div>
            <div>
              <div className="blue-line2"></div>
              <div className="blue-line1 mt-1"></div>
            </div>
          </div>
          <div className="card-title-section best-sell-head">
            <h2 className="card-title d-flex justify-content-center">
              Real Reviews by Real People
            </h2>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            {googleReviewData.map((item, index) => (
              <div
                key={item.id}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="d-flex justify-content-center" style={{height:"270px"}}>
                  <div className="carousel-content">
                    <div className="d-flex justify-content-center">
                      <img src={item.image} alt="Reviewer" className="user-image rounded-circle" />
                    </div>
                    <div className="mt-3 text-center review-description">
                      <p style={{color:"black"}}>{item.description}</p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="line"></div>
                    </div>
                    <div className="d-flex justify-content-center mt-3 user-name">
                      <h6>{item.name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="fa-solid fa-arrow-left icon-size"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="fa-solid fa-arrow-right icon-size"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleReview;
