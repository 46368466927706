import { course_images } from "./images";

const coursesData = [
    {
        id: "xJB-WU",
        category: "Programing",
        image: course_images.programing_5,
        course_name: "C & C++",
        description: "This thorough course explains the fundamentals of C and C++ programming languages.",
        rating_count: 2941,
        rating_star: 4.2,
        students: 10450,
        creator: "Infinite Skills",
        updated_date: "9/2019",
        lang: "english",
        actual_price: 49.99,
        discounted_price: 10.99,
        what_you_will_learn: [
            "Design Reusable Object-Oriented Python Classes",
            "How to handle Errors",
            "Work with Python file handling",
            "Apply OOP Concepts to handle complexity"
        ],
        content: [
            "Introduction to Object Oriented Python",
            "Classes",
            "Inhertiance and Polymorphism",
            "Advanced Features",
            "Object Serialization",
            "Efficiency and testing",
            "Conclusion"
        ],
        link:'/cplush-detail',
    },
    {
        id: "xMHCZl",
        category: "Programing",
        image: course_images.programing_1,
        course_name: "Java",
        description: "Java course! In this extensive instruction, acquire the essentials of Java programming technology. From the fundamentals basic grammar and data types to more complicated subjects like Object-Oriented Development and exception management.",
        rating_count: 3059,
        rating_star: 4.4,
        students: 18430,
        creator: "Avinash Jain, The Codex",
        updated_date: "9/2015",
        lang: "english",
        actual_price: 84.99,
        discounted_price: 9.99,
        what_you_will_learn: [
            "Create their own Python Programs",
            "Become an experienced Python Programmer",
            "Parse the Web and Create their own Games"
        ],
        content: [
            "Up and Running With Python",
            "The Basics(Data Types)",
            "Conditions and Loops",
            "Functions!",
            "Classes! (Object Oriented Programming)",
            "File Input/Output",
            "Using Python Modules"
        ],
        link:'/java-detail',

    },
    {
        id: "dtfo9e",
        category: "Programing",
        image: course_images.programing_2,
        course_name: "Javascript",
        description: " JavaScript, the key language for developing websites. Students will study the basic principles of JavaScript coding, including variables, data types, control structures, functions, and arrays.",
        rating_count: 3059,
        rating_star: 4.3,
        students: 184053,
        creator: "Jose Portilla",
        updated_date: "9/2019",
        lang: "english",
        actual_price: 84.99,
        discounted_price: 9.99,
        what_you_will_learn: [
            "Have an intermediate skill level of Python programming.",
            "Use the numpy library to create and manipulate arrays.",
            "Learn how to work with various data formats within python, including: JSON,HTML, and MS Excel Worksheets.",
            "Have a portfolio of various data analysis projects."
        ],
        content: [
            "Intro to Course and Python",
            "Learning Numpy",
            "Data Visualization",
            "Example Projects",
            "Machine Learning",
            "SQL and Python",
            "Web Scraping with Python"
        ],
        link:'/java-script-detail',
    },
    {
        id: "fLroW6",
        category: "Programing",
        image: course_images.programing_3,
        course_name: "PHP",
        description: "The course gives an in-depth review of PHP, an effective server-side programming language widely used in web development.",
        rating_count: 1844,
        rating_star: 4.3,
        students: 7330,
        creator: "Edwin Diaz, Coding Faculty Solutionss",
        updated_date: "5/2022",
        lang: "english",
        actual_price: 84.99,
        discounted_price: 9.99,
        what_you_will_learn: [
            "To learn the python language",
            "To learn the CORE skills to understand any programming language",
        ],
        content: [
            "Programming fundamentals",
            "Getting Started with Python",
            "Python Basics",
            "Conditional structures",
            "Functions",
            "Working with files",
        ],
        link:'/php-detail',
    },
    {
        id: "VwkN_o",
        category: "Programing",
        image: course_images.programing_4,
        course_name: "Python",
        description: "Python introduction for beginners. Learn complete Python from scratch!",
        rating_count: 2780,
        rating_star: 4.3,
        students: 29082,
        creator: "Abrar Hussain",
        updated_date: "9/2019",
        lang: "english",
        actual_price: 29.99,
        discounted_price: 9.99,
        what_you_will_learn: [
            "Create fully functional Python programs",
            "Learn about loop structures and conditionals",
            "Work with Python file handling",
            "Manipulate strings and data"
        ],
        content: [
            "Getting Started",
            "The Basics",
            "User Input",
            "Functions and Files",
            "Classes and Objects",
            "Graphics and Animations",
        ],
        link:'/python-detail',
    },
    {
        id: "qjdYG4",
        category: "web development",
        image: course_images.web_dev_1,
        course_name: "Become a Certified HTML, CSS, JavaScript Web Developer",
        description: "Complete coverage of HTML, CSS, Javascript while you Earn Four Respected Certifications",
        rating_count: 2760,
        rating_star: 4.1,
        students: 33455,
        creator: "Tech Learning Network, Mark Lassoff",
        updated_date: "11/2020",
        lang: "english",
        actual_price: 84.99,
        discounted_price: 9.99,
        link:'/web-dev-20',
        what_you_will_learn: [
            "Prepare for Industry Certification Exam",
            "Hours and Hours of Video Instruction",
            "Over 25 Engaging Lab Exercises",
            "Instructor Available by Email or on the Forums",
            "Comprehensive Coverage of HTML and CSS"
        ],
        content: [
            "Your Develpment Toolbox",
            "Certified HTML5 Specialist 2019",
            "Certified CSS Specialist 2019",
            "JavaScript Specialist 2019",
            "jQuery for Application Development",
            "Everything you wanted to know about formatting text",
            "Hyperlinks - Connecting Pages and Content",
            "Digital Media",
            "Displaying Data in Tables and iFrames",
            "Styling with CSS"
        ]
    },
    {
        id: "3pm9Ab",
        category: "web development",
        image: course_images.web_dev_2,
        course_name: "The Complete 2020 Fullstack Web Developer Course",
        description: "Learn HTML5, CSS3, JavaScript, Python, Wagtail CMS, PHP & MySQL from scratch!",
        rating_count: 6200,
        rating_star: 4.3,
        students: 90360,
        creator: "Kalob Taulien",
        updated_date: "9/2019",
        lang: "english",
        actual_price: 72.99,
        discounted_price: 14.99,
        link:'/web-dev-21',
        what_you_will_learn: [
            "Learn Python from beginner to advanced",
            "Lots of projects, big and small!",
            "Learn PHP for server code execution",
            "Build a Login/Registration/Members-only website, just like Facebook",
            "Learn the LAMP Stack: Linux, Apache, PHP and MySQL",
            "Learn HTML5, CSS3, Vanilla JS (ES6+), Python, Wagtail CMS, PHP and MySQL all from scratch"
        ],
        content: [
            "The Ultimate HTML Developer: Introduction",
            "Getting Started",
            "Advanced HTML",
            "Text Formatting",
            "Fonts and Font styling",
            "Links and their 'states'",
            "Better selectors",
            "Positions",
            "Advanced Selectors",
            "CSS3 Introduction",
            "CSS3 Backgrounds Images",
            "CSS3 Gradients",
            "CSS3 Text Formatting",
            "CSS3 Transitions",
            "CSS3 Animations",
            "CSS3 Flexbox",
            "Responsive Web Design"
        ]
    },
    {
        id: "RuUs0x",
        category: "web development",
        image: course_images.web_dev_3,
        course_name: "Introduction to Web Development",
        description: "Are you Interested in Learning Web Development? Enroll in this Free course for a Dynamic Introduction to the Profession!",
        rating_count: 1740,
        rating_star: 4,
        students: 22309,
        creator: "Tech Learning Network, Mark Lassoff",
        updated_date: "10/2020",
        lang: "english",
        actual_price: 19.99,
        discounted_price: 8.99,
        link:'/web-dev-22',
        what_you_will_learn: [
            "Understand the technologies used in web development",
            "Apply HTML document structure",
            "Add images to a web page",
            "Understand the HTML/CSS color model",
            "Alter CSS text properties",
            "Know the role of Javascript programming in web development",
            "Create a basic Javascript program to collect data",
            "Understand the next steps to learning professional web development skills"
        ],
        content: [
            "Tools and HTML",
            "CSS",
            "Javascript",
            "Bonus Lecture",
        ]
    },
    {
        id: "e_0hBc",
        category: "web development",
        image: course_images.web_dev_4,
        course_name: "Software quality testing - manual testing",
        description: "Examine the human and computerised approaches for software quality testing. Discuss the preparation, execution, reporting, and defect handling of tests. Acquire knowledge of industry-standard tools and methods to guarantee that software fulfills quality criteria, increasing user contentment and product reliability.",
        rating_count: 1577,
        rating_star: 4.8,
        students: 33100,
        creator: "Evan Kimbrell",
        updated_date: "8/2022",
        lang: "english",
        actual_price: 70.99,
        discounted_price: 14.99,
        link:'/web-dev-23',
        what_you_will_learn: [
            "Understand how development stacks work and pick which stack is ideal for you",
            "Decide between hiring in-house employees or contractors for your business",
            "Choose a winning combination of traits that you want your business to convey",
            "Find cheap and professional ways of creating an online presence",
            "Master the biggest platforms for contracting: Freelancer and Upwork",
            "Create and organize a professional and effective portfolio",
            "Utilize 15 different strategies to attract potential clients",
            "Use tools like Proposify and Nusii to create and streamline proposals"
        ],
        content: [
            "Introduction",
            "The Essentials",
            "Planning your strategy",
            "Setting up your presence",
            "Finding subcontractors",
            "Building a portfolio",
            "Getting clients",
            "Building proposals",
            "Managing clients",
            "Best practices"
        ]
    },
    {
        id: "z9dxdw",
        category: "web development",
        image: course_images.web_dev_5,
        course_name: "software quality testing MANUAL + SELENIUM",
        description: "Learn manual and automated software quality testing techniques. Covers test planning, execution, defect tracking, Selenium automation, frameworks, and integration with CI/CD pipelines.",
        rating_count: 2941,
        rating_star: 4.6,
        students: 10450,
        creator: "Brad Hussey",
        updated_date: "3/2022",
        lang: "english",
        actual_price: 50.99,
        discounted_price: 11.99,
        link:'/web-dev-24',
        what_you_will_learn: [
            "Build 23+ custom web design & development projects by hand",
            "Plan, design and code your very own self-directed website project from scratch to launch!",
            "Code websites & applications with HTML5 & CSS3",
            "Comfortably use Adobe Photoshop for photo manipulation, graphics creation & design",
            "Design a professional and modern business card in Adobe Photoshop",
            "Learn visual design fundamentals for beginners",
            "Learn how to choose the correct font combinations for your projects",
            "Design, build and manage custom database-driven websites with MySQL"
        ],
        content: [
            "Hey! Welcome to the course!",
            "Introduction to Visual Design for Web Designers & Web Developers",
            "Designing Websites, Graphics & Interfaces with Figma",
            "Introduction to Adobe Photoshop",
            "Introduction to Web Design (and more Photoshop)",
            "Advanced Web Design Challenge",
            "Introduction to HTML",
            "Intermediate HTML",
            "Advanced HTML & HTML5",
            "Expert HTML & HTML5"
        ]
    },
    {
        id: "X8Pg1X",
        category: "data science",
        image: course_images.data_science,
        course_name: "Data Science A-Z™: Real-Life Data Science Exercises Included",
        description: "Learn Data Science step by step through real Analytics examples. Data Mining, Modeling, Tableau Visualization and more!",
        rating_count: 32103,
        rating_star: 4.5,
        students: 204985,
        creator: "Kirill Eremenko, Ligency Team",
        updated_date: "9/2022",
        lang: "english",
        actual_price: 77.99,
        discounted_price: 16.99,
        what_you_will_learn: [
            "Successfully perform all steps in a complex Data Science project",
            "Perform Data Mining in Tableau",
            "Apply Ordinary Least Squares method to Create Linear Regressions",
            "Assess the Adjusted R-Squared for all types of models",
            "Create a Multiple Linear Regression (MLR)",
            "Interpret coefficients of an MLR",
            "Use Backward Elimination, Forward Selection, and Bidirectional Elimination methods to create statistical models",
            "Read a Confusion Matrix"
        ],
        content: [
            "What is Data Science?",
            "Part 1: Visualisation",
            "Introduction to Tableau",
            "Advanced Data Mining with Tableau",
            "Stats Refresher",
            "Simple Linear Regression",
            "Model maintenance",
            "SQL Programming for Data Science",
        ]
    },
    {
        id: "9Ui0L1",
        category: "data science",
        image: course_images.data_science,
        course_name: "Machine Learning, Data Science and Deep Learning with Python",
        description: "Complete hands-on machine learning tutorial with data science, Tensorflow, artificial intelligence, and neural networks",
        rating_count: 27687,
        rating_star: 4.5,
        students: 166929,
        creator: "Sundog Education by Frank Kane",
        updated_date: "2/2022",
        lang: ["english", "french"],
        actual_price: 87.99,
        discounted_price: 14.99,
        what_you_will_learn: [
            "Build artificial neural networks with Tensorflow and Keras",
            "Classify images, data, and sentiments using deep learning",
            "Data Visualization with MatPlotLib and Seaborn",
            "Classify data using K-Means clustering, Support Vector Machines (SVM), KNN, Decision Trees, Naive Bayes, and PCA",
            "Build a movie recommender system using item-based and user-based collaborative filtering",
            "Interpret coefficients of an MLR",
        ],
        content: [
            "Statistics and Probability Refresher, and Python Practice",
            "Predictive Models",
            "Machine Learning with Python",
            "Recommender Systems",
            "More Data Mining and Machine Learning Techniques",
            "Dealing with Real-World Data",
            "Apache Spark: Machine Learning on Big Data",
            "Deep Learning and Neural Networks",
        ]
    },
    {
        id: "TKGumi",
        category: "data science",
        image: course_images.data_science,
        course_name: "Data Science: Deep Learning and Neural Networks in Python",
        description: "The MOST in-depth look at Complete tutorial with data science, Tensorflow, artificial intelligence, and neural networks",
        rating_count: 8513,
        rating_star: 4.8,
        students: 50184,
        creator: "Lazy Programmer Inc.",
        updated_date: "8/2022",
        lang: ["english", "Portuguese"],
        actual_price: 90.99,
        discounted_price: 19.99,
        what_you_will_learn: [
            "Learn how Deep Learning REALLY works (not just some diagrams and magical black box code)",
            "Code a neural network from scratch in Python and numpy",
            "Describe different types of neural networks and the different types of problems they are used for",
            "Create a neural network with an output that has K > 2 classes using softmax",
            "Install TensorFlow",
        ],
        content: [
            "Preliminaries: From Neurons to Neural Networks",
            "Training a neural network",
            "Practical Machine Learning",
            "TensorFlow, exercises, practice, and what to learn next",
            "Project: Facial Expression Recognition",
            "Backpropagation Supplementary Lectures",
            "Higher-level Discussion",
        ]
    },
    {
        id: "oETsMx",
        category: "data science",
        image: course_images.data_science_4,
        course_name: "R Programming A-Z™: R For Data Science With Real Exercises!",
        description: "Learn Programming In R And R Studio. Data Analytics, Data Science, Statistical Analysis, Packages, Functions, GGPlot2",
        rating_count: 47264,
        rating_star: 4.8,
        students: 237914,
        creator: "Kirill Eremenko, Ligency Team",
        updated_date: "11/2021",
        lang: "english",
        actual_price: 45.99,
        discounted_price: 6.99,
        what_you_will_learn: [
            "Learn to program in R at a good level",
            "Learn the core principles of programming",
            "Learn how to create variables",
            "Learn how to create a while() loop and a for() loop in R",
            "Learn the matrix() function, learn rbind() and cbind()",
            "Learn how to customize R studio to suit your preferences"
        ],
        content: [
            "Hit The Ground Running",
            "Core Programming Principles",
            "Fundamentals of R",
            "Matrices",
            "Data Frames",
            "Advanced Visualization with GGPlot2",
            "Homework Solutions",
        ]
    },
    {
        id: "-aWjaj",
        category: "data science",
        image: course_images.data_science,
        course_name: "Data Science and Machine Learning Bootcamp with R",
        description: "Learn how to use the R programming language for data science and machine learning and data visualization!",
        rating_count: 32103,
        rating_star: 5,
        students: 204985,
        creator: "Jose Portilla",
        updated_date: "12/2020",
        lang: ["english", "arabic"],
        actual_price: 77.99,
        discounted_price: 16.99,
        what_you_will_learn: [
            "Program in R",
            "Create Data Visualizations",
            "Use R to manipulate data easily",
            "Use R for Data Science",
            "Use R for Data Analysis",
            "Use R to handle csv,excel,SQL files or web scraping",
            "Use R for Machine Learning Algorithms"
        ],
        content: [
            "Course Best Practices",
            "Windows Installation Set-Up",
            "Mac OS Installation Set-Up",
            "Linux Installation",
            "Development Environment Overview",
            "Introduction to R Basics",
            "R Data Frames",
        ]
    },
    {
        id: "xxxbMD",
        category: "aws",
        image: course_images.aws_1,
        course_name: "Advanced Architecting on AWS",
        description: "Videos, labs & practice exams - AWS Certified (Solutions Architect, Developer, SysOps Administrator, Cloud Practitioner)",
        rating_count: 21046,
        rating_star: 4.2,
        students: 134932,
        creator: "BackSpace Academy, Paul Coady",
        updated_date: "12/2021",
        lang: ["english", "arabic"],
        actual_price: 69.99,
        discounted_price: 11.99,
        what_you_will_learn: [
            "You will be fully prepared for the",
            "AWS Certified Solutions Architect",
            "Associate, AWS Certified Developer",
            "Associate and AWS Certified SysOps ...",
        ],
        content: [
            "AWS Cerified Cloud Practitioner",
            "AWS Certified Associate Core Knowledge",
            "AWS Certified Developer Associate - Final Preparation",
            "AWS Certified Solutions Architect Associate - Final Preparation",
            "AWS Certified SysOps Administrator Associate - Final Preparation",
        ],
        link:'/aws-150',
    },
    
    {
        id: "cIdBd2",
        category: "aws",
        image: course_images.aws_2,
        course_name: "Architecting On AWS",
        description: "All you need to master AWS Certified Security Specialty certification.",
        rating_count: 5387,
        rating_star: 4.5,
        students: 33385,
        creator: "Zeal Vora",
        updated_date: "12/2022",
        lang: ["english", "french"],
        actual_price: 34.99,
        discounted_price: 10.99,
        what_you_will_learn: [
            "Will be prepared to give AWS Certified Security Specialty Exam",
            "Gain deep insights about Enterprise grade Security implementation.",
            "You will be able to Master the Security aspect of AWS",
            "Will be able to detect attacks and protect the AWS infrastructure from Hackers.",
        ],
        content: [
            "Getting started with the course",
            "Domain 1 - Incident Response",
            "Domain 2 - Logging & Monitoring",
            "Domain 3 - Infrastructure Security",
            "Domain 4 - Identity & Access Management",
            "Domain 5 - Data Protection"
        ],
        link:'/aws-151',
    },
    {
        id: "NkZeqm",
        category: "aws",
        image: course_images.aws_3,
        course_name: "Architecting on AWS accelerator",
        description: "AWS Associate Exam - Master Foundations. Join Live Study Group Q&A!",
        rating_count: 1603,
        rating_star: 4.5,
        students: 10933,
        creator: "Chandra Lingam",
        updated_date: "8/2022",
        lang: "english",
        actual_price: 71.99,
        discounted_price: 15.99,
        what_you_will_learn: [
            "Breadth of understanding of various AWS services",
            "How to Integrate Services into a Complete Solution",
            "Hands-on Scenario Based Learning",
            "Weekly Study Group Discussion Sessions",
            "Concise and Precise Preparation for AWS Solutions Architect Certification"
        ],
        content: [
            "Housekeeping",
            "Infrastructure, Pricing, Support - Review",
            "Networking Primer",
            "Virtual Private Cloud (VPC) - Networking Infrastructure",
            "Elastic Compute Cloud (EC2) - Compute Service",
            "Monitoring Resource in Cloud"
        ],
        link:'/aws-152',

    },
    {
        id: "ZkZeqm",
        category: "aws",
        image: course_images.aws_1,
        course_name: "Running containers on amazon elastic Kubernetes service",
        description: "AWS Associate Exam - Master Foundations. Join Live Study Group Q&A!",
        rating_count: 1603,
        rating_star: 4.5,
        students: 10933,
        creator: "Chandra Lingam",
        updated_date: "8/2022",
        lang: "english",
        actual_price: 71.99,
        discounted_price: 15.99,
        what_you_will_learn: [
            "Breadth of understanding of various AWS services",
            "How to Integrate Services into a Complete Solution",
            "Hands-on Scenario Based Learning",
            "Weekly Study Group Discussion Sessions",
            "Concise and Precise Preparation for AWS Solutions Architect Certification"
        ],
        content: [
            "Housekeeping",
            "Infrastructure, Pricing, Support - Review",
            "Networking Primer",
            "Virtual Private Cloud (VPC) - Networking Infrastructure",
            "Elastic Compute Cloud (EC2) - Compute Service",
            "Monitoring Resource in Cloud"
        ],
        link:'/aws-153',

    },
    {
        id: "PkZeqm",
        category: "aws",
        image: course_images.aws_1,
        course_name: "AWS cloud for finance professionals",
        description: "AWS Associate Exam - Master Foundations. Join Live Study Group Q&A!",
        rating_count: 1603,
        rating_star: 4.5,
        students: 10933,
        creator: "Chandra Lingam",
        updated_date: "8/2022",
        lang: "english",
        actual_price: 71.99,
        discounted_price: 15.99,
        what_you_will_learn: [
            "Breadth of understanding of various AWS services",
            "How to Integrate Services into a Complete Solution",
            "Hands-on Scenario Based Learning",
            "Weekly Study Group Discussion Sessions",
            "Concise and Precise Preparation for AWS Solutions Architect Certification"
        ],
        content: [
            "Housekeeping",
            "Infrastructure, Pricing, Support - Review",
            "Networking Primer",
            "Virtual Private Cloud (VPC) - Networking Infrastructure",
            "Elastic Compute Cloud (EC2) - Compute Service",
            "Monitoring Resource in Cloud"
        ],
        link:'/aws-154',

    },
    {
        id: "NkZeqq",
        category: "design",
        image: course_images.design_2,
        course_name: "Canva + Photoshop",
        description: "This Adobe Photoshop Beginner Course will teach a Beginner Photoshop user all essentials of Adobe Photoshop CC",
        rating_count: 5066,
        rating_star: 4.9,
        students: 143812,
        creator: "Manfred Werner",
        updated_date: "7/2020",
        lang: ["english", "spanish"],
        actual_price: 13.99,
        discounted_price: 5.99,
        what_you_will_learn: [
            "What Photoshop is & how to get into it!",
            "Customize the programs layout & feel comfortable",
            "Understand layers & it's panels",
            "Understand adjustment layers how to use them",
            "Understand setting color profiles, save PSD files & web files",
            "Understand cutting objects out, fixing skin & manipulation"
        ],
        content: [
            "Photoshop Interface & Workspace - For Beginners",
            "Important Photoshop Tools - For Beginners",
            "Retouching Class - Photoshop Portrait Retouching Course",
            "Retouching Class 2 - Photoshop Portrait Retouching Course",
            "Retouching Class 3 - The Instagramer",
            "Bonus Lectures"
        ],
        link:'/photoshop',
    },
    {
        id: "bLZepe",
        category: "design",
        image: course_images.design_1,
        course_name: "Canva for 1 month",
        description: "Get a job in UX and build your user research and UX design skills with this hands-on user experience training course.",
        rating_count: 6250,
        rating_star: 4.4,
        students: 22090,
        creator: "David Travis",
        updated_date: "4/2020",
        lang: ["english", "italian"],
        actual_price: 79.99,
        discounted_price: 16.99,
        what_you_will_learn: [
            "Bake UX into your workflow by following a proven, user centred design framework.",
            "Moderate a usability test and prioritise the observations.",
            "Uncover and describe users’ mental models.",
            "Design and conduct online and offline card sorting sessions.",
            "Develop cheap, throwaway prototypes to get quick and frequent feedback from your users."
        ],
        content: [
            "Going where the action is: Understading users in context",
            "How to get niche quick",
            "UX Design Activities - Build your UX Portfolio",
            "What can a London bus teach us about usability?",
            "Beyong 'easy to use': Measuring the user experience",
            "Site structure and navigation: Finding is the new doing",
            "Interaction design: Simple rules for designing simple screens"
        ],
        link:'canva',
    },
    {
        id: "RkZecd",
        category: "marketing",
        image: course_images.marketing_1,
        course_name: "Email Marketing - Become a Freelance Email Marketer, your own boss",
        description: "Build a successful freelance copywriting business - turn basic writing skills into a paycheck.",
        rating_count: 2779,
        rating_star: 4.8,
        students: 32657,
        creator: "Len Smith, Sean Kaye",
        updated_date: "4/2018",
        lang: "english",
        actual_price: 88.99,
        discounted_price: 16.99,
        what_you_will_learn: [
            "Part one – everything you need for a fast-track start, including your first article",
            "Part three – the essential skills that mark out a real pro",
            "Part two – the copywriter’s ‘how to’: sales copy, expert copy, and more!",
            "Part four – how to boost your income and turn your new website into a money magnet",
        ],
        content: [
            "Choose projects whre you feel comfortable",
            "Back to basics II",
            "How to boost your copywriting income",
            "More on pricing your work",
            "This and that",
            "Conclusion"
        ],
        link:'/email-marketing'
    },
    {
        id: "NrZ_qd",
        category: "marketing",
        image: course_images.marketing_2,
        course_name: "Digital Marketer",
        description: "This Digital Marketing course provides a comprehensive understanding of key digital marketing concepts and strategies, including SEO, SEM, social media marketing, content marketing, and analytics. Participants will learn to develop effective digital marketing campaigns that drive traffic, engagement, and conversions across various online channels.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        what_you_will_learn: [
            "Identify who your Ideal Clients are so you know exactly who to market and sell to",
            "Learn how to create a Signature Program so you can stop selling your time or creating spec proposals",
            "Learn how to write Compelling Marketing Copy you can use to attract your ideal clients",
            "Learn how to create a Financially Viable Business and understand exactly what it will take to reach your financial goals",
            "Learn how to easily create your own Wordpress Coaching or Consulting Website"
        ],
        content: [
            "Building Your Business & Marketing Foundation",
            "Packaging Your Services",
            "Branding Yourself & Your Business",
            "Marketing Message Development",
            "Getting clients: Objectives & strategies",
            "Markting Yourself Online: Website Development",
            "Marketing Implementation: Create a 90-Day Marketing Plan",
        ],
        link:'digital-marketing'
    },
    // {
    //     id: "Sap_first",
    //     category: "SAP",
    //     image: course_images.sap_sec,
    //     course_name: "SAP First Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Sap_sec",
    //     category: "SAP",
    //     image: course_images.sap_sec,
    //     course_name: "SAP Second Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Sap_third",
    //     category: "SAP",
    //     image: course_images.sap_sec,
    //     course_name: "SAP Third Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Sap_fourth",
    //     category: "SAP",
    //     image: course_images.sap_sec,
    //     course_name: "SAP Fourth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Sap_fifth",
    //     category: "SAP",
    //     image: course_images.sap_sec,
    //     course_name: "SAP Fifth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    {
        id: "microsoft_first",
        category: "Microsoft",
        image: course_images.microsoft_365,
        course_name: "Microsoft 365 Fundamental",
        description: "This training presents an overview of Microsoft 365, which is an integrated cloud platform combining industry-leading productivity tools together with intelligent cloud services, & world-class security. You'll study core knowledge on the considerations & advantages of embracing cloud services & the Software as a Service - SaaS, cloud model, with a specific focus on Microsoft 365 cloud service offerings. You'll begin by learning about cloud principles, including an overview of cloud computing. You will be introduced to Microsoft 365 & understand how Microsoft 365 technologies enhance collaboration, boost productivity, & optimize communications. The training then analyzes how security, compliance, privacy, & trust are handled in Microsoft 365, & it concludes with a review of Microsoft 365 subscriptions, billing, licenses, & support",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/microsoft-108'
    },
    {
        id: "microsoft_second",
        category: "Microsoft",
        image: course_images.microsoft_azure,
        course_name: "Microsoft Azure AI Solution",
        description: "Software developers who wish to create AI-infused apps that make use of Microsoft Bot Framework, Azure Cognitive Services, and Azure Cognitive Search should read Designing & Implementing an Azure AI Solution. Python or C# will be the programming language used in the course.Time: Four Days",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/microsoft-100'
    },
    {
        id: "microsoft_third",
        category: "Microsoft",
        image: course_images.microsoft_dynamic_365,
        course_name: "Microsoft Dynamics 365 Sales",
        description: "Microsoft Dynamics 365 Sales is a complete solution for managing customer and potential customer interactions",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/microsoft-120'
    },
    {
        id: "microsoft_fourth",
        category: "Microsoft",
        image: course_images.microsoft_power_platform,
        course_name: "Microsoft Power Platform Developer",
        description: "The Microsoft Power Platform streamlines, automates, and transforms business tasks and processes to assist organizations in improving their operations. Students will learn how to create Power Apps, automate flows, and extend the platform to meet business requirements and resolve challenging business issues in this course.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/microsoft-102'
    },
    {
        id: "microsoft_fifth",
        category: "Microsoft",
        image: course_images.microsoft_365,
        course_name: "Microsoft 365 Identity & Services",
        description: "This training covers three fundamental parts of Microsoft 365 enterprise administration - Microsoft 365 tenant & service management, Microsoft 365 management, & Microsoft 365 identity management. In Microsoft 365 tenancy & service management, you'll cover all the key components that must be planned for when designing your Microsoft 365 tenant. Once this planning step is complete.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/microsoft-117'
    },
    // {
    //     id: "Google_first",
    //     category: "Google",
    //     image: course_images.google_first,
    //     course_name: "Google First Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Google_second",
    //     category: "Google",
    //     image: course_images.google_first,
    //     course_name: "Google Second Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Google_third",
    //     category: "Google",
    //     image: course_images.google_first,
    //     course_name: "Google Third Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Google_fourth",
    //     category: "Google",
    //     image: course_images.google_first,
    //     course_name: "Google Fourth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Google_fifth",
    //     category: "Google",
    //     image: course_images.google_first,
    //     course_name: "Google Fifth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Oracle_first",
    //     category: "Oracle",
    //     image: course_images.oracle,
    //     course_name: "Oracle First Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Oracle_second",
    //     category: "Oracle",
    //     image: course_images.oracle,
    //     course_name: "Oracle Second Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Oracle_third",
    //     category: "Oracle",
    //     image: course_images.oracle,
    //     course_name: "Oracle Third Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Oracle_fourth",
    //     category: "Oracle",
    //     image: course_images.oracle,
    //     course_name: "Oracle Fourth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    // {
    //     id: "Oracle_fifth",
    //     category: "Oracle",
    //     image: course_images.oracle,
    //     course_name: "Oracle Fifth Course",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    // },
    {
        id: "Snowflake_first",
        category: "Snowflake",
        image: course_images.snowflake_first,
        course_name: "Snowflake Snowpark DataFrame Programming Training",
        description: "This one-day session, targeted for professionals designing DataFrame data solutions in Snowflake, covers fundamental Snowpark ideas.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/snowflake-200'
    },
    {
        id: "Snowflake_second",
        category: "Snowflake",
        image: course_images.snowflake_first,
        course_name: "Snowflake Performance Automation and Tuning",
        description: "This three-day accelerated training provides important performance features, Snowflake best practices, and tuning strategies aimed to enable participants",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/snowflake-201'
    },
    {
        id: "Snowflake_third",
        category: "Snowflake",
        image: course_images.snowflake_first,
        course_name: "Snowflake New Features Training – Private",
        description: "The one-day, high-intensity workshop will strengthen your working understanding of the platform and widen your Snowflake palette.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/snowflake-202'
    },
    {
        id: "Snowflake_fourth",
        category: "Snowflake",
        image: course_images.snowflake_first,
        course_name: "Snowflake Fundamentals 4-Day – Private",
        description: "This 4-day training covers the key principles, design considerations, and Snowflake best practices intended for important stakeholders working on the Snowflake Cloud Data Platform.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/snowflake-203'
    },
    {
        id: "Snowflake_fifth",
        category: "Snowflake",
        image: course_images.snowflake_first,
        course_name: "Snowflake Data Governance Training – Private",
        description: "This 1-day Snowflake Data Governance add-on training equips attendees with practical concepts and skills to properly apply Snowflake data governance capabilities into your organization’s data governance program. ",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/snowflake-204'
    },
    {
        id: "HR_Training_first",
        category: "HR Training",
        image: course_images.hr_first,
        course_name: "HR Fundamentals",
        description: "The HR Fundamentals course offers a comprehensive introduction to the core principles and practices of human resource management.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/hr-50'
    },
    {
        id: "HR_Training_second",
        category: "HR Training",
        image: course_images.hr_second,
        course_name: "HR Generalist",
        description: "The HR Generalist course equips participants with the diverse skill set required to excel in HR roles.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/hr-51'
    },
    {
        id: "HR_Training_third",
        category: "HR Training",
        image: course_images.hr_third,
        course_name: "HR Analytics",
        description: "The HR Analytics course delves into the data-driven approach to human resource management.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/hr-52'
    },
    {
        id: "HR_Training_fourth",
        category: "HR Training",
        image: course_images.hr_fourth,
        course_name: "HR Social Compliance",
        description: "HR Social Compliance delves into the critical aspect of ensuring ethical and socially responsible practices within organizations. Participants will explore topics such as diversity and inclusion, workplace ethics, labor laws, and corporate social responsibility.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/hr-53'
    },
    {
        id: "HR_Training_fifth",
        category: "HR Training",
        image: course_images.hr_fifth,
        course_name: "HR Payroll Specialist",
        description: "The HR Payroll Specialist course delves into the intricacies of payroll processing, tax regulations, and compliance.",
        rating_count: 963,
        rating_star: 3.8,
        students: 11848,
        creator: "Debbie LaChusa",
        updated_date: "4/2022",
        lang: "english",
        actual_price: 40.99,
        discounted_price: 10.99,
        link:'/hr-54'
    },
    // {
    //     id: "HR_Training_sixth",
    //     category: "HR Training",
    //     image: course_images.hr_sixth,
    //     course_name: "All 5 HR Certifications",
    //     description: "Learn a Proven, Step-by-Step Process You Can Use to Package, Brand, Market, & Sell Your Coaching or Consulting Services",
    //     rating_count: 963,
    //     rating_star: 3.8,
    //     students: 11848,
    //     creator: "Debbie LaChusa",
    //     updated_date: "4/2022",
    //     lang: "english",
    //     actual_price: 40.99,
    //     discounted_price: 10.99,
    //     link:'/hr-56'
    // }
];


export default coursesData;