import React from 'react'
import NavBar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import BecomePartner from '../BecomePartner/becomePartner';
import PartneringStrategy from '../PartneringStrategy/partneringStrategy';
import PartnerType from '../PartnerType/partnerType';
import GoogleReview from '../GoogleReview/Google_review';
const PartnerPage = () => {
    return (
       
       <>
       <NavBar />
       <BecomePartner />
       <PartneringStrategy />
       <PartnerType />
      <GoogleReview />
      <Footer />
       
       </>
    )
  }
  
  export default PartnerPage