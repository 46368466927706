
import React, { useState } from "react";
import styles from "./Navbar.module.css";
import "./navbar.css";
import Image1 from "../../assets/images/logo1.png";

function NavBar() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div>
     
      <nav className={styles.navbar} style={{paddingTop:"15px", paddingBottom:"15px"}}>
        <div className={styles.container}>
          <img src={Image1} alt="Logo" className={styles.logo} />
          <ul className={`${styles.navLinks} ${styles.desktopMenu}`}>
            <li><a href="/">Home</a></li>
            <li><a href="/corporatetraining">Corporate Training</a></li>
            <li><a href="/courses-link">Course</a></li>
            <li><a href="/internship">Internship</a></li>
            <li><a href="/work">Carrer</a></li>
            {/* <li className={`nav-item dropdown-center`}>

             <a className={`nav-link dropdown-toggle`} aria-current="page" data-bs-toggle="dropdown" aria-expanded="false">

               Jobs

             </a>

             <ul className="dropdown-menu">

               <li><a className={`dropdown-item`} href="campus-ambassador">Campus Ambassador</a></li>

               <li><a className={`dropdown-item`} href="promotion-executive">Promotion Executive</a></li>

             </ul>

           </li> */}
            <li><a href="/contact-us">Contact Us</a></li>
            <li><a href="/modal-link">Get Started</a></li>
          </ul>
          <button className={styles.homebutton} onClick={toggleSidebar}>
            &#9776;
          </button>
        </div>
      </nav>

      {/* Sidebar for Mobile */}
      <div
        className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ""}`}
      >
        <button className={styles.closebtn} onClick={closeSidebar}>
          &times;
        </button>
        <ul className={styles.navLinks1} >
          <li><a href="/">Home</a></li>
          <li><a href="/corporatetraining">Corporate Training</a></li>
          <li><a href="/courses-link">Course</a></li>
          <li><a href="/internship">Internship</a></li>
          <li><a href="/work">Career</a></li>
          {/* <li className={`nav-item dropdown-center`}>

             <a className={`nav-link dropdown-toggle`} aria-current="page" data-bs-toggle="dropdown" aria-expanded="false">

               Jobs

             </a>

             <ul class="dropdown-menu">

               <li><a class="dropdown-item " href="campus-ambassador">Campus Ambassador</a></li>

               <li><a class="dropdown-item" href="promotion-executive">Promotion Executive</a></li>

             </ul>

           </li> */}
          <li><a href="/contact-us">Contact Us</a></li>
          <li><a href="/modal-link">Get Started</a></li>
        </ul>
      </div>

      {/* Overlay */}
      {isSidebarOpen && <div className={styles.overlay} onClick={closeSidebar}></div>}
    </div>
  );
}

export default NavBar;

